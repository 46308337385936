.bg-video-component {
   position: relative;
   height: 80vh;
   max-height: 600px;
   box-sizing: border-box;
   overflow: hidden;
   background: rgba(0, 0, 0, 0.3);
   z-index: 0;

   video {
      position: absolute;
      z-index: -2;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);

      .ua-mobile & {
        display: none;
      }
   }

   &.rvrbd {
      .ua-mobile & {
         background: url("/img/case-studies/riverbed/video/webenertia-riverbed-video-poster.jpg#{$assetVersion}") no-repeat center;
         background-size: cover;
      }
   }
   &.acmtrx {
      .ua-mobile & {
         background: url("/img/case-studies/acemetrix/video/video-bg-mbl.jpg#{$assetVersion}") no-repeat center;
         background-size: cover;
      }
   }

   .max-container {
      &.rvrbd { //riverbed case study
         position: relative;
         height: 80vh;
         max-height: 760px;
         box-sizing: border-box;
         .txt-holder {
            position: absolute;
            bottom: 80px;
            left: 0;
            h3 {
               text-transform: uppercase;
               color: $white;
               font-size: 70px;

               span {
                  font-size: 76px;
               }

               @media screen and (max-width: 1100px) {
                  font-size: 50px;
                  span {
                     font-size: 54px;
                  }
               }
               @media screen and (max-width: 900px) {
                  font-size: 40px;
                  span {
                     font-size: 43px;
                  }
               }
               @media screen and (max-width: 700px) {
                  font-size: 34px;
                  span {
                     font-size: 36px;
                  }
               }
            }
         }
      }
      &.acmtrx { //acemetrix case study
        margin: 0 auto;

        .txt-holder {
          text-align: center;
          @media screen and (max-width: 1300px) {
            margin: 0 40px;
          }
          @media screen and (max-width: 980px) {
            text-align: center;
            max-width: 1000px;
            margin: 0 auto;
            position: absolute;
            top: 50%;
            width: 100%;
            -webkit-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
          }

          @media screen and (max-width: 1000px) {
             padding: 80px 40px;
          }
          @media screen and (max-width: 700px) {
             padding: 40px;
          }

          h2 {
             color: $white;
             font-size: 60px;
             line-height: 1.1;
             margin-bottom: 10px;
             @media screen and (max-width: 1100px) {
                font-size: 50px;
             }
             @media screen and (max-width: 900px) {
                font-size: 40px;
             }
             @media screen and (max-width: 700px) {
                font-size: 34px;
             }
          }
          p {
             color: $white;
             font-size: 34px;
             margin-bottom: 0;
             @media screen and (max-width: 1100px) {
                font-size: 26px;
             }
             @media screen and (max-width: 700px) {
                font-size: 22px;
             }
          }
        }
      }
   }

   @media screen and (max-width: 1000px) {
      max-height: 500px;
      .max-container.rvrbd {
         max-height: 500px;
      }
   }
   @media screen and (max-width: 676px) {
      max-height: 405px;
      .max-container.rvrbd {
         max-height: 405px;
         .txt-holder {
            bottom: 50px;
         }
      }
   }
}
