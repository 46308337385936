//.case-rspnsv {
  //padding: 80px 0;

  .hortonworks {
    svg {
      width: 90%;
      margin: 0 5% 40px;
    }
  }

  .riverbed-devices,
  .acemetrix-devices {
    img {
      margin: 0 auto;
      width: 100%;
      max-width: 840px;
    }

    .txt-holder {
      max-width: 80%;
      margin: 0 auto 10px;
    }
  }

  .animated-devices {
    .devices-holder {
      position: relative;

      img {
        width: 100%;
      }

      .phone {
        position: absolute;
        left: 6%;
        bottom: 4%;
        max-width: 14%;
        z-index: 3;
      }

      .tablet {
        position: absolute;
        left: 16%;
        bottom: 2%;
        max-width: 30%;
        z-index: 2;
      }

      .laptop {
        position: absolute;
        left: 22%;
        bottom: 6%;
        max-width: 72%;
        z-index: 1;
      }
    }

    .txt-holder {
      max-width: 80%;
      margin: 0 auto 10px;
    }
  }

  .riverbed-devices {
    .devices-holder {
      position: relative;
      padding-top: 20px;

      img {
        width: 100%;
        max-width: 100%;
      }

      .phone {
        position: absolute;
        left: 28%;
        bottom: 4%;
        max-width: 16%;
        z-index: 3;
      }

      .tablet {
        position: absolute;
        left: 10%;
        bottom: 2%;
        max-width: 30%;
        z-index: 2;
      }

      .desktop {
        position: absolute;
        left: 26%;
        bottom: 6%;
        max-width: 58%;
        z-index: 1;

        .ua-mobile & {
          display: none;
        }

        &.only-mbl {
          display: none;

          .ua-mobile & {
            display: block;
      }
    }
  }

      .video-elm {
        position: absolute;
        left: 26.6%;
        bottom: 37%;
        max-width: 56%;
        z-index: 0;
      }
    }
  }

  .acemetrix-devices {
    img {
      max-width: 960px;
    }
  }
//}

.iphone-grid {
  padding: 34px 0 0;

  .col {
    width: 33.333%;
    float: left;
    padding: 0 3% 20px;
    box-sizing: border-box;
    position: relative;

    img {
      width: 100%;
    }

    .gif-holder {
      position: absolute;
      top: 11%;
      left: 19.6%;
      width: 61%;
      opacity: 1;
    }

    &:hover {
    }
  }

  .txt-holder {
    max-width: 80%;
    margin: 50px auto 10px;
  }

  @media screen and (max-width: 520px) {
    padding: 14px 0 0;

    .txt-holder {
      margin: 0 auto 20px;
    }
  }
}

.case-twothrd {
  width: 70%;
  display: inline-block;
  vertical-align: top;
}

/*
.case-twothrd img {
  width: 100%;
}
*/

/*
.case-twothrd.ng-mrg {
  margin-bottom: -80px;
}
*/

/*
.case-twothrd.ng-mrg img {
  max-width: 614px;
}
*/

.case-onethrd {
  width: 28%;
  display: inline-block;
  vertical-align: top;

  h4 {
    color: $darkGrey;
    font-weight: 300;
    font-size: 26px;
    margin: 0 0 10px 0;
  }
}


.case-thrffts {
  width: 56%;
  display: inline-block;
  vertical-align: top;
  margin: 0 4% 0 0;
}

/*
.case-thrffts img {
  width: 100%;
}
*/

.case-thrffts.icntrl {
  vertical-align: bottom;
}

.case-thrffts.icntrl img {
  max-width: 560px;
  margin: 0 auto;
}

.case-twffts {
  width: 39%;
  display: inline-block;
  vertical-align: bottom;
  margin: 0 0 -80px 0;
  text-align: left;
  .small-container {
    @media screen and (min-width: 981px) {
      text-align: left;
    }
  }
}

.case-twffts img {
  width: 100%;
  max-width: 462px;
  margin: 0 auto;
}

@media screen and (min-width: 781px) {
  img.hoover-site-graphic {
    margin: -80px auto 0 auto;
  }
}

.full-width-technology {
  background: $lightGrey;
  margin: 0;

  h2 {
    text-align: center;
  }

  p {
    font-size: 16px;
    line-height: 20px;
  }
}

.technology-wrapper {
  text-align: center;
}

.technology-box {
  width: 18%;
  display: inline-block;
  padding: 2%;
  text-align: center;
  vertical-align: top;
  line-height: 1.6;
}

.technology-box img {
  width: 100%;
  margin: 0 auto 30px auto;
  max-width: 130px;
}

.technology-box-five {
  width: 15%;
  display: inline-block;
  padding: 2%;
  text-align: center;
  vertical-align: top;
  line-height: 1.6;
}

.technology-box-five img {
  width: 100%;
  margin: 0 auto 30px auto;
  max-width: 130px;
}

.technology-box-six {
  width: 12%;
  display: inline-block;
  padding: 2%;
  text-align: center;
  vertical-align: top;
  line-height: 1.6;
}

.technology-box-six img {
  width: 100%;
  margin: 0 auto 30px auto;
  max-width: 130px;
}

.drupal-pstr-box {
  width: 18%;
  display: inline-block;
  padding: .6%;
  text-align: center;
}

.drupal-pstr-box img {
  width: 100%;
  margin: 60px 0 20px 0;
  -webkit-transition: -webkit-transform all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.drupal-pstr-box img:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.vcra-dgtl-dspl-box {
  background: url("/img/case-studies/vocera/pattern.png#{$assetVersion}") no-repeat center bottom;
}

.vcra-phn-box {
  width: 34%;
  display: inline-block;
  vertical-align: bottom;
  position: relative;
}

.vcra-phn-box img {
  width: 100%;
}

.vcra-dvcs {
  display: inline-block;
  vertical-align: top;
  width: 58%;
  text-align: right;
  margin-left: 6%;
}

.vcra-dvcs img {
  width: 100%;
}

.rae-dvces {
  float: right;
  width: 60%;
}

.rae-dvces img {
  max-width: 745px;
  width: 100%;
}

.case-rae-blprint {
  display: inline-block;
  width: 45%;
  vertical-align: bottom;
}

.case-rae-blprint img {
  width: 100%;
}

.rae-strategy-box {
  width: 48%;
  display: inline-block;
  vertical-align: top;
  margin: 0 5% 0 0;
}

.rae-strategy-box img {
  width: 100%;
}

.full-width-work h4 {
  color: $darkGrey;
  font-weight: 300;
  font-size: 26px;
  margin: 87px 0 10px 0;
}

.drpl-logo {
  width: 130px;
  margin: 0 0 0 -12px;
}

.drpl-logo.mrg-spcl {
  margin: (-30px) 0 0 -6px;
}

.drpl-logo img {
  width: 100%;
}

.drplpster-hlf {
  width: 48%;
  display: inline-block;
  vertical-align: top;
}

.pstr-txt-box {
  margin: 0 0 0 6%;
  max-width: 500px;
}

img.pstr-monsters {
  margin: 0 0 0 -2%;
  width: 100%;
  max-width: 685px;
}

.drplpster-hlf h4 {
  color: $darkGrey;
  font-weight: 300;
  font-size: 26px;
  margin: 0 0 10px 0;
}

.full-width-lght-gry-ea {
  background: $lightGrey;
  padding: 90px 0 0 0;
}

.ea-ticket-bg {
  background: url("/img/case-studies/ea/ticket.png#{$assetVersion}") center no-repeat $lightGrey;
  background-size: 50%;
  position: initial !important;
}

.ea-vrtcl-phn-box {
  width: 34%;
  float: left;
  vertical-align: bottom;
  z-index: 10;
}

.ea-vrtcl-phn-box img {
  width: 100%;
}

.ea-ticket-box {
  width: 40%;
  position: absolute;
  left: 50%;
  margin: 240px 0 0 -20%;
}

.ea-ticket-box img {
  width: 100%;
}

.ea-hrzntl-phn-box {
  display: inline-block;
  width: 44%;
  max-width: 690px;
  float: right;
  z-index: 10;
}

.ea-hrzntl-phn-box img {
  width: 100%;
}

.ea-mobil-txt-box {
  width: 52%;
  padding: 40px 0;
  display: inline-block;
  vertical-align: top;
  margin: 0 4% 0 0;
  float: left;
}

.ea-mobil-txt-box h4 {
  color: $darkGrey;
  font-weight: 300;
  font-size: 26px;
  margin: 0 0 10px 0;
}


.instantly-banners-box {
   .banners-wrapper {
    max-width: 1030px;
    margin: 40px auto 0 auto;

    .banner {
      width: 19%;
      display: inline-block;
      margin: 0 3.5%;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      @media screen and (max-width: 520px) {
        width: 30%;
        margin: 30px 3.5% 0 3.5%;
      }
    }
  }
}



.mobil-top-paddng {
  display: none;
}

.splitter-section {
  box-sizing: border-box;

  .txt-holder {
    display: inline-block;
    vertical-align: middle;
    width: 32%;

    @media screen and (max-width: 900px) {
      width: 100%;
      display: block;
      text-align: center;
    }

  }

  img {
    display: inline-block;
    vertical-align: middle;
    max-width: 58%;
    margin-left: 8%;

    @media screen and (max-width: 900px) {
      max-width: 100%;
      display: block;
      margin: 0 auto;
    }
  }

}

.intel-plans {
  text-align: center;
  padding-top: 20px;

  img {
    width: 100%;
  }

  .col-left {
    display: inline-block;
    vertical-align: middle;
    width: 24%;
  }

  .col-right {
    display: inline-block;
    vertical-align: middle;
    width: 50%;
  }

  h2 {
    margin: 30px auto 0;
    font-weight: 100;
    font-size: 30px;
    line-height: 1.4;
    padding: 0 0 14px 0;

    @media screen and (max-width: 1300px) {
      font-size: 26px;
    }

    @media screen and (max-width: 900px) {
      font-size: 22px;
    }
  }
}

