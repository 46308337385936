.years-number {
	position: absolute;
  font-weight: 700;
  letter-spacing: -5.1px;
	color: $webePurple;
  font-size: 300px;
  line-height: 0.8;

  .number-counter {
    font-family: "Poppins", serif;
    line-height: 0;
   }
}

@media screen and (max-width: 1200px) {
   .years-number {
      font-size: calc( (100vw - 80px) / 4);
   }
}

@media screen and (max-width: 780px) {
   .years-number {
      font-size: calc( (100vw - 80px) / 2);
   }
}

@media screen and (max-width: 480px) {
   .years-number {
      font-size: calc( (100vw - 40px) / 2);
   }
}

