#header_wrapper {
  left: 0px;
  top: 0px;
  z-index: 101;
  position: fixed;
  left: 0;
  top: 0px;
  width: 100%;
}

#header {
  max-width: $max_width;
  position: relative;
  margin: 0 auto;
  z-index: 4;
  &:after {
    content:'';
    display: block;
    clear: both;
  }

  @media screen and (max-width: 1300px) {
    margin: 0 40px;
  }

  @media screen and (max-width: 580px) {
    margin: 0 20px;
  }
}

#logo {
  float: left;
  position: relative;
  z-index: 1;

  span.cover_left,
  span.cover_right {
    display: block;
    height: 89px;
    width: 2000px;
    background: $white;
    position: absolute;
  }

  span.cover_left {
    left: -2000px;
  }

  span.cover_right {
    right: -1998px;
  }

  img {
    max-width: 215px;
  }

}


.overlay-nav {
   opacity: 1;
   visibility: hidden;
   -webkit-transition: opacity 0s, all 0.5s ease-in-out;
   transition: opacity 0s, all 0.5s ease-in-out;
  z-index: 2;
  font-family: 'Poppins', serif !important;
  ul {
    opacity: 1;
  }

   ul.nav-sec {
      min-width: 600px;
      list-style: none;
      padding: 30px 0;
      margin: 30px auto;
      position: relative;
      border-top: solid 1px #707071;
      border-bottom: solid 1px #707071;
      display: inline-block;
      -webkit-transform: translateY(-25px);
      transform: translateY(-25px);

      a {
         color: #707071;
         font-size: 18px;
      }



      li {
         display: inline-block;
         text-transform: uppercase;
         color: #707071;
         margin-right: 10px;
         border-right: solid 1px #707071;
         padding-right: 10px;

         &:last-child {
            border-right: none;
         }

         a {
            &:hover,
            &:focus {
               color: #ef9123;
            }
         }
      }
   }

   .nav-social-box div {
      opacity: 1;
      -webkit-transition: -webkit-transform 0.8s, opacity 1.5s;
      transition: transform 0.8s, opacity 1.5s; /* was 0.1 */

      -webkit-transform: translateY(-32px);
              transform: translateY(-32px);

      @media screen and (max-width: 375px) {
         width: 14%;
         padding: 10px 0;
      }
   }

   & li {
      -webkit-transition: transform .5s ease;
      transition: transform .5s ease;
   }
   & li.blog {
   -webkit-transition-delay: 0s !important;
         transition-delay: 0s !important;
   }

   & li.about {
   -webkit-transition-delay: 0.1s !important;
         transition-delay: 0.1s !important;
   }

   & li.solutions {
   -webkit-transition-delay: 0.2s !important;
         transition-delay: 0.2s !important;
   }

   & li.work {
   -webkit-transition-delay: 0.3s !important;
         transition-delay: 0.3s !important;
   }


   &.open {
      opacity: 1;
      visibility: visible;
      /*-webkit-transition: opacity 1s;
      transition: opacity 1s;*/

      -webkit-transition-delay: 0s !important;
      transition-delay: 0s !important;

      ul,
      ul.nav-sec,
      a.nav-contact-us,
      .nav-social-box div {
         opacity: 1;
         -webkit-transform: translateY(0);
         transform: translateY(0);
      }

      -webkit-transition-delay: 0s !important;
      transition-delay: 0s !important;

      & li.work, & li.solutions, & li.about, & li.blog {
         -webkit-transform: translateY(0%) !important;
             -ms-transform: translateY(0%) !important;
                 transform: translateY(0%) !important;
      }

      @media screen and (max-width: 1023px) {
        li.work {
          -webkit-transition-delay: 0s !important;
          transition-delay: 0s !important;
        }
        li.solutions {
          -webkit-transition-delay: 0.1s !important;
          transition-delay: 0.1s !important;
        }
        li.about {
          -webkit-transition-delay: 0.2s !important;
          transition-delay: 0.2s !important;
        }
        li.blog {
          -webkit-transition-delay: 0.3s !important;
          transition-delay: 0.3s !important;
        }
      }
      @media screen and (max-width: 767px) {
        li.work ,
        li.solutions,
        li.about,
        li.blog {
          -webkit-transition-delay: 0s !important;
          transition-delay: 0s !important;
        }
      }

   }

   &.close {
      ul,
      ul.nav-sec,
      a.nav-contact-us,
      .nav-social-box div {
         opacity: 1;
      }

      /* nav ul {
         -webkit-transform: translateY(-30px);
                 transform: translateY(-30px);
      } */

      ul.nav-sec {
         -webkit-transform: translateY(-25px);
                 transform: translateY(-25px);
      }

      a.nav-contact-us {
         -webkit-transform: translateY(-28px);
                 transform: translateY(-28px);
      }

      .nav-social-box div {
         -webkit-transform: translateY(-32px);
                 transform: translateY(-32px);
      }
   }
}

.overlay {
   position: absolute;
   width: 100%;
   height: calc(100vh - 87px);
   top: 100%;
   left: 0;
   background: none !important;
   text-align: center;
   overflow: hidden;
   a {
      text-decoration: none;
   }

   ul.nav-main {
      list-style: none;
      padding: 0;
      margin: 0 auto;
      position: relative;
      font-size: 44px;
      line-height: 1.2;
      overflow: hidden;
      @media screen and (min-width: 1024px) {
        &:after {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 0;
          z-index: 1;
          content:'';
          background: rgba(0,0,0,0.7);
          transition: height 0s  ease 0s;
        }
     }
      @media screen and (max-width: 1099px) {
        font-size: 34px;
      }
      .social-networks {
        margin: 0;
        padding: 0;
        position: static;
        visibility: visible;
        opacity: 1;
        &:after {
          content:'';
          display: block;
          clear: both;
        }
        li {
          margin: 0;
          padding: 0 15px 6px 0;
          float: left;
          clear:none;
          line-height: 0;
          display: block;
          &:last-child {
            padding-right: 0;
          }
          a {
            display: block;
            margin: 0;
            padding: 0 4px 2px;

          }
          img {
            display: block;
            height: 20px;
            width: auto;
          }
          @media screen and (max-width: 1023px) {
            float: none;
            display: inline-block;
            vertical-align: top;
            padding: 8px 4px 0 !important;
            &:last-child,
            &:first-child  {
              padding: 8px 4px 0 !important;
            }
            a {
              padding: 0 2px 6px;
            }
            img {
              height: 18px;
            }
          }
        }
      }

      > li {
         display: block;
         height: calc(100vh - 87px);
         width: 25%;
         float: left;
         position: relative;
         padding: 89px 2% 10px;
         margin: 0;
         text-align: left;
         z-index: 2;
         &:before {
            content:'';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            transition: opacity 0.3s ease;
            @media screen and (max-width: 1023px) {
              opacity: 1;
            }
         }
         &:hover:before {
            opacity: 1;
         }
         &:hover {
          >ul >li:first-child:before {
            border-color: #ffffff !important;
          }
         }

         &.work, &.solutions, &.about, &.blog {
            -webkit-transform: translateY(-110vh);
                -ms-transform: translateY(-110vh);
                    transform: translateY(-110vh);
            -webkit-transform-origin:0 0;
            -ms-transform-origin:0 0;
            transform-origin:0 0;
            @media screen and (max-width: 1023px), screen and (max-height: 899px) {
				-webkit-transform: translateY(-150vh);
                	-ms-transform: translateY(-150vh);
                    	transform: translateY(-150vh);
            }
            @media screen and (max-width: 1023px), screen and (max-height: 679px) {
				-webkit-transform: translateY(-205vh);
                	-ms-transform: translateY(-205vh);
                    	transform: translateY(-205vh);
            }
            @media screen and (max-width: 1023px), screen and (max-height: 519px) {
					-webkit-transform: translateY(-245vh);
                	-ms-transform: translateY(-245vh);
                    	transform: translateY(-245vh);
            }
         }

         &.work {
            background: url(/img/homepage/work.jpg#{$assetVersion});
            background-repeat: no-repeat;
            background-size: cover;
            &:before {
              background: #ef9123;
            }
         }

         &.solutions {
            background: url(/img/homepage/solutions.jpg#{$assetVersion});
            background-repeat: no-repeat;
            background-size: cover;
            &:before {
              background: #a3cf5f;
            }
            @media screen and (max-width: 767px) {
              -webkit-transform: translateY(-166vh);
                -ms-transform: translateY(-166vh);
                    transform: translateY(-166vh);
              transition-duration: 0.6s;
            }
            @media screen and (max-width: 767px), screen and (max-height: 319px) {
            	-webkit-transform: translateY(-180vh);
                	-ms-transform: translateY(-180vh);
                    	transform: translateY(-180vh);
            }
         }

         &.about {
            background: url(/img/homepage/about_us.jpg#{$assetVersion});
            background-repeat: no-repeat;
            background-size: cover;
            &:before {
              background: #a072a5;
            }
            @media screen and (max-width: 767px) {
              -webkit-transform: translateY(-231vh);
                -ms-transform: translateY(-231vh);
                    transform: translateY(-231vh);
              transition-duration: 0.9s;
            }
            @media screen and (max-width: 767px), screen and (max-height: 319px) {
            	-webkit-transform: translateY(-260vh);
                	-ms-transform: translateY(-260vh);
                    	transform: translateY(-260vh);
            }
         }

         &.blog {
            background: url(/img/homepage/blog.jpg#{$assetVersion});
            background-repeat: no-repeat;
            background-size: cover;
            &:before {
              background: #62b4e5;
            }
            @media screen and (max-width: 767px) {
              -webkit-transform: translateY(-315vh);
                -ms-transform: translateY(-315vh);
                    transform: translateY(-315vh);
               transition-duration: 1s;
            }
            @media screen and (max-width: 767px), screen and (max-height: 319px) {
            	-webkit-transform: translateY(-350vh);
                	-ms-transform: translateY(-350vh);
                    	transform: translateY(-350vh);
            }
         }

         @media screen and (max-height: 820px) {
            padding: 50px 2% 10px;
         }
         a {
            font-weight: 400;
            font-family: 'Poppins', serif !important;
            display: inline-block;
            vertical-align: top;
            text-align: left;
            color: #ffffff;
            z-index: 2;
            position: relative;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            &:before {
              content: '';
              position: absolute;
              bottom: -3px;
              left: 0;
              width: 100%;
              height: 2px;
              background: #ffffff;
              -webkit-transform: scale3d(0, 1, 1);
              transform: scale3d(0, 1, 1);
              -webkit-transition: -webkit-transform 0.1s;
              transition: transform 0.1s;
              @media screen and (max-width: 1023px) {
                bottom: 0;
              }
            }
            &:hover {
              text-decoration: none;
              &:before {
                -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
                -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
              }
            }

            span {
               margin-right: 7px;

               &.sm {
                  margin-right: 4px;
               }
            }
         }
         @media screen and (max-width: 1099px) {
           padding: 50px 15px 10px;
         }

         >ul {
            position: relative;
            bottom: 0;
            left: 0;
            margin: 0;
            margin-top: 40px;
            visibility: visible;
            opacity: 1;
            padding: 0 0 3px;

            @media screen and (max-width: 1099px) {
              padding: 0;
            }

            &.open {
               visibility: visible;
               opacity: 1;
               overflow: auto;
            }

            >li {
               display: block;
               font-size: 24px;
               margin: 0;
               padding: 16px 0 0 15px;
               &:first-child {
                padding: 0 0 0 15px;
               }
               &:first-child:before {
                    content: "";
                    position: absolute;
                    left:0;
                    top: 0;
                    bottom: 0;
                    transition: border 0.3s ease;
                    @media screen and (max-width: 1023px) {
                      left: 0;
                      top: 0;
                      display: none;
                    }
                }

               a {
                  position: relative;
                  font-family: 'Poppins', serif !important;
				  font-weight: 300;
                  &:hover,
                  &:focus {
                     color: #ffffff;
                  }
               }
               @media screen and (max-width: 1099px) {
                  font-size: 20px;
               }
            }

            &.a-orange {
               li {
                  &:first-child:before {
                      border-left: 3px solid #ef9123;
                  }
               }
            }

            &.a-green {
               li {
                  &:first-child:before {
                      border-left: 3px solid #a3cf5f;
                  }
               }
            }

            &.a-purple {
               li {
                  &:first-child:before {
                      border-left: 3px solid #a072a5;
                  }
               }
            }

            &.a-blue {
               li {
                  &:first-child:before {
                      border-left: 3px solid #62b4e5;
                  }
               }
            }
         }
      }
   }
}

.fadeInLeft {
   -webkit-animation-name: fadeInLeft;
   animation-name: fadeInLeft;
}

/*** main navigation ***/
#navigation_one {
  float: left;
  position: relative;
  z-index: 2;
}

#navigation_one ul {
  list-style: none;
  margin-left: 22px;
  margin-top: 45px;
}

#navigation_one ul li {
  float: left;
  list-style: none;
  padding: 0;
  margin-right: 32px;
}

#navigation_one ul li a {
  text-decoration: none;
  text-transform: uppercase;
  color: $darkGrey;
}

#navigation_one ul li.work a:hover {
  color: $webeOrange;
}

#navigation_one ul li.solutions a:hover {
  color: $webeGreen;
}

#navigation_one ul li.inside-we a:hover {
  color: $webePurple;
}

#navigation_one ul li.blog a:hover {
  color: $webeBlue;
}

#navigation_two {
  float: right;
  z-index: 2;
  position: relative;
}

#navigation_two ul {
  list-style: none;
  margin-top: 50px;
  height: 10px;
}

#navigation_two ul li {
  float: left;
  padding: 0;
  border-right: 1px solid $darkGrey;
  padding: 0;
  line-height: 6px;
  padding-right: 8px;
  margin-right: 8px;
  list-style: none;
  padding-left: 0;
  margin-left: 0px;
}

#navigation_two ul li:nth-child(3) {
  padding-right: 0px;
  margin-right: 0px;
  border-right: none;
}

#navigation_two ul li a {
  color: $darkGrey;
  font-size: 10px;
  text-transform: uppercase;
  text-decoration: none;
}

#navigation_two ul li a:hover {
  text-decoration: underline;
}

/*** nav css ***/

#nav-toggle {
  position: absolute;
  right: 0;
  top: 34px;
  z-index: 1000;
}

#nav-toggle {
  cursor: pointer;
  padding: 10px 35px 16px 0px;
}

#nav-toggle span, #nav-toggle span:before, #nav-toggle span:after {
  cursor: pointer;
  border-radius: 1px;
  height: 2px;
  width: 32px;
  background: $darkGrey;
  position: absolute;
  display: block;
  content: '';
}

#nav-toggle span:before {
  top: -7px;
}

#nav-toggle span:after {
  bottom: -7px;
}

#nav-toggle:hover span:before {
  top: -9px;
}

#nav-toggle:hover span:after {
  bottom: -9px;
}

#nav-toggle span, #nav-toggle span:before, #nav-toggle span:after {
  transition: all 140ms ease-in-out;
}

#nav-toggle.active span {
  background-color: transparent;
}

#nav-toggle.active span:before, #nav-toggle.active span:after {
  top: 0;
}

#nav-toggle.active span:before {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}

#nav-toggle.active span:after {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
}

.nav-social-box {
  margin: 0 auto;
  text-align: center;

  div {
    background: $webeBlack;
    display: inline-block;
    margin: 0 1.2px;
    width: 47px;
    border-radius: 2px;
    padding: 10px 0;
    box-sizing: border-box;
    text-align: center;
    -webkit-border-radius: 2px;

    i {
      background: url("/img/sprite.png#{$assetVersion}") no-repeat;
      background-size: 140px;
      width: 28px;
      height: 27px;
      display: block;
      margin: 0 auto;

      &.facebook {
        background: url("/img/social-icons/Facebook.svg") no-repeat 50% 50%;
        background-size: auto 25px;
        width: 100%;
      }

      &.twitter {
        background: url("/img/social-icons/Twitter.svg") no-repeat 50% 50%;
        background-size: auto 25px;
        width: 100%;
      }

      &.youtube {
        background-position: -56px 0;
      }

      &.linkedin {
        background: url("/img/social-icons/LinkedIn.svg") no-repeat 50% 50%;
        background-size: auto 25px;
        width: 100%;
      }

      &.instagram {
        background: url("/img/social-icons/Instagram_Solid.svg") no-repeat 50% 50%;
        background-size: auto 25px;
        width: 100%;
      }

      &.google {
        background: url("/img/social-icons/Google+.svg") no-repeat 50% 50%;
        background-size: auto 25px;
        width: 100%;
      }

      &.pintrst {
        background-position: -112px 0;
      }
    }

    &:hover {
      background-color: $webeOrange;
    }

    img {
      width: 100%;
    }

    @media screen and (max-width: 375px) {
      width: 18%;
      padding: 12px 0;
    }

    @media screen and (max-width: 330px) {
      width: 17.8%;
    }
  }
}

@media screen and (max-width: 1023px) {
    .overlay {
      margin: 0;
      position: absolute;
      left: 0;
      right: 0;
      top: 87px;
      overflow-y: scroll;
      bottom: 0;
      height: auto;
      -webkit-overflow-scrolling: touch;
    }
    .overlay-nav ul {
      -webkit-transform: none;
      transform: none;
      -webkit-transform: translate3d(0,0,0);
    }
    .overlay ul.nav-main>li>a {
      font-size: 34px !important;
   }
   .page.with-navigation {
		  position: relative;
		&:after {
			content:'';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: $white;
			z-index: 1;
			visibility: hidden;
			transition: visibility 0s linear 0s;
		}
   }
   #header_wrapper {
    transition: height 0s 0.6s;
    .nav-open & {
      transition-delay: 0s;
      height: 100%;
    }
  	&:before {
  		content:'';
  		position: fixed;
  		top: 0;
  		left: 0;
  		right: 0;
  		height:0;
  		background: $webeOrange;
  		z-index: -1;
  		overflow: hidden;
  		transition: height 0s linear 0s;
  	}
  	&:after {
  		content:'';
  		position: fixed;
  		bottom: 0;
  		left: 0;
  		right: 0;
  		z-index: -1;
  		height: 0;
  		background: $webeBlue;
  		overflow: hidden;
  		transition: height 0s linear 0s;
  	}
  }
  .nav-open {
  	#header_wrapper:before,
  	#header_wrapper:after  {
  		height: 50vh;
  		transition-delay: 0.6s;
  	}
  	.page.with-navigation:after {
  		visibility: visible;
  		transition-delay: 0.6s;
  	}
  }
  .noScroll {
    overflow: hidden !important;
    height: 100% !important;
  }
  body.noScroll {
    position: fixed;
    width: 100%;
  }
  .overlay ul.nav-main>li {
    width: 100%;
    height: auto;
    padding:30px 20px;
    min-height: 0;
    text-align: center;
    background-position: center center !important;
  }
  .overlay ul.nav-main>li ul li {
    font-size: 20px;
    line-height: 30px;
    padding:0 0 4px !important;
    &:last-child {
      padding: 0 !important;
    }
  }

  .overlay ul.nav-main>li ul {
    display: block !important;
    margin-top: 22px;
    padding: 0;
  }

  .overlay ul.nav-main>li ul li a {
    font-size: 20px !important;
    margin-left: 0;
  }

}
@media screen and (max-width: 767px) {
  .overlay ul.nav-main>li {
    padding: 20px;
  }
}

@media screen and (max-width: 767px), screen and (max-height: 480px) {
   .overlay ul.nav-main {
      font-size: 25px;
    }
}
#header {
   &:before {
      content:'';
      position: absolute;
      top: 0;
      height:0;
      left: 0;
      right: -9999px;
      background: #000;
      transition: height 0.3s ease 0.5s;
   }
}
.nav-open  #header:before {
  height: 89px;
  transition-delay: 0s;
}
.nav-open .overlay ul.nav-main:after {
    height: 100%;
    transition-delay: 0.6s;
}
#header_wrapper {
  height: 89px;
  .request-services {
    z-index: 99999;
    position: absolute;
    right: 135px;
    top: 24px;
    padding: 11px 100px;
    @media screen and (max-width: 767px) {
      right: 57px;
      padding: 11px 30px;
    }
    @media screen and (max-width: 479px) {
      display: none;
    }
  }
}

#logo {
	left: 0;
	position: relative;
  z-index: 2;
  &:before {
    content:'';
    position: absolute;
    z-index: -1;
    right: 100%;
    top: 0;
    bottom: 0;
    width: 9999px;
    background: $white;
  }
  &:after {
    background: $white;
    content:'';
    position: absolute;
    z-index: -1;
    left: 100%;
    margin-left: -3px;
    top: 0;
    bottom: 0;
    width: 9999px;
  }
}

.latestPost {
	color: $white;
	font-size: 13px;
  line-height: 18px !important;
  padding: 0 0 0 15px;
  font-weight: 300;
	font-family: 'Poppins', serif !important;
  a {
    padding: 0 !important;
    margin: 0 !important;
    cursor: default;
    pointer-events: none;
  }
	& span {
    font-family: 'Poppins', serif !important;
    font-weight: 300;
    line-height: 18px;
		&.latest {
			border: 1px solid white;
      font-size: 12px;
		}
    &.date {
      padding: 0;
    }
		padding-left: 10px;
		padding-right: 10px;
		font-size: 13px;
	}
  @media screen and (max-width: 1023px) {
    padding: 0 0 10px !important;
  }
}


.lastBlog {
	color: $white;
  margin-top: 4px;
	& span.date, & span.title {
		font-family: 'Poppins', serif !important;
		font-size: 24px;
    line-height: 32px;
		font-weight: 300;
	}
  & span.title {
    display: block;
    float: none;
    margin: 0 0 10px;
  }
	& span.excerpt {
		font-family: 'Poppins', serif !important;
		font-size: 18px;
		font-weight: 300;
		display: block;
    line-height: 24px;
    & p {
      margin: 0 0 10px;
      font-family: 'Poppins', serif !important;
      color: $white !important;
      font-size: 18px;
    }
    & .lastBlogLink {
      font-size: 18px;
      font-weight: bold;
      & .readMore {
        padding: 0px;
        margin: 0px;
        font-weight: bold;
        font-size: 18px;
      }
    }
	}
  @media screen and (max-width: 1099px) {
    & span.date, & span.title {
      font-size: 20px;
      line-height: 28px;
    }
    & .lastBlogLink {
      font-size: 16px;
      & .readMore {
        font-size: 16px;
      }
    }
    & span.excerpt {
      font-size: 16px;
    }
  }
  @media screen and (max-width: 1023px) {
    margin-top:0;
    text-align: center;
    & p,
    & span.title a {
      display: -webkit-box !important;
      max-height: 56px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    & p {
      max-height: 49px;
    }
    & span.title a {
      text-align: center !important;
    }
    & span.date {
      font-size: 18px;
      line-height: 22px;
    }
    & span.excerpt {
      line-height: 20px;
    }
    .readMore {
      line-height: 28px;
    }
  }
}
body.noScroll {
   overflow: hidden;
}

// Limit width of blog description
.overlay ul.nav-main>li ul li.lastBlog {
  @media screen and (max-width: 1023px) { padding: 0 30% !important; }
  @media screen and (max-width: 767px) { padding: 0 15% !important; }
  @media screen and (max-width: 450px) { padding: 0 !important; }
}

// Main menu scrollbar (using iScroll)
div.iScrollVerticalScrollbar {
  width: 5px !important;
  opacity: 0 !important;
  -webkit-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;

  .overlay.open & { opacity: 1 !important; }
}
div.iScrollIndicator {
  border: 0 none !important;
  border-radius: 5px !important;
}
