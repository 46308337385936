.tabsets {
	padding: 0;
	margin: 0 -1px 50px 0;
	list-style: none;
	display: flex;
	width: 100%;
	z-index: 2;
	font-size: 12px;
	font-weight: bold;
	position: relative;
	li {
		flex: 1;
		padding: 0 1px 1px 0;
	}
	a {
		display: flex;
		align-items: center;
		text-decoration: none;
		text-transform: uppercase;
		color: #fff;
		text-align: center;
		height: 60px;
		padding: 5px 10px;
		justify-content: center;
		transition: all 0.3s ease;
		background: #707071;
		&.active, &:hover {
			background: #262728;
		}
	}
	@media screen and (max-width: 767px) {
		flex-wrap: wrap;
		font-size: 11px;
		li {
			flex: 50%;
		}
		a {
			height: 39px;
		}
	}
}