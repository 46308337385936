@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.clear {
  clear: both;
}

.clearfix:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
  overflow: hidden;
  visibility: hidden;
}

// devices viewport width starts at:
$widescreen: 1372px;
$tablet: 960px;
$irm-mobile: 767px;
$mobile-sm: 479px;

$max_width: 1200px;

.v-align {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  z-index: 2;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.v-align-only {
  position: absolute;
  top: 50%;
  width: 100%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);

  @media screen and (max-width: 980px) {
    position: relative;
    top: auto;
    -ms-transform: none;
    -webkit-transform: none;
    transform: none;
    width: auto;
    height: 100%;
  }

}

.top-padding {
  padding-top: 40px !important;
}
.no-bottom-margin {
  margin-bottom: 0 !important;
}

.no-bottom-padding {
  padding-bottom: 0 !important;
}

img.img-center {
  margin: 0 auto;
}



@media screen and (max-width: 992px) {
  .hide-on-mobile {
    display: none !important;
  }
}

@-webkit-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

.rotate {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}

img.rspnsvimg {
  max-width: 100%;
  margin: 0 auto;
}

img.full-width {
  width: 100%;
}

.hide {
  display: none;
}

