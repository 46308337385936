.no-parallax{
	position: relative;
	z-index: 2;
}
.parallax-index{
	z-index: 0 !important;
}
.cisco-z {
  z-index: 1;

  &.paralax {
    z-index: 0;
  }
}
.case-study-cisco-top {
	position: relative;
	height: 520px;
	color: #ffffff;
	overflow: hidden;
	z-index: 2;
	background: #001d34;
    background: -webkit-linear-gradient(left, rgb(0, 29, 52) 0%, rgb(2, 34, 63) 57%, rgb(1, 11, 19) 98%, rgb(2, 10, 16) 100%);
    background: -o-linear-gradient(left, rgb(0, 29, 52) 0%, rgb(2, 34, 63) 57%, rgb(1, 11, 19) 98%, rgb(2, 10, 16) 100%);
    background: -ms-linear-gradient(left, rgb(0, 29, 52) 0%, rgb(2, 34, 63) 57%, rgb(1, 11, 19) 98%, rgb(2, 10, 16) 100%);
    background: -moz-linear-gradient(left, rgb(0, 29, 52) 0%, rgb(2, 34, 63) 57%, rgb(1, 11, 19) 98%, rgb(2, 10, 16) 100%);
    background: linear-gradient(to right, rgb(0, 29, 52) 0%, rgb(2, 34, 63) 57%, rgb(1, 11, 19) 98%, rgb(2, 10, 16) 100%);
	video {
		position: absolute;
		min-width: 100%;
		min-height: 100%;
		width: auto;
		height: auto;
		left: 50%;
		top: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		opacity: .09;
	}
	#bg-hex {
		width: 100%;
	    height: 100%;
	    position: absolute;
	    top: 0;
	    left: 0;
	    background-image: url(/img/case-studies/cisco/CIS17002_Mobility_Infographic_R1.png);
	    -webkit-background-size: cover;
	    -moz-background-size: cover;
	    -o-background-size: cover;
	    background-size: cover;
	}
	#animation_container {
		position: absolute;
	    margin: auto;
	    left: 38%;
	    top: 50%!important;
	    -webkit-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	    -o-transform: translateY(-50%);
	    transform: translateY(-50%);
	}
	.case-top-wrapper {
		padding: 142px 0 0;
		z-index: 3;
	}
	.case-logo-each {
		width: 230px;
	}
	@media screen and (max-width: 1023px) {
		height: 360px;
		.case-logo-each {
			width: 170px;
		}
		.case-top-wrapper {
			padding: 95px 0 0;
			margin: 0 40px;
		}
		#animation_container {
			left: 33%;
		}
	}
	@media screen and (max-width: 767px) {
		.case-top-wrapper {
			margin: 0 50px;
		}
		video {
			height: 100%;
			width: auto;
		}
	}
	@media screen and (max-width: 520px) {
		.case-top-wrapper {
			margin: 0 20px;
		}
	}
}
.item .case-study-cisco-top {
	background: url(/img/case-studies/cisco/Cisco_Long_Thumbnail.jpg) no-repeat 50% 50%;
	background-size: cover;
	height: 440px;
	@media screen and (max-width: 767px) {
		height: 410px;
		background-position: 60% 50%;
	}
	.case-top-wrapper {
		padding-top: 80px;
		min-height: 0;
		@media screen and (max-width: 1023px) {
			padding-top: 109px;
		}
		@media screen and (max-width: 767px) {
			padding-top: 62px;
		}
		@media screen and (max-width: 580px) {
			padding-top: 37px;
		}
	}
}
.full-width-slider.bg-white {
	background: #fff;
	position: relative;
	.maxwidth img{
		max-width: 78%;
		@media screen and (max-width: 767px) {
			max-width: 100%;
		}
	}
}
.cisco-bg {
	position: relative;
	&:after {
		content:'';
		position: absolute;
		z-index: 1;
		left: 0;
		top: 100%;
		background: url(/img/case-studies/cisco/helix.png) 0 0 no-repeat;
		width: 370px;
		height: 1600px;
		margin-top: 50px;
		@media screen and (max-width: 1300px) {
			left: -100px;
		}
		@media screen and (max-width: 1023px) {
			display: none;
		}
	}
}
.full-width-slider {
	.demo {
		position: relative;
		z-index: 2;
	}
}
.cisco-block {
	overflow: hidden;
	video {
		display: block;
		margin: 0 -1%;
		width: 102%;
		height: auto;
		@media screen and (max-width: 767px) {
			height: 350px;
			width: auto;
			margin: 0;
		}
	}
	.bg-animate-holder.small {
		color: #fff;
		text-align: center;
		.container {
			z-index: 4;
		}
		h2 {
			color: #fff;
			font-size: 48px;
			line-height: 1.25;
			max-width: 840px;
			margin: 0 auto;
			font-weight: normal;
			font-family: "proxima-nova" !important;
			@media screen and (max-width: 1023px) {
				font-size: 32px;
			}
			@media screen and (max-width: 767px) {
				font-size: 26px;
			}
		}
		@media screen and (max-width: 1150px) {
			video {
				height: 100%;
				width: auto;
				margin: 0;
			}
			>.centered {
				width: auto;
			}
		}
		@media screen and (max-width: 767px) {
			video {
				height: 116%;
				margin: -4% 0 0;
			}
			.txt-holder.centered {
				height: auto;
			}
		}
	}
}
.bg-animate-holder {
	overflow: hidden;
	width: 100%;
	background: #000;
	position: relative;
	height: 600px;
	display: block;
	.centered {
		position: absolute;
		top: 50%;
		width: 100%;
		height: auto;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	@media screen and (max-width: 1023px) {
		height: 500px;
	}
	@media screen and (max-width: 767px) {
		height: 350px;
		.centered {
			height: 100%;
			width: auto;
		}
	}
}
.cisco-vr {
	position: relative;
	padding: 140px 0 90px;
	&:before {
		content:'';
		position: absolute;
		right: 0;
		z-index: 1;
		width: 543px;
		height: 470px;
		background: url(/img/case-studies/cisco/hex.png) center center no-repeat;
		top: -240px;
	}
	.container {
		position: relative;
		z-index: 2;
		display: flex;
		flex-wrap: nowrap;
		align-items: flex-end;
		flex-direction: row-reverse;
	}
	.img {
		margin: 0 -80px 0 0;
		img {
			max-width: 100%;
			height: auto;
		}
		@media screen and (max-width: 1300px) {
			margin: 0;
		}
	}
	.txt-holder {
		width: 415px;
		padding: 0 40px 0 0;
		margin: 0 auto;
		font-size: 16px;
		line-height: 24px;
/*
		h2 {
			color: #707071;
		    font-family: 'Poppins', serif;
		    font-weight: 300;
		    font-size: 26px;
		    letter-spacing: -0.5px;
		    line-height: 1.5;
	    	margin: 0 0 14px;
			padding-bottom: 0;
		}
*/
	}
	@media screen and (max-width: 767px) {
		padding: 40px 0;
		&:before {
			display: none;
		}
		.container {
			display: block;
		}
		.txt-holder {
			width: 80%;
			padding: 20px 0 0;
			margin: 0 auto;
			text-align: center;
		}
	}
}
.cisco-paralax-text {
	background: #fff;
	position: relative;
	z-index: 2;
}
.cisco-container {
	padding: 60px 0;
	font-size: 16px;
/*
	h4, h2 {
		line-height: 1.5;
	   	margin: 0 0 14px;
	}
*/
	.txt-holder  {
		width: 80%;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding: 0;
    margin: 0 auto;
	}
	@media screen and (max-width: 767px) {
		padding: 40px 0;
	}
}