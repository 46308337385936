.our-mission-container {
	width:100%;
	background: $white;
	min-height: 100px;
	max-width: 1200px;
	margin: 0 auto;
}

.our-mission-content {
  text-align: center;
}

.our-mission-container h3 {
	font-size: 72px;
	font-weight: bold;
	letter-spacing: -1.8px;
	line-height: 1;
	color:$darkGrey;
}

.our-mission-container h3 span {
  font-size: 72px;
  font-weight: bold;
  font-family: inherit;
  letter-spacing: -1.8px;
  line-height: 1;
  color:$webeBlack;
}

.our-mission-container h4 {
	font-size: 26px;
	font-weight: 300;
  margin-bottom: 39px;
}

.our-core-values-container, .partnerships-certifications-container {
	background: $lightGrey;
}

.certification-grid {
  padding: 50px 0;
}

.certification-grid h4 {
  font-weight: 300;
  color: $darkGrey;
  font-size: 24px;
  letter-spacing: -0.5px;
  margin: 0 0 18px;
}

.our-core-values-content h4 {
  font-size: 26px;
  font-weight: 300;
  margin-bottom: 48px;
}

.certification-image {
  margin: 0 auto;
  max-width: 100%;
}

.aligner {
  vertical-align: bottom !important;
  padding: 4px 30px 20px !important;
}

@media screen and (max-width: 1200px) {
  .certification-grid {
    text-align: center;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1300px) {
	.our-mission-container {
	  margin: 0;
	  padding-left: 40px;
	  padding-right: 40px;
	}
}

@media screen and (max-width: 1150px) {
  .our-mission-container h3,
  .our-mission-container h3 span {
    font-size: 64px !important;
  }
}


@media screen and (max-width: 1023px) {
  .our-mission-container h3,
  .our-mission-container h3 span {
    font-size: 50px !important;
  }

}

@media screen and (max-width: 767px) {
  .our-mission-container h3,
  .our-mission-container h3 span {
    font-size: 34px !important;
  }

  .solution-grid .aligner {
    width:48% !important;
  }

}

@media screen and (max-width: 480px) {
  .our-mission-container  {
    margin: 0 20px;
  }

  .our-mission-container h3,
  .our-mission-container h3 span {
    font-size: 28px !important;
  }

}

@media screen and (max-width: 400px) {
  .our-mission-container h3,
  .our-mission-container h3 span {
    font-size: 22px !important;
  }
}


.solution-grid {
  margin: 0 -15px 17px;
  font-size: 0;
  line-height: 0;
  letter-spacing: -4px;
  text-align: center;
  .certification-box {
    display: inline-block;
    vertical-align: top;
    width: 18%;
    padding: 4px 0px 20px;
    margin: 0 6%;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    color: #707071;
    letter-spacing: 1px;
    line-height: 1.2;

    @media screen and (max-width: 1023px) {
      width: 33.33%;
    }
    @media screen and (max-width: 767px) {
      width: 55%;
      margin: 0 0%;
    }
  }
}
