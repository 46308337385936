/*** main footer ***/

.footer_head_wrapper {
  width: 100%;
  background: $webeBlack;
  line-height: 42px;
  color: $darkGrey;
  height: 42px;

  &.fixed {
    left: 0px;
    bottom: 0px;
    z-index: 99;
  }

}

.footer_head {
  max-width: $max_width;
  position: relative;
  margin: auto;

  .copyright {
    float: left;
    font-size: 12px;
  }

  a.connect {
    float: right;
    font-size: 12px;
    color: $white;
    text-transform: uppercase;
    text-decoration: none !important;

    div {
      display: inline-block;
      background-color: $webeOrange;
      border-radius: 2px;
      width: 14px;
      height: 14px;
      padding: 2px;
      margin: -2px 0 0 16px;
      vertical-align: middle;
    }

    img {
      transition: all 140ms ease-in-out;
      width: 100%;
    }

  }

  .footer_content {
    height: 300px;
    display: none;
  }

}



.footer_section {
	background: $lightGrey;
  i {
    background: url("/img/sprite.png#{$assetVersion}") no-repeat;
    background-size: 150px;
    width: 30px;
    height: 35px;
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px 0 -4px;

    &.pdf {
      background-position: 0 -30px;
    }

    &.phone {
      background-position: -30px -30px;
      margin: 6px 5px 0 -4px;
    }

    &.mail {
      background-position: -60px -30px;
    }
  }
}

.about_wrapper {
  margin: 0 auto;
  max-width: 1280px;
  padding: 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  @media screen and (max-width: 580px) {
    padding: 20px;
  }

  h4 {
    font-weight: 300;
    font-size: 24px;
    line-height: 1.2;
    letter-spacing: -1.3px;
    margin: 0 0 14px;
	}
	ul {
		padding: 0;
		margin: 0;
		list-style: none;
		color: $webeBlack;
		font-size: 16px;
		li {
			padding: 0 0 4px;
		}
		a {
			color: $webeBlack;
			text-decoration: none;
		}
	}
	p {
		font-size: 16px;
		line-height: 1.2;
	}

	.about-contact-us {
		flex-basis: 0;
		flex-grow: 1;
		padding: 0;
		box-sizing: border-box;

		img.about-icon-pdf {
			display: inline-block;
			width: 18px;
			vertical-align: top;
			margin: -3px 8px 0 0;
		}
		img.about-icon-phone {
			display: inline-block;
			width: 15px;
			margin-right: 8px;
			vertical-align: top;
			margin: -4px 8px 0 0;
		}
		img.about-icon-email {
			display: inline-block;
			width: 20px;
			vertical-align: top;
			margin: 3px 8px 0 0;
		}

		@media screen and (max-width: 1200px) { flex-basis: auto; }
		@media screen and (max-width: 767px) {
      width: 100%;
      padding-bottom: 10px;
      margin-bottom: 20px;
      border-bottom: solid 1px $darkGrey;
		}
	}
	.about-social {
		flex-basis: 0;
		flex-grow: 1;
		padding-left: 50px;

		.nav-social-box {
			text-align: left;
			white-space: nowrap;
		}
		@media screen and (max-width: 1200px) { padding-left: 25px; }
		@media screen and (max-width: 1100px) { flex: 1 0 auto; }
		@media screen and (max-width: 1023px) {
  		flex: 1 0 100%;
			padding-top: 30px;
			padding-left: 0;
		}
		@media screen and (max-width: 767px) { padding-top: 0; }
	}
	@include clearfix;

	a:not(.request-services):hover {
		color: $webeOrange;
	}
	a.about-link {
		text-transform: uppercase;
		color: $webeBlack;
    text-decoration: none;
    font-size: 14px;
    &.small {
    	text-transform: none;
    }
	}

	.about-link-break {
		height: 2px;
		clear: both;
	}
}

.footer-holder {
	background: $webeBlack;
	color: $lightGrey;
	padding: 20px 0;
	.container {
		font-size: 16px;
		line-height: 20px;
		overflow: hidden;
	}
	.left {
		float: left;
		color: $darkGrey;
		width: 50%;
		p {
			padding: 0;
  		font-size: 12px;
  		line-height: 32px;
		}
	}
	p {
		margin: 0;
	}
	.right {
		float: right;
		img {
			display: inline-block;
			vertical-align: middle;
			margin: 0 5px 3px;
		}
		p {
			padding: 5px 0 0;
			font-size: 16px;
		}
	}
	.logo {
		float: left;
		width: 33px;
		height: 32px;
		margin: 0 20px 0 0;
		overflow: hidden;
		img {
			width: auto;
			max-width: none;
			height: 32px;
		}
	}
	@media screen and (max-width: 767px) {
		.logo {
			float: none;
			display: inline-block;
			vertical-align: top;
			margin: 0;
		}
		.right,
		.left {
			float: none;
			width: 100%;
			text-align: center;
		}
	}
}
#mc_embed_signup {
	overflow: hidden;
	padding-bottom: 4px;
	.mc-field-group {
		overflow: hidden;
		position: relative;
		padding: 0 40px 0 0;
		width: 100%;
		max-width: 230px;
	}
	[type="email"] {
		display: block;
		width: 100%;
		background: $white;
		border:none;
		padding: 0 18px;
		height: 40px;
		line-height: 40px;
		color: $darkGrey;
	}
	button {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		width: 40px;
		text-align: center;
		border:none;
		border-radius: 0 !important;
		padding: 0;
		margin: 0;
		height: 40px;
		color: $white;
		background: $webeOrange;
		cursor: pointer;
		z-index: 2;
		svg {
			display: inline-block;
			vertical-align: middle;
			fill: $white;
			width: 16px;
			height: 14px;
		}

		&:hover {
  		background: $webeBlack !important;
		}
	}
}
#mc_embed_signup div.mce_inline_error {
	margin: 0;
	font-weight: normal;
	font-size: 12px;
	background-color: $webeRed !important;
}
