/*** solutions ***/

.multiDicplLeft,
.multiDicplRight {
  max-width: 20%;
  margin: 0;
  display: inline-block;
  vertical-align: top;
  text-align: right;

  &.only-mobile {
    display: none;
  }

  div {
    margin: 70px 0;
  }

  h3 {
    font-size: 16px;
    text-transform: uppercase;
    font-family: "proxima-nova";
    color: $darkGrey;
    font-weight: 400;
    margin: 0 0 6px;
  }

  p {
    font-size: 16px;
  }
}

.multiDicplLeft {
  margin-right: 8%;
}

.multiDicplRight {
  margin-left: 8%;
}

.green-bubbles-wrapper {
  max-width: 40%;
  display: inline-block;
}

.green-rounded {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  max-height: 38%;
  max-width: 38%;
  text-align: middle;
  display: inline-block;
  padding: 10%;

  img {
    width: 100%;
    margin: 0 auto;
  }
}

.strategy-rounded {
  background: rgba(127, 199, 66, 0.8);

  &:hover {
    background: rgba(107, 168, 55, 0.8);
  }
}


.creative-rounded {
  margin: 0 0 0 -18%;
  background: rgba(74, 163, 14, 0.7);

  &:hover {
    background: rgba(61, 136, 11, 0.7);
  }
}


.development-rounded {
  margin: -18% 0 0 0;
  background: rgba(13, 60, 1, 0.8);
  z-index: 1;

  &:hover {
    background: rgba(10, 46, 0, 0.8);
  }
}

.client-rounded {
  margin: -18% 0 0 -18%;
  background: rgba(68, 121, 0, 0.8);
  z-index: 10;

  &:hover {
    background: rgba(58, 102, 1, 0.8);
  }
}


.process-tabs-wrapper {
  margin: 40px 0;
}

.tabs nav {
  max-width: 1100px;
  margin: 0 auto;
}

.tabs *,
.green-bubbles-wrapper * {
  box-sizing: content-box;
}

.tabs nav ul {
  list-style: none;
  margin: 0 auto;
  li {
    display: inline;
  }
}

.tabs-content section {
  padding: 60px 0;
  display: none;

  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }

}


/* Fallback example */
.no-js .tabs-content section {
  display: block;
  padding-bottom: 2em;
  border-bottom: 1px solid $webeBlue;
}

.tabs-content section.content-current {
  display: block;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-name: fadeInFromNone;
  animation-name: fadeInFromNone;
}

div.refine-wrapper {
  margin: -34px 0 0 0;
}

div.refine-txt {
  margin: -18px auto 0 -50px;
  width: 100px;
  text-align: center;
  font-family: 'Poppins', serif;
  text-transform: uppercase;
  color: $darkGrey;
  font-size: 17px;
  letter-spacing: 2px;
  z-index: 20;
  background: $white;
  position: absolute;
  left: 50%;
}

div.refine-left-arrow {
  background: $refine_left no-repeat;
  height: 67px;
  width: 28px;
  float: left;
}

div.refine-line {
  width: calc(100% - 58px) !important;
  background: $lightGrey;
  height: 2px;
  z-index: 0;
  margin: 65px auto 0 auto;
  display: inline-block;
}

div.refine-right-arrow {
  background: $refine_right no-repeat;
  height: 67px;
  width: 30px;
  float: right;
}

.green-arrow-tab-first {
  background: url("/img/solutions/tab_one.png#{$assetVersion}") no-repeat;
  background-size: 100% 100%;
  width: 18%;
  display: inline-block;
  color: $white;
  padding: 16px 2% 12px 2%;
  font-size: 22px;
  font-weight: 100;
  cursor: pointer;
  position: relative;

  img {
    display: inline-block;
    width: 28px;
    margin: 0 6% 0 0;
    position: relative;
    z-index: 2;
  }

  span {
    display: inline-block;
    font-family: 'Poppins', serif;
    font-weight: 300;
    vertical-align: top;
    font-size: 20px;
    position: relative;
    z-index: 2;
  }
}



.green-arrow-tab-rest {
  background: url("/img/solutions/tab_arrow.png#{$assetVersion}") no-repeat;
  background-size: 100% 100%;
  width: 16.2%;
  display: inline-block;
  margin: 0 0 0 -12px;
  color: $white;
  padding: 16px 2% 12px 2%;
  font-size: 22px;
  font-weight: 100;
  cursor: pointer;
  position: relative;

  img {
    display: inline-block;
    width: 28px;
    margin: 0 6% 0 0;
    z-index: 2;
    position: relative;
  }

  span {
    display: inline-block;
    font-family: 'Poppins', serif;
    font-weight: 300;
    vertical-align: top;
    font-size: 20px;
    z-index: 2;
    position: relative;
  }
}


.green-arrow-tab-first::before {
  background: url("/img/solutions/tab_one_active.png#{$assetVersion}") no-repeat;
  background-size: 100% 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  opacity: 0;
  -webkit-transition: all 0.5s cubic-bezier(0.42, 0, 0.58, 1);
  transition: all 0.5s cubic-bezier(0.42, 0, 0.58, 1);
}

.green-arrow-tab-rest::before {
  background: url("/img/solutions/tab_arrow_active.png#{$assetVersion}") no-repeat;
  background-size: 100% 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  opacity: 0;
  -webkit-transition: all 0.5s cubic-bezier(0.42, 0, 0.58, 1);
  transition: all 0.5s cubic-bezier(0.42, 0, 0.58, 1);
}

.green-arrow-tab-first:hover::before,
.tab-current .green-arrow-tab-first::before,
.green-arrow-tab-rest:hover::before,
.tab-current .green-arrow-tab-rest::before {
  opacity: 1;
}

.tab-content-column {
  width: 45%;
  display: inline-block;
  vertical-align: top;
  text-align: left;
  margin: 0 4% 0 0;

  h3 {
    color: $darkGrey;
    font-weight: 300;
    font-size: 26px;
    margin: 0 0 16px 0;
  }

  p, li {
    font-size: 16px;
    line-height: 1.3;
  }
}

.tab-content-column-right {
  width: 48%;
  display: inline-block;
  vertical-align: top;
  text-align: left;
}

img.nmbr-grn {
  float: left;
  margin: 0 30px 100px 0;
  max-width: 100px;
}

.tab-content-column ul {
  float: left;
  margin: 20px;
}

.solutions-list {
  h3 {
    font-size: 26px;
    font-weight: 300;
    margin: 0 0 16px 0;

    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  }
}

.solutions-column {
  width: 21%;
  display: inline-block;
  vertical-align: top;

  h3 {
    font-size: 26px;
    font-weight: 300;
    margin: 8px 0 16px 0;

    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  }

  ul > li {
    margin: 22px 20px;
    &:first-child {
      margin-top: 0;
    }
  }

  @media screen and (max-width: 920px) {
    width: 32%;
  }

  @media screen and (max-width: 580px) {
    width: 100%;
    margin: 0;

    ul {
      margin-bottom: 0;
      margin-top: 0;
      li {
        margin: 6px 20px;
        &:first-child {
          margin-top: 6px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

  }

}


.solutions-column-two {
  width: 33%;
  display: inline-block;
  vertical-align: top;

  .svg-container {
    visibility: hidden;
    text-align: center;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      width: 100%;
      height: 0;
      padding-top: 100%;
      position: relative;

      &.marketing {
        padding-top: 51%;
      }
      &.creative {
        padding-top: 88%;
      }
      &.agency {
        padding-top: 65%;
      }

      svg {
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &.move-up {
      margin-top: -40px;
      @media screen and (max-width: 920px) {
        margin-top: 0;
      }
    }

    &.done {
      visibility: visible;
    }

    .svg-btm {
      width: 100%;
      display: block;
      height: 1px;
    }
  }

  @media screen and (max-width: 920px) {
    width: 50%;
    display: block;
    margin: 0 auto;
  }

  @media screen and (max-width: 580px) {
    width: 80%;
    display: block;
    margin-top: 20px;
  }
}

.solutions-column-two img {
  width: 100%;
}


