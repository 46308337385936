.case-study-mcafee-top {
    height: 497px;
    background:#fff;
    overflow: hidden;
    position: relative;
    &:before {
        content:'';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background:#fff url('/img/case-studies/mcafee/we_mcafee_top.jpg#{$assetVersion}') 77% center no-repeat;
        background-size: contain;
    }
    .case-top-wrapper {
        padding: 160px 0 0;
        position: relative;
        z-index: 2;
    }
    h1 {
        color: #000 !important;
    }
    @media screen and (max-width: 1300px) {
        .case-top-wrapper {
            margin: 0 40px;
        }
    }
    @media screen and (max-width: 767px) {
        height: 350px;
        &:before {
            right: -44%;
            bottom: -20px;
            background-size: cover;
        }
        .case-top-wrapper {
            padding: 139px 0 0;
        }
    }
    @media screen and (max-width: 520px) {
        &:before {
            right: -78%;
            bottom: -18px;
            top: -8px;
        }
        .case-top-wrapper {
            margin: 0 20px;
        }
    }
}
.wrap-section-mcafee {
    position: relative;
    padding: 90px  0 58px;
    color: $white;
    text-align: center;

    @media screen and (min-width: 1000px) {
      height: 405px;
    }
    @media screen and (min-width: 767px) and (max-width: 999px) {
      height: 350px;
    }

    @media screen and (max-width: 1023px) {
        padding-top: 50px;
        padding-bottom: 35px;
    }
    @media screen and (max-width: 767px) {
        padding-top: 45px;
        padding-bottom: 25px;
    }
    .video {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 0;
        overflow: hidden;
    }
    .bg-video {
        background-size: cover;
        height: auto;
        left: 50%;
        min-height: 100%;
        min-width: 100%;
        position: absolute;
        top: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        width: auto;
        z-index: -1;
    }
    .container {
      @media screen and (min-width: 768px) {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        z-index: 2;
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }
    .cta-component {
        position: relative;
        font-size: 18px;
        font-weight: 300;
        height: 100%;
        width: 100%;
        max-width: 840px;
        margin: 0 auto;
        padding: 10px 0 40px;
        z-index: 2;
        line-height: 1.667;
        @media screen and (max-width: 1023px) {
            padding: 20px 0 35px;
        }
        h2 {
            font-size: 48px;
            font-weight: normal;
            color: #fff;
            line-height: 1.25;
            margin: 0;
            font-family: "proxima-nova";
            @media screen and (max-width: 1023px) {
                font-size: 32px;
            }
            @media screen and (max-width: 767px) {
                font-size: 26px;
            }
        }
    }
    .btn {
        display: inline-block;
        vertical-align: top;
        font-size: 19px;
        line-height: 24px;
        border: 2px solid #fff;
        margin: 0;
        border-radius: 0;
        position: relative;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        text-align: center;
        overflow: hidden;
        min-width: 160px;
        padding: 13px 25px;
        white-space: normal;
        font-family: "proxima-nova", Helvetica, Arial, sans-serif !important;
        background: #fff;
        color: #c01818;
        text-decoration: none;
        &:before {
            content: "";
            line-height: 50px;
            position: absolute;
            -webkit-transition: all 0.3s;
            transition: all 0.3s;
            top: 0;
            bottom: 0;
            opacity: 0;
            right: 17px;
            background:transparent url('/img/case-studies/mcafee/chevron-right.svg#{$assetVersion}') center center no-repeat;
            width: 10px;
            background-size: 10px auto;
        }
        &:hover {
            text-decoration: none;
            &:before {
                opacity: 1;
                right: 7px;
            }
        }
        @media screen and (max-width: 1023px) {
            min-width: 140px;
            font-size: 16px;
            padding: 10px 22px;
        }
        @media screen and (max-width: 767px) {
            min-width: 126px;
            font-size: 14px;
            line-height: 18px;
            border-width: 1px;
            padding: 8px 20px;
            &:before {
                background-size: 8px auto;
            }
        }
    }
}
.full-width-content-lghtgry.mcafee-wrap {
    background: $white;
    .case-rspnsv .animated-devices .devices-holder .tablet {
        bottom: 6%;
    }
    .case-rspnsv .animated-devices .devices-holder .phone {
        bottom: 6%;
        left: 7%;
    }
    .case-info-halfw {
        padding-top: 10px;
        line-height: 1.5;
        h4 {
            margin: 0 0 18px;
        }
    }
}
.mcafee-wrap {
    position: relative;
    img {
        max-width: 100%;
        height: auto;
    }
    .txt-holder {
        max-width: 80% !important;
        font-size: 16px;
        margin: 0 auto;
        line-height: 1.5;
        text-align: center;
        @media screen and (max-width: 767px) {
        	max-width: 100% !important;
        }
        h4 {
            margin: 0 0 14px;
        }
    }
    .rbrbd-wireframes {
        margin: 0 -2%;
        &:after {
            display: block;
            clear: both;
            content:'';
        }
        .col {
            text-align: center;
            &:first-child {
                text-align: left;
            }
            &:last-child {
                text-align: right;
            }
        }
        .col img {
            max-width: 100%;
            width: auto;
            display: inline-block;
            vertical-align: top;
        }
    }
}
.gallery-description {
    text-align: center;
    font-size: 30px;
    line-height: 1.5;
    padding: 63px 0 101px;
    p {
        margin: 0;
    }
    @media screen and (max-width: 1023px) {
        padding: 50px 0 80px;
        font-size: 24px;
    }
    @media screen and (max-width: 767px) {
        padding: 30px 0 45px;
        font-size: 18px;
    }
}
.fade-gallery {
    position: static;
    margin: 0;
    img {
        width: 100%;
        height: auto;
    }
    .slick-dots {
        left: 0;
        right: 0;
        bottom: 59px;
        text-align: center;
        li {
            width: auto;
            height: auto;
        }
        li button {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: #d5d7dc;
            &:before {
                display: none;
            }
        }
        .slick-active button {
            background: #c01818;
        }
        @media screen and (max-width: 1023px) {
            bottom: 40px;
        }
        @media screen and (max-width: 767px) {
            bottom: 22px;
        }
    }
}
.light-gradient {
    background: rgb(189,190,195);
    background: -moz-linear-gradient(top, rgba(189,190,195,1) 0%, rgba(234,236,239,1) 100%);
    background: -webkit-linear-gradient(top, rgba(189,190,195,1) 0%,rgba(234,236,239,1) 100%);
    background: linear-gradient(to bottom, rgba(189,190,195,1) 0%,rgba(234,236,239,1) 100%);
}
.case-mcafee-prllx {
    background: url(/img/case-studies/mcafee/webenertia_mcafee_bg.png#{$assetVersion}) center;
    background-attachment: fixed;
    background-size: cover;
}
.ua-mobile .case-mcafee-prllx {
    background-attachment:scroll;
}
.item .case-study-mcafee-top {
    height: auto;
    background: #fff;
    &:before {
        display: none;
        @media screen and (max-width: 767px) {
            display: block;
        }
    }
    .case-banner-right-box {
        bottom: 50%;
        -webkit-transform: translateY(50%);
        transform: translateY(50%);
    }
    .case-top-wrapper {
        padding: 120px 0 0;
        min-height: 320px;
        .case-banner-left-box {
            margin-top: 10px;
        }
        @media screen and (max-width: 767px) {
            padding: 110px 0 0;
            min-height: 300px;
        }
    }
}
