.no-parallax{
	position: relative;
	z-index: 2;
}
.parallax-index{
	z-index: 0;
}
.case-study-couchbase-top {
	position: relative;
	height: 520px;
	color: $white;
	overflow: hidden;
	z-index: 2;
	video {
		position: absolute;
		min-width: 100%;
		min-height: 100%;
		width: auto;
		height: auto;
		left: 50%;
		top: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
	.case-top-wrapper {
		padding: 142px 0 0;
	}
	.case-logo-each {
		width: 370px;
	}
	@media screen and (max-width: 1023px) {
		height: 360px;
		.case-logo-each {
			width: 200px;
		}
		.case-top-wrapper {
			padding: 122px 0 0;
		}
	}
	@media screen and (max-width: 767px) {
		.case-top-wrapper {
			margin: 0 50px;
		}
		video {
			height: 100%;
			width: auto;
		}
	}
	@media screen and (max-width: 520px) {
		.case-top-wrapper {
			margin: 0 20px;
		}
	}
}
.full-width-slider.bg-white {
	background: $white;
}
.over-hidden {
	overflow: hidden;
}
.full-width-slider {
	.center-container {
		max-width: 1200px;
		margin: 0 auto;
	}
	.container .txt-holder {
		width: 80%;
		padding: 0 20px;
		margin: 0 auto;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		h4, h2 {
			color: #707071;
		    font-family: 'Poppins', serif;
		    font-weight: 300;
		    font-size: 26px;
		    letter-spacing: -0.5px;
		    line-height: 1.5;
	    	margin: 0 0 14px;
			padding-bottom: 0;
		}
	}
}
.full-width-slider .item {
	.centerd-container {
		max-width: 1240px;
		padding: 31px 20px 10px;
		margin: 0 auto;
		text-align: center;
		.iphone-grid {
			overflow: hidden;
			margin-top: 158px;
		}
		.centered {
			margin: 0 auto;
		}
		&:after {
			content:'';
			clear: both;
			display: block;
		}
		img {
			width: auto;
			max-width: 100%;
			height: auto;
			margin: 0;
			display: inline-block;
			vertical-align: top;
		}
		@media screen and (max-width: 1300px) {
			.right-align {
				margin: 0 -40px 0 0;
				padding: 0 0 0 14px;
			}
		}
		@media screen and (max-width: 1023px) {
			.right-align {
				margin: 0 -20px 0 0;
				padding: 0 0 0 20px;
			}
			.iphone-grid {
				margin-top: 7%;
			}
		}
		@media screen and (max-width: 767px) {
			.right-align {
				margin: 0;
				padding: 0 0 0 20px;
			}
		}
	}
}
.gradient-block {
	overflow: hidden;
	background: rgb(249,132,17);
    background: -moz-linear-gradient(top, rgba(249,132,17,1) 0%, rgba(245,116,22,1) 14%, rgba(238,61,34,1) 53%, rgba(249,130,18,1) 99%, rgba(241,131,23,1) 100%, rgba(241,131,23,1) 100%);
    background: -webkit-linear-gradient(top, rgba(249,132,17,1) 0%,rgba(245,116,22,1) 14%,rgba(238,61,34,1) 53%,rgba(249,130,18,1) 99%,rgba(241,131,23,1) 100%,rgba(241,131,23,1) 100%);
    background: linear-gradient(to bottom, rgba(249,132,17,1) 0%,rgba(245,116,22,1) 14%,rgba(238,61,34,1) 53%,rgba(249,130,18,1) 99%,rgba(241,131,23,1) 100%,rgba(241,131,23,1) 100%);
    position: relative;
    z-index: 2;
    &:before {
    	content:'';
    	position: absolute;
  		bottom: 0;
  		left: 0;
  		width: 0;
  		height: 0;
  		border-bottom: 895px solid $white;
  		border-right: 110vw solid transparent;
  		@media screen and (max-width: 1023px) {
  			border-bottom: 650px solid $white;
  		}
  		@media screen and (max-width: 767px) {
  			border-bottom: 420px solid $white;
  			border-right: 205vw solid transparent;
  		}
    }
    .large-block {
    	padding: 159px 0 130px;
    	color: $white;
    	position: relative;
    	z-index: 3;
    	@media screen and (max-width: 1023px) {
    		padding: 100px 0;
    	}
    	@media screen and (max-width: 767px) {
			padding: 45px 0;
    	}
    	img {
    		float: right;
    		width: auto;
    		height: auto;
    		margin: 0 -227px 0 0;
    		@media screen and (max-width: 1300px) {
				width: 90%;
    		}
    		@media screen and (max-width: 1023px) {
    			margin: 0 -20% 0 0;
    			width: 80%;
    		}
    		@media screen and (max-width: 767px) {
    			float: none;
    			width: auto;
    			max-width: 100%;
    			margin: 0 0 30px;
    		}
    	}
    	&:after {
    		content:'';
    		display: block;
    		clear: both;
    	}
    	h2 {
  			color: $white;
    	}
    	.txt-holder {
    		padding: 130px 50px 0 0;
    		@media screen and (max-width: 1300px) {
    			width: 300px;
    		}
    		@media screen and (max-width: 1023px) {
    			width: 270px;
    			padding: 0 30px 0 0;
    		}
    		@media screen and (max-width: 767px) {
    			width: 100%;
    			padding: 0;
    			text-align: center;
    		}
    	}
    }
    .txt-holder {
    	overflow: hidden;
    	font-size: 16px;
    	line-height: 24px;
    	h4, h2 {
	    	margin: 0 0 14px;
  		}
    }
    .center-block {
    	padding: 134px 0 120px;
    	text-align: center;
    	position: relative;
    	z-index: 3;
    	img {
    		display: inline-block;
    		vertical-align: top;
    		@media screen and (max-width: 1300px) {
    			max-width: 40%;
    			height: auto;
    		}
    	}
    	.txt-holder {
    		position: absolute;
    		left: 0;
    		top: 38%;
    		width: 28%;
    		text-align: left;
    	}
    	@media screen and (max-width: 1023px) {
  			padding: 100px 0;
  			img {
  				max-width: 36%;
  			}
  			.txt-holder {
  				top: 24%;
  			}
    	}
    	@media screen and (max-width: 767px) {
    		padding: 45px 0;
    		img {
  				max-width: 50%;
    			display: block;
    			margin: 0 auto 20px;
  			}
  			.txt-holder {
  				position: relative;
  				width: 100%;
  				text-align: center;
  			}
    	}
    }
}
.paralax-block {
	height: 600px;
	position: relative;
	overflow: hidden;
	z-index: -1;
	.bgcover {
		background-position: 50% 50%;
		background-repeat: no-repeat;
		-webkit-background-size: cover;
		background-size: cover;
		background-attachment: fixed;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
	}
	@media screen and (max-width: 1023px) {
		height: 500px;
	}
	@media screen and (max-width: 767px) {
		height: 350px;
	}
}
.couchbase-paralax-text {
	background: $white;
	position: relative;
	z-index: 2;
}
.couchbase-container {
	padding: 96px 0 135px;
	font-size: 16px;
	h4, h2 {
		line-height: 1.5;
	   	margin: 0 0 14px;
	}
	.txt-holder  {
		width: 80%;
	    font-size: 16px;
	    line-height: 24px;
	    text-align: center;
	    padding: 0;
	    margin: 0 auto;
	}
	@media screen and (max-width: 767px) {
		padding: 45px 0 60px;
	}
}
.gradient-block2 {
	background: rgb(30,87,153);
  background: -moz-linear-gradient(top, rgba(30,87,153,1) 0%, rgba(238,62,34,1) 0%, rgba(249,133,18,1) 100%);
  background: -webkit-linear-gradient(top, rgba(30,87,153,1) 0%,rgba(238,62,34,1) 0%,rgba(249,133,18,1) 100%);
  background: linear-gradient(to bottom, rgba(30,87,153,1) 0%,rgba(238,62,34,1) 0%,rgba(249,133,18,1) 100%);
  color: $white;
  position: relative;
  z-index: 2;
  padding: 0 0 50px;
  h2 {
  	color: $white;
  }
  .top-image {
  	position: relative;
  	@media screen and (max-width: 767px) {
  		//margin: 0;
  	}
  	img {
  		display: block;
    	margin:0 auto;
    	max-width: 100%;
  	}
  }
}
.couchbase-wireframes {
	overflow: hidden;
	margin: 0 -15px;
	-webkit-display: flex;
	display: flex;
	align-items:center;
	-webkit-flex-direction: row-reverse;
	flex-direction: row-reverse;
	@media screen and (max-width: 767px) {
		display: block;
	}
	.col {
		float: left;
		width: 33.33%;
		padding: 0 15px;
		img {
			max-width: 100%;
			height: auto;
		}
		@media screen and (max-width: 767px) {
			width: 50%;
			&:first-child {
				text-align: center;
				width: 100%;
				padding: 0 15px 30px;
			}
		}
	}
	.small-container {
    padding: 0 0 0 6%;
    text-align: left;
    @media screen and (max-width: 767px) {
    	text-align: center;
    }
	}
}
.case-couchbase-prllx {
	background-image: url(/img/case-studies/couchbase/webenertia_couchbase_bg.jpg#{$assetVersion});
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-attachment: fixed;
	-webkit-background-size: cover;
	background-size: cover;

	&.architecture {
  	background-attachment: inherit;
	}
}
.item .case-study-couchbase-top {
   background:#000 url("/img/case-studies/couchbase/couchbase_banner_bg.jpg#{$assetVersion}") center;
   background-size: cover;
   height: auto;
   .case-top-wrapper {
	    max-width: 1200px;
	    margin: 0 auto;
	    padding: 80px 0 0 0;
	    min-height: 360px;
	    position: relative;
	    -webkit-box-sizing: content-box;
	    box-sizing: content-box;
		@media screen and (max-width: 780px) {
			.case-banner-left-box {
		    	margin: 30px 0 0 0;
			}
		}
		@media screen and (max-width: 580px) {
			.case-banner-left-box {
		    	margin: 60px 0 0 0;
			}
		}
	}
   .case-logo-each {
      max-width: 236px;
   }
	h1 {
		color: $white;
		font-weight: 300 !important;
		font-size: 38px !important;
		line-height: 1 !important;
		@media screen and (max-width: 980px) {
			font-size: 30px !important;
		}
	}
   @media screen and (max-width: 767px) {
      .case-top-wrapper {
         margin: 0 40px;
         padding: 50px 0 0;
      }
      .case-logo-each {
      	width: 190px;
      }
   }

   @media screen and (max-width: 520px) {
      .case-top-wrapper {
         margin: 0 20px;
      }
   }
}
.parallax-remove{
	.paralax-block{
		position: relative;
		z-index: 2;
		.bgcover{
			position: absolute;
			background-attachment: inherit;
		}
	}
}