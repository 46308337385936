@import "variables";
@import "images";
@import "base/common";
@import "base/colors";
@import "base/fonts";

@import "base/slick";
@import "base/slick-images";
@import "base/slick-theme";
@import "base/about";
@import "base/animate";
@import "base/case-studies";
@import "base/solutions";
@import "base/homepage-animations";
@import "base/solutions-parallax";
@import "base/bottom-container";
@import "base/footer-about";
@import "base/main-nav";
@import "base/image-rotation";
@import "base/bg-video-component";
@import "base/infographic";
@import "base/overlay";
@import "base/couchbase";
@import "base/mcafee";
@import "base/cisco";
@import "base/mission";
@import "base/number-count";
@import "base/sandhill";
@import "base/sandhill-component";
@import "base/tabsets";
@import "base/jquery.fancybox";
@import "base/riverbed";
@import "base/internship";

// needed on new Wordpress site
.admin-bar {
  #header_wrapper{
    top: 32px;
    @media screen and (max-width: 782px){
      top: 46px;
    }
  }
  .sec-nav-container{
    top: 121px;
    @media screen and (max-width: 782px){
      top: 135px;
    }
  }
}

* {
  padding: 0;
  margin: 0;
  outline: none;
  font-family: "proxima-nova", arial, sans-serif;
  box-sizing: border-box;
  &:before, &:after {
    box-sizing: border-box;
  }
}

body,
html {
  height: 100%;
  width: 100%;
  color: $darkGrey;
}
a {
  cursor: pointer;
  color: $webeOrange;
}

a.hover-link {
  text-decoration: none;
  &:hover { text-decoration: underline; }
}

img {
  display: block;
  border: 0 none;
  max-width: 100%;
  height: auto;
}

.center {
  text-align: center;
}

.padding,
.container.padding {
  padding-top: 71px;
  padding-bottom: 85px;
  @media screen and (max-width: 767px) {
    padding: 40px 0;
  }
  @media screen and (max-width: 580px) {
    padding: 20px 0;
  }
}

.region-home-page-content.padding,
.full-min-height.padding {
  padding-top: 0;
  padding-bottom: 0;
  @media screen and (max-width: 767px) {
    padding: 40px 0;
  }
  @media screen and (max-width: 580px) {
    padding: 20px 0;
  }
}

/*** iPhoneX support ***/
@supports(padding: max(0px)) {
  #page > .padding,
  #page > .bottom-container,
  #page > .full-width-content,
  #page > .full-width-content-grey,
  #page .location-section .box .text,
  #page_wrapper .location-section .box .text,
  #page > .footer-section,
  #page > .work-grey-content {
    padding-left: unquote('max(12px, env(safe-area-inset-left))');
    padding-right: unquote('max(12px, env(safe-area-inset-right))');
  }

  #page .team-crsl .leadership-box {
    padding-left: unquote('calc(16px + max(20px, env(safe-area-inset-left)))');
    padding-right: unquote('calc(16px + max(20px, env(safe-area-inset-right)))');
  }

  #page .team-crsl .team-info-box {
    h5, p {
      padding-left: unquote('max(20px, env(safe-area-inset-left))');
      padding-right: unquote('max(20px, env(safe-area-inset-right))');
    }
    .lnkd-icon {
      left: unquote('max(20px, env(safe-area-inset-left))');
    }
  }

  @media screen and (max-width: 1023px) {
    #page .location-section .box .anim-btn,
    #page_wrapper .location-section .box .anim-btn {
      right: unquote('max(20px, env(safe-area-inset-right))');
    }
  }

  #page .crsl-home .slick-prev,
  #page .team-crsl .slick-prev,
  .full-width-slider .slick-slider .slick-prev {
    left: unquote('max(0px, env(safe-area-inset-left))');
  }
  #page .crsl-home .slick-next,
  #page .team-crsl .slick-next,
  .full-width-slider .slick-slider .slick-next {
    right: unquote('max(0px, env(safe-area-inset-right))');
  }

  .sandhill-casestudy .infographic-slider .slick-slider .slick-prev {
    margin-left: unquote('max(0px, calc(env(safe-area-inset-left) - 12px))');
  }
  .sandhill-casestudy .infographic-slider .slick-slider .slick-next {
    margin-right: unquote('max(0px, calc(env(safe-area-inset-right) - 12px))');
  }

  .page .cs-nav {
    padding-left: unquote('max(30px, env(safe-area-inset-left))');
    padding-right: unquote('max(30px, env(safe-area-inset-right))');
  }

  #page_wrapper .irm-infographic .irm-container {
    padding-left: unquote('max(0px, env(safe-area-inset-left))');
    padding-right: unquote('max(0px, env(safe-area-inset-right))');
  }

  #page > .full-width-content.cisco-block {
    padding-left: 0;
    padding-right: 0;
  }

  @media only screen  and (min-device-width : 375px)  and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) and (orientation : landscape) {
    #page > .full-width-content-grey > .max-container {
      margin: 0 auto;
    }
    #page > .max-container {
      margin-left: unquote('max(0px, env(safe-area-inset-left)) !important');
      margin-right: unquote('max(0px, env(safe-area-inset-right)) !important');
    }

    .bg-video-component .max-container.acmtrx .txt-holder {
      padding: 20px 0;
    }
    .ua-mobile .bg-video-component.acmtrx {
      height: 90vh;
    }

  }

}

#wrapper {
  background: $white;
}


.full-width {
  width: 100%;
  margin: 0 auto;
}

.full-min-height {
  display: flex;
  align-items: center;
  @media screen and (min-width: 1000px) {
    min-height: 405px !important;
  }
  @media screen and (min-width: 767px) and (max-width: 999px) {
    min-height: 350px !important;
  }
  &:after {
    display: block;
    content: '';
    min-height: inherit;
    font-size: 0;
  }
}

/*
.ua-ie-11 .full-min-height {
}
*/


/*** pace loader bar ***/
.pace {
  pointer-events: none;
  user-select: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transform: translate3d(0, -50px, 0);
  transition: transform .5s ease-out;

  &.pace-active {
    transform: translate3d(0, 0, 0);
  }

  .pace-progress {
    display: block;
    position: fixed;
    z-index: 2000;
    top: 0;
    right: 100%;
    width: 100%;
    height: 5px;
    background: $webeBlack;
    pointer-events: none;
  }

}

body.home.pace-done {
  background: $webeBlack;
}
body.solutions.pace-done {
  background: $webeGreen;
}
body.work.pace-done {
  background: $webeOrange;
}
body.about.pace-done {
  background: $webePurple;
}
body.blog.pace-done,
body.pace-done[class^="main-nav-"],
body.pace-done[class*=" main-nav-"] {
  background: $webeBlue;
}


#hm-sldr-wrap {
  background: url(/img/spinner3.gif#{$assetVersion}) no-repeat center center;
  width: 100%;
  min-height: 0;
}

.purple {
  background: $webePurple;
}

.orange {
  background: $webeOrange;
  color: $white;
  h1, h2, h3, h4 {
    color: $white;
  }
}

.burnt-orange {
  background: $burntOrange;
  color: $white;
}

.green {
  background: $webeGreen;
}

.light-grey-bg {
  background: $lightGrey;
}

.lightest-grey-bg {
  background: $lightestGrey;
}

.dark-blue-grey {
  background: $webeBlack;
}

.bg-white {
  background-color: $white;
}

.fixed {
  position: fixed !important;
}

.full-width-content {
  width: 100%;
}

.full-width-content-grey {
  width: 100%;
  min-height: 100px;
  background: $lightGrey;

  &.solutions {
    a {
      color: $webeGreen;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.ie-fix {
    min-height: 520px;
  }
}

.full-width-content-darkgrey {
  width: 100%;
  background: $webeBlack;
  color: $white;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#skip {
  display: none;
}

#overlay {
  z-index: 98;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: none;
}

.max-container {
  max-width: $max_width;
  margin: 0 auto;
  position: relative;

  @media screen and (max-width: 1300px) {
    margin: 0 40px;
  }

  @media screen and (max-width: 580px) {
    margin: 0 20px;
  }
}


/*** main page ***/
.why-webe {
  p {
    font-size: 17px;
  }
}

.counter-holder {
  font-size: 34px;
  color: $webeOrange;
  p {
    font-size: 34px;
  }
  .counter {
    font-weight: bold;
    font-size: 150px;
    line-height: 120px;
    letter-spacing: -2px;
    margin: 0 0 12px;
    span {
      font-size: 66%;
      display: inline-block;
      vertical-align:top;
      margin-top: 11px;
      line-height: 1;
    }
  }
  @media screen and (max-width: 1023px) {
    font-size: 24px;
    .counter {
      font-size: 100px;
      line-height: 90px;
      margin: 0 0 5px;
      span {
        margin-top: 16px;
      }
    }
  }
}


.recent-blogs {
  overflow: hidden;
  font-size: 0;
  line-height: 0;
  letter-spacing: -4px;
  padding: 32px 45px 0;
  @media screen and (max-width: 1023px) {
    padding: 32px 0 0;
  }
  @media screen and (max-width: 767px) {
    max-width: 357px;
    margin: 0 auto;
  }
  .box {
    letter-spacing: normal;
    display: inline-block;
    vertical-align: top;
    width: 33.33%;
    overflow: auto;
    padding: 0 0 15px;
    @media screen and (max-width: 767px) {
      width: 100%;
      display: block;
      font-size: 14px;
    }

    h3 {
      margin: 25px 30px 15px;
      font-weight: 300;
      font-size:  24px;
      @media screen and (max-width: 1023px) {
        margin: 25px 20px 15px;
      }
      @media screen and (max-width: 767px) {
        font-size: 22px;
      }
    }

    p {
      font-size: 16px;
      line-height: 1.25;
      padding: 0 30px 15px;
      margin: 0;
      @media screen and (max-width: 1023px) {
        padding: 0 20px 15px;
      }
    }

    a {
      text-decoration: none;

      &:hover h3 {
        color: $webeOrange;
      }

      &:hover img {
        //opacity: 0.9; //maybe do this later?
      }

    }
  }
  img {
    width: 100%;
    height: auto;
    opacity: 1;
    transition: opacity 0.3s ease;
  }
  .inner-box {
    position: relative;
    background-color: $webeBlack;
    .anim-btn {
      position: absolute;
      right: 20px;
      bottom: 20px;
    }
  }
}

.solution-grid {
  margin: 0 -15px 17px;
  font-size: 0;
  line-height: 0;
  letter-spacing: -4px;
  text-align: center;
  .box {
    display: inline-block;
    vertical-align: top;
    width: 20%;
    padding: 4px 15px 20px;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    color: $darkGrey;
    letter-spacing: 1px;
    line-height: 1.2;
    a {
      color: $webeBlack;
      text-decoration: none;
    }
    h4 {
      font-weight: bold;
      font-size: 18px;
      margin: 0 0 24px;
    }
    @media screen and (max-width: 1023px) {
      width: 33.33%;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      h4 {
        margin: 0 0 15px;
      }
    }
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      padding: 0 0 10px;
    }
  }
  .image {
    position: relative;
    margin: 0 0 17px;
    text-align: center;
    a {
      display: inline-block;
      vertical-align: top;
      *> {
        display: block;
        margin: 0 auto;
      }
    }
    img {
      display: block;
      margin: 0 auto;
    }
    @media screen and (max-width: 767px) {
      margin: 0 0 12px;
    }
  }
}
.video-section {
  overflow: hidden;
  text-align: center;

  a,
  .mcafee-video-full {
    position: relative;
    z-index: 3;
    display: block;
    height: 100%;
    text-decoration: none;
    color: $white;
    background: $webeBlack;
    &:hover {
      .bg {
        opacity: 0.7;
      }
    }
  }
  h2 {
    margin-bottom: 10px;
  }
  .bg {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    opacity: 0.8;
    transition: all 0.3s ease;
    video {
      width: 100%;
      height: auto;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background-size: cover;
      min-width: 100%;
    }
  }
  @media screen and (min-width: 1000px) {
    height: 405px;
  }
  @media screen and (min-width: 767px) and (max-width: 999px) {
    height: 350px;
  }
  @media screen and (max-width: 1023px) {
    h2 {
      font-size: 24px;
    }
  }
  @media screen and (max-width: 767px) {
    a {
      height: 240px;
    }
  }
  @media screen and (max-width: 479px) {
    .bg video {
      width: 144%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}


a.request-services {
  border-radius: 2px;
  color: $white;
  text-decoration: none;
  padding: 11px 0;
  text-align: center;
  margin: 10px 0 0;
  text-transform: uppercase !important;
  font-size: 12px;
  display: block;
  font-weight: 600;
  letter-spacing: 1px;
  transition: all 0.3s;
  font-family: proxima-nova !important;

  &.home {
    background: $webeOrange;
    border: solid 3px $webeOrange;

    &:hover {
      background: none;
      color: $webeOrange;
    }
  }

  &.case-studies {
    background: none;
    color: $webeBlack;
    border: solid 3px $webeBlack;
    max-width: 300px;
    margin: 0 auto;

    &:hover {
      background: $webeBlack;
      color: $white;
    }
  }

  &.work {
    background: $webeOrange;
    max-width: 300px;
    border: solid 3px $webeOrange;
    margin-top: 30px;

    &:visited {
      color: $white;
    }

    &:hover {
      background: none;
      color: $webeOrange;
    }
  }

  &.work-overview {
    background: none;
    max-width: 300px;
    border: solid 3px $white;
    margin-top: 30px;

    &:hover {
      background: $white;
      color: $webeOrange;
    }
  }

  &.solutions {
    background: $webeGreen;
    border: solid 3px $webeGreen;
    margin-top: 30px;
    max-width: 300px;

    &:hover {
      background: none;
      color: $webeGreen;
    }
  }

  &.solutions-overview {
    background: none;
    max-width: 300px;
    border: solid 3px $white;
    margin-top: 30px;

    &:hover {
      background: $white;
      color: $webeGreen;
    }
  }

  &.about {
    background: $webePurple;
    max-width: 280px;
    border: solid 3px $webePurple;

    &:hover {
      background: none;
      color: $webePurple;
    }
  }

  &.awards {
    background: $webeBlack;
    max-width: 300px;
    margin: 0 auto;
    border: solid 3px $webeBlack;

    &:hover {
      background: none;
      border-color: $white;
    }
  }

}


/*** work css ***/

.common-top-section {
  position: relative;
  width: 100%;
  min-height: 134px;
  margin: 0;
  padding: 134px 0 0;

}
body.solutions .common-top-section {
  background: $webeGreen;
  @media screen and (max-width: 730px) {
    padding-top: 154px;
  }
}

body.work .common-top-section {
  background: $webeOrange;
}

body.about .common-top-section {
  background: $webePurple;
}

ul.nav-sec {
  list-style: none;
}

.sec-nav-container {
  width: 100%;
  position: fixed;
  top: 89px;
  padding: 12px 0;
  z-index: 4;
}

body.solutions .sec-nav-container {
  background: $webeGreen;

  a.active {
    color: #F0FFAC !important;
  }
}
body.work .sec-nav-container {
  background: $webeOrange;

  a.active {
    color: #FFDE70 !important;
  }
}

body.about .sec-nav-container {
  background: $webePurple;

  a.active {
    color: #EDBFF2 !important;
  }
}


.sec-nav-wrapper {
  max-width: 1200px;
  margin: 0 auto;

  @media screen and (max-width: 1300px) {
    margin: 0 40px;
  }

  @media screen and (max-width: 580px) {
    margin: 0 20px;
  }

}


ul.sec-nav {
  li {
    display: inline-block;
    vertical-align: middle;
    margin: -1px 12px 1px 0;

    &::after {
      content: "|";
      color: $white;
      margin: 5px 0 0 14px;
      font-size: 70%;
      display: inline-block;
      vertical-align: top;
    }

    &:first-child::after {
      content: ":";
      margin: 3px 0 0 14px;
      font-size: 80%;
    }

    &:last-child::after {
      content: "";
      margin: 0 0 0 8px;
    }
  }

  a {
    text-transform: uppercase;
    text-decoration: none;
    color: $white;
    font-family: "proxima-nova";
    font-size: 12px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    font-weight: 400;
    margin: 0;
    line-height: 14px;
    -webkit-font-smoothing: initial;
    &:before {
      content:'';
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 1px;
      background: $white;
      transform: scale3d(0, 1, 1);
      transition: transform 0.2s;
    }
    &:hover:before {
      transform: scale3d(1, 1, 1);
    }

  }

}

.top-section-wrapper {
  margin: 0 auto;

  .solutions-inner &,
  .work-inner &,
  .about-inner & {
    background-color: $white;
    max-width: none;

    h1 {
      color: $darkGrey;
      margin-top: 0;

    }

    h2 {
      color: $webeBlack;
    }


    p {
      color: $darkGrey;
    }

    span,
    span a {
      font-family: 'Poppins', serif;
      text-decoration: none;
    }

  }

  .solutions-inner & {
    h1 a {
      font-family: 'Poppins', serif;
      color: $webeGreen;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
    p a {
      color: $webeGreen;
    }
  }

  .work-inner & {
    h1 a {
      font-family: 'Poppins', serif;
      color: $webeOrange;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
    p a {
      color: $webeOrange;
    }
  }

  .about-inner & {
    h1 a {
      font-family: 'Poppins', serif;
      color: $webePurple;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
    p a {
      color: $webePurple;
    }
  }

  .top-section-inner {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 35px;
    padding-bottom: 71px;

    @media screen and (max-width: 1300px) {
      margin: 0 40px;
      overflow: hidden;
    }
    @media screen and (max-width: 767px) {
      padding-bottom: 40px;
      padding-top: 20px;
    }
    @media screen and (max-width: 580px) {
      padding-bottom: 20px;
      margin: 0 20px;
    }
  }

  h1 {
    color: $white;
    font-weight: 300;
    font-size: 24px;
    letter-spacing: -1.2px;

    @media screen and (max-width: 780px) { font-size: 20px; }
  }

  h2 {
    color: $white;
    margin: 3px 0 30px 0;
    font-weight: bold;
    font-size: 60px;
    letter-spacing: -1px;
    text-transform: uppercase;
    line-height: 1.1;

    @media screen and (max-width: 1200px) { font-size: 50px; }

    @media screen and (max-width: 960px) {
      font-size: 40px;
      margin-bottom: 15px;
    }

    @media screen and (max-width: 768px) {
      font-size: 32px;
    }

    @media screen and (max-width: 580px) {
      font-size: 26px;
      margin-bottom: 10px;
    }
  }

  p {
    color: $white;
    font-weight: 100;
    max-width: 1000px;

    &:last-child {
      margin-bottom: 0;
    }

    @media screen and (max-width: 580px) {
      font-size: 16px;
      line-height: 1.2;
      margin-bottom: 10px;
    }

    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

  }

}

.quote-wrapper {
  background-color: $lightGrey;
  width: 100%;
}

blockquote {
  padding: 71px 40px 85px 40px;
  text-align: center;
  max-width: 1280px;
  margin: 0 auto;

  @media screen and (max-width: 767px) {
    padding: 40px;
  }

  @media screen and (max-width: 580px) {
    padding: 20px;
  }

  q {
    display: block;
    margin: 0 auto;
    max-width: 1200px;
    font-style: italic;
    font-weight: 300;
    font-size: 30px;
    letter-spacing: -1.3px;
    color: $webeBlack;
    font-family: 'Poppins', serif;
    line-height: 1.6;
    padding-bottom: 14px;

    @media screen and (max-width: 1023px) {
      font-size: 26px;
      line-height: 1.4;
      letter-spacing: -1.1px;
    }
    @media screen and (max-width: 580px) {
      font-size: 24px;
      letter-spacing: -0.5px;
    }
  }

  cite {
    display: block;
    margin: 0;
    font-size: 16px;
    line-height: 1;
    color: $darkGrey;
    font-style: normal;
  }
}


.grey-content-wrapper {
  margin: 0px auto;
}

.grey-content-box {
  padding: 30px 0 50px 0;
  margin: 0 15px 0 15px;

  h4 {
    color: $darkGrey;
    font-weight: 300;
    font-size: 26px;
    margin: 0 0 10px 0;
  }

  p {
    color: $darkGrey;
    margin: 0 0 18px 0;
    font-size: 14px;
  }
}


a.learn-more-btn {
  color: $white;
  text-transform: uppercase;
  padding: 3px 10px 2px 10px;
  text-decoration: none;
  font-size: 12px;
  font-weight: 400;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.max-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 0;
}


.drupal-logo {
  max-width: 1168px !important;
}

.clients-logo-container {
  background: $lightGrey;
}

.clients-logo-wrapper {
  padding: 0;
  display: flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  overflow: hidden;
  &.home {
    margin: 0 0 50px;
    @media screen and (max-width: 767px) {
      margin: 0 0 40px;
    }
  }
  >div {
    width: 25%;
    position: relative;
    &:before {
      content:'';
      position: absolute;
      z-index: 1;
      bottom: -3px;
      left: 0;
      right: 0;
      background-image: linear-gradient(to right, #bac1c2 15%, $lightGrey 16%);
      background-size: 22px 3px;
      background-repeat: repeat-x;
      background-position: 0 0;
      height: 3px;
    }
    &:after {
      content:'';
      position: absolute;
      z-index: 1;
      top: -4px;
      bottom: 0;
      right: -3px;
      background-image: linear-gradient(to bottom, #bac1c2 15%, $lightGrey 16%);
      background-size: 3px 22px;
      background-repeat: repeat-y;
      background-position: 0 0;
      width: 3px;
    }
    .box {
      width: 100%;
      position: relative;
      padding: 0;
      &:before {
        content: "";
        display: block;
        padding-top: 100%;
      }
    }
    @media screen and (max-width: 767px) {
      width: 50%;
    }
  }
  a {
    display: block;
    text-decoration: none;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: transparent;
    transition: all 0.3s ease;
    &:hover {
      background: $webeBlack;
      .anim-btn {
        border-color: $white;
        .arrow:before {
          border-color: $webeBlack;
        }
        .arrow:after {
          background: $webeBlack;
        }
        @media screen and (max-width: 1023px) {
          border-color: $webeBlack;
          span {
            max-width: 0;
            width: 0;
            padding: 0;
          }
        }
      }
      img.hide {
        opacity: 1;
      }
    }
  }
  img {
    width: 80%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    backface-visibility:hidden;
    &.hide {
      display: block;
      z-index: 4;
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  }
  .anim-btn {
    //z-index: 10;
    position: absolute;
    bottom: 20px;
    right: 20px;
    color: $webeBlack;
    border-color: $webeBlack;
    .arrow:before {
      border-color: $webeBlack;
    }
    .arrow:after {
      background: $webeBlack;
    }
    .ua-mobile & {
      background: transparent;
      span {
        max-width: 0;
        padding: 0;
      }
      .arrow:before {
        border-color: $webeBlack;
      }
      .arrow:after {
        background: $webeBlack;
      }
    }
    @media screen and (max-width: 1023px) {
      background: transparent;
      span {
        max-width: 0;
        padding: 0;
      }
    }
    @media screen and (max-width: 767px) {
      bottom: 10px;
      right: 10px;
    }
  }
}

#page_wrapper {
  overflow: hidden;
  position: relative;
}

.work-logo-wrapper {
  max-width: 1200px;
  margin: 40px auto;
}

.work-logo-wrapper div {
  width: 26.5%;
  display: inline-block;
  padding: 3%;
}

.work-logo-wrapper img {
  width: 100%;
}

.view-awards-container {
  padding-top: 433px;
}

/*---------------------------------------------------------------*/
/* inside webenertia page css*/
/*---------------------------------------------------------------*/

.two-thirds-wrapper {
  width: 894px;
  width: calc(100% - 310px) !important;
  display: inline-block;
  margin: 20px 0;

  h4 {
    text-align: center;
  }
}

.one-third-box {
  display: inline-block;
  width: 32%;
  text-align: center;
  vertical-align: top;

  p {
    margin: 8px 0;
  }

  &.what-we-like p {
    font-size: 16px;
  }
}

.half-width-box {
  width: 50%;
  margin: 0 -2px;
  display: inline-block;
  vertical-align: top;

  img {
    width: 100%;
  }
}

.email-list-form {
  margin: 0 0 60px 0;
}

input.input-email-list {
  -webkit-appearance: none;
  border: solid 2px $darkGrey;
  font-size: 16px;
  padding: 8px 6px;
  width: 220px;
  border-radius: 2px 0 0 2px;
  -webkit-border-radius: 2px 0 0 2px;
  text-align: center;
}

input.email-submit-btn {
  appearance: none;
  border: solid 3px $darkGrey;
  background: $darkGrey;
  font-size: 12px;
  color: $white;
  margin: 0 0 0 -8px;
  border-radius: 0 2px 2px 0;
  height: 40px;
  width: 60px;
  vertical-align: top;

  &:hover {
    background: $webeBlack;
    border: solid 3px $webeBlack;
    cursor: pointer;
  }
}

/*---------------------------------------------------------------*/
/* case studies grid css*/
/*---------------------------------------------------------------*/
.grid-container {
  padding: 80px 0 0 0;
  background: $lightGrey;
}

.grid {
  padding: 20px 40px 100px 40px;
  max-width: 1600px;
  margin: 0 auto;
  list-style: none;

  @media screen and (max-width: 580px) {
    padding: 20px;
    margin: 0;
  }

  &.creative {
    padding-bottom: 80px;
  }

  li {
    float: left;
    margin: 0;
    text-align: left;
    position: relative;
    width: 25%;

    @media screen and (max-width: 920px) {
      width: 50%;
    }

    @media screen and (max-width: 580px) {
      width: 100%;
      float: none;
    }
  }

  figure {
    margin: 0;
    position: relative;

    img {
      max-width: 100%;
      display: block;
      position: relative;

      @media screen and (max-width: 580px) {
        width: 100%;
      }
    }
  }

  figcaption {
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px 4% 40px 4%;
    background: $webeBlack;
    color: $darkGrey;
    width: 100%;

    @media screen and (max-width: 780px) {
      padding-top: 12px;
      padding-bottom: 20px;
      min-height: 90px;
    }

    h3 {
      margin: 0;
      padding: 0;
      color: $white;
      font-weight: 300;
      font-size: 24px;
      line-height: 1.5;
      @media screen and (max-width: 1300px) {
        font-size: 20px;
      }

    }
  }

}



.cs-style-3 {

  figure {
    overflow: hidden;

    img {
      -webkit-transition: -webkit-transform 0.4s;
      -moz-transition: -moz-transform 0.4s;
      transition: transform 0.4s;
    }
  }

  figcaption {
    top: auto;
    bottom: 0;
    opacity: 0;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transition: -webkit-transform 0.4s, opacity 0.1s 0.3s;
    -moz-transition: -moz-transform 0.4s, opacity 0.1s 0.3s;
    transition: transform 0.4s, opacity 0.1s 0.3s;

    a {
      position: absolute;
      bottom: 20px;
      right: 20px;
    }
  }

  .cse-itm {
    overflow: hidden;

    a {
      text-decoration: none;
    }

    img {
      -webkit-transition: -webkit-transform 0.4s;
      -moz-transition: -moz-transform 0.4s;
      transition: transform 0.4s;
    }

    .cse-cptn {
      top: auto;
      bottom: 0;
      -webkit-transform: translateY(28%);
      -moz-transform: translateY(28%);
      -ms-transform: translateY(28%);
      transform: translateY(28%);
      -webkit-transition: -webkit-transform 0.4s, opacity 0.1s 0.3s;
      -moz-transition: -moz-transform 0.4s, opacity 0.1s 0.3s;
      transition: transform 0.4s, opacity 0.1s 0.3s;
    }

  }

}


.no-touch .cs-style-3 figure:hover img,
.cs-style-3 figure.cs-hover img {
  -webkit-transform: translateY(-50px);
  -moz-transform: translateY(-50px);
  -ms-transform: translateY(-50px);
  transform: translateY(-50px);
  opacity: 1;
}

.no-touch .cs-style-3 figure:hover figcaption,
.cs-style-3 figure.cs-hover figcaption {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition: -webkit-transform 0.4s, opacity 0.1s;
  -moz-transition: -moz-transform 0.4s, opacity 0.1s;
  transition: transform 0.4s, opacity 0.1s;
}


.no-touch .cs-style-3 .cse-itm:hover img,
.cs-style-3 .cse-itm.cs-hover img {
  -webkit-transform: translateY(-50px);
  -moz-transform: translateY(-50px);
  -ms-transform: translateY(-50px);
  transform: translateY(-50px);
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  opacity: 1;
}

.no-touch .cs-style-3 .cse-itm:hover .cse-cptn,
.cs-style-3 .cse-itm.cs-hover .cse-cptn {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition: -webkit-transform 0.4s, opacity 0.1s;
  -moz-transition: -moz-transform 0.4s, opacity 0.1s;
  transition: transform 0.4s, opacity 0.1s;
}

.crsl-hm-wrppr {
  overflow: hidden;
  position: relative;
  background: $webeBlack;
  .slick-slider {
    margin: 0;
    z-index: 4;
  }
  .intro-text-holder {
    position: absolute;
    top: 89px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    text-transform: uppercase;
    text-align: center;
    opacity: 0;

    .load-done & {
      -webkit-animation-name: fadeInUpCustom;
      animation-name: fadeInUpCustom;
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
    }
  }
  .intro-text {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    padding: 15px;
    width: 100%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-family: 'Poppins', serif;
    color: white;
    font:50px/1.2 'Poppins', serif;

    @media screen and (max-width:1449px) {
      font-size: 40px;
    }
    @media screen and (max-width:1023px) {
      font-size: 32px;
    }
    @media screen and (max-width:767px) {
      font-size: 24px;
    }

    span {
      font-family: 'Poppins', serif;
    }
    strong {
      font-family: 'Poppins', serif;
      display: block;
      font-weight: bold;
      font-size: 110px;
      letter-spacing: -2px;
      margin: 0 0 -15px;
      @media screen and (max-width:1449px) {
    		font-size: 80px;
    		margin: 0 0 -7px;
    		letter-spacing: -1px;
    	}
    	@media screen and (max-width:1023px) {
			font-size: 60px;
    	}
    	@media screen and (max-width:767px) {
    		font-size: 45px;
    		margin: 0;
    	}
    }
  }
}

.grid-hm .cse-itm {
  width: 100%;
  padding-top: 120%;
  margin: 0;
  text-align: left;
  position: relative;
  overflow: hidden;
  font-size: 14px;
  @media screen and (max-width:1449px) {
		padding-top: 140%;
  }
  @media screen and (max-width:1249px) {
		padding-top: 155%;
  }
  @media screen and (max-width:1023px) {
		padding-top: 125%;
		font-size: 12px;
  }
  @media screen and (max-width:767px) {
    padding-top: 88%;
  }
  @media screen and (max-width:519px) {
		padding-top: 128%;
  }
  img {
    position: absolute;
    width: 100%;
    height: auto;
    max-width: none;
    top: 50%;
    left: 0%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: .80;
    -webkit-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
  }
  &:hover img {
    opacity: 0.7;
  }
  h3 {
		font:normal 30px/1.2 'Poppins', serif;
		margin: 0 0 13px;
		color: $white;
		@media screen and (max-width:1023px) {
			font-size: 24px;
		}
  }
  hr {
		margin: 0 0 16px;
		width: 70px;
		border:1px solid $white;
		@media screen and (max-width:1023px) {
			margin: 0 0 12px;
		}
  }
  a {
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 166px 62px 75px;
		color: $white;
		text-decoration: none;
		@media screen and (max-width:1099px) {
      padding:120px 62px 70px;
		}
		@media screen and (max-width:1023px) {
			padding: 120px 62px 60px;
		}
  }
  .head {
		overflow: hidden;
		margin: 0 0 11px;
		font-size: 14px;
		strong {
			display: inline-block;
			vertical-align: top;
			margin: 0 9px 0 0;
		}
		@media screen and (max-width:1023px) {
			font-size: 12px;
		}
  }
  .separator {
		display: inline-block;
		vertical-align: top;
		padding: 0 9px 0 0;
		height: 14px;
		border-left:1px solid $white;
  }
  p {
		text-transform: uppercase;
		margin: 0 0 4px;
		font-weight: bold;
		min-height: 0;
  }
  .large {
		font-size: 24px;
		@media screen and (max-width:1023px) {
			font-size: 17px;
		}
  }
  .anim-btn {
		position: absolute;
		right: 62px;
		bottom: 30px;
    @media screen and (max-width:1023px) {
      background: $white;
      span {
        max-width: 200px;
        padding: 0 5px 0 10px;
        z-index: 5;
      }
      .arrow:before {
        border-color: $webeBlack;
      }
      .arrow:after {
        background: $webeBlack;
      }
    }
  }
}
.crsl-home {
  .slick-prev, .slick-next {
    opacity: 0;
    -webkit-transition: opacity 0.3s ease 2.5s;
    transition: opacity 0.3s ease 2.5s;
  }
}
.crsl-home .grid-hm {
  background: $webeBlack;
  position: relative;
  &.slick-active {
    opacity: 0;
    transition: top 0.7s ease 2.3s;
    top: -100vh;
    & + .slick-active {
      -webkit-transition-delay: 2.2s;
      transition-delay: 2.2s;
    }
    & + .slick-active + .slick-active {
      -webkit-transition-delay: 2.1s;
      transition-delay: 2.1s;
    }
  }
}
.load-done .crsl-home  {
  .slick-prev, .slick-next {
    opacity: 1;
  }
  .grid-hm.slick-active {
    opacity: 1;
    top: 0;
  }
}
.anim-btn {
  display: inline-block;
  vertical-align: top;
  color: $white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  white-space: nowrap;
  line-height: 24px;
  border:1px solid $white;
  border-radius: 2px;
  position: relative;
  padding: 0 24px 0 0;
  overflow: hidden;
  transition: all 0.3s ease;
  background: transparent;
  span {
    display: inline-block;
    vertical-align: top;
    max-width: 0;
    overflow: hidden;
    color: $webeBlack;
    transition: all 0.3s ease;
  }
  .arrow {
    width: 16px;
    height: 12px;
    position: absolute;
    top: 6px;
    display: block;
    max-width: none;
    right: 5px;
    overflow: visible;
    transition: all 0.3s ease;
    &:before {
      content:'';
      position: absolute;
      transition: all 0.3s ease;
      right: 0;
      top: 50%;
      width: 9px;
      height: 9px;
      border: 2px solid $white;
      border-width: 0 2px 2px 0;
      transform:translateY(-53%) rotate(-45deg);
    }
    &:after {
      transition: all 0.3s ease;
      content:'';
      position: absolute;
      right: 1px;
      top: 50%;
      width: 14px;
      height: 2px;
      background: $white;
      margin-top: -1px;
    }
  }
  .ua-mobile & {
    background: $white;
    span {
      max-width: 200px;
      padding: 0 5px 0 10px;
    }
    .arrow:before {
      border-color: $webeBlack;
    }
    .arrow:after {
      background: $webeBlack;
    }
  }
  @media screen and (max-width: 1023px) {
    background: $white;
    span {
      max-width: 200px;
      padding: 0 5px 0 10px;
    }
    .arrow:before {
      border-color: $webeBlack;
    }
    .arrow:after {
      background: $webeBlack;
    }
  }
}
a:hover .anim-btn {
  background: $white;
  span {
    max-width: 200px;
    padding: 0 5px 0 10px;
  }
  .arrow:before {
    border-color: $webeBlack;
  }
  .arrow:after {
    background: $webeBlack;
  }
}


.play-icon {
  display: inline-block;
  vertical-align: top;
  width:  42px;
  height: 42px;
  background: url("/img/play-icon.png#{$assetVersion}") center center no-repeat;
  background-size: cover;
}


/*grid home blogs -----------------------------------------------*/

.box {
  overflow: hidden;

  a.blg-link-box {
    position: relative;

    .blg-cptn {
      top: auto;
      -webkit-transform: translateY(100%);
      -moz-transform: translateY(100%);
      -ms-transform: translateY(100%);
      transform: translateY(100%);
      -webkit-transition: -webkit-transform 0.4s, opacity 0.1s 0.3s;
      -moz-transition: -moz-transform 0.4s, opacity 0.1s 0.3s;
      transition: transform 0.4s, opacity 0.1s 0.3s;
      padding: 12px;
      background: $webeBlack;
      color: $darkGrey;
      width: 100%;
      margin: -44px 0 0 0;

      span {
        text-align: center;
        padding: 3px 10px 2px;
        border-radius: 2px;
        display: inline-block;
        background: $darkGrey;
        color: $darkGrey;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 12px;
      }
    }
  }
}



.box a.blg-link-box:hover .blg-cptn,
.box .cse-itm.cs-hover .blg-cptn {
  opacity: 1;
  -webkit-transform: translateY(3%);
  -moz-transform: translateY(3%);
  -ms-transform: translateY(3%);
  transform: translateY(3%);
  -webkit-transition: -webkit-transform 0.4s, opacity 0.1s;
  -moz-transition: -moz-transform 0.4s, opacity 0.1s;
  transition: transform 0.4s, opacity 0.1s;
}

/*---------------------------------------------------------------*/
/* case studies each css*/
/*---------------------------------------------------------------*/

.case-study-hoover-top {
  background: url("/img/case-studies/hoover/hoover_header_bg1.jpg#{$assetVersion}") center;
  background-size: cover;
}

.case-study-ea-top {
  background: url("/img/case-studies/ea/ea_header_bg.jpg#{$assetVersion}") center;
  background-size: cover;
}

.case-study-drupal-top {
  background: url("/img/case-studies/drupal/banner1.jpg#{$assetVersion}") center;
  background-size: cover;
}

.case-study-instantly-top {
  background: url("/img/case-studies/instantly/banner.jpg#{$assetVersion}") center;
  background-size: cover;

  @media screen and (max-width: 767px) {
    .case-banner-right-box {
      position: absolute;
      left: 0;

      img {
        width: 85%;
      }
    }
  }
}

.case-study-rae-top {
  background: url("/img/case-studies/rae-systems/banner.jpg#{$assetVersion}") center;
  background-size: cover;
}

.case-study-vocera-top {
  background: url("/img/case-studies/vocera/vocera_banner_2.jpg#{$assetVersion}") center;
  background-size: cover;
}

.case-study-silverpeak-top {
  background: url("/img/case-studies/silver-peak/banner.jpg#{$assetVersion}") center;
  background-size: cover;
}

.case-study-icontrol-top {
  background: url("/img/case-studies/icontrol/banner.jpg#{$assetVersion}") center;
  background-size: cover;
}

.case-study-acemetrix-top {
  background: url("/img/case-studies/acemetrix/video/webenertia_acemetrix_banner.jpg#{$assetVersion}") center;
  background-size: cover;
  position: relative;
  overflow: hidden;

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    .ua-mobile & {
      display: none;
    }
  }

  .case-logo-each {
    max-width: 230px;
  }

  .case-banner-right-box {
    /*width: 56%;*/
    width:47%;

    @media screen and (max-width: 780px) {
      width: 47%;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    @media screen and (max-width: 600px) {
      width: 100%;
      max-width: 360px;
      position: absolute;
      bottom: -15px;
    }
  }

  .case-banner-right-box img {
    max-width: 500px;
  }
}

.case-study-full-width {
  background-size: cover;

  .case-study-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    box-sizing: content-box;
  }

  .case-logo-each {
    max-width: 236px;
  }

  .case-banner-right-box {
    width:47%;

    @media screen and (max-width: 780px) {
      width: 52%;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    @media screen and (max-width: 600px) {
      width: 100%;
      max-width: 360px;
      position: absolute;
      bottom: 0;
    }
  }

}
.case-study-riverbed-top {
  background: url("/img/case-studies/riverbed/webenertia_riverbed_banner_a.jpg#{$assetVersion}") center;
  background-size: cover;

  .case-logo-each {
    max-width: 236px;
  }

  .case-banner-right-box {
    /*width: 56%;*/
    width:47%;

    @media screen and (max-width: 780px) {
      width: 52%;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    @media screen and (max-width: 600px) {
      width: 100%;
      max-width: 360px;
      position: absolute;
      bottom: 0;
    }
  }

  @media screen and (max-width: 767px) {
    .case-top-wrapper {
      margin: 0 40px;
      padding: 50px 0 0;
    }
  }

  @media screen and (max-width: 580px) {
    .case-top-wrapper {
      margin: 0 20px;
    }
  }
}

.case-study-intel-top {
  background: url("/img/case-studies/intel/banner-intel.jpg#{$assetVersion}") center;
  background-size: cover;

  .case-logo-each {
    padding-top: 30px;
    max-width: 300px;
  }

  .case-banner-right-box {
    width: 47%;

    @media screen and (max-width: 780px) {
      width: 47%;
      position: absolute;
      right: 0;
      bottom: 0;
    }

    @media screen and (max-width: 600px) {
      width: 100%;
      max-width: 360px;
      position: static;
      bottom: 0;
    }
  }

  @media screen and (max-width: 767px) {
    .case-top-wrapper {
      margin: 0 40px;
      padding: 50px 0 0;
    }
  }

  @media screen and (max-width: 580px) {
    .case-top-wrapper {
      margin: 0 20px;
    }
  }
}

.case-study-hortonworks-top {
  background: url("/img/case-studies/hortonworks/webenertia_hortonworks_banner.jpg#{$assetVersion}") center top;
  background-size: cover;

  .case-logo-each {
    max-width: 300px;
    margin-top: 40px;
  }

  .case-top-wrapper {
    min-height: 440px;

    h1 {
      color: $webeBlack;
    }

    .case-banner-right-box {
      max-width: 530px;
    }
  }
}

.case-study-vocera-top h1, .case-study-icontrol-top h1 {
  color: $darkGrey !important;
}

.rvrbed-nav-component {
  position: relative;
  box-sizing: border-box;

  .max-container {

    .rvrbd-nav-lbl-box {
      width: 48%;
      position: relative;
      display: inline-block;
      vertical-align: middle;
      @media screen and (max-width: 1023px) {
        width: 60%;
      }

      img {
        max-width: 110px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 2%;
      }

      .txt-holder {
        display: inline-block;
        vertical-align: middle;
        width: calc(100% - 150px);

      }

      @media screen and (max-width: 900px) {
        display: block;
        width: 100%;
        //position: static;
        //margin-top: 0;
      }

      @media screen and (max-width: 420px) {
        img {
          max-width: 80px;
          display: block;
          margin: 0 auto 20px;
        }

        .txt-holder {
          display: block;
          width: 100%;
        }
      }
    }

    .rvrbd-video {
      position: relative;
      display: inline-block;
      width: 48%;
      vertical-align: middle;
      right: -82px;
      @media screen and (max-width: 1023px) {
        width: 35%;
      }
      @media screen and  (max-width: 900px) {
        display: block;
        width: 100%;
        right: auto;
        margin: 0 auto;
      }

      .ua-mobile & {
        display: none;
      }

      video {
        max-width: 100%;
      }

    }

  }

}

.full-width-slider .item .hover-expand-grid {
  padding: 60px 50px 0 50px;

  .e-one {
    margin-bottom: 6px;

    img {
      width: 100%;
      max-width: 720px;
      -webkit-transition: -webkit-transform all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;

      &:hover {
        background: rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 10px 20px 0px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 10px 20px 0px 0px rgba(0, 0, 0, 0.2);
        -webkit-transform: translate(0px, -30px) scale(1.1);
        -ms-transform: translate(0px, -30px) scale(1.1);
        transform: translate(0px, -30px) scale(1.1);
      }
    }
  }

  .e-two {
    margin-bottom: 6px;

    img {
      width: 100%;
      max-width: 720px;
      -webkit-transition: -webkit-transform all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;

      &:hover {
        background: rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 10px 20px 0px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 10px 20px 0px 0px rgba(0, 0, 0, 0.2);
        -webkit-transform: translate(0px, 7px) scale(1.1);
        -ms-transform: translate(0px, 7px) scale(1.1);
        transform: translate(0px, 7px) scale(1.1);
      }
    }
  }

  .e-three {
    margin-bottom: 50px;

    img {
      width: 100%;
      max-width: 720px;
      -webkit-transition: -webkit-transform all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;

      &:hover {
        background: rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 10px 20px 0px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 10px 20px 0px 0px rgba(0, 0, 0, 0.2);
        -webkit-transform: translate(0px, 14px) scale(1.1);
        -ms-transform: translate(0px, 14px) scale(1.1);
        transform: translate(0px, 14px) scale(1.1);
      }
    }
  }
}

.case-top-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 0 0 0;
  min-height: 360px;
  position: relative;
  box-sizing: content-box;

  @media screen and (max-width: 1300px) {
    margin: 0 40px;
  }

  @media screen and (max-width: 580px) {
    margin: 0 20px;
  }

  h1 {
    color: $white;
    font-weight: 300;
    font-size: 38px;
    line-height: 1;
  }

}

.case-banner-left-box {
  width: 52%;
  display: inline-block;
  vertical-align: top;
}

.case-banner-right-box {
  width: 47%;
  display: inline-block;
  vertical-align: bottom;
  position: absolute;
  bottom: 0;
  right: 0;

  &.overflow-hidden {
    overflow: hidden;
  }

  img {
    width: 100%;
  }

}


.case-logo-each {
  width: 300px;
  margin: 0 0 20px 0;

  img {
    width: 100%;
  }

  &.rae-sys {
    margin-left: -4px;
  }

  &.indx img {
    max-width: 249px;
  }

  &.slvr-pk img {
    max-width: 249px;
  }

}




.case-info-full {
  max-width: 80%;
  margin: 0 auto;
  padding: 40px 0;
}

.case-info-half {
  width: 45%;
  display: inline-block;
  vertical-align: top;
  margin: 0 4% 0 0;

  &:last-child {
    margin-right: 0;
  }

  .dark-blue-grey & {
    p {
      color: $white;
    }
  }

  &.dplblk {
    display: block;
  }

  ul {
    color: $webeOrange;
    display: inline-block;
    vertical-align: top;
    margin: 0 60px 0 20px;

    li {
      margin: 0 0 8px 0;

      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.grybar-two-thrds {
  width: 65%;
  padding: 53px 0 40px;
  display: inline-block;
  vertical-align: top;
  margin: 0 4% 0 0;

  h4 {
    color: $darkGrey;
    font-family: 'Poppins', serif;
    font-weight: 300;
    font-size: 24px;
    margin: 0 0 10px;
  }

  ul {
    color: $webeOrange;
    display: inline-block;
    vertical-align: top;
    margin: 0 60px 0 20px;

    li {
      margin: 0 0 8px 0;

      a {
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }

    }
  }

}

.graybar-half {
  width: 50%;
  padding: 0 2% 0 0;
  display: inline-block;
  vertical-align: top;
  margin: 0 4% 0 0;
  box-sizing: border-box;

  h2 {
    margin-bottom: 10px;
    font-weight: 300;
  }

  p {
    &:last-child {
      margin: 0;
    }
  }

  @media screen and (max-width: 780px) {
    width: 100%;
    padding-bottom: 20px;
  }
  @media screen and (max-width: 520px) {
    padding-bottom: 10px;
  }

}

.graybar-one-third {
  width: 33%;
  padding: 0;
  display: inline-block;
  vertical-align: top;

  @media screen and (max-width: 780px) {
    width: 100%;
    padding-top: 20px;
  }
  @media screen and (max-width: 520px) {
    padding-top: 10px;
  }

  h2 {
    margin-bottom: 10px;
    font-weight: 300;
  }

  ul {
    color: $darkGrey;
    display: inline-block;
    vertical-align: top;
    padding: 0 15px 0 0;
    margin: 0 0 0 20px;
    font-size: 20px;
    line-height: 1.4;
    &:last-child {
      padding-right: 0;
    }

    @media screen and (max-width: 1023px) { font-size: 18px; }
    @media screen and (max-width: 500px) { font-size: 16px; }

    li {
      margin: 0 0 8px 0;

      a {
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

}

.rbrbd-wireframes {
  .col {
    width: 33.333%;
    float: left;
    padding: 10px 2%;
    box-sizing: border-box;

    img {
      width: 100%;
    }
  }

  @media screen and (max-width: 780px) {
    margin-top: 30px;
  }
}


/**/
.gmscm-cntr {
  text-align: center;

  img {
    margin: 0 auto;
    width: 100%;
    max-width: 881px;
  }
}


.full-width-cntr-mdia {
  background: $lightGrey;
  padding: 60px 0;
}

.full-width-cntr-mdia img {
  margin: 0 auto;
  width: 100%;
  max-width: 652px;
}

.full-width-lght-gry {
  background: $lightGrey;
  padding: 80px 0;
}

.full-width-slider {
  background: $lightGrey;
  text-align: center;

  &.mcafee {
    background: $white;
    padding-top: 0;

    .item img {
      max-width: none;
    }

    &.not-full-width {
      padding-top: 71px;
      .item img {
        max-width: 1200px;
        -webkit-box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
      }
    }
  }

  .item img {
    max-width: 1200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .case-logo-smaller {
    width: 70% !important;
    margin-left: 0px !important;
  }

  .riverbed-monitor {
    max-width: 1200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    left: 50px;
  }

  &.hortonworks {
    background: $white;
    //padding: 80px 0 40px;

    .item {
      img {
        width: 80%;
        max-width: 1096px;
      }
    }
  }

  &.intel {
    .slick-slider {
      //margin: 0;
    }

    .item {
      .item-wrapper {
        margin: 0 auto;
        width: 80%;
        max-width: 1200px;
      }

      .txt-holder {
        width: 100%;
        max-width: 80%;
        margin: 0 auto 40px;
        text-align: center;
      }

      &.one {
        img {
          display: inline-block;
          vertical-align: middle;
          margin-right: 4%;
          width: 50%;

          @media screen and (max-width: 700px) {
            width: 70%;
            max-width: 70%;
            display: block;
          }
        }

        .txt-holder {
          max-width: 40%;
          display: inline-block;
          vertical-align: middle;
          text-align: left;

          @media screen and (max-width: 700px) {
            width: 80%;
            max-width: 80%;
            display: block;
          }
        }
      }

    }
  }

  &.acmtrx {
    .item img {
      width: 90%;
      max-width: 1100px;
      margin-top: 40px;

      @media screen and (max-height: 860px) {
        max-width: 900px;
      }
    }
  }

  &.fs-instly {
    padding: 40px 0 0 0;
  }


}


.demo .container {
  max-width: none;
  padding: 0;
}


.drpl-prllx-box {
  background: url("/img/case-studies/drupal/addy.jpg#{$assetVersion}") center;
  background-attachment: fixed;
  background-size: cover;
  min-height: 600px;
}

.wrk-awrds-prllx {
  background: url("/img/work_aaa_bg.png#{$assetVersion}") center no-repeat #000;
  background-attachment: fixed;
  background-size: 900px;
  text-align: center;
  padding: 150px 0 80px 0;
}

.wrk-awrds-prllx h2 {
  color: $white;
  font-size: 100px;
  font-weight: 300;
  margin: 0 0 100px 0;
}

.wrk-awrds-prllx span {
  font-size: 120px;
  font-family: inherit;
  font-weight: 600;
  display: inline-block;
  min-width: 200px;
}

.awrds-yr-clmn {
  width: 16%;
  display: inline-block;
  vertical-align: top;
  padding: 26px 0 0 0;
}

.awrds-yr-clmn h2 {
  font-size: 54px;
  font-weight: 300;
}

.awrds-yr-clmn a,
.awrds-list-clmn a {
  text-decoration: none;
  font-family: 'Poppins', serif;
}

.awrds-list-clmn p {
  font-size: 14px;
}

.awrds-list-clmn-wrapper {
  width: 83%;
  display: inline-block;
}

.awrds-list-clmn {
  width: 48%;
  min-height: 200px;
  display: inline-block;
  vertical-align: top;
  font-size: 12px;

  div {
    padding-left: 50px;
  }

  h4 {
    margin: 44px 0 8px 0;
    color: $darkGrey;
    font-size: 24px;
    letter-spacing: -1.1px;
    font-weight: 300;
  }

}

.awrds-yr-brk {
  width: 100%;
  background: url("/img/hrz-dot-brk.png#{$assetVersion}") repeat-x;
  height: 9px;
  margin: 70px 0 20px 0;
}

div.awrds-bstof-shw {
  background: url("/img/work/best_show.png#{$assetVersion}") no-repeat;
  background-size: 39px 51px;
}

div.awrds-judge {
  background: url("/img/work/judges.png#{$assetVersion}") no-repeat;
  background-size: 39px 51px;
}

div.awrds-statue {
  background: url("/img/work/statue.png#{$assetVersion}") no-repeat;
  background-size: 39px 51px;
}

div.awrds-pixel {
  background: url("/img/work/icon_pixel_logo.png#{$assetVersion}") no-repeat;
  background-size: 39px 51px;
}

div.awrds-lma {
  background: url("/img/work/icon_lma.png#{$assetVersion}") no-repeat;
  background-size: 39px 51px;
}

.contact-us-form {
  input.cntc-halfw-inpt-left {
    width: 48%;
    display: inline-block;
    margin: 10px 1% 10px 0;
    padding: 18px 12px;
    border: solid 1px $white;
    font-size: 16px;
    appearance: none;
    border-radius: 0;
    box-sizing: border-box;
  }

  input.cntc-halfw-inpt-right {
    width: 48.5%;
    display: inline-block;
    margin: 10px 0 10px 1%;
    padding: 20px 12px;
    border: solid 1px $white;
    font-size: 16px;
    appearance: none;
    border-radius: 0;
    box-sizing: border-box;
  }

  select.cntc-halfw-inpt-right {
    appearance: button;
    user-select: none;
/*
    padding-end: 20px;
    padding-start: 2px;
*/
    background-color: $white;
    background-image: url(/img/select_arrow.png#{$assetVersion});
    background-position: center right;
    background-repeat: no-repeat;
    background-size: 32px;
    color: $darkGrey;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 48.5%;
    display: inline-block;
    margin: 10px 0 -24px 1%;
    padding: 18px 12px;
    border: solid 1px $white;
    border-radius: 0px;
    font-size: 16px;

    &::ms-expand {
      //display: none;
      opacity: 0.01;
    }
  }

  @-moz-document url-prefix() {
    select.cntc-halfw-inpt-right {
      //background-image: none !important;
    }
  }

  textarea.cntc-fullw-inpt {
    width: 99%;
    margin: 10px 0;
    border: solid 1px $white;
    padding: 20px 12px;
    border: solid 1px $white;
    font-size: 16px;
    -webkit-appearance: none;
    border-radius: 0;
    box-sizing: border-box;
  }

  .frm-bttn-wrapper {
    width: 300px;
    margin: 10px auto 0 auto;
  }

  input.contact-us-submit {
    -webkit-appearance: none;
    text-align: center;
    color: $white;
    margin: 10px auto;
    margin-bottom: 0;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    background: $webePurple;
    padding: 10px;
    font-size: 12px;
    font-weight: 600;
    font-family: proxima-nova;
    min-width: 300px;
    text-transform: uppercase;
    cursor: pointer;
    border: solid 3px $webePurple;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    letter-spacing: 1px;
  }

  ul.bdgt-type {
    list-style-type: none;
    width: 48%;
    display: inline-block;
    margin: 10px 1% 10px 0;
    vertical-align: top;

    li {
      float: left;
      margin: 0;
      width: 49%;
      height: 56px;
      position: relative;

      &.left {
        margin: 0 2% 0 0;
      }

      @media screen and (max-width: 580px) {
        width: 100%;
        height: 46px;

        &.left {
          margin: 0 0 10px;
        }
      }
    }

    label, input {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    input[type="radio"] {
      opacity: 0.011;
      z-index: 20;
    }

    input[type="radio"]:checked + label {
      border: 3px solid $webePurple;
      color: $webeBlack;

      label {
        padding: 16px 0;
        text-align: center;
        background: $white;
        border: 3px solid $darkGrey;
        cursor: pointer;
        z-index: 10;

        &.error {
           border: 3px solid $white;
        }

        &:hover {
           border: 3px solid $webePurple;
        }

        @media screen and (max-width: 910px) and (min-width: 810px) {
          font-size: 14px;
        }

        @media screen and (max-width: 821px) and (min-width: 811px) {
          padding: 8px 0;
        }

        @media screen and (max-width: 810px) and (min-width: 765px) {
          font-size: 12px;
          padding: 18px 0;
        }
      }
   }

    label {
      padding: 16px 0;
      text-align: center;
      background: $white;
      border: 3px solid $darkGrey;
      cursor: pointer;
      z-index: 10;

      &.error {
        border: 3px solid $white;
      }

      &:hover {
        border: 3px solid $webePurple;
      }

      @media screen and (max-width: 910px) and (min-width: 810px) {
        font-size: 14px;
      }

      @media screen and (max-width: 810px) and (min-width: 765px) {
        font-size: 12px;
        padding: 18px 0;
      }

      @media screen and (max-width: 580px) {
        padding: 12px 0;
      }
    }
  }

  input.contact-us-submit:hover {
    background: none;
    color: $webePurple;
  }

  @media screen and (max-width: 767px) {
    input.cntc-halfw-inpt-left,
    input.cntc-halfw-inpt-right,
    ul.bdgt-type {
      width: 100%;
      margin: 10px 0;
      font-size: 14px;
    }
    input.cntc-halfw-inpt-left,
    input.cntc-halfw-inpt-right {
      padding: 10px;
    }

    textarea.cntc-fullw-inpt {
      width: 100%;
      padding: 10px;
      font-size: 14px;
    }

    select.cntc-halfw-inpt-right {
      width: 100%;
      display: inline-block;
      margin: 10px 0;
      padding: 10px;
      font-size: 14px;
    }
  }

  @media screen and (max-width: 580px) {
    .frm-bttn-wrapper {
      width: 100%;
    }
    input.contact-us-submit {
      min-width: 100px;
      width: 100%;
      margin-top: 0;
    }
  }

  @media screen and (max-width: 340px) {
    input.contact-us-submit {
      max-width: 280px;
    }
  }
}

#warning {
  color: $webeRed;
  text-align: center;
  margin: -20px 0 20px 0;
}

.success {
  color: $darkGrey;
  font-weight: normal;
  font-size: 20px;
  font-family: "proxima-nova";
  max-width: 1000px;
  line-height: 1.4;
}

.error {
  outline: 2px solid $webeRed;
}

.spce-only-mbl-tw {
  display: inline-block;
}

img.map-img-rplc {
  display: none;
  width: 100%;
}

.max-container .hvr-sldr-cptn {
  margin: -80px auto 0 auto;
}

.careers-itm-box {
  display: inline-block;
}

.work-prllx-container {
  width:100%;
}

.case-prllx-container {
  width: 100%;
  text-align: center;


  img {
    margin: 0 auto;
  }

  h2 {
    color: $white;

    p {
      line-height: 1.6;
    }
  }
}

.case-vocera-prllx {
  background: url("/img/case-studies/prllx-bgs/vocera.jpg#{$assetVersion}") center;
  background-attachment: fixed;
  background-size: cover;
}

.case-silver-prllx {
  background: url("/img/case-studies/prllx-bgs/silverpeak.jpg#{$assetVersion}") center;
  background-attachment: fixed;
  background-size: cover;
}

.case-icontrol-prllx {
  background: url("/img/case-studies/prllx-bgs/icontrol.jpg#{$assetVersion}") center;
  background-attachment: fixed;
  background-size: cover;
}

.case-instantly-prllx {
  background: url("/img/case-studies/prllx-bgs/instantly.jpg#{$assetVersion}") center;
  background-attachment: fixed;
  background-size: cover;
}

.case-hoover-prllx {
  background: url("/img/case-studies/prllx-bgs/hoover.jpg#{$assetVersion}") center;
  background-attachment: fixed;
  background-size: cover;
}

.case-riverbed-prllx {
  background: url("/img/case-studies/prllx-bgs/webenertia_riverbed_bg.jpg#{$assetVersion}") center;
  background-attachment: fixed;
  background-size: cover;
}

.case-acemetrix-prllx {
  background: url("/img/case-studies/acemetrix/webenertia_acemetrix_sitemap_bg.jpg#{$assetVersion}") center;
  background-attachment: fixed;
  background-size: cover;
}

.case-hortonworks-prllx {
  background: url("/img/case-studies/hortonworks/webenertia_hortonworks_bg.jpg#{$assetVersion}") center;
  background-attachment: fixed;
  background-size: cover;
}

.case-intel-prllx {
  background: url("/img/case-studies/intel/webenertia_intel_bg.jpg#{$assetVersion}") center;
  background-attachment: fixed;
  background-size: cover;
  padding-bottom: 0;

  @media screen and (max-width: 580px) {
    padding-bottom: 0px !important;
  }

  .col {
    width: 44%;
    display: inline-block;
    vertical-align: top;
    padding: 60px 2% 0;

    @media screen and (max-width: 900px) {
      display: block;
      width: 100%;
      padding-bottom: 40px;
    }

    img {
      margin: 0;
      width: 90%;
      max-width: 500px;
    }

    h4 {
       border-bottom: solid 1px $darkGrey;
       padding-bottom: 20px;
       margin-bottom: 20px;
    }

    ol {
      margin-left: 20px;

      li {
        margin-bottom: 24px;
      }
    }
  }
}

.case-rae-prllx {
  background: url("/img/case-studies/prllx-bgs/rae.jpg#{$assetVersion}") center;
  background-attachment: fixed;
  background-size: cover;
}

.cs-scial-media-fxd {
  float: right;
  right: 100px;
  top: 60px;
  width: 110px;
  min-height: 100px;
  z-index: 1000;
  text-align: right;
  position: relative;
}

.cs-scial-media-fxd .cs-lnkdin {
  background: url(/img/case-studies/linkedin.png#{$assetVersion}) no-repeat;
  background-size: 100%;
  width: 60px;
  height: 60px;
  position: absolute;
  right: 0px;
  transition: all 0.4s ease-out;
  border: solid 3px $darkGrey;
}

.cs-scial-media-fxd .cs-lnkdin:hover {
  border: solid 3px $webeOrange;
  width: 60px;
  height: 60px;
  z-index: 8;
}

.cs-scial-media-fxd .cs-facebk {
  background: url(/img/case-studies/facebook.png#{$assetVersion}) no-repeat;
  background-size: 100%;
  width: 60px;
  height: 60px;
  position: absolute;
  right: 0px;
  top: 63px;
  transition: all 0.4s ease-out;
  border: solid 3px $darkGrey;
}

.cs-scial-media-fxd .cs-facebk:hover {
  border: solid 3px $webeOrange;
  width: 60px;
  height: 60px;
  z-index: 8;
}

.cs-scial-media-fxd .cs-twtr {
  background: url(/img/case-studies/twitter.png#{$assetVersion}) no-repeat;
  background-size: 100%;
  width: 60px;
  height: 60px;
  position: absolute;
  right: 0px;
  top: 126px;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  border: solid 3px $darkGrey;
}

.cs-scial-media-fxd .cs-twtr:hover {
  border: solid 3px $webeOrange;
  width: 60px;
  height: 60px;
  z-index: 8;
}

img.scl-icon-addths {
  max-width: 60px;
  border: solid 3px $darkGrey !important;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  z-index: 1;
}

img.scl-icon-addths:hover {
  border: solid 3px $webeOrange !important;
  z-index: 8;
}

.cntc-cnfrm {
  margin: 100px auto;
  max-width: 600px;
}

.item .case-banner-left-box .request-services-work {
  margin-top: 30px;
}

img.vcr_gif {
  width: 47%;
  position: absolute;
  bottom: 30%;
  left: 28%;
}

img.animated-monstr {
  position: absolute;
  width: 48%;
  max-width: 290px;
  left: 50%;
  top: 38%;
}

.cs-nav {
  width: 100%;
  padding: 40px 30px;
  background-color: $lightestGrey;
  border-top: solid 1px $lightGrey;
  border-bottom: solid 1px $lightGrey;
  box-sizing: border-box;
}

.cs-nav .prev {
  float: left;
}

.cs-nav .next {
  float: right;
}

.cs-nav .prev a,
.cs-nav .next a {
  color: $darkGrey;
  text-transform: uppercase;
  text-decoration: none;
  font-family: proxima-nova;
  vertical-align: middle;
  font-size: 12px;
}

.cs-nav .prev a span {
  margin: 0 6px 0 0;
}

.cs-nav .next a span {
  margin: 0 0 0 6px;
}

.cs-nav .prev a:hover,
.cs-nav .next a:hover {
  color: $webeOrange;
}

/* icontrol icons ----------------------------- */
.icntrl-icon-box {
  text-align: center;
  max-width: 540px;
  margin: 0 auto;
}

.img-mngmnt-box {
  width: 13%;
  display: inline-block;
  margin: 14px 5%;
  border-radius: 50%;
  border: solid 1px $webeBlue;
  position: relative;
  overflow: hidden;
  transition: all .4s ease-in-out;
}

.img-mngmnt-box img {
  width: 100%;
  border-style: none;
}

.img-mngmnt-box:hover {
  background-color: $white;
  transition: all .4s ease-in-out;
}

.img-mngmnt-box:hover .wht-icn.a,
.img-mngmnt-box:hover .wht-icn.b,
.img-mngmnt-box:hover .wht-icn.c,
.img-mngmnt-box:hover .wht-icn.d,
.img-mngmnt-box:hover .wht-icn.f,
.img-mngmnt-box:hover .wht-icn.g,
.img-mngmnt-box:hover .wht-icn.h {
  opacity: 1 !important;
  animation: none;
}

.img-mngmnt-box .wht-icn {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-border-radius: 50%;
}

.img-mngmnt-box .wht-icn.a {
  background-color: $webeBlue;
  -webkit-animation: iconAnimation 5s infinite;
  animation: iconAnimation 5s infinite;
}

.img-mngmnt-box .wht-icn.b {
  background-color: $webeBlue;
  -webkit-animation: iconAnimationOne 5s infinite;
  animation: iconAnimationOne 5s infinite;
}

.img-mngmnt-box .wht-icn.c {
  background-color: $webeBlue;
  -webkit-animation: iconAnimationTwo 5s infinite;
  animation: iconAnimationTwo 5s infinite;
}

.img-mngmnt-box .wht-icn.d {
  background-color: $webeBlue;
  -webkit-animation: iconAnimationThree 5s infinite;
  animation: iconAnimationThree 5s infinite;
}

.img-mngmnt-box .wht-icn.e {
  background-color: $webeBlue;
  -webkit-animation: iconAnimationFour 5s infinite;
  animation: iconAnimationFour 5s infinite;
}

.img-mngmnt-box .wht-icn.f {
  background-color: $webeBlue;
  -webkit-animation: iconAnimationFive 5s infinite;
  animation: iconAnimationFive 5s infinite;
}

.img-mngmnt-box .wht-icn.g {
  background-color: $webeBlue;
  -webkit-animation: iconAnimationSix 5s infinite;
  animation: iconAnimationSix 5s infinite;
}

.img-mngmnt-box .wht-icn.h {
  background-color: $webeBlue;
  -webkit-animation: iconAnimationSeven 5s infinite;
  animation: iconAnimationSeven 5s infinite;
}

.not-found-box {
  background: $lightGrey;
  padding: 90px 0;
  text-align: center;
}

.not-found-box div {
  max-width: 1200px;
  margin: 14vh auto;
}

.not-found-box h1 {
  color: $darkGrey;
  font-size: 120px;
  margin-bottom: 0px;
}

.not-found-box h2 {
  color: $darkGrey;
  font-size: 40px;
  margin-bottom: 60px;
}

.not-found-box a.request-services {
  margin: 10px auto 0 auto;
}

.pstr-sdw-cncl {
  max-width: 566px;
}

.cntc-tch-clmn .nav-social-box {
  text-align: left;
}

.drplpster-hlf.btm-rltv {
  vertical-align: bottom;
  position: relative;
}

h4.sltn-pkr {
  margin-bottom: 10px;
}

h1.blk-shdw {
  text-shadow: 0 0 14px $webeBlack;
}

/*---------------------------------------------------------------*/
/* media queries css*/
/*---------------------------------------------------------------*/

@media screen and (min-width: 2300px) {
  .ea-mobil-txt-box {
    margin: 0 40% 0 0;
  }
}

@media screen and (max-width: 1600px) {
  .cs-scial-media-fxd {
    right: 0px;
  }
}

@media screen and (max-width: 1300px) {
  .footer_head {
    margin: 0 40px;
  }

  .max-wrapper {
    margin: 0 40px;
  }

  .half-width-box img {
    width: auto;
  }

  img.nmbr-grn {
    margin-right: 20px;
  }

  .tabs nav ul {
    margin: 0 20px;
  }

  .wrk-awrds-prllx h2 {
    font-size: 80px;
  }

  .wrk-awrds-prllx span {
    font-size: 100px;
    min-width: 150px;
  }

}

@media screen and (max-width: 1200px) {
  .break-line {
    background-color: transparent;
  }

  .center-quote h2 {
    font-size: 26px;
  }

  .multiDicplLeft.only-desktop {
    display: none;
  }

  .multiDicplLeft.only-mobile {
    display: inline-block;
    margin: 0 0 -30px 0 !important;
  }

  .green-bubbles-wrapper {
    margin: 40px auto;
    max-width: 500px;
    display: block;
  }

  .multiDicpl-box {
    text-align: center;
    max-width: 900px;
    margin: 0 auto;
  }

  .multiDicplLeft {
    text-align: left;
    margin: 0;
    max-width: 500px;
  }

  .multiDicplRight {
    text-align: left;
    margin: 0 0 30px 0;
    max-width: 500px;
  }

  .multiDicplLeft div,
  .multiDicplRight div {
    margin: 30px 0;
  }

  .green-arrow-tab-rest span {
    font-size: 16px;
    margin: 4px 0 0 0;
  }

  .green-arrow-tab-first {
    width: 17%;
  }

  .green-arrow-tab-rest {
    width: 16%;
  }

  .green-arrow-tab-first span {
    font-size: 16px;
    margin: 4px 0 0 0;
  }

  img.hover-site-grphc {
    margin: 0 auto 0 auto;
    width: 100%;
  }

  .case-logo-each {
    width: 200px;
  }

  .case-top-wrapper h2 {
    font-size: 28px !important;
  }

  .awrds-list-clmn {
    width: 100%;
  }

  .awrds-yr-clmn {
    width: 28%;
  }

  .awrds-list-clmn-wrapper {
    width: 60%;
  }

   .max-container .hvr-sldr-cptn {
      margin: -60px auto 0 auto;
   }
}

@media screen and (max-width: 1120px) {
  .technology-box-six {
    width: 100%;
    text-align: left;
  }

  .technology-box-six img {
    width: 100px;
    float: left;
    margin: 0 4% 0 0;
  }

  img.animated-monstr {
    display: none;
  }
}

/* iPad Landscape Menu Fix */
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  .overlay ul.nav-sec {
    padding: 15px 0;
    margin: 20px auto;
  }
}

@media screen and (max-width: 980px) {
  div.addrs-clmn {
    width: 48%;
    display: inline-block;
    vertical-align: top;
  }

  div.cntc-tch-clmn {
    width: 38%;
  }

  div.sn-js-img-box {
    width: 100%;
    margin: 60px 0 0 0;
  }

  .case-twothrd {
    width: 100%;
  }

  .case-onethrd {
    width: 100%;
    text-align: center;
  }

  .tchnlgy-wrapper {
    margin: 40px 0 0 0;
  }

  .technology-box {
    width: 100%;
    text-align: left;
  }

  .technology-box img {
    width: 100px;
    float: left;
    margin: 0 4% 0 0;
  }

  .technology-box-five {
    width: 100%;
    text-align: left;
  }

  .technology-box-five img {
    width: 100px;
    float: left;
    margin: 0 4% 0 0;
  }

  .case-thrffts {
    width: 100%;
    img {
      margin: 0 auto;

    }
  }

  .case-twffts {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  .case-top-wrapper {
    padding: 40px 0 0 0;
  }

  .full-width-slider h2 {
    font-size: 26px;
  }

  .tabs {
    margin: 0 20px;
  }

  .rae-dvces {
    float: none;
    width: 100%;
    margin: 0 auto;
  }

  .case-rae-blprint {
    width: 100%;
    text-align: center;
  }

  .case-rae-blprint img {
    width: 100%;
    max-width: 512px;
    margin: 40px auto 0 auto !important;
  }

  .rae-strategy-box {
    width: 100%;
    margin: 0;
  }

  .rae-strategy-box img {
    width: 100%;
    max-width: 512px;
    margin: 40px auto 0 auto !important;
  }

  .drplpster-hlf {
    width: 100%;
  }

  .pstr-txt-box {
    margin: 0 auto;
  }

  .spce-only-mbl-neight {
    height: 60px;
    width: 100%;
  }

  .case-top-wrapper h1 {
    font-size: 30px !important;
  }
}

@media screen and (max-width: 920px) {

  .career-grey-container.full-min-height {
    display: block;
  }

  .half-width-box {
    width: 100%;
  }

  .half-width-box img {
    width: 100%;
  }

  .half-width-box img {
    display: none;
  }

  .two-thirds-wrapper {
    width: 100%;
  }

  .green-arrow-tab-first {
    margin: 0 0 0 -6px;
  }

  .green-arrow-tab-rest {
    margin: 0 0 0 -6px;
  }

  .green-arrow-tab-rest span {
    display: none;
  }

  .green-arrow-tab-first span {
    display: none;
  }

   .tab-content-column {
      width: calc(100% - 100px);
      display: inline-block;
      vertical-align: top;
      margin: 0 60px;
   }

  img.nmbr-grn {
    margin-right: 10px;
    width: 100px;
  }

  .tabs nav ul {
    margin: 0 30px;
  }

  .spce-only-mbl-tw {
    height: 120px;
    width: 100%;
  }

  .ca-sj-box {
    border-right: none;
    width: 100%;
    text-align: center;
  }

  .two-thirds-wrapper {
    width: calc(100% - 10px) !important;
    display: inline-block;
    margin: 20px 0;
  }

  .cs-scial-media-fxd {
    display: none;
  }
}

@media screen and (max-width: 780px) {
  .square-bubble {
    max-width: calc(93% - 150px);
  }

  .client-testimonial .rounded {
    width: 150px;
    height: 150px;
    margin-left: 0;
  }

  .square-bubble-txt h2 {
    font-size: 16px;
  }

  .overlay ul.nav-sec {
    min-width: 220px;
    max-width: 410px;
  }

  .overlay ul.nav-sec a {
    font-size: 13px;
  }

  .center-quote h2 {
    font-size: 20px;
  }

  img.nmbr-grn {
    width: 60px;
  }

  .tabs-content {
    margin: 0;
  }

  .case-info-half {
    width: 100%;
    padding: 20px 0 0 0;
    text-align: center;

    img {
      margin: 0 auto;
    }
  }

  .case-top-wrapper h2 {
    font-size: 22px !important;
  }

  .spce-only-mbl {
    height: 40px;
    width: 100%;
  }

  img.slvr-wrfrm {
    margin: 0;
  }

  .crsl-hm-wrppr {
    margin-top: 0;
  }

  .purple-graphic {
    width: 100%;
  }

  .purple-graphic-wrapper .nmbr-up {
    width: 50%;
  }

  .ca-sj-box {
    border-right: none;
    width: 100%;
    text-align: center;
  }

  .technology-box {
    border-bottom: solid 1px $darkGrey;
    margin: 0 0 30px 0;
    padding: 0 0 30px 0;
  }

  .technology-box-five {
    border-bottom: solid 1px $darkGrey;
    margin: 0 0 30px 0;
    padding: 0 0 30px 0;
  }

  .technology-box-six {
    border-bottom: solid 1px $darkGrey;
    margin: 0 0 30px 0;
    padding: 0 0 30px 0;
  }

   .max-container .hvr-sldr-cptn {
      margin: -40px auto 0 auto;
   }

  .grybar-two-thrds {
    width: 100%;
    padding: 40px 0 0 0;
  }

/*
  .grybar-one-thrd {
    width: 100%;
    padding: 40px 0 0 0;
  }
*/

  .case-vocera-prllx,
  .case-silver-prllx,
  .case-icontrol-prllx,
  .case-instantly-prllx,
  .case-hoover-prllx,
  .case-riverbed-prllx,
  .case-acemetrix-prllx,
  .case-hortonworks-prllx,
  .case-intel-prllx {
    background-attachment: scroll;
  }

  .mobil-top-paddng {
    padding-top: 40px;
    display: block;
  }

  .drpl-prllx-box {
    background-attachment: scroll;
    min-height: 400px;
  }

  .wrk-awrds-prllx {
    background-attachment: scroll;
  }

  div.lnkd-icon {
    background: url(/img/lnkd_icon_hover.png#{$assetVersion});
    background-size: 100%;
  }

  .item .case-top-wrapper h1 {
    font-size: 32px !important;
  }

  .item .case-top-wrapper .case-banner-left-box {
    margin: 30px 0 0 0;
  }

  .case-top-wrapper h1 {
    font-size: 28px !important;
  }

  img.vcr_gif {
    display: none;
  }

  div.rae-ipad-gif {
    display: none;
  }

  .footer_head {
    margin: 0 20px;
  }
}

@media screen and (max-width: 767px) {
  .one-third-box {
    width: 100%;
    text-align: center;
  }

  .square-bubble {
    max-width: 100%;
    display: block;
    padding: 40px 0;
    border-top: solid 8px #a3cf5f;
    border-left: none;
  }

  .case-top-wrapper {
    padding: 40px 0;
  }

  .case-banner-left-box {
    width: 100%;
  }

  .work-case-top-wrapper {
    padding: 40px 0 !important;
  }

  .case-banner-right-box {
    width: 60%;
    display: block;
    position: static;
    margin: 30px 0 -1px 0;
  }

  .full-width-slider h2 {
    font-size: 22px;
  }

  img.rounded {
    width: 33%;
    margin: 20px 0;
  }

  .ea-ticket-bg {
    background: none;
  }

  .ea-hrzntl-phn-box {
    display: none;
  }

  .ea-mobil-txt-box {
    width: 100%;
  }

  .ea-vrtcl-phn-box {
    float: none;
    width: 80%;
    max-width: 350px;
  }

  .awrds-yr-clmn {
    width: 100%;
  }

  .awrds-list-clmn-wrapper {
    width: 100%;
  }

  .wrk-awrds-prllx h2 {
    font-size: 50px;
    margin: 0 0 60px 0;
  }

  .wrk-awrds-prllx span {
    font-size: 70px;
    min-width: 100px;
  }

  .wrk-awrds-prllx {
    padding: 100px 0 60px 0;
  }

  .work-logo-wrapper {
    margin: 40px 60px;
  }

  .sldr-stlss .case-banner-right-box {
    display: none;
  }

  .overlay ul.nav-sec {
    margin: 15px auto;
    padding: 10px 0;
  }

  .client-testimonial {
    text-align: center;
  }

  .client-testimonial h4 {
    text-align: center;
  }

  a.nav-contact-us {
    max-width: 235px;
  }

  .not-found-box h1 {
    font-size: 80px;
  }

  .not-found-box h2 {
    font-size: 26px;
  }

  .not-found-box a.request-services-work {
    max-width: 270px;
  }

}

@media screen and (max-width: 700px) {
  .crsl-hm-wrppr {
    margin-top: 0;
  }
}

@media screen and (max-width: 680px) {
  .footer_section .nav-social-box {
    //text-align: center !important;
  }
}

@media screen and (max-width: 580px) {
  .multiDicpl-box {
    margin: 0 40px;
  }

  .tab-content-column {
    margin: 0;
  }

  .green-arrow-tab-first {
    margin: 0 0 0 -4px;
    padding: 10px 2% 4px 2%;
    width: 16%;
  }

  .green-arrow-tab-rest {
    margin: 0 0 0 -4px;
    padding: 10px 2% 4px 2%;
    width: 16%;
  }

  div.addrs-clmn {
    width: 100%;
  }

  div.cntc-tch-clmn {
    width: 100%;
    border-left: none;
    border-top: solid 1px $darkGrey;
    margin: 22px 0;
    padding: 14px 0 0 0;
  }

  div.sn-js-img-box {
    width: 100%;
    margin: 20px 0 0 0;
  }

  .case-logo-each {
    width: 150px;
  }

  .vcra-dgtl-dspl-box {
    background: none;
  }

  .tabs {
    margin: 0px;
  }

   .max-container .hvr-sldr-cptn {
      margin: -10px auto 0 auto;
   }

  input.carrers-input-two {
    margin: 14px 12px 14px 0;
  }

  .careers-itm-box {
    display: block;
    margin: 0 0 0 3px;
  }

  .top-section-wrapper p {
    font-size: 18px;
    line-height: 1.2;
  }

  .case-prllx-container {
    padding: 60px 0 90px 0;
  }

  .pstr-sdw-cncl {
    display: none;
  }

  .pstr-sdw-mbl {
    display: block;
  }

  ul.sec-nav li:first-child::after {
    margin: 2px 0 0 8px;
  }
  ul.sec-nav li {
    margin: 0 5px 0 0;
  }
  ul.sec-nav li::after {
    margin: 4px 0 0 9px;
  }

  .footer_head_wrapper.fixed {
    position: static !important;
  }

  .item .case-top-wrapper h1 {
    font-size: 24px !important;
  }

  .item .case-top-wrapper .case-banner-left-box {
    margin: 60px 0 0 0;
  }

  .case-banner-right-box {
    width: 80%;
  }
}

@media screen and (max-width: 520px) {

  .work-logo-wrapper div {
    width: 100%;
    display: block;
  }
  .adjusted-wrapper {
    //margin: 0 20px !important;
  }
  .overlay ul.nav-sec {
    min-width: 220px;
    max-width: 260px;
  }

  .overlay ul.nav-sec li {
    display: block;
    border-right: none;
    margin: 0;
    padding: 0;
  }

  .no-mobil {
    display: none;
  }

  .footer_head .copyright {
    display: none;
  }

  .one-third-box {
    width: 100%;
  }

  .frm-bttn-wrapper {
    width: 100%;
  }

  .multiDicpl-box {
    margin: 0 20px;
  }

  .max-wrapper {
    margin: 0 20px;
  }

  .gmscm-cntr h2 {
    font-size: 20px;
    line-height: 1.4;
  }

  .drupal-pstr-box {
    width: 17.5%;
  }

  .wrk-awrds-prllx h2 {
    font-size: 36px;
    margin: 0 0 42px 0;
  }

  .wrk-awrds-prllx span {
    font-size: 42px;
    min-width: 60px;
  }

  .career-grey-container {
    max-height: 1000px;
  }

  .ca-sj-box h4 {
    font-size: 20px;
    margin: 10px 0 0 130px;
  }

  .case-rspnsv {
    padding: 40px 0;
  }

  .drpl-prllx-box {
    min-height: 200px;
  }

  .case-top-wrapper h1 {
  }

  .case-banner-right-box {
    width: 100%;
  }

  .cs-nav {
    padding: 30px 14px;
  }
}

@media screen and (max-width: 420px) {
  .case-client-logo-box {
    display: block;
    min-height: 60px;
    width: 30%;
    border-right: none;
  }

  .square-bubble-txt {
    width: auto;
  }

  .case-banner-right-box {
    width: 100%;
  }

  input.input-email-list {
    width: 200px;
  }
}

@media screen and (max-width: 767px), screen and (max-height: 680px) {
  .overlay ul.nav-sec {
    margin: 15px auto;
    padding: 10px 0;
  }

  a.nav-contact-us {
    margin: 10px auto 0 auto;
  }
}

@media screen and (max-height: 430px) {
  a.nav-contact-us {
    margin: 5px auto 12px;
  }

  .overlay ul.nav-sec {
    display: none;
  }
}

.blog-modern-comment {
  display: none !important;
}

.hwx-canvas {
  width: 100%;
  height: auto;
  margin-top: 30px;
}

.first-four .grid-container {
  padding: 0;

  .grid {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.first-four li {
  display: none;
}


.first-four li:nth-child(1),
.first-four li:nth-child(2),
.first-four li:nth-child(3),
.first-four li:nth-child(4) {
  display: block;

 }

.case-studies-title .first-four-only {
  display: none;
}

@media screen and (max-width: 425px) {
  .team-member-box img {
    opacity: 1 !important;
  }
}


.rae-systems-wrapper {
  position: relative;
  display: inline-block;
}

.ipad-wrapper {
  max-width: 100%;
}

.rae-ipad-gif img {
  position: absolute;
  top:16%;
  left:26%;
  width: 48% !important;
}

.small-container {
  text-align: center;
  padding: 0 15px;
  max-width: 800px;
  display: block;
  font-size: 20px;
  margin: 0 auto;
  .button-wrapper {
    padding: 9px 0 0;
    a.request-services {
      display: inline-block;
      vertical-align: top;
      margin: 0 4px;
      min-width: 270px;
    }
  }
  @media screen and (max-width: 1023px) { font-size: 18px; }
  @media screen and (max-width: 767px) {
    font-size: 16px;
    .button-wrapper {
      padding: 0;
      a.request-services {
        display: block;
        min-width: 0;
        margin: 10px auto 0;
      }
    }
  }
}
.bg-gray {
  background: $lightGrey;
}
.centered {
  text-align: center;
  .request-services {
    margin: 0 auto;
  }
}
.container {
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;
  font-size: 20px;
  h3 {
    //font:30px/1.2 'Poppins', serif;
    margin: 0 0 18px;
    letter-spacing: -1.3px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 18px;
    h3 {
      font-size: 24px;
    }
  }
  @media screen and (max-width: 780px) {
    padding: 0 20px;
  }
  @media screen and (max-width: 767px) {
    font-size: 16px;
    h3 {
      margin: 0 0 12px;
    }
  }
}

.bottom-margin {
  margin-bottom: 32px;
}

.grid-3-col  {
  overflow: hidden;
  margin: 36px -15px 0;
  position: relative;
  display: flex;
  display: -ms-flexbox;
  align-items:center;
  color: $darkGrey;
  font-weight: 300;
  font-size:  17px;
  line-height: 24px;
  font-family: 'Poppins', serif;
  @media screen and (max-width: 1023px) {
    font-size: 14px;
    line-height: 20px;
  }
  @media screen and (max-width: 767px) {
    display: block;
    margin: 20px -15px 0;
  }
  * {
    font-family: 'Poppins', serif;
  }
  .col {
    padding: 11px 15px;
    width: 33.33%;
    position: relative;
    &:before {
      content:'';
      position: absolute;
      top: -9999px;
      left: 0;
      bottom: -9999px;
      border-left: 1px solid $darkGrey;
    }
    >* {
      text-align: center;
    }
    &:first-child:before {
      display: none;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      max-width: 357px;
      margin: 0 auto;
      padding: 30px 15px;
      &:before {
        border-left: none;
        top: 0;
        right: 0;
        bottom: auto;
        border-top: 1px solid $darkGrey;
      }
    }
  }
  h4 {
    color: $webeOrange;
    font:300 40px/1 'Poppins', serif;
    margin: 0 0 14px;
    letter-spacing: -2px;
    sup {
      font-size: 30%;
      vertical-align: top;
    }
    @media screen and (max-width: 1199px) {
      font-size: 34px;
    }
    @media screen and (max-width: 1023px) {
      letter-spacing: -1px;
      font-size: 24px;
    }
  }
  p {
    margin: 0;
  }
}
.custom-image-block {
  position: relative;
  overflow: hidden;
  text-align: left !important;
  img {
    max-width: none;
  }
  .text {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 65%;
  }
  p {
    a {
      color: $darkGrey;
      text-decoration: none;
      span {
        color: #e74769;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
  h4 {
    font-size: 34px;
    margin: 0 0 17px;
  }
  @media screen and (max-width: 1199px) {
    img {
      width: 300px;
      height: auto;
    }
    h4 {
      font-size: 30px;
      margin: 0 0 7px;
    }
    p {
      font-size: 15px;
    }
  }
  @media screen and (max-width: 1023px) {
    margin: 0 -8px 0 0;
    .text {
      max-width: 72%;
    }
    h4 {
      font-size: 26px;
    }
    p {
      margin: 17px 0 0 44px;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 17px 0 0;
    margin: 0;
    p {
      display: block;
      margin: 0 0 0 35px;
    }
    h4 {
      margin: 0 0 9px;
    }
    .text {
      max-width: 73%;
      top: 24px;
    }
  }
}
.location-section {
  overflow: hidden;
  text-align: center;
  p {
    margin: 0;
    font-size: 20px;
  }
  .box {
    float: left;
    width: 50%;
    @media screen and (max-width: 767px) {
      width: 100%;
      float: none;
    }
  }
  a {
    overflow: hidden;
    display: block;
    background: $webeBlack;
    text-decoration: none;
    color: $white;
    position: relative;
    &:hover {
      img {
        opacity: 0.7;
      }
    }
    @media screen and (max-width: 1023px) {
      height: 300px;
    }
    @media screen and (max-width: 767px) {
      height: 240px;
    }
  }
  img {
    width: 100%;
    max-width: none;
    opacity: 0.8;
    transition: opacity 0.3s ease;
    @media screen and (max-width: 1023px) {
      height: 100%;
      width: auto;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      height: auto;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    @media screen and (max-width: 479px) {
      height: 112%;
      width: auto;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .anim-btn {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
  .text {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    z-index: 2;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  h3 {
    color: $white;
    margin: 0 0 10px;
    letter-spacing: -1.3px;
    font:normal 30px/1.2 'Poppins', serif;
  }
  @media screen and (max-width: 1023px) {
    h3 {
      font-size: 24px;
    }
  }
}
.scroll-top-btn {
  position: fixed;
  z-index: 10;
  right: 20px;
  bottom: 20px;
  width: 47px;
  height: 47px;
  text-align: center;
  border-radius: 2px;
  overflow: hidden;
  background: rgba(43, 51, 55, 0.2);
  text-decoration: none;
  line-height: 47px;
  color: $white;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
  display: none;
  svg {
    fill: $white;
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    width: 18px;
    height: 19px;
  }
  &:hover {
    text-decoration: none;
    background: rgba(43, 51, 55, 0.6);
  }
  @media screen and (max-width: 1023px) {
    display: none !important;
  }
}

.drupal-col {
  width:33%;
  float: right;
  display: block;

  img {
    margin: 0 auto;
    float: left;
  }

  @media screen and (max-width: 1224px) {
    width: 25%;

    img {
      float: none;
    }
  }

  @media screen and (max-width: 580px) {
    width: 100%;
    float: none;
    margin: 10px auto;
    overflow: hidden;
    text-align: center;

    img {
      display: inline-block;
      margin: 0 10px;
    }
  }

}


.no-max-width {
  max-width: none !important;
}

.newest-case-studies {
  .item {
    > div {
      height: 405px;
      @media screen and (max-width:767px) {
        height: auto;
      }
    }

    .case-top-wrapper {
      @media screen and (min-width: 768px) {
        padding: 0 !important;
        top: 50%;
        transform: translateY(-50%);
      }

      @media screen and (max-width: 780px) { margin: 0px 40px; }
      @media screen and (max-width: 767px) { padding: 40px 0; }
      @media screen and (max-width: 520px) { margin: 0px 20px; }

      .case-banner-left-box {
        margin-top: 0;
      }

      h3 {
        font-weight: 300 !important;
        font-size: 38px !important;
        @media screen and (max-width: 980px) { font-size: 30px !important; }
        @media screen and (max-width: 780px) { font-size: 26px !important; }
        @media screen and (max-width: 520px) { font-size: 24px !important; }
      }

      a.request-services {
        margin-bottom: 0;
      }
    }

    .case-study-mcafee-top {

      &:before {
        content: none;
        display: none;
      }
      .case-top-wrapper {
        min-height: 0 !important;
        top:auto;
        transform: none;

        @media screen and (max-width: 1200px) { padding: 30px 0 !important; }
        @media screen and (max-width: 1000px) { padding: 40px 0 !important; }
        @media screen and (max-width: 900px) { padding: 50px 0 !important; }
        @media screen and (max-width: 767px) { padding: 40px 0 0 !important; }

        .case-banner-left-box {
          display: inline-block;
          vertical-align: middle;
          @media screen and (max-width: 767px) {
            width: 52%;
          }
          @media screen and (max-width: 522px) {
            display: block;
            width: 100%;
          }
        }

        .case-banner-right-box {
          display: inline-block;
          position: relative;
          vertical-align: middle;
          bottom: auto;
          right: auto;
          transform: none;
          @media screen and (max-width: 767px) {
            width: 46%;
            margin: 0;
          }
          @media screen and (max-width: 522px) {
            display: block;
            width: 100%;
          }

        }
      }
    }
  }


}

.item .case-study-sandhill-top {
  background: url(/img/case-studies/sandhill/SH_background.jpg) no-repeat 50% 50%;
  background-size: cover;
  height: 440px;
  @media screen and (max-width: 767px) {
    height: 410px;
    background-position: 60% 50%;
  }
  .case-top-wrapper {
    padding-top: 80px;
    min-height: 0;
    @media screen and (max-width: 1023px) {
      padding-top: 109px;
    }
    @media screen and (max-width: 767px) {
      padding-top: 62px;
    }
    @media screen and (max-width: 580px) {
      padding-top: 37px;
    }
  }
}

.item .case-study-riverbed-rebrand-top {
  background: url(/img/case-studies/riverbed_re_brand/riv_background.jpg) no-repeat 50% 50%;
  background-size: cover;
  height: 440px;
  @media screen and (max-width: 767px) {
    height: 410px;
    background-position: 60% 50%;
  }
  .case-top-wrapper {
    padding-top: 80px;
    min-height: 0;
    @media screen and (max-width: 1023px) {
      padding-top: 109px;
    }
    @media screen and (max-width: 767px) {
      padding-top: 62px;
    }
    @media screen and (max-width: 580px) {
      padding-top: 37px;
    }
  }
}

// Marketing image
#page_wrapper > img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

// fancybox styles
@media screen and (max-width: 768px) {
  .fancybox-slide {
   padding-left: 0;
   padding-right: 0;
  }
}


.stay-connected-container {
  text-align: center;
  align-items: stretch;

  @media screen and (max-width: 768px) {
    display: block;
  }

  .half-width-box {
    display: block;
    position: relative;
    margin: 0;

    .v-align {
      @media screen and (max-width: 768px) {
        position: relative;
        top: auto;
        left: auto;
        transform: none;
        padding: 40px;
      }
    }
    .mailing-box {
      padding-left: 60px;
      padding-right: 60px;
      max-width: 820px;
      @media screen and (max-width: 768px) {
        padding: 40px;
        max-width: 100%;
      }
      @media screen and (max-width: 580px) {
        padding: 20px;
      }
      h2 {
        color: $white;
      }
      p {
        color: $white;
        margin: 26px auto;
      }
      #mc_embed_signup {
        .mc-field-group {
          margin: 0 auto;
          max-width: 240px;
          padding: 0 50px 0 0;
        }
        button {
          width: 50px;
          border: 1px solid;
          background: transparent;
          text-transform: uppercase;
        }
      }
    }
    .social-box {
      padding-left: 60px;
      padding-right: 60px;
      max-width: 700px;
      @media screen and (max-width: 768px) {
        padding: 40px;
      }
      @media screen and (max-width: 580px) {
        padding: 20px;
      }
      p {
        margin: 26px auto;
      }
    }
  }
}

.thank-you-first-four {
  padding-bottom: 40px;
  .grid-container {
    background-color: $white;
  }
}

.max-wrapper .centered {
  margin-bottom: 20px;
}

a.thank-you-view-all {
  background: $webePurple;
  max-width: 280px;
  border: solid 3px $webePurple;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  color: $white;
  text-decoration: none;
  padding: 11px 0;
  text-align: center;
  margin: 0 auto;
  text-transform: uppercase !important;
  font-size: 12px;
  display: block;
  font-weight: 600;
  letter-spacing: 1px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  font-family: proxima-nova !important;
}

a.thank-you-view-all:hover {
  background: none;
  color: $webePurple;
}
