// Animation
$irm-flip-duration: 1s;
$irm-flip-rotate-0: perspective(1000px) rotateX(0);
$irm-flip-rotate-180: perspective(1000px) rotateX(180deg);
$irm-flip-rotate-neg-180: perspective(1000px) rotateX(-180deg);

/* Demo Styles */
.irm-card {
  width: 100%;
  max-width: 800px;
  height: 640px;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 50px 0;

  @media screen and (max-width: 1300px), screen and (max-height: 1100px) {
    max-width: 700px;
    height: 600px
  }

  @media screen and (max-height: 800px) {
    max-width: 500px;
    height: 400px
  }

  @media screen and (max-width: 900px) {
    width: 80%;
    height: 500px
  }
  @media screen and (max-width: 600px) {
    height: 400px
  }
  @media screen and (max-width: 420px) {
    width: 70%;
    height: 280px
  }
}
.irm-card-front {
  background: url('/img/case-studies/riverbed/webenertia_riverbed_grid_a.png#{$assetVersion}') no-repeat center center;
  background-size: 100%;
}
.irm-card-back {
  background: url('/img/case-studies/riverbed/webenertia_riverbed_grid_b.png#{$assetVersion}') no-repeat center center;
  background-size: 100%;
}

/* Card Flip */
.irm-card {
  position: relative;
  
  .irm-card-front,
  .irm-card-back {
    height: 100%;
    width: 100%;
    overflow: hidden;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transition: -webkit-transform $irm-flip-duration;
    -ms-transition: -ms-transform $irm-flip-duration;
    transition: transform $irm-flip-duration;
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
  }
  .irm-card-front {
    position: relative;
    z-index: 2;
    -webkit-transform: $irm-flip-rotate-0;
    -ms-transform: $irm-flip-rotate-0;
    transform: $irm-flip-rotate-0;
  }
  .irm-card-back {
    position: absolute;
    top: 0;
    z-index: 1;
    -webkit-transform: $irm-flip-rotate-neg-180;
    -ms-transform: $irm-flip-rotate-neg-180;
    transform: $irm-flip-rotate-neg-180;
  }
  &.irm-flipped {
    .irm-card-front {
      -webkit-transform: $irm-flip-rotate-180;
      -ms-transform: $irm-flip-rotate-180;
      transform: $irm-flip-rotate-180;
    }
    .irm-card-back {
      -webkit-transform: $irm-flip-rotate-0;
      -ms-transform: $irm-flip-rotate-0;
      transform: $irm-flip-rotate-0;
    }
  }
  &.irm-flip-index {
    .irm-card-front {
      z-index: 1;
    }
    .irm-card-back {
      z-index: 2;
    }
  }
  &.irm-flip-click,
  &.irm-flip-hover {
    cursor: pointer;
  }
}
