.case-study-riverbed2-top {
	position: relative;
	overflow: hidden;
	background: url(/img/case-studies/riverbed_re_brand/webenertia_riverbed_hero-board.jpg#{$assetVersion}) no-repeat 50% 50%;
	background-size: cover;
	.case-top-wrapper {
		height: 480px;
		min-height: 200px;
		position: relative;
		z-index: 2;
		display: flex;
		align-items:center;
		padding: 20px 0;
	}
	.case-banner-left-box {
		display: block;
	}
	.case-banner-right-box img {
		width: auto;
		max-width: 100%;
		margin: 0 0 0 auto;
	}
	@media only screen and (min-width: 1024px) and (max-height: 690px) {
		.case-top-wrapper {
			height: 360px;
		}
	}
	@media screen and (max-width: 1023px) {
		.case-top-wrapper {
			height: 320px;
			margin: 0 40px;
		}
	}
	@media screen and (max-width: 520px) {
		.case-top-wrapper {
			margin: 0 20px;
		}
	}
}
.rvrb-row {
	padding: 12px 0 30px;
	position: relative;
	margin: 0 0 83px;
	@media screen and (max-width: 767px) {
		margin: 0 0 40px;
	}
	p {
		font-family: 'Poppins', serif;
    font-size: 26px;
    font-weight: 100;
		letter-spacing: -0.5px;
		color: $darkGrey;
		margin: 0 0 44px;
		@media screen and (max-width: 767px) {
			margin: 0 0 20px;
		}
	}
	&:after {
		content:'';
		display: block;
		clear: both;
		height: 0;
	}
	img {
		max-width: 100%;
		@media screen and (max-width: 767px) {
			margin: 0 auto;
		}
	}
	.col {
		width: 50%;
		float: left;
		padding: 0 0 0 100px;
		&:last-child {
			&:before {
				content:'';
				position: absolute;
				left: 50%;
				top: -10px;
				bottom: 0;
				width: 4px;
				background: $lightGrey;
				margin-left: -2px;
			}
			@media screen and (max-width: 1023px) {
				padding-right: 0;
				padding-left: 50px;
			}
			@media screen and (max-width: 767px) {
				padding: 25px 0 0;
				&:before {
					display: none;
				}
			}
		}
		@media screen and (max-width: 1200px) {
			padding: 0 50px;
		}
		@media screen and (max-width: 1023px) {
			padding-left: 0;
		}
		@media screen and (max-width: 767px) {
			padding: 0 0 25px;
			width: 100%;
			float:none;
			text-align: center;
		}
	}
	.image-wrap {
		display: flex;
		align-items: center;
		height: 100px;
		@media screen and (max-width: 767px) {
			display: block;
			height: auto;
		}
	}
}
.rvrb-wrap .txt-holder {
    max-width: 80% !important;
    font-size: 16px;
    margin: 0 auto;
    line-height: 1.5;
    text-align: center;
    margin: 0 auto 10px;
}
.rvrb-image-grid {
	overflow: hidden;
	display: flex;
	align-itens: center;
	flex-wrap: wrap;
	position: relative;
	.col {
		width: 100%;
		position: relative;
		overflow: hidden;
		&:before {
			content: "";
			display: block;
			padding-top: 48%;
		}
		@media screen and (max-width: 767px) {
			&:before {
				display: none;
			}
		}
	}
	.col-small {
		width: 37%;
		position: relative;
		overflow: hidden;
		&:before {
			content: "";
			display: block;
			padding-top: 128%;
		}
		@media screen and (max-width: 767px) {
			width: 100%;
			&:before {
				display: none;
			}
		}
	}
	.col-large {
		width: 63%;
		position: relative;
		overflow: hidden;
		&:before {
			content: "";
			display: block;
			padding-top: 75%;
		}
		@media screen and (max-width: 767px) {
			width: 100%;
			&:before {
				display: none;
			}
		}
	}
	img {
		position: absolute;
		width: 104%;
		max-width: none;
		z-index: 2;
		top: 0;
		left: -2%;
		@media screen and (max-width: 767px) {
			position: relative;
		}
	}
	.txt-holder {
		text-align: left;
		@media screen and (max-width: 767px) {
			text-align: center;
		}
	}
	.rvrb-wrap {
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		p:last-child {
			margin: 0;
		}
		@media screen and (max-width: 767px) {
			position: relative;
			display: block;
			//padding: 40px 0;
		}
	}
}
.rvrb-box {
	overflow: hidden;
	padding: 75px 0 50px;
	.col {
		float: left;
		width: 16.66%;
		padding: 0 10px 40px;
		text-align: center;
		text-transform: uppercase;
	}
	p {
  	font-size: 14px;
		margin: 0 0 13px;
		strong {
			font-family: 'Poppins', serif;
		}
	}
	.circle {
		max-width: 140px;
		width: 100%;
		border-radius: 50%;
		overflow: hidden;
		margin: 0 auto 37px;
		&:before {
			content: "";
			display: block;
			padding-top: 100%;
		}
	}
	@media screen and (max-width: 1023px) {
		.col {
			width: 33.33%;
		}
	}
	@media screen and (max-width: 767px) {
		padding: 40px 0 20px;
		.col {
			width: 50%;
			padding: 0 10px 30px;
			&:nth-child(odd) {
				clear: both;
			}
		}
		.circle {
			margin: 0 auto 20px;
		}
		p {
			margin: 0 0 7px;
		}
	}
}
.rvrb-large-grid {
	overflow: hidden;
	margin: 0 -6px;
	.col {
		float: left;
		width: 50%;
		padding:0 6px 12px;
		overflow: hidden;
		@media screen and (max-width: 767px) {
			float: none;
			width: 100%;
			max-width: 420px;
			margin: 0 auto;
		}
	}
	.holder {
		position: relative;
		overflow: hidden;
		color: $white;
		padding: 0 0 0 210px;
		@media screen and (max-width: 1023px) {
			font-size: 20px;
			padding: 0 0 0 140px;
		}
	}
	img {
		width: 100%;
	}
	p {
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;
		bottom: 0;
		width: 210px;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content:center;
		font-size: 28px;
		line-height: 1.2;
		font-weight: 100;
		@media screen and (max-width: 1023px) {
			width: 140px;
		}
	}
}
.rvrb-row-center {
	display: flex;
	align-items: center;
	padding: 20px 0;
	.col:first-child {
		width: 54%;
		padding: 0 15px 0 0;
		p {
			font-family: 'Poppins', serif;
	    font-size: 26px;
	    margin: 0 0 20px;
	    font-weight: 100;
  		letter-spacing: -0.5px;
  		color: $darkGrey;
		}
		div {
			margin: 0 0 65px;
			&:last-child {
				margin: 0;
			}
		}
		div + p {
			margin-top: -20px;
		}
	}
	.col {
		width: 46%;
	}
	img {
		max-width: 100%;
		height: auto;
	}
	.txt-holder {
		text-align: left;
		margin: 0;
		max-width: 100% !important;
	}
	@media screen and (max-width: 767px) {
		display: block;
		padding: 0;
		.col:first-child,
		.col {
			width: 100%;
			padding: 0;
		}
		.txt-holder {
			padding: 35px 0 0;
			margin: 0 auto;
			text-align: center;
			max-width: 80% !important;
		}
	}
}
.rvrb-wrap.bg-figure {
	overflow: hidden;
	background:$lightGrey url(/img/case-studies/riverbed_re_brand/webenertia_riverbed_gradient.png#{$assetVersion}) no-repeat 50% 25%;
	background-size: cover;
	img {
		float: left;
		max-width: 100%;
		height: auto;
	}
	.txt-holder {
		text-align: left;
		float: right;
		width: 400px;
		padding: 70px 0 0;
	}
	.max-container {
		overflow: hidden;
		//padding: 113px 0;
	}
	@media screen and (max-width: 1023px) {
		img {
			width: 50%;
		}
		.max-container {
			overflow: hidden;
			padding: 80px 0;
		}
		.txt-holder {
			width: 48%;
			padding: 20px 0 0;
		}
	}
	@media screen and (max-width: 767px) {
		img {
			float:none;
			margin: 0 auto;
			width: auto;
		}
		.txt-holder {
			float:none;
			width: 100%;
			margin: 0 auto;
			text-align: center;

			h2, p {
  			color: $white;
			}
		}
	}
}
.rvrb-images {
	overflow: hidden;
	padding: 47px 0 0;
	margin: 0 -55px;
	display: flex;
	flex-wrap:wrap;
	align-items:center;
	.col {
		width: 50%;
		padding: 0 15px 6px;
		&:nth-child(even) {
			img {
				width: auto;
				max-width: 100%;
			}
		}
	}
	img {
		margin: 0 auto;
		width: 100%;
	}
	@media screen and (max-width: 1299px) {
		margin: 0 -50px;
	}
	@media screen and (max-width: 520px) {
		padding: 30px 0 0;
		margin: 0 -30px;
	}
}
.rvrb-wrap.bg-figure2 {
	overflow: hidden;
	background:$lightGrey url(/img/case-studies/riverbed_re_brand/webenertia_riverbed_gradient_line.png#{$assetVersion}) no-repeat 50% 66%;
	background-size: cover;
	position: relative;
	//padding: 140px 0  80px;
	img {
		max-width: 100%;
	}
	.video-box {
		margin: 0 -140px 0 0;
		float: right;
		position: relative;
	}
	video {
		position: absolute;
		z-index: 2;
		top: 16%;
		left: 12%;
		width: 76%;
		height: auto;
	}
	.txt-holder {
		text-align: left;
		position: absolute;
		bottom: 100px;
		left: 50%;
		margin-left: -600px;
		width: 400px;
	}
	.case-rspnsv {
		padding: 0;
	}
	@media screen and (max-width: 1299px) {
		.txt-holder {
			margin: 0;
			left: 40px;
		}
		.video-box {
			width: 65%;
		}
	}
	@media screen and (max-width: 1023px) {
		padding: 100px 0;
		.txt-holder {
			width: 350px;
			bottom: 50px;
		}
	}
	@media screen and (max-width: 767px) {
		padding: 40px 0;
		background-size: 100% auto;
		.video-box {
			float:none;
			margin: 0 auto;
			width: 80%;
		}
		.txt-holder {
			position: static;
			width: auto;
			text-align: center;
			margin: 0 auto;
			padding: 0 0 20px;
		}
	}
}