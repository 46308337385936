.irm-overlay-wrapper {
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   z-index: 4;
   display: none;

   @media screen and (max-width: 1340px) {
      background: rgba(0, 0, 0, 0.6);
   }

   &.irm-open {
      display: block;
   }

   .irm-overlay {
      position: relative;
      background: rgba(0, 0, 0, 1);
      margin: 60px auto 0;
      border-radius: 6px;
      text-align: center;
      padding: 80px 7% 80px;
      max-width: 1180px;
      -webkit-transition: all 3s ease-in-out;
      transition: all 3s ease-in-out;
      min-height: 460px;

      @media screen and (max-width: 1300px) {
         margin: 60px;
      }

      @media screen and (max-width: $tablet) {
         margin: 80px 40px;
         padding: 40px 5%;
         min-height: auto;
      }

      @media screen and (max-width: $irm-mobile) {
         margin: 0;
      }

      .irm-overlay-header {
         border-bottom: solid 1px #707071;
         margin-bottom: 40px;
         padding-bottom: 30px;

         @media screen and (max-width: $tablet) {
            margin-bottom: 20px;
            padding-bottom: 0;
         }

         h2 {
            font-size: 36px;
            color: #ffffff;
            display: inline-block;
            vertical-align: middle;
            text-transform: uppercase;
            font-weight: 700;
            line-height: 1.1;
            margin-top: 20px;

            @media screen and (max-width: 1340px) {
               font-size: 34px;
            }

            @media screen and (max-width: $tablet) {
               font-size: 26px;
            }

            @media screen and (max-width: $irm-mobile) {
               font-size: 22px;
            }

            &.num {
               font-size: 110px;
               line-height: 1;
               margin: 0 6% 0 2%;
               position: relative;

               @media screen and (max-width: 1340px) {
                  font-size: 90px;
               }

               @media screen and (max-width: $tablet) {
                  font-size: 70px;
               }

               @media screen and (max-width: $irm-mobile) {
                  font-size: 50px;
               }

               &.sm-num {
                  font-size: 62px;
                  margin: 0 2%;

                  @media screen and (max-width: 1340px) {
                     font-size: 50px;
                  }

                  @media screen and (max-width: 1030px) {
                     font-size: 40px;
                  }

                  @media screen and (max-width: $irm-mobile) {
                     font-size: 50px;
                  }
               }

               span {
                  font-size: 48px;
                  position: absolute;
                  top: 10px;

                  @media screen and (max-width: 1340px) {
                     font-size: 36px;
                  }

                  @media screen and (max-width: $tablet) {
                     font-size: 32px;
                  }

                  @media screen and (max-width: $irm-mobile) {
                     font-size: 22px;
                  }
               }
            }

            &.title {
               max-width: 46%;
               text-align: left;

               @media screen and (max-width: $irm-mobile) {
                  max-width: 100%;
                  text-align: center;
               }
            }
         }

         .outer-sphere {
            position: static;
            width: 130px;
            height: 130px;
            display: inline-block;
            vertical-align: top;
            margin: 0 4%;
            cursor: auto;

            @media screen and (max-width: $tablet) {
               width: 100px;
               height: 100px;
            }

            @media screen and (max-width: $irm-mobile) {
               width: 80px;
               height: 80px;
               display: block;
            }

            &:hover {
               -webkit-transform: scale(1.05);
               -ms-transform: scale(1.05);
               transform: scale(1.05);
               -moz-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2);
               -webkit-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2);
               box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2);

               .txt-holder {
                  background: #415464;

                  > p {
                     color: #ffffff;
                  }

                  .irm-popup > div {
                     // -webkit-transform: scale(1.02);
                     // -ms-transform: scale(1.02);
                     // transform: scale(1.02);
                     -moz-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2);
                     -webkit-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2);
                     box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2);

                     p.link:after {
                        margin-left: 12px;
                     }
                  }
               }
            }

            .txt-holder {
               background: #415464;
               border-radius: 0;

               p {
                  font-size: 20px;
                  font-weight: 700;
                  letter-spacing: 0px;

                  @media screen and (max-width: $tablet) {
                     font-size: 16px;
                  }

                  @media screen and (max-width: $irm-mobile) {
                     font-size: 14px;
                  }
               }
            }
         }
      }

      .irm-overlay-body {
         p {
            color: #ffffff;
            font-size: 25px;
            line-height: 1.2;
            margin-bottom: 50px;

            @media screen and (max-width: 1100px) {
               font-size: 20px;
            }

            @media screen and (max-width: $tablet) {
               font-size: 18px;
            }

            @media screen and (max-width: $irm-mobile) {
               font-size: 18px;
            }
         }

         a {
            text-decoration: none;
            border: 0;
            outline: 0;
         }

         .document-col {
            display: inline-block;
            vertical-align: top;
            width: 80%;
            max-width: 330px;
            padding: 0 14px;
            text-align: left;
            cursor: pointer;
            margin-bottom: 20px;

            img {
               max-width: 50px;
               margin-right: 16px;
               float: left;

               @media screen and (max-width: $tablet) {
                  max-width: 40px;
               }

               @media screen and (max-width: $irm-mobile) {
                  max-width: 34px;
               }
            }

            p.link {
               color: #ff681d;
               font-size: 18px;
               text-transform: capitalize;
               font-weight: 600;
               line-height: 1.1;
               margin-bottom: 0;
               position: relative;

               &:after {
                  position: absolute;
                  content: "";
                  width: 16px;
                  height: 16px;
                  background: url("/img/case-studies/riverbed/infographic/arrow-icons.png#{$assetVersion}") no-repeat 0 0;
                  background-size: 400%;
                  margin: 2% 0 0 6px;
                  -webkit-transition: all 0.3s ease-out;
                  transition: all 0.3s ease-out;

                  @media screen and (max-width: $irm-mobile) {
                     width: 14px;
                     height: 14px;
                  }

                  @media screen and (max-width: $mobile-sm) {
                     width: 12px;
                     height: 12px;
                  }
               }

               @media screen and (max-width: $tablet) {
                  font-size: 16px;
               }

               @media screen and (max-width: $irm-mobile) {
                  font-size: 14px;
               }
            }

            &:hover {
               p.link:after {
                  margin-left: 12px;
               }
            }
         }
      }
   }
}

#irm-agility p.link {
   color: #5fb4e5;
}

#irm-visibility p.link {
   color: #ff681d;

   &:after {
      background: url("/img/case-studies/riverbed/infographic/arrow-icons.png#{$assetVersion}") no-repeat 29% 0;
      background-size: 400%;
   }
}

#irm-performance p.link {
   color: #5b7e96;

   &:after {
      background: url("/img/case-studies/riverbed/infographic/arrow-icons.png#{$assetVersion}") no-repeat 100% 0;
      background-size: 400%;
   }
}

#irm-security p.link {
   color: #ffb81d;

   &:after {
      background: url("/img/case-studies/riverbed/infographic/arrow-icons.png#{$assetVersion}") no-repeat 64% 0;
      background-size: 400%;
   }
}

.irm-close {
   position: absolute;
   top: 16px;
   right: 16px;
   width: 30px;
   height: 30px;
   display: inline-block;
   cursor: pointer;
   -webkit-transition: all 0.3s ease-out;
   transition: all 0.3s ease-out;

   &:hover {
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
   }
}

.irm-close::before, .irm-close::after {
   content: '';
   position: absolute;
   height: 3px;
   width: 22px;
   background-color: #ffffff;
   top: 50%;
   left: 50%;
   border-radius: 2px;
}

.irm-close::before {
   transform: translate(-50%, -50%) rotate(45deg);
}

.irm-close::after {
   transform: translate(-50%, -50%) rotate(-45deg);
}
