/**NOTES:
For future interns:

- in-session means we are hiring
- not-in-session means we are NOT hiring

These classes are applied to the body tag found in header.php
Happy coding!
*/

/* SPECIFIC STUFF */

// when in hiring session
.in-session {
  // hide in body
  #internship-session {
    display: none !important;
  }
}

// when not in hiring session
.not-in-session {
  // hide in body
  #hiring {
    display: none !important;
  }

  // hide nav
  .hiring-only {
    display: none !important;
  }
}

// header
#header_wrapper {

  // regular nav
  #nav-menu {
    position: absolute;
    z-index: 2;
    right: 0;
    padding-top: 30px;

    ul {
      list-style: none;
      li {
        display: inline;
        text-align: center;
        padding: 0 30px;

        &:last-child {
          padding-right: 0;
        }

        a {
          color: #707071;
          font-size: 20px;
          line-height: 30px;
          font-family: 'Poppins', serif;


          // basic link overwrite
          &:visited,
          &:link {
            text-decoration: none;
          }

          // on hover
          display: inline-block;
          vertical-align: top;
          position: relative;

          &:before {
            // positioning
            position: absolute;
            bottom: -3px;
            left: 0;

            // dimensions
            content: '';
            width: 100%;
            height: 2px;
            background: #707071;

            // animation
            -webkit-transform: scale3d(0, 1, 1);
            transform: scale3d(0, 1, 1);
            -webkit-transition: -webkit-transform 0.1s;
            transition: -webkit-transform 0.1s;
            transition: transform 0.1s;
            transition: transform 0.1s, -webkit-transform 0.1s, -o-transform 0.1s;
          }

          &:hover {
            text-decoration: none;
            &:before {
              -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
              -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
              transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
              -webkit-transition-duration: 0.3s;
              transition-duration: 0.3s;
            }
          }
        }
        .nav-active {
          text-decoration: none;
          &:before {

            //animation
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
            -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
          }
        }

      }
    }
  }

  // hamburger nav
  #nav-burger {

    /* positioning */
    z-index: 9999;
    position: absolute;
    right: 0;
    margin-top: 20px;
    display: inline-block;
    overflow: visible;
    padding: 15px;

    /* animation */
    cursor: pointer;
    transition-timing-function: linear;
    transition-duration: .15s;
    transition-property: opacity, filter;
    text-transform: none;
    background-color: transparent;

    #burger-box {
      position: relative;
      display: inline-block;
      width: 32px;
      height: 24px;

      #burger-patty {
        transition-timing-function: cubic-bezier(.55, .055, .675, .19);
        transition-duration: 75ms;
        position: absolute;
        width: 32px;
        height: 2px;
        transition-property: transform;
        border-radius: 4px;
        background-color: #707071;
        top: 50%;
        display: block;
        margin-top: -2px;

        /* buns */
        &:before {
          transition: top 75ms ease .12s, opacity 75ms ease;
          top: -7px;
          display: block;
          content: '';
          position: absolute;
          width: 32px;
          height: 2px;
          transition-timing-function: ease;
          transition-duration: .15s;
          transition-property: transform;
          border-radius: 4px;
          background-color: #707071;
        }

        &:after {
          transition: bottom 75ms ease .12s, opacity 75ms cubic-bezier(.55, .055, .675, .19);
          bottom: -7px;
          display: block;
          content: '';
          position: absolute;
          width: 32px;
          height: 2px;
          transition-timing-function: ease;
          transition-duration: .15s;
          transition-property: transform;
          border-radius: 4px;
          background-color: #707071;
        }

        // burger activate
        // pledge
        &.is-active {
          transition-delay: .12s;
          transition-timing-function: cubic-bezier(.215, .61, .355, 1);
          transform: rotate(45deg);

          // turn
          &:before {
            top: 0;
            transition: top 75ms ease, opacity 75ms ease .12s;
            opacity: 0;
          }

          // prestige
          &:after {
            bottom: 0;
            transition: bottom 75ms ease, transform 75ms cubic-bezier(.215, .61, .355, 1) .12s;
            transform: rotate(-90deg);
          }
        }
      }
    }
  }
}

// magical burger nav
#hamnav-container {
  display: none;
  overflow-y: auto;

  /* positioning */
  position: fixed;
  left: 0px;
  top: 89px;
  z-index: 3;

  /* dimensions */
  height: calc(100vh - 89px);
  width: 100%;

  /* background */
  background-color: #da254d;

  #nav-for-hamburger {
    position: relative;

    #hamburger-nav {
      list-style-type: none;
      text-align: center;

      // center me please
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 50%);

      @media screen and (max-width: 767px) {
        position: relative;
        top: unset;
        left: unset;
        transform: none;
      }

      li {
        // alignment
        vertical-align: middle;
        padding: 30px;

        a {
          font-family: 'Poppins', serif;
          font-size: 44px !important;
          color: white;
          line-height: 52.8px;
          position: relative;

          &:visited,
          &:link {
            text-decoration: none;
          }

          // on hover
          display: inline-block;
          vertical-align: top;

          &:before {
            // positioning
            position: absolute;
            bottom: -3px;
            left: 0;

            // dimensions
            content: '';
            width: 100%;
            height: 2px;
            background: #ffffff;

            // animation
            -webkit-transform: scale3d(0, 1, 1);
            transform: scale3d(0, 1, 1);
            -webkit-transition: -webkit-transform 0.1s;
            transition: -webkit-transform 0.1s;
            transition: transform 0.1s;
            transition: transform 0.1s, -webkit-transform 0.1s, -o-transform 0.1s;
          }

          &:hover {
            text-decoration: none;
            &:before {
              -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
              -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
              transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
              -webkit-transition-duration: 0.3s;
              transition-duration: 0.3s;
            }
          }
        }

        .nav-active {
          text-decoration: none;
          &:before {

            //animation
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
            -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
          }
        }


        @media screen and (max-width: 767px) {
          a { font-size: 36px !important; }
          padding: 15px;
        }
      }
    }
  }
}

// header-blade
.header-blade {
  position: relative;
  text-align: center;
  overflow: hidden;

  // header blade background image
  background: linear-gradient(rgba(20, 20, 20, 0.5), rgba(20, 20, 20, 0.5)), url("/img/Microsite_Hero.jpg");
  background-position: center center;
  background-size: cover;
  height: 889px;
  @media screen and (max-width: 760px) {
    height: 689px;
  }

  // landing page text
  .text {
    color: #ffffff;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 100%;
    max-width: 1100px;
    margin: auto;
    margin-top: 44.5px;

    // title
    h3 {
      font-size: 72px;
      line-height: 1;
      letter-spacing: -0.09px;
      color: #ffffff;
      width: 100%;
      margin: auto;
      margin-bottom: 24px;
      font-weight: bold;
    }

    // subtext
    p {
      font-size: 20px;
      line-height: 28px;
      margin: 0 auto;
      max-width: 950px;
    }


    @media screen and (max-width: 1300px) {
      padding: 0 40px;
      max-width: 800px;
    }

    @media screen and (max-width: 1023px) {
      h3 {
        font-size: 34px;
      }
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 40px;
      margin-top: 20px;
      max-width: 600px;

      h3 {
        font-size: 30px;
        width: 100%;
      }

      p {
        max-width: 550px;
      }
    }

    @media screen and (max-width: 580px) {
      margin-bottom: 20px;
      padding: 0 20px;
    }
  }
}

// about-us
.about-us {

  // text box
  .box {
    height: 566px;
    display: flex;
    align-content: center;
    flex-direction: column;
    -ms-flex-align: center;

    // text span inside box
    .text {
      max-width: 500px;
      text-align: left;
      margin: auto;  // edge center fix

      @media screen and (max-width: 1300px) {
        max-width: 100%;
        padding: 0 40px;
      }

      @media screen and (max-width: 767px) {
        margin-bottom: 40px;
        margin-top: 20px;
      }

      @media screen and (max-width: 580px) {
        margin-bottom: 20px;
        padding: 0 20px;
      }

      .blade-title {
        margin: 0 0 15px;
      }
    }

    &:first-child {
      background-color: #da254d;
      * {
        color: #ffffff;
      }
    }

    &.about-us-image {
      background: url("/img/About_Image.jpg");
      background-size: cover;
      background-position: center center;
    }

    @media screen and (max-width: 1023px) {
      display: block;
      height: auto;
      padding: 40px 0;

      .text {
        max-width: none;
        text-align: center;

        .blade-title {
          margin-bottom: 24px;
        }
      }
    }
  }
}

// logos
.logos {
  .solutions-grid { overflow: hidden; }

  h2 {
    max-width: 1002px;
    margin: 0 auto 25px auto;
    font-size: 30px;
    line-height: 35px;

    @media screen and (max-width: 1300px) {
      max-width: none;
      padding: 0 40px;
    }

    @media screen and (max-width: 1023px) {
      font-size: 26px;
    }

    @media screen and (max-width: 767px) {
      letter-spacing: -1px;
    }

    @media screen and (max-width: 580px) {
      padding: 0 20px;
    }

    @media screen and (max-width: 500px) {
      font-size: 24px;
    }
  }

  // logo images
  img {
    @media screen and (max-width: 1023px) {
      margin: auto;
    }
    @media screen and (max-width: 760px) {
      margin-top: 10px;
    }
  }
}

// about-internship
.about-internship {
  position: relative;
  text-align: center;
  padding-top: 0!important;

  .blade-title {
    margin-bottom: 24px;
  }

  // about internship text wrapper
  .about-internship-text {
    max-width: 955px;
    width: 100%;
    margin: auto;

    // red lines
    .red-break {
      margin-bottom: 18px;
      color: #da254d;
      background-color: #da254d;
      border: none;
      height: 5px;
    }

    // paragraphs
    p {
      margin: 0 auto 18px auto;
    }

    // @media screen and (max-width: 1300px) {
    //   padding: 0 40px;
    // }

    @media screen and (max-width: 1023px) {
      max-width: none;
      padding: 0 40px;
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 40px;
      margin-top: 40px;
    }

    @media screen and (max-width: 580px) {
      margin-bottom: 20px;
      padding: 0 20px;
    }

    .row {
      width: 100%;
      padding-top: 46px;

      @media screen and (max-width: 767px) { padding: 0 }

      .col {
        max-width: 218px;
        .icon-wrapper {
          width: 70px;
          margin-bottom: 10px;
          &:first-child { width: 80px; }

          .icon {
            height: 60px;
            &.designer {
              background: url("/img/Designer_icon.svg") no-repeat 50% 50%;
            }
            &.developer {
              background: url("/img/Developer_icon.svg") no-repeat 50% 50%;
            }
            &.project-manager {
              background: url("/img/PM_Icon.svg") no-repeat 50% 50%;
            }
          }
        }

        .blade-title {
          font-size: 18px;
          line-height: 1.2;
          font-weight: bold;
          font-family: proxima-nova, 'arial', 'sans-serif';

          @media screen and (max-width: 767px) { letter-spacing: 0px !important; }
        }

        p {
          font-size: 12px;
          line-height: 1.5;
          margin-bottom: 58px;
          letter-spacing: 0.01px;
          font-weight: bold;

          @media screen and (max-width: 1023px) {
            font-size: 14px;
            line-height: 1.2;
          }
        }
      }
    }
  }

  .apply {
    margin: 47px auto 0 auto;
  }
}

// slider-for-mobile
.slider-for-mobile {
  position: relative;

  .slider-image {
    width: 100%;

    // the pledge
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;

    // the turn
    -webkit-transition: opacity 1.5s ease-in-out;
    -moz-transition: opacity 1.5s ease-in-out;
    -o-transition: opacity 1.5s ease-in-out;
    transition: opacity 1.5s ease-in-out;

    // the prestige
    &.active {
      position: relative;
      opacity: 1;
    }
  }
}

// meet-interns
.meet-interns {
  padding-top: 0px !important;
  text-align: center;

  // intern blade
  .intern-blade {

    &:nth-child(odd) { background: #d6dede; }

    // container override
    @media screen and (max-width: 580px) {
      &.padding {
        padding: 35px 0 !important;
      }
    }

    // subtitle
    .subtitle {
      font-weight: bold;
      margin-bottom: 24px;

      @media screen and (max-width: 767px) { margin-bottom: 40px; }
    }
  }

  // intern image
  .intern-image {
    align-content: center;
    max-width: 344px;
    margin-bottom: 15.5px;

    @media screen and (max-width: 767px) { margin-bottom: 5px; }

    img {
      padding: 0;
      width: 100%;
    }
  }

  // intern name
  .intern-name {
    font-size: 18px;
    line-height: 35px;
    font-weight: bold;
    font-family: proxima-nova, 'arial', 'sans-serif';
  }

  // intern title
  .intern-title {
    font-size: 12px;
    line-height: 15px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  // override row/col
  .row {
    &.container {
      max-width: 1110px !important;
    }

    @media screen and (max-width: 1023px) {
      &.container {
        justify-content: center;
        padding: 40px 40px 0 40px !important;
      }
    }

    @media screen and (max-width: 767px) {
      &.container {
        padding-top: 0 !important;
      }
    }

    .col {
      width: calc(33% - 8px);
      max-width: 344px;

      @media screen and (max-width: 1023px) {
        margin-bottom: 24px;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }

  // social media icons
  .social-media-icons {
    z-index: 2;
    font-size: 0;
    width: 125px;
    display: flex;
    margin: auto;
    justify-content: center;

    a {
      .icon-wrapper {
        width: 35px;

        margin-right: 10px;

        .icon {
          background-size: auto 25px;
          height: 35px;
          &.linkedin {
            background: url('/img/linkedin.svg') no-repeat 50% 50%;
          }
          &.facebook {
            background: url('/img/Facebook.svg') no-repeat 50% 50%;
          }
          &.instagram {
            background: url('/img/Instagram.svg') no-repeat 50% 50%;
          }
        }
      }

      &:last-child {
        .icon-wrapper { margin-right: 0; }
      }
    }
  }

  // projects involved
  .projects-involved {
    padding-top: 50px;

    // blade-title
    .blade-title {
      padding-bottom: 30px;
    }

    // project-button
    .project-button {
      width: 60%;
    }

    // project-buttons
    .project-buttons {
      width: 68%;
      margin: auto;
      text-align: center;
      font-size: 0;

      // "override" project-button style
      .project-button {
        max-width: 210px;
        display: inline-block;
        margin-right: 15px;

        // just in case we have more than one project
        &:last-child {
          margin-right: 0px;
        }

      }
      @media screen and (max-width: 639px) {
        width: 210px;
        .project-button {
          width: 100%;
          margin: 0 0 20px 0;
        }
      }
    }
  }
}

// internship-session
.internship-session {
  background: #da254d;
  text-align: center;
  color: #ffffff;
  width: 100%;

  // session text
  .text {
    @media screen and (max-width: 1300px) {
      padding: 0 40px;
    }

    @media screen and (max-width: 580px) {
      padding: 15px 20px;
    }

    // next session title
    .blade-title {
      color: #ffffff;
      margin-bottom: 18px;
    }

    // next session text
    p {
      max-width: 955px;
      margin: auto;
    }
  }
}

// hiring
.hiring {
  text-align: center;

  // override padding
  @media screen and (max-width: 580px) {
    padding: 40px 0 !important;
  }

  // title
  .blade-title {
    margin: 0 0 32px 0;
  }

  // overriding container class
  .container {
    @media screen and (max-width: 780px) {
      padding: 0 40px;
    }

    @media screen and (max-width: 767px) {
      padding: 0 20px;
    }
  }

  // internship positions
  .internship-positions {

    // position
    .position {
      background: #ffffff;
      padding: 0 30px 40px 30px;
      max-width: 397px;

      // aligning stuff using flexbox
      display: flex;
      flex-direction: column;
      align-items: center;

      // @media screen and (max-width: 1023px) {
      //   justify-content: center !important;
      //   margin-bottom: 20px;
      //   &:last-child { margin-bottom: 0; }
      // }

        // icon
      .icon-wrapper {
        margin: 75px 5px 24px 5px;
        width: 80px;
        flex-grow: 1;

        .icon {
          background-size: auto 25px;
          width: 100%;
          height: 60px;
          &.designer {
            background: url('/img/Designer_icon.svg') no-repeat 50% 50%;
          }
          &.developer {
            background: url('/img/Developer_icon.svg') no-repeat 50% 50%;
          }
          &.project-manager {
            background: url('/img/PM_Icon.svg') no-repeat 50% 50%;
          }
        }
      }

      .blade-title {
        flex-grow: 1;
        margin-bottom: 20px;
        font-family: proxima-nova, 'arial', 'sans-serif';
        font-weight: bold;
        font-size: 18px;

        @media screen and (max-width: 767px) { letter-spacing: 0 !important; }
      }

      // text
      .text {
        width: 100%;
        max-width: 220px;
        margin-bottom: 50px;
        font-weight: bold;
        font-family: "proxima-nova", arial, sans-serif;
        font-size: 12px;
        line-height: 1.4;
        flex-grow: 2;
      }

      // apply button
      .button-container {
        width: 100%;
        .we-button {
          width: 100%;
          flex-grow: 1;
        }
      }
    }
  }
}

// contact-form
.contact-form-blade {
  text-align: center;

  .contact-form-wrapper {
    width: 100%;
    margin: auto;

    @media screen and (max-width: 1300px) {
      padding: 0 40px;
    }

    @media screen and (max-width: 1023px) {
      .text {
        margin-bottom: 37px !important;
      }
    }

    @media screen and (max-width: 580px) {
      padding: 0 20px;
    }

    // contact title
    .blade-title {
      line-height: 30px;
      margin-bottom: 30px;
    }

    .text {
      line-height: 28px;
      max-width: 955px;
      margin: 0 auto 67px auto;
    }

    form {
      max-width: 955px;
      margin: auto;

      input,
      textarea {
        background-color: #d6dede;
        font-size: 16px;
        line-height: 20px;
        white-space: pre-wrap;
      }

      input[aria-invalid="true"],
      textarea[aria-invalid="true"] {
        border: solid 2px #f00 !important;
      }

      .clearfix {
        margin-bottom: 15px;

        input {
          float: left;
          width: calc(50% - 10px);
          height: 56px;
          padding: 18px 12px;
          border: solid 1px #d6dede;
          -webkit-appearance: none;
          border-radius: 0;

          &#name {
            margin-right: 15px;
          }

          @media screen and (max-width: 767px) {
            float: none;
            width: 100%;
            &#name {
              margin-right: 0;
              margin-bottom: 15px;
            }
          }
        }
      }

      // message
      #message {
        padding: 20px 12px;
        border: solid 1px #d6dede;
        -webkit-appearance: none;
        border-radius: 0;
        width: 100%;
        height: 216px;
        margin-bottom: 20px;
      }

      // submit button
      #contact-submit {
        width: 100%;
        max-width: 270px;

        @media screen and (max-width: 767px) {
          max-width: none;
        }
      }
    }

    #form-failed {
      display: none;
      color: #f00;
      padding: 10px 0;
      margin: auto;
    }
  }
}

.thank-you {
  background-color: #ffffff;
  text-align: center;
  width: 100%;
  display: none;

  .text {
    @media screen and (max-width: 1300px) {
      padding: 0 40px;
    }

    @media screen and (max-width: 580px) {
      padding: 15px 20px;
    }

    // next session title
    .blade-title {
      font-size: 72px;
      line-height: 1;
      letter-spacing: -0.09px;
      margin-bottom: 18px;
      font-weight: bold;
    }

    // next session text
    .diddy {
      max-width: 955px;
      margin: auto;
    }
  }
}

/* GENERIC STUFF */

*, h1, h2, h3, h4, h5 { letter-spacing: normal; }

p {
  font-size: 20px;
  line-height: 28px;

  @media screen and (max-width: 1023px) {
    font-size: 18px;
    line-height: 1.4;
  }

  @media screen and (max-width: 580px) {
    font-size: 16px;
  }
}

// generic row for 3 columns (flex-wrap)
.row {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  .col {
    width: calc(33% - 10px);
    &:first-child { margin-right: 15px; }
    &:last-child { margin-left: 15px; }
  }

  @media screen and (max-width: 767px) {
    display: block;
    .col {
      margin: 20px auto;
      width: 100%;

    &:first-child { margin-right: auto; }
    &:last-child {
      margin-left: auto;
      margin-bottom: 0;
    }
    }
  }
}

// two-column
.two-column {
  overflow: hidden;

  // column wrapper
  .box {
    position: relative;
    float: left;
    width: 50%;
    @media screen and (max-width: 1023px) {
      width: 100%;
      &:last-child {
        display: none;
      }
      float: none;
    }
  }
}

// WE button
.we-button {
  /* border + color */
  border-radius: 2px;
  -webkit-border-radius: 2px;
  border: solid 3px black;
  color: black;

  /* positioning */
  text-align: center;
  text-transform: uppercase!important;
  display: block;

  /* font */
  font-size: 12px;
  padding: 11px 0;
  font-weight: 600;
  letter-spacing: 1px;
  font-family: proxima-nova!important;
  text-decoration: none;

  /* sizing */
  max-width: 210px;
  margin: auto;

  /* animation */
  transition: all 0.3s;
  cursor: pointer;

  &:visited {
    color: black;
  }

  &:hover {
    color: #ffffff;
    background: black;
  }
}

.we-button[disabled="disabled"] {
  cursor: not-allowed;
  opacity: .1;
}

// we-button-red
.we-button-red {
  background: #da254d;
  color: #ffffff;
  border: solid 3px #da254d;
  &:visited {
    color: #ffffff;
  }
  &:hover {
    background: none;
    color: #da254d;
  }
}

// generic h3
.blade-title {
  margin: auto;
  font-size: 30px;
  line-height: 1.2;
  letter-spacing: 0.04px;

  @media screen and (max-width: 1023px) {
    font-size: 26px;
  }

  @media screen and (max-width: 767px) {
    margin: 0 0 12px;
    letter-spacing: -1px !important;
  }

  @media screen and (max-width: 500px) {
    font-size: 24px;
  }
}

// generic svg icon
.icon-wrapper {
  display: inline-block;
  .icon {
    display: block;
    width: 100%;
  }
}

// clearfix - when you know, you know
.clearfix {
  overflow: auto;
}

// burger magic
@media screen and (min-width: 1024px) {
  #nav-burger, #nav-for-burger {
    display: none !important;
  }
}

// slider blade shuffle
@media screen and (min-width: 768px) {
  #slider-for-mobile { display: none !important; }
}

@media screen and (max-width: 767px) {
  #grid-wrapper { display: none !important; }
}

// nav shuffle
@media screen and (max-width: 1023px) {
  #nav-menu { display: none; }
}

@media screen and (min-width: 1024px) {
  #hamnav-container { display: none !important; }
}

// scrolling magic
.lock-screen {
  overflow: hidden;
}
