.sandhill-casestudy {

  .full-width-content {
    //padding: 90px 0px;
    @media (max-width: 767px) {
      //padding: 40px 0px;
    }
    width: 100%;
    max-width: 1280px;
    margin: auto;
    .left-container {
      width: 35%;
      float: left;
      @media (min-width: 992px) and (max-width: 1200px) {
        width: 40%;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        width: 45%;
      }
      @media (max-width: 767px) {
        width: 100%;
        float: none;
      }
      .txt-holder {
        width: 100%;
        padding: 0 14px 0px 0;
        text-align: left;
        margin-top: 172px;
        @media (min-width: 768px) and (max-width: 991px) {
          margin-top: 100px;
        }
        @media (max-width: 767px) {
          width: 80%;
          margin: 0 auto;
          text-align: center;
        }
        @media (max-width: 595px) {
          width: 100%;
          padding: 0;
        }
      }
    }
    .right-container {
      width: 63%;
      padding-left: 7px;
      float: right;
      img {
        float: right;
      }
      @media (min-width: 992px) and (max-width: 1200px) {
        width: 60%;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        width: 55%;
      }
      @media (max-width: 767px) {
        width: 100%;
        padding: 20px;
        float: none;
      }
    }
/*
    img {
      width: auto;
      height: auto;
      max-width: 100%;
    }
*/
  }
  .col-two-block {
    .container {
      font-size: 0;
      &:after {
        content: '';
        display: block;
        clear: both;
      }
    }
    .left-block {
      display: inline-block;
      vertical-align: middle;
      width: 78%;
      margin: 0 0 0 -310px;
      @media (min-width: 992px) and (max-width: 1200px) {
        width: 78%;
        margin: 0 0 0 -230px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        width: 50%;
        margin: 0 0 0 -80px;
      }
      @media (max-width: 767px) {
        width: 100%;
        margin: 0 auto;
      }
      @media (max-width: 595px) {
        width: 100%;
        margin: 0 auto;
      }
      @media (max-width: 480px){
        width: 100%;
        margin: 0 auto;
      }
      img {
        max-width: 1277px;
        height: auto;
        margin: 0 0 0 -170px;
        @media (min-width: 992px) and (max-width: 1200px) {
          max-width: 800px;
          margin: 0 auto;
        }
        @media (min-width: 768px) and (max-width: 991px) {
          max-width: 450px;
          height: auto;
          margin: 0 auto;
        }
        @media (max-width: 767px) {
          max-width: 100%;
          height: auto;
          margin: 0 auto;
        }
      }
    }
    .right-block {
      width: 32%;
      margin: 0 0 0 150px;
      display: inline-block;
      vertical-align: middle;
      overflow: hidden;
      @media (min-width: 992px) and (max-width: 1200px) {
        margin: 0 0 0 100px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        width: 50%;
        margin: 0 0 0 60px;
        padding: 0 0 0 59px;
      }
      @media (max-width: 767px) {
        width: 100%;
        margin: 0 auto;
      }
      @media (max-width: 595px) {
        width: 100%;
        margin: 0 auto;
      }
      @media (max-width: 480px){
        width: 100%;
        margin: 0 auto;
      }

/*
      h4 {
        font-size: 26px;
        font-weight: 300;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        margin-top: 9px;
      }
*/
    }
  }
  .infographic-quote-block {
    padding: 40px 0 80px;
    @media (min-width: 768px) and (max-width: 991px) {
      padding: 0px 0 40px;
    }
    @media (min-width: 596px) and (max-width: 767px) {
      padding: 0px 0 40px;
    }
    @media (max-width: 595px) {
      padding: 10px 0 30px;
    }
    .content-wrap{
      width: 100%;
      max-width: 385px;
      margin:0 auto;
      text-align: center;
      h2 {
        margin: 0 auto 14px;
        font-size: 24px;
        font-family: 'Poppins', serif;
        line-height: 2;
        padding: 0 0 0 0;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.77;
      }
    }
  }

  .gradient-block {
    background-image: url(/img/case-studies/sandhill/circle-block-bg-1.png);
    background-position: 50% 50%;
    background-repeat: repeat;
    &:before {
      @media (min-width: 1024px) and (max-width: 1200px) {
        border-right: 117vw solid transparent;
      }
      @media (min-width: 1500px) {
        border-bottom: 800px solid $white;
      }
      @media (min-width: 768px) and (max-width: 1023px) {
        border-bottom: 650px solid $white;
        border-right: 114vw solid transparent;
      }
      @media (min-width: 596px) and (max-width: 767px) {
        border-bottom: 650px solid $white;
        border-right: 136vw solid transparent;
      }
      @media (max-width: 595px) {
        border-bottom: 590px solid $white;
        border-right: 168vw solid transparent;
      }
    }
    .large-block {
      padding-top: 71px;
      padding-bottom: 85px;
      //padding: 0;
      @media (min-width: 992px) and (max-width: 1023px) {
        //padding: 80px 0 0px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        //padding: 70px 0 40px;
      }
      @media (max-width: 768px) {
        padding: 40px 0 20px;
      }
      .txt-holder {
        @media screen and (max-width: 1200px) and (min-width: 1024px){
          padding: 50px 40px 0 0;
        }
        @media screen and (max-width: 1023px) {
          padding: 0 30px 30px 0;
        }
        @media screen and (max-width: 767px) {
          padding: 0 0 30px;
          text-align: center;
        }
      }
    }
    .center-block {
      padding-top: 71px;
      padding-bottom: 85px;
      @media screen and (max-width: 767px) {
        padding: 20px 0 40px;
      }
      .txt-holder {
        @media screen and (max-width: 1200px) and (min-width: 1024px){
          padding: 50px 40px 0 0;
        }
        @media screen and (max-width: 1023px) {
          padding: 0 30px 30px 0;
        }
        @media screen and (max-width: 767px) {
          padding: 0 0 30px;
          text-align: center;
        }
      }

      img {
        @media screen and (max-width: 767px) {
          margin-bottom: 30px;
        }
      }
    }
  }

  .blue-quote-block {
    padding: 105px 0 94px;
    background-color: #053d55;
    @media screen and (max-width: 1200px) and (min-width: 992px){
      padding: 70px 0;
    }
    @media screen and (max-width: 991px) and (min-width: 768px){
      padding: 60px 0;
    }

    @media screen and (max-width: 767px) {
      padding: 40px 0;
    }
    p {
      font-size: 16px;
      font-weight: 100;
      margin: 0 auto;
      line-height: 1.3;
      color: $white;
    }
    h4 {
      margin: 0 auto;
      font-style: italic;
      max-width: 1100px;
      font-weight: 100;
      font-size: 30px;
      line-height: 1.6;
      padding: 0 0 14px 0;
      color: $white;
      @media screen and (max-width: 991px) and (min-width: 768px){
        font-size: 24px;
      }
      @media screen and (max-width: 767px) {
        font-size: 22px;
      }
      &:before {
        content: "''";
        font-size: 32px;
        display: inline-block;
        line-height: 1;
        height: 20px;
        width: 20px;
        position: relative;
        top: 1px;
      }
      &:after {
        content: "''";
        font-size: 32px;
        display: inline-block;
        line-height: 1;
        height: 20px;
        width: 20px;
        position: relative;
        top: 3px;
        left: -15px;
      }
    }
    .content-wrap {
      text-align: center;
    }
  }

  .full-width-tchnlgy {
    float: left;
    width: 100%;
    .case-rspnsv {
      padding: 106px 0 33px;
      @media screen and (max-width: 1200px) and (min-width: 992px){
        padding: 70px 0 30px;
      }
      @media screen and (max-width: 991px) and (min-width: 768px){
        padding: 60px 0 30px;
      }
      @media screen and (max-width: 767px) {
        padding: 40px 0 30px;
      }
    }
    //background-color: $lightGrey;

    .tchnlgy-wrapper {
      @media screen and (max-width: 991px) and (min-width: 768px){
        margin: 0;
      }
      @media screen and (max-width: 767px) {
        margin: 0;
      }
      &:nth-child(6) {
        .technology-box {
          &:last-child {
            @media screen and (max-width: 767px) {
              border-bottom: none;
            }
          }
        }
      }
    }
    .technology-box {
      padding: 26px 25px 27px 23px;
      width: 20%;
      @media screen and (max-width: 1200px) and (min-width: 992px){
        width: 30%;
      }
      @media screen and (max-width: 991px) and (min-width: 768px){
        width: 30%;
        text-align: center;
        border-bottom: none;
        margin: 0;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        text-align: center;
        margin-bottom: 4px;
      }
      img {
        margin: 0 auto 27px auto;
        @media screen and (max-width: 991px) and (min-width: 768px){
          float: none;
        }
        @media screen and (max-width: 767px) {
          float: none;
        }
      }
      p {
        line-height: 1.5;
        margin-bottom: 0;
        float: left;
        width: 100%;
        span {
          display: block;
        }
      }

    }
  }

  .cs-nav,
  .bottom-container {
/*
    float: left;
    width: 100%;
*/
  }

  .case-prllx-container {
    padding: 48px 0 108px 0;
    //float: left;
    @media screen and (max-width: 991px) and (min-width: 768px){
      padding: 20px 0 80px 0;
    }
    @media screen and (max-width: 767px) {
      padding: 10px 0 70px 0;
    }
    &.sandhill-prllx {
      background-image: url(/img/case-studies/sandhill/webenertia_sandhill_bg.jpg?ver=2.2.5);
      background-position: 50% 50%;
      background-break: no-repeat;
      background-attachment: fixed;
      background-size: cover;
    }
    img {
      height: auto;
      max-width: 100%;
      vertical-align: top;
      border: 0;
      width: auto
    }
  }
  .sandhill-frame {
    float: left;
    width: 100%;
    padding: 119px 0;
    @media screen and (max-width: 1200px) and (min-width: 992px){
      padding: 80px 0;
    }
    @media screen and (max-width: 991px) and (min-width: 768px){
      padding: 60px 0;
    }
    @media screen and (max-width: 767px) {
      padding: 40px 0;
    }
    .sandhill-wireframes {
      float: left;
      width: 100%;
      display: table;
      @media screen and (max-width: 767px) {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -moz-box-align: start;
        -ms-flex-align: start;
        -webkit-align-items: flex-start;
        align-items: flex-start;
      }
      .col-seven {
        width: 67%;
        display: table-cell;
        vertical-align: middle;
        @media screen and (max-width: 991px) and (min-width: 768px){
          width: 70%;
        }
        @media screen and (max-width: 767px) {
          float: left;
          width: 100%;
          -webkit-box-ordinal-group: 2;
          -moz-box-ordinal-group: 2;
          -ms-flex-order: 2;
          -webkit-order: 2;
          order: 2;
        }
        img {
          max-width: 100%;
          height: auto;
          @media screen and (max-width: 767px) {
            margin: 0 auto 20px;
          }
        }
        .col {
          float: left;
          margin-right: 55px;
          @media screen and (max-width: 1200px) and (min-width: 992px){
            margin-right: 28px;
          }
          @media screen and (max-width: 991px) and (min-width: 768px){
            margin-right: 20px;
            max-width: 214px;
          }
          @media screen and (max-width: 767px) {
            width: 100%;
            max-width: 267px;
            margin: 0 auto;
            float: none;
          }
          &:last-child {
            margin-right: 0;
            @media screen and (max-width: 767px) {
              margin: 0 auto;
            }
          }
        }
      }
      .col-three {
        width: 33%;
        display: table-cell;
        vertical-align: middle;
        @media screen and (max-width: 991px) and (min-width: 768px){
          width: 30%;
        }
        @media screen and (max-width: 767px) {
          float: left;
          width: 100%;
          -webkit-box-ordinal-group: 1;
          -moz-box-ordinal-group: 1;
          -ms-flex-order: 1;
          -webkit-order: 1;
          order: 1;
        }
      }
      .txt-holder {
        font-size: 16px;
        line-height: 24px;
        padding: 0 0 0 45px;
        @media screen and (max-width: 991px) and (min-width: 768px){
          padding: 0 0 0 20px;
        }
        h3 {
          @media screen and (max-width: 991px) and (min-width: 768px){
            margin: 0 0 10px;
          }
          @media screen and (max-width: 767px) {
            margin: 0 0 10px;
          }
        }
        @media screen and (max-width: 767px) {
          padding: 0;
        }
      }
    }
  }
}

/*
.footer_section {
  float: left;
  width: 100%;
  position: relative;
  z-index: 2;
}
*/


