
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,700");

// @import "base/markup-variables";
// @import "base/markup-mixins";
// @import "base/base-reset";
// @import "base/animate";
// @import "base/overlay";

@media screen and (max-width: $irm-mobile) {
   body {
      font-size: 16px;
   }
}

.irm-container-intro {
   max-width: 1000px;
   margin: 0 auto;
   position: relative;
   text-align: center;
   padding: 80px 0;

   @media screen and (max-width: 1080px) {
      margin: 0 40px;
   }

   @media screen and (max-width: $tablet) {
      margin: 0 30px;
   }

   @media screen and (max-width: $irm-mobile) {
      margin: 0 20px;
   }

   h2 {
      font-size: 35px;
      font-family: 'Source Sans Pro', sans-serif;
      -webkit-font-smoothing: antialiased;
      font-weight: 400;
      margin-bottom: 20px;
      line-height: 1.1;

      @media screen and (max-width: $irm-mobile) {
         font-size: 28px;
      }
   }

   p {
      color: #5b7e96;
      font-size: 25px;
      font-family: 'Source Sans Pro', sans-serif;
      -webkit-font-smoothing: antialiased;
      line-height: 1.3;

      @media screen and (max-width: $irm-mobile) {
         font-size: 20px;
      }
   }
}

a.irm-download-infographic {
   padding: 12px 30px;
   border: solid 1px #415464;
   border-radius: 40px;
   color: #415464;
   font-size: 24px;
   text-align: center;
   margin: 26px auto 0;
   text-decoration: none;
   text-transform: uppercase;
   display: block;
   max-width: 280px;
   -webkit-transition: all 0.3s ease-out;
   transition: all 0.3s ease-out;

   @media screen and (max-width: $tablet) {
      font-size: 18px;
      padding: 8px 20px;
      max-width: 240px;
   }

   &:hover {
      background: #415464;
      color: $white;
   }
}

/*
.centered {
  text-align: center;
}
*/

#irm-wrapper {
   overflow: hidden;
   position: relative;
}

.irm-infographic {
   min-height: 400px;
   padding: 130px 0 150px;
   position: relative;
   overflow: hidden;
   display: none;
   background: rgb(69, 124, 105);
   background: -moz-linear-gradient(-45deg, rgba(69, 124, 105, 1) 1%, rgba(40, 99, 120, 1) 37%, rgba(29, 71, 111, 1) 67%, rgba(28, 41, 75, 1) 100%);
   background: -webkit-linear-gradient(-45deg, rgba(69, 124, 105, 1) 1%, rgba(40, 99, 120, 1) 37%, rgba(29, 71, 111, 1) 67%, rgba(28, 41, 75, 1) 100%);
   background: linear-gradient(135deg, rgba(69, 124, 105, 1) 1%, rgba(40, 99, 120, 1) 37%, rgba(29, 71, 111, 1) 67%, rgba(28, 41, 75, 1) 100%);
   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#457c69', endColorstr='#1c294b',GradientType=1 );
   background-size: 100%;
   overflow: hidden;

   * {
      -webkit-font-smoothing: antialiased;
      font-family: "Source Sans Pro", sans-serif !important;
   }

   video {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      opacity: .04;

      .ua-mobile & {
        display: none;
      }
   }

   .irm-container {
      margin: 0 auto;
      max-width: 1000px;

      @media screen and (max-width: 640px) {
        margin: 60px 0;
      }
   }

   .irm-holder {
      width: 50%;
      margin: 0 auto;
      position: relative;

      img {
         width: 100%;
      }

      @media screen and (max-width: 640px) {
         width: 80%;
         max-width: 380px;
      }

      .center-sphere {
         position: absolute;
         top: 24%;
         left: 24%;
         width: 52%;
         height: 52%;
         border-radius: 50%;
         z-index: 3;
         background: rgb(88, 88, 88);
         background: -moz-radial-gradient(center, ellipse cover, rgba(88, 88, 88, 1) 0%, rgba(44, 44, 44, 1) 65%, rgba(44, 44, 44, 1) 65%);
         background: -webkit-radial-gradient(center, ellipse cover, rgba(88, 88, 88, 1) 0%, rgba(44, 44, 44, 1) 65%, rgba(44, 44, 44, 1) 65%);
         background: radial-gradient(ellipse at center, rgba(88, 88, 88, 1) 0%, rgba(44, 44, 44, 1) 65%, rgba(44, 44, 44, 1) 65%);
         filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#585858', endColorstr='#2c2c2c',GradientType=1 );
         -webkit-transition: all 0.3s ease-out;
         transition: all 0.3s ease-out;

         &:hover {
            background: rgb(102, 102, 102); /* Old browsers */
            background: -moz-radial-gradient(center, ellipse cover, rgba(102, 102, 102, 1) 0%, rgba(44, 44, 44, 1) 65%, rgba(44, 44, 44, 1) 65%); /* FF3.6-15 */
            background: -webkit-radial-gradient(center, ellipse cover, rgba(102, 102, 102, 1) 0%, rgba(44, 44, 44, 1) 65%, rgba(44, 44, 44, 1) 65%); /* Chrome10-25,Safari5.1-6 */
            background: radial-gradient(ellipse at center, rgba(102, 102, 102, 1) 0%, rgba(44, 44, 44, 1) 65%, rgba(44, 44, 44, 1) 65%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#666666', endColorstr='#2c2c2c',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

            -moz-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2);
            -webkit-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2);
            box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2);

            p.link:after {
               margin-left: 12px;
            }
         }

         p {
            color: $white;
            text-transform: uppercase;
            text-align: center;
            font-size: 36px;
            font-weight: 700;
            line-height: 1;
            margin: 8px auto 0 auto;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            letter-spacing: -4px;

            @media screen and (max-width: 1340px) {
               font-size: 32px;
            }

            @media screen and (max-width: $tablet) {
               font-size: 26px;
               letter-spacing: -3px;
               margin-top: 0;
            }

            @media screen and (max-width: $irm-mobile) {
               font-size: 22px;
            }

            @media screen and (max-width: $mobile-sm) {
               letter-spacing: -2px;
               font-size: 18px;
            }

            &.link {
               font-size: 16px;
               margin: 0;
               min-height: auto;
               position: relative;
               padding-right: 4%;
               letter-spacing: 0;
               color: #5fb4e5;

               &:after {
                  position: absolute;
                  content: "";
                  width: 16px;
                  height: 16px;
                  background: url("/img/case-studies/riverbed/infographic/arrow-icons.png#{$assetVersion}") no-repeat 0 0;
                  background-size: 400%;
                  margin: 1% 0 0 6px;
                  -webkit-transition: all 0.3s ease-out;
                  transition: all 0.3s ease-out;

                  @media screen and (max-width: $irm-mobile) {
                     width: 14px;
                     height: 14px;
                  }

                  @media screen and (max-width: $mobile-sm) {
                     width: 12px;
                     height: 12px;
                  }
               }

               @media screen and (max-width: $irm-mobile) {
                  font-size: 14px;
                  min-height: auto;
               }

               @media screen and (max-width: $mobile-sm) {
                  font-size: 12px;
               }
            }
         }
      }

      .bg-sphere {
         position: absolute;
         top: 5%;
         left: 5%;
         border-radius: 50%;
         width: 90%;
         height: 90%;
         opacity: .4;
         -webkit-animation: pulseMin 3s ease infinite;
         -moz-animation: pulseMin 3s ease infinite;
         animation: pulseMin 3s ease infinite;
         background: -moz-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0) 47%, rgba(0, 0, 0, 0.8) 100%);
         background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0) 47%, rgba(0, 0, 0, 0.8) 100%);
         background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0) 47%, rgba(0, 0, 0, 0.8) 100%);
         filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=1 );
      }

      .connect-line {
         position: absolute;
         top: 50%;
         left: 50%;
         height: 1px;
         width: 80%;
         margin-left: -40%;
         background: #ffffff;
         -webkit-transform: rotate(45deg);
         -ms-transform: rotate(45deg);
         transform: rotate(45deg);
         z-index: 2;

         &.scnd-line {
            -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
         }
      }
   }
}

.outer-sphere {
   position: absolute;
   top: 50%;
   left: 50%;
   width: 28%;
   height: 28%;
   border-radius: 50%;
   z-index: 3;
   cursor: pointer;
   -webkit-transition: all 0.5s ease-in-out;
   transition: all 0.5s ease-in-out;
   animation-iteration-count: 1;
   box-sizing: border-box;

   &:hover {
      -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
      transform: scale(1.05);
      -moz-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2);

      .txt-holder {
         background: #ffffff;

         > p {
            color: #252728;
         }

         .irm-popup > div {
            -moz-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2);
            -webkit-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2);
            box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2);

            p.link:after {
               margin-left: 12px;
            }
         }
      }
   }

   &.hovered {
      -webkit-transform: scale(1.02);
      -ms-transform: scale(1.02);
      transform: scale(1.02);
      -moz-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2);

      .txt-holder {
         background: #ffffff;

         > p {
            color: #252728;
         }

         .irm-popup > div {
            p.link:after {
               margin-left: 6px;
            }
         }
      }
   }

   &.animation-none {
      animation-name: none !important;
   }

   &.top-left {
      top: 0;
      left: 0;
      background: rgb(97, 187, 232); /* Old browsers */
      background: -moz-radial-gradient(center, ellipse cover, rgba(97, 187, 232, 1) 1%, rgba(72, 137, 175, 1) 64%); /* FF3.6-15 */
      background: -webkit-radial-gradient(center, ellipse cover, rgba(97, 187, 232, 1) 1%, rgba(72, 137, 175, 1) 64%); /* Chrome10-25,Safari5.1-6 */
      background: radial-gradient(ellipse at center, rgba(97, 187, 232, 1) 1%, rgba(72, 137, 175, 1) 64%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#61bbe8', endColorstr='#4889af',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

      &:hover {
         background: rgb(86, 190, 242); /* Old browsers */
         background: -moz-radial-gradient(center, ellipse cover, rgba(86, 190, 242, 1) 1%, rgba(41, 146, 207, 1) 64%); /* FF3.6-15 */
         background: -webkit-radial-gradient(center, ellipse cover, rgba(86, 190, 242, 1) 1%, rgba(41, 146, 207, 1) 64%); /* Chrome10-25,Safari5.1-6 */
         background: radial-gradient(ellipse at center, rgba(86, 190, 242, 1) 1%, rgba(41, 146, 207, 1) 64%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
         filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#56bef2', endColorstr='#2992cf',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
      }
   }

   &.top-right {
      top: 0;
      right: 0;
      left: auto;
      background: rgb(242, 98, 28);
      background: -moz-radial-gradient(center, ellipse cover, rgba(242, 98, 28, 1) 2%, rgba(185, 75, 21, 1) 56%);
      background: -webkit-radial-gradient(center, ellipse cover, rgba(242, 98, 28, 1) 2%, rgba(185, 75, 21, 1) 56%);
      background: radial-gradient(ellipse at center, rgba(242, 98, 28, 1) 2%, rgba(185, 75, 21, 1) 56%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2621c', endColorstr='#b94b15',GradientType=1 );

      &:hover {
         background: rgb(255, 95, 15); /* Old browsers */
         background: -moz-radial-gradient(center, ellipse cover, rgba(255, 95, 15, 1) 2%, rgba(206, 69, 0, 1) 60%); /* FF3.6-15 */
         background: -webkit-radial-gradient(center, ellipse cover, rgba(255, 95, 15, 1) 2%, rgba(206, 69, 0, 1) 60%); /* Chrome10-25,Safari5.1-6 */
         background: radial-gradient(ellipse at center, rgba(255, 95, 15, 1) 2%, rgba(206, 69, 0, 1) 60%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
         filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff5f0f', endColorstr='#ce4500',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
      }
   }

   &.bottom-right {
      top: auto;
      bottom: 0;
      right: 0;
      left: auto;
      background: rgb(88, 120, 143);
      background: -moz-radial-gradient(center, ellipse cover, rgba(88, 120, 143, 1) 0%, rgba(72, 97, 115, 1) 57%);
      background: -webkit-radial-gradient(center, ellipse cover, rgba(88, 120, 143, 1) 0%, rgba(72, 97, 115, 1) 57%);
      background: radial-gradient(ellipse at center, rgba(88, 120, 143, 1) 0%, rgba(72, 97, 115, 1) 57%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#58788f', endColorstr='#486173',GradientType=1 );

      &:hover {
         background: rgb(38, 129, 194); /* Old browsers */
         background: -moz-radial-gradient(center, ellipse cover, rgba(38, 129, 194, 1) 0%, rgba(31, 105, 157, 1) 64%); /* FF3.6-15 */
         background: -webkit-radial-gradient(center, ellipse cover, rgba(38, 129, 194, 1) 0%, rgba(31, 105, 157, 1) 64%); /* Chrome10-25,Safari5.1-6 */
         background: radial-gradient(ellipse at center, rgba(38, 129, 194, 1) 0%, rgba(31, 105, 157, 1) 64%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
         filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2681c2', endColorstr='#1f699d',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
      }
   }

   &.bottom-left {
      top: auto;
      bottom: 0;
      left: 0;
      background: rgb(247, 194, 22); /* Old browsers */
      background: -moz-radial-gradient(center, ellipse cover, rgba(247, 194, 22, 1) 1%, rgba(188, 130, 22, 1) 57%); /* FF3.6-15 */
      background: -webkit-radial-gradient(center, ellipse cover, rgba(247, 194, 22, 1) 1%, rgba(188, 130, 22, 1) 57%); /* Chrome10-25,Safari5.1-6 */
      background: radial-gradient(ellipse at center, rgba(247, 194, 22, 1) 1%, rgba(188, 130, 22, 1) 57%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7c216', endColorstr='#bc8216',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

      &:hover {
         background: rgb(255, 199, 15); /* Old browsers */
         background: -moz-radial-gradient(center, ellipse cover, rgba(255, 199, 15, 1) 1%, rgba(210, 137, 0, 1) 65%); /* FF3.6-15 */
         background: -webkit-radial-gradient(center, ellipse cover, rgba(255, 199, 15, 1) 1%, rgba(210, 137, 0, 1) 65%); /* Chrome10-25,Safari5.1-6 */
         background: radial-gradient(ellipse at center, rgba(255, 199, 15, 1) 1%, rgba(210, 137, 0, 1) 65%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
         filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffc70f', endColorstr='#d28900',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
      }
   }

   .txt-holder {
      background: #252728;
      padding: 7px;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      width: 114%;
      margin-left: -7%;
      border-radius: 2px;
      -webkit-transition: all 0.1s ease-out;
      transition: all 0.1s ease-out;
      box-sizing: border-box;

      @media screen and (max-width: $mobile-sm) {
         width: 124%;
         margin-left: -12%;
      }

      p {
         color: #ffffff;
         text-transform: uppercase;
         text-align: center;
         font-size: 18px;
         line-height: 1;
         margin: 0 auto;
         letter-spacing: -2px;
         font-weight: 600;

         @media screen and (max-width: $tablet) {
            font-size: 16px;
         }

         @media screen and (max-width: $irm-mobile) {
            font-size: 14px;
         }

         @media screen and (max-width: $mobile-sm) {
            font-size: 12px;
            letter-spacing: -1px;
            font-weight: 400;
         }
      }

      .irm-popup {
         position: absolute;
         width: 114%;

         div {
            position: relative;
            border-radius: 6px;
            background: rgba(0, 0, 0, 0.7);
            text-align: center;
            width: 100%;
            padding: 16px 3% 22px;
            cursor: pointer;
            -webkit-transition: all 0.9s ease-out;
            transition: all 0.9s ease-out;

            @media screen and (max-width: $mobile-sm) {
               padding: 10px 2% 16px;
            }

            &:after {
               position: absolute;
               content: "";
               width: 0;
               height: 0;
            }
         }

         h2 {
            font-size: 46px;
            color: #ffffff;
            margin: 0;
            font-weight: 700;
            font-family: 'Source Sans Pro', sans-serif;
            -webkit-font-smoothing: antialiased;
            vertical-align: top;
            line-height: 1;
            position: relative;

            @media screen and (max-width: $tablet) {
               font-size: 38px;
            }

            @media screen and (max-width: $irm-mobile) {
               font-size: 28px;
            }

            @media screen and (max-width: $mobile-sm) {
               font-size: 24px;
            }

            &.sm-ttl {
               font-size: 42px;
               margin-bottom: 6px;

               @media screen and (max-width: $tablet) {
                  font-size: 32px;
               }

               @media screen and (max-width: $irm-mobile) {
                  font-size: 24px;
               }

               @media screen and (max-width: $mobile-sm) {
                  font-size: 22px;
               }
            }

            span {
               font-size: 30px;
               position: relative;
               top: -10px;

               &.xelm {
                  position: static;
                  top: 6px;
               }

               @media screen and (max-width: $tablet) {
                  font-size: 26px;
               }

               @media screen and (max-width: $irm-mobile) {
                  font-size: 22px;
                  min-height: 42px;
               }

               @media screen and (max-width: $mobile-sm) {
                  font-size: 18px;
               }
            }
         }

         p {
            font-weight: 700;
            letter-spacing: -1px;
            line-height: 1.2;
            margin-bottom: 20px;
            min-height: 63px;

            @media screen and (max-width: $irm-mobile) {
               margin-bottom: 10px;
               min-height: 42px;
            }

            &.link {
               font-size: 16px;
               margin: 0;
               min-height: auto;
               position: relative;
               padding-right: 16px;

               &:after {
                  position: absolute;
                  content: "";
                  width: 16px;
                  height: 16px;
                  background: url("/img/case-studies/riverbed/infographic//arrow-icons.png#{$assetVersion}") no-repeat 0 0;
                  background-size: 400%;
                  margin: 2% 0 0 6px;
                  -webkit-transition: all 0.3s ease-out;
                  transition: all 0.3s ease-out;

                  @media screen and (max-width: $irm-mobile) {
                     width: 14px;
                     height: 14px;
                  }

                  @media screen and (max-width: $mobile-sm) {
                     width: 12px;
                     height: 12px;
                  }
               }

               @media screen and (max-width: $irm-mobile) {
                  font-size: 14px;
                  min-height: auto;
               }

               @media screen and (max-width: $mobile-sm) {
                  font-size: 12px;
               }
            }
         }
      }
   }
}

.top-left .txt-holder .irm-popup {
   top: -200%;
   left: -140%;

   @media screen and (max-width: 640px) {
      top: -720%;
      left: -10%;
   }

   p.link {
      color: #5fb4e5;
   }

   div {
      &:after {
         top: 34%;
         right: -15.4px;
         border-top: 16px solid transparent;
         border-bottom: 16px solid transparent;
         border-left: 16px solid rgba(0, 0, 0, 0.7);

         @media screen and (max-width: 640px) {
            top: auto;
            bottom: -32px;
            left: 50%;
            margin-left: -15px;
            border-top: 16px solid rgba(0, 0, 0, 0.7);
            border-right: 16px solid transparent;
            border-left: 16px solid transparent;
         }
      }
   }
}

.top-right .txt-holder .irm-popup {
   top: -200%;
   right: -140%;

   @media screen and (max-width: 640px) {
      top: -720%;
      left: -10%;
   }

   p.link {
      color: #ff681d;

      &:after {
         background: url(/img/case-studies/riverbed/infographic/arrow-icons.png#{$assetVersion}) no-repeat 29% 0;
         background-size: 400%;
      }
   }

   div {
      &:after {
         top: 34%;
         left: -15.4px;
         border-top: 16px solid transparent;
         border-bottom: 16px solid transparent;
         border-right: 16px solid rgba(0, 0, 0, 0.7);

         @media screen and (max-width: 640px) {
            top: auto;
            bottom: -32px;
            left: 50%;
            margin-left: -15px;
            border-top: 16px solid rgba(0, 0, 0, 0.7);
            border-right: 16px solid transparent;
            border-left: 16px solid transparent;
         }
      }
   }
}

.bottom-right .txt-holder .irm-popup {
   bottom: -230%;
   right: -140%;

   @media screen and (max-width: 640px) {
      bottom: -700%;
      right: -10%;
   }

   p.link {
      color: #5b7e96;

      &:after {
         background: url(/img/case-studies/riverbed/infographic/arrow-icons.png#{$assetVersion}) no-repeat 100% 0;
         background-size: 400%;
      }
   }

   div {
      &:after {
         top: 42%;
         left: -15.4px;
         border-top: 16px solid transparent;
         border-bottom: 16px solid transparent;
         border-right: 16px solid rgba(0, 0, 0, 0.7);

         @media screen and (max-width: 640px) {
            top: -32px;
            left: 50%;
            margin-left: -15px;
            border-bottom: 16px solid rgba(0, 0, 0, 0.7);
            border-right: 16px solid transparent;
            border-left: 16px solid transparent;
         }
      }
   }
}

.bottom-left .txt-holder .irm-popup {
   bottom: -230%;
   left: -140%;

   @media screen and (max-width: 640px) {
      bottom: -700%;
      left: -10%;
   }

   p.link {
      color: #ffb81d;

      &:after {
         background: url(/img/case-studies/riverbed/infographic/arrow-icons.png#{$assetVersion}) no-repeat 64% 0;
         background-size: 400%;
      }
   }

   div {
      &:after {
         top: 42%;
         right: -15.4px;
         border-top: 16px solid transparent;
         border-bottom: 16px solid transparent;
         border-left: 16px solid rgba(0, 0, 0, 0.7);

         @media screen and (max-width: 640px) {
            top: -32px;
            left: 50%;
            margin-left: -15px;
            border-bottom: 16px solid rgba(0, 0, 0, 0.7);
            border-right: 16px solid transparent;
            border-left: 16px solid transparent;
         }
      }
   }
}

@-webkit-keyframes AnimationName {
   0% {
      background-position: 0% 50%;
   }

   50% {
      background-position: 100% 51%;
   }

   100% {
      background-position: 0% 50%;
   }
}

@-moz-keyframes AnimationName {
   0% {
      background-position: 0% 50%;
   }

   50% {
      background-position: 100% 51%;
   }

   100% {
      background-position: 0% 50%;
   }
}

@keyframes AnimationName {
   0% {
      background-position: 0% 50%;
   }

   50% {
      background-position: 100% 51%;
   }

   100% {
      background-position: 0% 50%;
   }
}
