.no-parallax {
	position: relative;
	z-index: 2;
}
.parallax-index {
	z-index: 0 !important;
}
.solutions-parallax-box {
	width: 100%;
	text-align: center;
	color: $white;
	padding: 71px 0 85px 0;

	&.min-height {
  	@media screen and (min-width: 1024px) {
    	position: relative;
    	height: 600px;
    	.full-width {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        z-index: 2;
        transform: translate(-50%, -50%);
    	}
  	}
	}

	p.number {
		font-size: 160px;
		font-family: "proxima-nova";
		font-weight: 700;
		margin-bottom: 0;
		line-height: 1;
	}
	p.item {
		font-size: 74px;
		margin: -16px 0 20px;
	  letter-spacing: -1.2px;
	  line-height: 1;
	}
	p {
		font-size: 28px;
		font-family: 'Poppins', serif;
		font-weight: 300;
	}

	&.strategy-prllx {
    background: url("/img/solutions/solutions_bg2.jpg#{$assetVersion}") center;
    background-attachment: fixed;
		background-size: cover;
	}
	&.creative-prllx {
    background: url("/img/solutions/creative1.jpg#{$assetVersion}") center;
    background-attachment: fixed;
		background-size: cover;
	}
	&.development-prllx {
    background: url("/img/solutions/solutions_bg3.jpg#{$assetVersion}") center;
    background-attachment: fixed;
		background-size: cover;
	}
	&.marketing-prllx {
    background: url("/img/solutions/marketing1.jpg#{$assetVersion}") center;
    background-attachment: fixed;
		background-size: cover;
	}
	&.agency-prllx {
    background: url("/img/solutions/agency_partnership1.jpg#{$assetVersion}") center;
    background-attachment: fixed;
		background-size: cover;
	}
	&.video-parallax {
		padding: 150px 0 200px 0;
		z-index: 0;
		video {
			position: fixed;
			z-index: -9999;
			top: 50%;
			left: 50%;
			min-width: 100%;
			min-height: 100%;
			transform: translate(-50%, -50%);

			.ua-mobile & {
				display: none;
			}
		}
	}
	@media screen and (max-width: 780px) {
		padding: 60px 0 90px 0;
		&.video-parallax {
			padding: 60px 0 90px 0;
		}
		&.strategy-prllx,
		&.creative-prllx,
		&.development-prllx,
		&.marketing-prllx,
		&.agency-prllx {
	    background-attachment: scroll;
		}
	}
	@media screen and (max-width: 580px) {
		p.number {
			font-size: 100px;
		}
		p.item {
			font-size: 50px;
			margin: -20px 0 0 0;
			letter-spacing: 2px;
		}
		p {
			font-size: 20px;
			letter-spacing: 0px;
		}
	}
	@media screen and (max-width: 520px) {
		padding: 40px 0 65px 0;
		&.video-parallax {
			padding: 40px 0 65px 0;
			background: url("/img/solutions/videos/webenertia-solutions-video.jpg#{$assetVersion}") center;
			background-size: cover;
			video {
				display: none;
			}
		}
	}
	@media screen and (max-width: 420px) {
		p.number {
			font-size: 80px;
		}
		p.item {
			font-size: 40px;
			margin: -10px 0 0 0;
			letter-spacing: 2px;
		}
		p {
			font-size: 16px;
		}
	}
}
