.sandhill-blue-bg {
  background-color: #053d55 !important;
  blockquote {
    q, cite {
      color: $white;
    }
  }
}

.sandhill-casestudy {

  .v-middle-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 3

/*
    @media screen and (max-width: 767px) {
      position: relative;
    }
*/
  }
  .v-middle-inner {
    display: table;
    position: relative;
    text-align: center;
    width: 100%;
    height: 100%;
  }
  .v-middle {
    display: table-cell;
    vertical-align: middle;
  }
  .hero-banner {
    overflow: hidden;
    height: calc(100vh - 70px);
    max-height: 495px;
    left: 0;
    top: 0;
    right: 0;
    @media (max-width: 812px) {
      max-height: 450px;
    }
    .inner-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      &.show {
        opacity: 1;
      }
      &.hide {
        opacity: 0;
      }

    }
    .background-image {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: url(/img/case-studies/sandhill/banner-bg-image.png) top no-repeat;
      background-size: 100% 100%;
      .max-container {
        margin: 0 auto;
        height: 100%;
        position: relative;
        @media (max-width: 1300px) {
          padding: 0px 40px;
        }
        @media (max-width: 780px) {
          padding: 0px 20px;
        }
      }
      .background {
        position: absolute;
        z-index: 1;
        right: 15px;
        bottom: 0;
      }
      .background-1 {
        bottom: 0px;
        right: 20px;
        z-index: 1;
        width: auto;
        padding-bottom: 34px;
        @media (min-width: 1201px) and(max-height: 790px) {
          padding-bottom: 32px;
        }
        @media (min-width: 992px) and (max-width: 1200px) {
          right: 40px;
          padding-bottom: 32px;
        }
        @media (min-width: 992px) and (max-width: 1024px) {
          right: 40px;
          padding-bottom: 33px;
        }
        @media (min-width: 813px) and (max-width: 991px) {
          right: 40px;
          padding-bottom: 33px;
        }
        @media (max-width: 991px) and (min-width: 813px) and(max-height: 400px) {
          padding-bottom: 20px;
        }
        @media (min-width: 596px) and (max-width: 812px) {
          right: 35px;
          padding-bottom: 15px;
        }
        @media (max-width: 812px) and (min-width: 596px) and(max-height: 400px) {
          padding-bottom: 19px;
        }
        @media (max-width: 595px) {
          right: 30px;
          padding-bottom: 15px;
        }
        @media (max-width: 595px) and (min-width: 481px) and(max-height: 400px) {
          padding-bottom: 15px;
        }
        @media (max-width: 480px) {
          right: 30px;
          padding-bottom: 15px;
        }
        #hero_animation_container {
          height: 100% !important;
          float: right;
          margin: 0px;
          @media (min-width: 1201px) and(max-height: 790px) {
            margin-right: -5px;
          }
          @media (max-width: 991px) and (min-width: 813px) and(max-height: 400px) {
            margin-right: -7px;
          }
          @media (max-width: 812px) and (min-width: 596px) and(max-height: 400px) {
            margin-right: -4px;
          }
          @media (min-width: 596px) and (max-width: 812px) {
            margin-right: -5px;
          }
          @media (max-width: 595px) {
            margin-right: -12px;
          }
          @media (max-width: 595px) and (min-width: 481px) and(max-height: 400px) {
            margin-right: -12px;
          }
          @media (max-width: 480px) {
            margin-right: -17px;
          }
          canvas#hero_canvas {
            max-width: 420px;
            width: auto !important;
            height: auto !important;

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
              width: 100% !important;
              height: 100% !important;
            }
          }
          #hero_dom_overlay_container {
            height: 100% !important;
            width: 100% !important;
          }
        }
        .bg-left {
          position: absolute;
          bottom: 0;
          width: 100%;
          z-index: -1;
          img {
            height: 100%;
            max-width: 100%;
            vertical-align: top;
            border: 0;
          }
        }
        .bg-right {
          position: absolute;
          bottom: 0;
          z-index: -1;
          right: -12px;
          width: calc(100% + 9px);

          @media (min-width: 992px) and (max-width: 1200px) {
            right: -13px;
            width: calc(100% + 9px);
          }
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            @media (min-width: 1201px) and(max-height: 790px) {
              right: -13px;
              width: calc(100% + 10px);
            }
          }
          @media (min-width: 992px) and (max-width: 1024px) {
            right: -13px;
            width: calc(100% + 11px);
          }
          @media (min-width: 813px) and (max-width: 991px) {
            right: -12px;
            width: calc(100% + 9px);
          }
          @media (min-width: 596px) and (max-width: 812px) {
            right: -11px;
            width: calc(100% + 8px);
          }
          @media (max-width: 595px) {
            right: -22px;
            width: calc(100% + 19px);
          }
          img {
            height: auto;
            max-width: 100%;
            vertical-align: top;
            border: 0;
          }
        }
      }
    }
    .v-middle-wrapper {
      z-index: 0;
    }
    .v-middle-inner {
      text-align: left;
      z-index: 0;
      margin: 0px auto;
      @media (max-width: 1300px) {
        padding: 0px 40px;
      }
      @media (max-width: 780px) {
        padding: 0px 20px;
      }
      .v-middle {
        @media (max-width: 480px) {
          vertical-align: top;
        }
        .content {
          max-width: 470px;
          width: 100%;
          margin-top: 6px;
          position: relative;
          float: left;
          @media (min-width: 596px) and (max-width: 812px) {
            max-width: 300px;
          }
          @media (max-width: 595px) {
            max-width: 230px;
          }
          @media (max-width: 480px) {
            margin-top: 60px;
            max-width: 100%;
          }
          @media (max-width: 595px) and (orientation: landscape) {
            margin-top: 60px;
          }
          .animate {
            visibility: hidden;
            -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
            animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
          }
          .banner-logo {
            display: block;
            /* float: left; */
            max-width: 370px;
            @media (min-width: 813px) and (max-width: 991px) {
              max-width: 320px;
            }
            @media (min-width: 596px) and (max-width: 812px) {
              max-width: 280px;
            }
            @media (max-width: 595px) {
              max-width: 200px;
            }
            img {
              max-width: 100%;
              height: auto;
            }
          }
          h1 {
            padding: 10px 0 10px;
            backface-visibility: hidden;
            font-size: 38px;
            line-height: 1;
            font-weight: 300;
            text-align: left;
            /* float: left; */
            @media (min-width: 991px) and (max-width: 1024px) {
              font-size: 36px;
            }
            @media (min-width: 813px) and (max-width: 991px) {
              font-size: 32px;
            }
            @media (min-width: 596px) and (max-width: 812px) {
              padding: 10px 0;
              font-size: 30px;
            }
            @media (min-width: 481px) and (max-width: 595px) {
              font-size: 26px;
            }
            @media (max-width: 480px) {
              font-size: 24px;
            }
          }
/*
          a {
            margin: 9px 0 0 0;
            backface-visibility: hidden;
            opacity: 0;
            -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
            animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
            &.stop-animation {
              -webkit-animation-fill-mode: inherit;
              animation-fill-mode: inherit;
            }
            @media (max-width: 812px) {
              font-size: 14px;
            }
            @media (max-width: 480px) {
              margin-top: 0px;
            }
          }
*/
        }
      }
    }
    #canvas, #dom_overlay_container, #animation_container {
      max-width: 100% !important;
      vertical-align: top;
    }
  }
}
.ie9 {
  .hero-banner {
    .background-image {
      .background {
        opacity: 1;
        visibility: visible;
      }
    }
    #animation_container,
    #canvas,
    #dom_overlay_container {
      height: 633px !important;
    }
  }
}

.touch {
  .hero-banner {
    .inner-wrapper {
      position: relative;
    }
    .background-image {
      opacity: 1 !important;
    }
    .v-middle-inner {
      .v-middle {
        .content {
          bottom: 0 !important;
          h1, h4, a {
            opacity: 1 !important;
          }
        }
      }
    }
  }
}




/* Infographic slider*/
.sandhill-casestudy {
  .infographic-slider {
    background: #fff;
    padding-top: 106px;
    padding-bottom: 50px;
    overflow: hidden;
    @media (min-width: 992px) and (max-width: 1200px){
      padding-top: 80px;
    }
    @media (min-width: 813px) and (max-width: 991px){
      padding-top: 50px;
    }
    @media (min-width: 596px) and (max-width: 812px){
      padding-top: 40px;
    }
    @media (min-width: 481px) and (max-width: 595px){
      padding-top: 30px;
      padding-bottom: 30px;
    }
    @media (max-width: 480px) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    .slick-dots {
      height: 0;
      overflow: hidden;
    }
    .slider-wrapper {
      position: relative;
      max-width: 1210px;
      padding: 0 20px;
      height: 622px;
      overflow: hidden;
      margin: 0 auto;
      @media only screen  and (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3) and (orientation : landscape) {
        padding: 0;
      }
      @media (min-width: 813px) and (max-width: 991px){
        height: 600px;
      }
      @media (min-width: 596px) and (max-width: 812px){
        height: 560px;
      }
      @media (min-width: 481px) and (max-width: 595px){
        height: 510px;
      }
      @media (max-width: 480px){
        height: 480px;
      }
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: calc(-100% + 20px);
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.75);
        z-index: 1;
        @media (max-width: 1209px) {
          display: none;
        }
      }
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: calc(-100% + 20px);
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.75);
        z-index: 1;
        @media (max-width: 1209px) {
          display: none;
        }
      }
      .slide-wrapp {
        width: calc(100vw - 40px);
        max-width: 1170px;
        float: left;
        overflow: visible !important;
        &.scale-child {
          position: relative;
          z-index: 1;
        }
        &:after {
          content: "";
          clear: both;
          display: block;
        }
      }
      .slide-track {
        width: 10000000px;
        @media (max-width: 812px) {
          width: 100%;
          display: block;
          position: relative;
        }
        &:after {
          content: "";
          display: block;
          clear: both;
        }
        .slick-list {
          float: left;
          height: 100%;
          width: 100%;
          overflow: visible;
          transform-style: preserve-3d;
          &:after {
            content: "";
            clear: both;
            display: block;
          }
        }
        .slick-track {
          float: left;
          height: 100%;
        }
        .slide-item {
          position: relative;
          float: left;
          width: 25%;
          height: 622px;
          border-right: 1px solid #fff;
          box-sizing: border-box;
          cursor: pointer;
          @media (min-width: 992px) and (max-width: 1024px) {
            width: 33.33%;
          }
          @media (min-width: 813px) and (max-width: 991px){
            height: 600px;
            width: 33.33%;
          }
          @media (min-width: 596px) and (max-width: 812px){
            height: 560px;
            width: calc(50vw - 20px);
          }
          @media (min-width: 481px) and (max-width: 595px){
            height: 510px;
            width: calc(50vw - 20px);
          }
          @media (max-width: 480px){
            height: 480px;
            width: calc(100vw - 40px);
          }
          &.scale-parent {
            position: relative;
            z-index: 2;
          }
          &.scale-parent-previous {
            .content-wrapper {
              .bg-img {
                img {
                  width: 100% !important;
                  height: auto !important;
                  margin-left: 0 !important;
                }
                > div {
                  canvas {
                    width: 100% !important;
                    height: auto !important;
                    margin-left: 0 !important;
                  }
                }
              }
            }
          }
          &.scale-down {
            position: relative;
          }

          &.scale-up {
            transform: translateY(-4.7%);
            transition: all, 0.4s, cubic-bezier(0, 0, 1, 1);
            @media (min-width: 596px) and (max-width: 812px){
              height: 560px !important;
              transform: translateY(0%);
            }
            @media (min-width: 481px) and (max-width: 595px){
              height: 510px !important;
              transform: translateY(0%);
            }
            @media (max-width: 480px){
              height: 480px !important;
              transform: translateY(0%);
            }
            .hover-active {
              transition: all 0.4s cubic-bezier(0, 0, 1, 1);
              border: 0 !important;
              &:before {
                border-color: #fff !important;
              }
            }
            .scale-item {
              border: 0 !important;
              @media (max-width: 595px){
                width: 100% !important;
                height: 100% !important;
                transform: translateY(0) translateX(0) !important;
              }
              &:before {
                border-color: #fff !important;
                opacity: 1;
              }
            }
          }
          &.scale-parent-previous {
            transform: translateY(0%);
            .hover-active {
              width: 100% !important;
              height: 100% !important;
              transform: translateX(0) !important;
              transition: all 0s cubic-bezier(0, 0.62, 1, 1);
              border: 0 !important;
              &:before {
                border-color: #fff !important;
              }
            }
            .scale-item {
              width: 100% !important;
              height: 100% !important;
              transform: translateX(0) !important;
              transition: all 0s cubic-bezier(0, 0.62, 1, 1);
              border: 0 !important;
              &:before {
                border-color: #fff !important;
              }
            }
          }
          &.scale-parent-previous {
            .slide-inner {
              width: 100% !important;
              height: 100% !important;
              transform: translateX(0) !important;
              transition: all 0s cubic-bezier(0, 0.62, 1, 1);
              border: 0 !important;
              &:before {
                border-color: #fff !important;
              }
            }
          }

          .pop-up-content {
            display: none;
          }
          .slide-inner {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 622px;
            overflow: hidden;
            background: #272e3e;
            @media (min-width: 813px) and (max-width: 991px){
              height: 600px;
            }
            @media (min-width: 596px) and (max-width: 812px){
              height: 560px;
            }
            @media (min-width: 481px) and (max-width: 595px){
              height: 510px;
            }
            @media (max-width: 480px){
              height: 480px;
            }
            &.scale-item {
              margin-top: 0 !important;
              margin-left: 0 !important;
            }
            &.scale-item {
              z-index: 2;
              border: 1px solid #fff;
              height: 100%;
              @media (max-width: 812px){
                width: 100% !important;
                height: 100% !important;
                transform: translateX(0%) !important;
              }
              .content-wrapper {
                opacity: 0;
                transition: opacity 0s 0s cubic-bezier(0, 0.62, 1, 1);
              }
              .bg-img {
                img {
                  width: 100% !important;
                  height: auto !important;
                  margin-left: 0 !important;
                }
                > div {
                  canvas {
                    width: 100% !important;
                    height: auto !important;
                    margin-left: 0 !important;
                  }
                }
              }
              .hover-content {
                opacity: 1;
                h5 {
                  font-size: 24px;
                  font-family: 'Poppins', serif;
                  bottom: 300px;
                  opacity: 1;
                  line-height: 1.3;
                  transform: translate(0, 0);
                  transition: opacity .35s linear .9s, -webkit-transform .45s linear .9s;
                  transition: opacity .35s linear .9s, transform .45s linear .9s;
                  transition: opacity .35s linear .9s, transform .45s linear .9s, -webkit-transform .45s linear .9s;
                  @media (min-width: 992px) and (max-width: 1200px) {
                    font-size: 22px;
                  }
                  @media (min-width: 813px) and (max-width: 991px) {
                    font-size: 20px;
                  }
                  @media (min-width: 596px) and (max-width: 812px) {
                    font-size: 18px;
                    bottom: 350px !important;
                  }
                  @media (min-width: 481px) and (max-width: 595px) {
                    font-size: 18px;
                    bottom: 350px !important;
                  }
                  @media (max-width: 480px) {
                    font-size: 16px;
                  }
                  &:after {
                    bottom: -34px;
                    transform: scaleX(1.8);
                  }
                }
                p {
                  opacity: 1;
                  transform: translate(0, 0);
                  transition: opacity .35s linear .9s, -webkit-transform .45s linear .9s;
                  transition: opacity .35s linear .9s, transform .45s linear .9s;
                  transition: opacity .35s linear .9s, transform .45s linear .9s, -webkit-transform .45s linear .9s;
                  @media (min-width: 596px) and (max-width: 812px){
                    top: 270px !important;
                  }
                  @media (min-width: 481px) and (max-width: 595px) {
                    top: 220px !important;
                  }
                }
                .bg-img {
                  opacity: 1;
                  transform: translate(0, 0);
                  transition: opacity .35s linear .9s, -webkit-transform .45s linear .9s;
                  transition: opacity .35s linear .9s, transform .45s linear .9s;
                  transition: opacity .35s linear .9s, transform .45s linear .9s, -webkit-transform .45s linear .9s;
                  &:before {
                    opacity: 0.7;
                    background: linear-gradient(270deg, transparent 0, transparent 18%, #000 75%, #000);
                    background: -moz-linear-gradient(270deg, transparent 0, transparent 18%, #000 75%, #000);
                    background: -webkit-linear-gradient(270deg, transparent 0, transparent 18%, #000 75%, #000);

                    @media screen and (max-width: 767px) {
                      background: rgba(39, 46, 62, 0.8);
                      opacity: 1;
                    }
                    @media only screen  and (min-device-width : 375px)  and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) and (orientation : landscape) {
                      background: rgba(39, 46, 62, 0.8);
                      opacity: 1;
                    }
                  }

                }
              }
            }
            &:before {
              content: "";
              position: absolute;
              border: 1px solid #4398b5;
              width: 0;
              height: 0;
              box-sizing: border-box;
              top: 20px;
              left: 20px;
              z-index: 2;
              width: calc(100% - 40px);
              height: calc(100% - 40px);
              opacity: 0;
              transition: all 0.4s cubic-bezier(0, 0.62, 1, 1);
            }
            .bg-img {
              width: 100%;
              height: 100%;
              overflow: hidden;
              &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: rgba(39, 46, 62, .8);
                z-index: 1;
              }
              > div {
                width: 100% !important;
                height: 100% !important;
              }
              img {
                width: 100%;
                height: auto;
              }
            }
            .content-wrapper {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              text-align: center;
              transition: opacity 0.4s 0.3s cubic-bezier(0, 0.62, 1, 1);

              h5 {
                position: absolute;
                left: 0;
                bottom: 303px;
                font-family: 'Poppins', serif;
                color: #fff;
                font-size: 18px;
                font-weight: 500;
                width: 100%;
                padding: 0 20px;
                z-index: 1;
                transition: all, 0.2s, cubic-bezier(0, 0.62, 1, 1);
                @media (min-width: 992px) and (max-width: 1200px) {
                  font-size: 22px;
                }
                @media (min-width: 813px) and (max-width: 991px) {
                  font-size: 20px;
                }
                @media (min-width: 596px) and (max-width: 812px) {
                  font-size: 18px;
                }
                @media (min-width: 481px) and (max-width: 595px) {
                  font-size: 18px;
                }
                @media (max-width: 480px) {
                  font-size: 16px;
                }
                &:after {
                  content: '';
                  position: absolute;
                  bottom: -37px;
                  left: calc(50% - 26px);
                  width: 52px;
                  height: 4px;
                  background: #cf473a;
                  transition: all 0.3s cubic-bezier(0, 0.62, 1, 1);
                }
              }
              p {
                position: absolute;
                top: 415px;
                left: 0;
                padding: 0 40px;
                color: #fff;
                opacity: 0;
                line-height: 2;
                z-index: 1;
                @media (min-width: 813px) and (max-width: 991px){
                  top: 345px;
                }
                @media (min-width: 596px) and (max-width: 812px){
                  top: 270px !important;
                }
                @media (min-width: 481px) and (max-width: 595px){
                  top: 220px !important;
                }
              }
              .bg-img {
                position: relative;
                z-index: 1;
              }
              .canvas-container {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 0;
                opacity: 0;
                transition: all .4s linear;
                &:before {
                  content: "";
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  top: 0;
                  left: 0;
                  background: rgba(39, 46, 62, .8);
                  z-index: 1;
                }
                > div {
                  width: 100% !important;
                  height: 100% !important;
                }
              }
            }
            .hover-content {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              text-align: center;
              opacity: 0;
              h5 {
                position: absolute;
                left: 0;
                bottom: 303px;
                font-family: 'Poppins', serif;
                color: #fff;
                font-size: 18px;
                font-weight: 500;
                width: 100%;
                padding: 0 20px;
                z-index: 1;
                transform: translate(-10px, 0);
                opacity: 0;
                @media (min-width: 992px) and (max-width: 1200px) {
                  font-size: 22px;
                }
                @media (min-width: 813px) and (max-width: 991px) {
                  font-size: 20px;
                }
                @media (min-width: 596px) and (max-width: 812px) {
                  font-size: 18px;
                }
                @media (min-width: 481px) and (max-width: 595px){
                  font-size: 18px;
                }
                @media (max-width: 480px) {
                  font-size: 16px;
                }
                &:after {
                  content: '';
                  position: absolute;
                  bottom: -37px;
                  left: calc(50% - 26px);
                  width: 52px;
                  height: 4px;
                  background: #cf473a;
                }
              }
              p {
                position: absolute;
                top: 417px;
                left: 0;
                padding: 0 40px;
                color: #fff;
                opacity: 1;
                font-size: 14px;
                font-weight: 300;
                line-height: 1.9;
                z-index: 1;
                transform: translate(-10px, 0);
                opacity: 0;
                @media (min-width: 813px) and (max-width: 991px){
                  top: 375px;
                  font-size: 14px;
                }
                @media (min-width: 596px) and (max-width: 812px){
                  top: 345px;
                  font-size: 14px;
                }
                @media (min-width: 481px) and (max-width: 595px){
                  top: 295px;
                  font-size: 12px;
                }
                @media (max-width: 480px){
                  top: 255px;
                  font-size: 12px;
                }
              }
              .bg-img {
                transform: translate(0, 100px);
                opacity: 0;
                iframe {
                  width: 100%;
                  height: 100%;
                  border: 0;
                }
              }
            }
          }
          &:hover {
            .slide-inner {
              &:before {
                opacity: 1;
              }
              .content-wrapper {
                h5 {
                  &:after {
                    transform: scaleX(1.8);
                  }
                }
                .bg-img {
                  opacity: 0;
                }
                .canvas-container {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
    .slide-arrow {
      position: relative;
      width: calc(100% - 50px);
      max-width: 1170px;
      height: 34px;
      margin: 26px auto 0;
      @media (max-width: 812px) {
        display: none;
      }
      &:after {
        content: "";
        display: block;
        clear: both;
      }

      .next {
        position: absolute;
        top: 0;
        right: 0;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        overflow: hidden;
        float: right;
        &.active {
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
            transition: all 0.2s cubic-bezier(0, 0.62, 1, 1);
            &:nth-child(1) {
              opacity: 1;
            }
            &:nth-child(2) {
              opacity: 0;
            }
          }
        }
        img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          &:nth-child(1) {
            opacity: 0;
          }
        }
      }
      .prev {
        position: absolute;
        top: 0;
        left: 0;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        overflow: hidden;
        float: left;
        &.active {
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
            transition: all 0.2s cubic-bezier(0, 0.62, 1, 1);
            transform: rotate(180deg);
            &:nth-child(1) {
              opacity: 1;
            }
            &:nth-child(2) {
              opacity: 0;
            }
          }
        }
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transform: rotate(180deg);
          &:nth-child(1) {
            opacity: 0;
          }

        }
      }
      .slide-nav {
        position: relative;
        margin: 0 auto;
        top: calc(50% - 5px);
        //max-width: 1170px;
        width: calc(100% - 136px);
        height: 100%;
        @media (max-width: 812px){
          visibility: hidden;
        }
        &:after {
          content: "";
          position: absolute;
          top: 5px;
          left: 0px;
          width: 100%;
          height: 1px;
          background: #e6edef;
        }
        span {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 11px;
          height: 11px;
          float: left;
          z-index: 1;
          transition: all 0.4s cubic-bezier(0, 0.62, 1, 1);
          cursor: pointer;
          &:nth-child(1) {
            margin-left: 0px;
          }
          &:last-child {
            margin-right: 0px;
          }
          &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 11px;
            height: 11px;
            background: #e6edef;
            transform: rotate(45deg);
            transition: all 0.4s cubic-bezier(0, 0.62, 1, 1);
          }
          &:hover {
            .pop-up {
              transform: translateY(0) scale(1);
            }
            &:after {
              background: #4298b5;
            }

          }
          &.active {
            &:after {
              background: #4298b5;
            }
          }
          .pop-up {
            position: absolute;
            top: -290px;
            left: calc(50% - 143px);
            width: 286px;
            height: 286px;
            border-radius: 50%;
            overflow: hidden;
            background: #4298b5;
            -webkit-transform: translatey(143px) scale(0);
            -moz-transform: translatey(143px) scale(0);
            -ms-transform: translatey(143px) scale(0);
            -o-transform: translatey(143px) scale(0);
            transform: translatey(143px) scale(0);
            transition: all 0.4s cubic-bezier(0, 0.62, 1, 1);
            .bg-img {
              text-align: center;
              img {
                width: 100%;
              }
            }
            h5 {
              font-size: 20px;
              font-weight: 900;
              text-align: center;
              padding: 0 40px;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
.ie10 .client-experience .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner.scale-item .bg-img canvas {
  height: 100% !important;
}

/*
 * intro-with-image css
 */

.intro-with-image {
  z-index: 1;
  background: #fff;
  max-height: 1078px;
  overflow: hidden;
  position: relative;
  @media (min-width: 1900px) {
    max-height: inherit;
  }

  .intro-video {
    position: relative;
    @media  (max-width: 1024px) {
      height: 550px;
    }
    .v-middle-wrapper {
      @media screen and (min-width: 1400px) {
        -webkit-transform: translateX(0%) translateY(10px);
        transform: translateX(0%) translateY(10px);
      }
      @media (min-width: 992px) and (max-width: 1399px) {
        -webkit-transform: translateX(0%) translateY(-10%);
        transform: translateX(0%) translateY(-10%);
      }
      @media (min-width: 813px) and (max-width: 991px) {
/*
        -webkit-transform: translateX(0%) translateY(-12%);
        transform: translateX(0%) translateY(-12%);
*/
      }
      @media (min-width: 596px) and (max-width: 812px) {
/*
        -webkit-transform: translateX(0%) translateY(-12%);
        transform: translateX(0%) translateY(-12%);
*/
      }
      @media (min-width: 481px) and (max-width: 595px) {
/*
        -webkit-transform: translateX(0%) translateY(-14%);
        transform: translateX(0%) translateY(-14%);
*/
      }
      @media  (max-width: 480px){
/*
        -webkit-transform: translateX(0%) translateY(-16%);
        transform: translateX(0%) translateY(-16%);
*/
      }
      .v-middle {
        vertical-align: top;
        @media screen and (max-width: 1400px) and (min-width: 1201px){
          padding-top: 0;
        }
        @media screen and (max-width: 1200px) and (min-width: 992px){
          padding-top: 0;
        }
        @media screen and (max-width: 991px) and (min-width: 813px){
          padding-top: 50px;
        }
        @media screen and (max-width: 812px) and (min-width: 768px) {
          padding-top: 40px;
        }

/*
        @media screen and (max-width: 812px) and (min-width: 596px){
          padding-top: 0;
        }
        @media screen and (max-width: 595px) and (min-width: 481px){
          padding-top: 44px;
        }
*/
        @media screen and (max-width: 480px) {
          padding-top: 0;
        }
      }
    }
    .video-canvas{
      @media  (max-width: 1024px){
        height: 100%;
        width: 100%;
      }
    }
    .location {
      position: absolute;
      background: #FFFFFF;
      max-width: 160px;
      min-width: 160px;
      margin: 4px auto 0;
      padding: 6px 10px 4px 36px;
      right: calc(50% - 160px);
      opacity: 0;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      display: none;
      &:not(:root:root) {
        @media screen and (max-width: 812px) {
          opacity: 1;
          backface-visibility: visible;
        }
      }
      @media (min-width: 481px) and (max-width: 595px){
        padding: 5px 10px 5px 35px;
      }
      @media (max-width: 480px) {
        max-width: 125px;
        min-width: 125px;
        padding: 5px 10px 5px 13px;
        right: calc(50% - 140px);
      }
      p {
        text-align: left;
        color: #CF473A;
        font-size: 10px;
        font-weight: 700;
        line-height: 1.28;
        text-transform: uppercase;
        margin: 0;
        @media (max-width: 480px) {
          font-size: 9px;
        }
      }
    }
    video {
      position: relative;
      width: 100%;
      height: 100%;
      -webkit-appearance: none;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      z-index: -100;
      -webkit-transform: translateX(-50%) translateY(2%);
      transform: translateX(-50%) translateY(2%);
      div {
        display: none;
      }
      &::-webkit-media-controls-start-playback-button {
        display: none!important;
        -webkit-appearance: none;
      }
      @media screen and (min-width: 1400px) {
        -webkit-transform: translateX(-50%) translateY(100px);
        transform: translateX(-50%) translateY(69px);
      }
      @media (min-width: 813px) and (max-width: 991px) {
        height: 600px;
        -webkit-transform: translateX(-50%) translateY(2%);
        transform: translateX(-50%) translateY(2%);
      }
      @media (min-width: 596px) and (max-width: 812px) {
        height: 550px;
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-35%);
      }
      @media screen and (max-width: 595px) {
        height: 550px;
        -webkit-transform: translateX(-50%) translateY(2%);
        transform: translateX(-50%) translateY(-50%);
      }
/*
      @media  (max-width: 480px) {
        height: 550px;
        -webkit-transform: translateX(-50%) translateY(3%);
        transform: translateX(-50%) translateY(3%);
      }
*/
      @media screen and (-webkit-min-device-pixel-ratio:0) {
        object-fit: cover;
      }
    }
    video[poster]{
      width: 100%;
    }
  }
  .content {
    width: 100%;
    max-width: 500px;
    padding: 0 20px;
    margin: auto;
    h2 {
      color: #707071;
      font-size: 24px;
      font-weight: 300;
      margin: 0;
      @media (min-width: 992px) and (max-width: 1200px) {
        // margin-bottom: 55px;
      }
      @media (min-width: 813px) and (max-width: 991px) {
        margin-bottom: 0;
      }
      @media (max-width: 812px) {
        margin-bottom: 0;
      }
      @media (min-width: 596px) and (max-width: 812px) {
        padding-top: 0;
      }
      @media (min-width: 481px) and (max-width: 495px) {
        padding-top: 0;
      }
      @media (max-width: 480px) {
        padding-top: 0;
      }
    }
    p {
      //color: $darkGrey;
      @media (min-width: 813px) and (max-width: 991px) {
        margin-bottom: 0;
      }
      @media (max-width: 812px) {
        margin-top: 14px;
        margin-bottom: 0;
        line-height: 1.3;
      }
    }
    .fade-in-down {
      visibility: hidden;
      visibility: visible\ie9;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
    }
  }
  //}
  &.overlay {
    vertical-align: middle;
    padding-bottom: 142px;
    padding-top: inherit;
  }
  //}
}



@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    visibility: visible;
    transform: none;
  }
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    visibility: visible;
    transform: none;
  }
}
@-moz-keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    visibility: visible;
    transform: none;
  }
}
@-ms-keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    visibility: visible;
    transform: none;
  }
}

.home {
  .intro-with-image {
    .bg-img {
      img {
        display: none;
      }
    }
    .intro-video {
      display: block;
      position: relative;
      padding-top: 140px;
      @media (min-width:1400px){
        padding-top: 0px;
      }
      .v-middle-wrapper {
        z-index:99;
      }
    }
  }
}




.statistics-circle-block {
  position: relative;
  overflow: hidden;
  background-image: url(/img/case-studies/sandhill/circle-block-bg-1.png);
  background-repeat: repeat;
  @media (min-width: 992px) and (max-width: 1200px) {
    //padding: 70px 0px;
  }
  @media (min-width: 813px) and (max-width: 991px) {
    //padding: 60px 0px;
  }
  @media  (max-width: 812px) {
    //padding: 40px 0px;
  }
  .col-wrapper {
    width: calc(100% + 76px);
    margin-left: -38px;
    text-align: center;
    color: white;
    .col {
      width: calc(20% - 76px);
      max-width: 176px;
      margin: 0 38px;
      float: left;
      display: block;
      @media (min-width: 992px) and (max-width: 1200px) {
        width: 33.33%;
        margin: 0 25px;
        float: none;
        display: inline-block;
        vertical-align: top;
      }

      @media (min-width: 813px) and (max-width: 991px) {
        width: 33.33%;
        margin: 0 25px;
        float: none;
        display: inline-block;
        vertical-align: top;
      }

      @media (min-width: 596px) and (max-width: 812px) {
        width: 33.33%;
        margin: 0 25px;
        float: none;
        display: inline-block;
        vertical-align: top;
      }
      @media (min-width: 481px) and (max-width: 595px) {
        width: 50%;
        margin: 0 25px;
        float: none;
        display: inline-block;
        vertical-align: top;
      }
      @media (max-width: 480px) {
        width: 100%;
        float: none;
        margin: 0 auto 38px;
      }

      .progress {
        width: 176px;
        height: 176px;
        background: none;
        margin: 0 auto;
        box-shadow: none;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: calc(50% - 89px);
          border: 22px solid #e4e7ea;
          opacity: 0.3;
          border-radius: 50%;
          z-index: 99;
        }
        p {
          font-size: 45px;
          color: $white;
          margin: 0 auto;
          font-weight: 700;
        }
        .value {
          font-size: 45px;
          font-weight: 700;
          color: white;
          margin: 0 auto;
        }
        .suffix-icon {
          color: $white;
          margin-left: -11px;
          display: inline-block;
        }
        .progress-inner {
          width: 50%;
          height: 100%;
          overflow: hidden;
          position: absolute;
          top: 0px;
          z-index: 1;
        }
        .progress-bar {
          width: 100%;
          height: 100%;
          background: none;
          border-width: 22px;
          border-style: solid;
          position: absolute;
          top: 0;
        }
        .progress-left {
          left: 0;
          .progress-bar {
            left: 100%;
            border-top-right-radius: 100px;
            border-bottom-right-radius: 100px;
            border-left: 0;
            -webkit-transform-origin: center left;
            transform-origin: center left;
          }
        }
        .progress-right {
          right: 0;
          @media (max-width: 1024px) {
            right: 1px;
          }
          .progress-bar {
            border-top-left-radius: 100px;
            border-bottom-left-radius: 100px;
            left: -100%;
            border-right: 0;
            -webkit-transform-origin: center right;
            transform-origin: center right;
            animation: loading-1 1.8s linear forwards;
          }
        }
      }
      .text-box {
        p {
          color: $white;
          font-size: 18px;
          line-height: 1.4;
          width: 100%;
          max-width: 150px;
          font-weight: 300;
          margin: 27px auto;
          padding: 10px 0 14px;
          @media screen and (max-width: 991px) and (min-width: 813px){
            margin: 10px auto 27px;
          }
          @media screen and (max-width: 812px) {
            margin: 10px auto 27px;
          }
        }
      }
    }
  }
  @media  (min-width: 596px) {
    .col.animated {
      .progress {
        &.circle-one {
          .progress-bar {
            border-color: white;
          }
          .progress-right {
            .progress-bar {
              animation: loading-1 2s linear forwards;
            }
          }

        }
        &.circle-two {
          .progress-bar {
            border-color: $white;
          }
          .progress-left {
            .progress-bar {
              animation: loading-2 1s linear forwards 1s;
            }
          }
          .progress-right {
            .progress-bar {
              animation: loading-2 1s linear forwards;
            }
          }
        }
        &.circle-three {
          .progress-bar {
            border-color: $white;
          }
          .progress-right {
            .progress-bar {
              animation: loading-3 2s linear forwards;
            }
          }
        }
        &.circle-four {
          .progress-bar {
            border-color: $white;
          }
          .progress-right {
            .progress-bar {
              animation: loading-4 2s linear forwards;
            }
          }
        }
        &.circle-five {
          .progress-bar {
            border-color: $white;
          }
          .progress-right {
            .progress-bar {
              animation: loading-5 2s linear forwards;
            }
          }
        }
      }
    }
  }
  @media  (max-width: 595px) {
    .col {
      .progress {
        &.circle-one {
          .progress-bar {
            border-color: $white;
          }
          .progress-right {
            .progress-bar {
              animation: loading-1 2s linear forwards;
            }
          }

        }
        &.circle-two {
          .progress-bar {
            border-color: $white;
          }
          .progress-left {
            .progress-bar {
              animation: loading-2 1s linear forwards 1s;
            }
          }
          .progress-right {
            .progress-bar {
              animation: loading-2 1s linear forwards;
            }
          }
        }
        &.circle-three {
          .progress-bar {
            border-color: $white;
          }
          .progress-right {
            .progress-bar {
              animation: loading-3 2s linear forwards;
            }
          }
        }
        &.circle-four {
          .progress-bar {
            border-color: $white;
          }
          .progress-right {
            .progress-bar {
              animation: loading-4 2s linear forwards;
            }
          }
        }
        &.circle-five {
          .progress-bar {
            border-color: $white;
          }
          .progress-right {
            .progress-bar {
              animation: loading-5 2s linear forwards;
            }
          }
        }
      }
    }
  }
  .small-container {
    text-align: center;
    h2, p {
      color: $white;
    }
  }
  @keyframes loading-1 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(60deg);
      transform: rotate(60deg);
    }
  }
  @keyframes loading-2 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
  @keyframes loading-3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(80deg);
      transform: rotate(80deg);
    }
  }
  @keyframes loading-4 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(100deg);
      transform: rotate(100deg);
    }
  }
  @keyframes loading-5 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(26deg);
      transform: rotate(26deg);
    }
  }
}



