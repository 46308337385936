@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,700");
.clear {
  clear: both; }

.clearfix:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
  overflow: hidden;
  visibility: hidden; }

.v-align {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  z-index: 2;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.v-align-only {
  position: absolute;
  top: 50%;
  width: 100%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }
  @media screen and (max-width: 980px) {
    .v-align-only {
      position: relative;
      top: auto;
      -ms-transform: none;
      -webkit-transform: none;
      transform: none;
      width: auto;
      height: 100%; } }

.top-padding {
  padding-top: 40px !important; }

.no-bottom-margin {
  margin-bottom: 0 !important; }

.no-bottom-padding {
  padding-bottom: 0 !important; }

img.img-center {
  margin: 0 auto; }

@media screen and (max-width: 992px) {
  .hide-on-mobile {
    display: none !important; } }

@-webkit-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0; }
  1% {
    display: block;
    opacity: 0; }
  100% {
    display: block;
    opacity: 1; } }

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0; }
  1% {
    display: block;
    opacity: 0; }
  100% {
    display: block;
    opacity: 1; } }

.rotate {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg); }

img.rspnsvimg {
  max-width: 100%;
  margin: 0 auto; }

img.full-width {
  width: 100%; }

.hide {
  display: none; }

h1,
h2,
h3,
h4,
h5 {
  color: #252728;
  font-family: 'Poppins', serif;
  font-weight: 400;
  -webkit-font-smoothing: initial; }

h2,
h3 {
  font-size: 30px;
  letter-spacing: -1.3px;
  line-height: 1.2;
  margin: 0 0 18px; }
  @media screen and (max-width: 1023px) {
    h2,
    h3 {
      font-size: 26px; } }
  @media screen and (max-width: 767px) {
    h2,
    h3 {
      margin: 0 0 12px;
      letter-spacing: -1px; } }
  @media screen and (max-width: 500px) {
    h2,
    h3 {
      font-size: 24px; } }

h4 {
  font-size: 26px;
  letter-spacing: 0; }
  @media screen and (max-width: 767px) {
    h4 {
      font-size: 24px; } }
  @media screen and (max-width: 500px) {
    h4 {
      font-size: 22px; } }

.full-width-content-darkgrey h2 {
  color: #ffffff; }

.purple-font {
  color: #a072a5; }
  .purple-font a:hover {
    text-decoration: underline; }

.orange-font {
  color: #ef9123; }
  .orange-font a:hover {
    text-decoration: underline; }

.green-font {
  color: #a3cf5f; }
  .green-font a {
    color: #a3cf5f !important; }
    .green-font a:hover {
      text-decoration: underline; }

.white-font {
  color: #ffffff; }
  .white-font a {
    text-decoration: none;
    color: #ffffff; }
    .white-font a:hover {
      text-decoration: underline; }

a.white-font {
  color: #ffffff; }
  a.white-font:hover {
    text-decoration: underline; }

p {
  margin: 0 0 20px;
  font-size: 20px;
  line-height: 1.4;
  -webkit-font-smoothing: initial; }
  @media screen and (max-width: 1023px) {
    p {
      font-size: 18px; } }
  @media screen and (max-width: 580px) {
    p {
      font-size: 16px; } }

/* Slider */
.team-crsl .slick-prev,
.team-crsl .slick-next {
  top: 30%; }

.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #ffffff "/img/ajax-loader.gif?ver=2.5.5" center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: slick-font-url("slick.eot");
  src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  width: 42px;
  height: 50px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background-color: transparent;
  top: 50%;
  margin-top: -10px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  z-index: 70; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none; }

.slick-prev {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEUAAABUCAMAAADj2QolAAAAbFBMVEUAAAAAAADQ0NAODg68vLweHh4EBASlpaUoKCgAAAC1tbXU1NTIyMjFxcWOjo5zc3NWVlZKSko9PT0zMzOvr6+bm5uCgoJnZ2cWFhYAAAAAAADMzMxjY2MAAAAAAAAAAAAAAAAAAAAAAADV1dXAdPAZAAAAI3RSTlO/AP3D9sfA7cm48/76+ebe1dLOzPHq4trEYFv82JmXeRQYF4JmdtwAAADmSURBVHgB7dhJcsIwEEbhHw+yZGYwBDtzdP87Rr3N9qlSpqq/A7wFuCVVC0nv88+mEJUWq3CfVuGWKpX0pRpm1fChGpKelHMujlHYZZe7VtCpyTkPYo652L6ICAeLNGcR7WCR/VVEP1qk60Vc9xYZWhHnV4scgoi3rUWOQu7WaE4iws0iu4uIx2SRLoqInUWmBx2/4tby8ct3/eHjN/ZCJos0IAKOAvC7gP8IfC/o2w0C8BzxmebnCz/r+LnL7wB+H9W/G304+buOvzH/7b3rnHNpRZu2eT0byKX+ZhZvicHG+ntT/AKXpB15Ebi8agAAAABJRU5ErkJggg==) !important;
  background-size: 100%;
  border-radius: 0 8px 8px 0;
  left: 0; }
  .slick-prev:hover {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEUAAABUCAMAAADj2QolAAAAclBMVEUAAAAAAAAVFRX4+PgkJCQwMDAAAAAAAADx8fHs7Ozj4+PZ2dmrq6t6enpZWVk9PT0JCQnS0tL8/Pzd3d3FxcW5ubmbm5tmZmZJSUkAAAAAAACIiIjHx8fCwsKNjY1ra2tMTEwAAAAAAAAAAACIiIj///9953URAAAAJXRSTlO/AMP9x8m4Xfv59/Pm2tLMwfH+9O3q4tTOmBXd7uze1s55AgHcH20CeAAAAONJREFUeAHtmAlOgDAQRSuFln1BQQFx7/2vKHOGN4mYzDvAC6H9M813iLDF/eHCUUL8Fgvm80vD4qKKJexOg6hi2VQswRmG8U8pmoJLXspUZ1Ty7FNKJ5QcSeiYpBWH75EjexJJNSBJ3ojkMUeSoRLJKzuf3oukHZGkm0Xy5hCrOOZ35BgXkZQf7IQnkdQFi18tkimj8btYRh6/tFr8lOPnJpF4keh/C/8v/IwIP/y+8LvLc6SfaT5f+Kzjc5fvALiPwG60cB6K4Tz/4o0J3ruGYRjhRk1bvE8DGfWbWd4Sw8b6F07YH1gCv+gyAAAAAElFTkSuQmCC) !important;
    background-size: 100%; }

.slick-next {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEUAAABUCAMAAADj2QolAAAAb1BMVEUAAAAAAADPz88ODg4EBASkpKQZGRkAAAC0tLQqKirT09PHx8fExMS9vb2Ojo5lZWVKSko9PT0zMzMiIiKurq4AAACBgYFxcXFVVVUAAAAAAAC5ubnLy8ubm5uZmZl1dXVZWVkAAAAAAAAAAADU1NTLGDBqAAAAJHRSTlO/AP3DwO3FXvPK/vr59+ba0s7MyPG44t3UmBX0/Orp3ta7erJrDvygAAAA7klEQVR42u3YVw6DMBAE0KEaSOglhfT4/mcMyw+/SOOIRNp3gBFmvba18CZnc+rBkBTzhOBSXuB5Bg6cezhg4MIJLvRQSqkV0iQFLcjtrgYrstb6R5DKzE4OIFW+xBQhOM1eYqIAnHaUmCRm6xRJzL4BJywk5lKBdJeYrATpMZdqAKneScwtZBsql5iOLVXcSUzeshW/SgzdnOFgtTm/3JztvKiODEmYb+H/C18j9/tl2btb9tHS06nr84U/6/hzl78D+Pto+7uxHbX9VrzrqDcm/95VSv2p/ocmbQYbTCCJaSgxmd1kSjxPrN9gfACctB59h6DZJwAAAABJRU5ErkJggg==) !important;
  background-size: 100%;
  border-radius: 8px 0 0 8px;
  right: 0; }
  .slick-next:hover {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEUAAABUCAMAAADj2QolAAAAe1BMVEUAAAAAAAAVFRUkJCTFxcUAAAD6+vr39/cAAADx8fHs7Ozj4+PZ2dmrq6uKiop6enpZWVk9PT0vLy8JCQn+/v7d3d1mZmZJSUkAAAAAAADT09PQ0NC7u7u4uLienp6ZmZlra2tMTEw1NTUAAAAAAAAAAAAAAAAAAAD////KoiNuAAAAKHRSTlO/AMPH7V7+/Ln7+ffz5t7a0szJwf701M6YFfHw6uni4dbOygIBsnt5sh7/KwAAAO5JREFUeAHt2GlOhTAUxfEWKPMMKuAsDt3/CuXGDZj8a+yHexbwy3u0p22usVd2t5UGxdp3BwlRzi/DY50JkL0MoTgTIlsQ5Vd/SKPRaN7amSNJ46s7rGTe+/yWKsPqr/SUGQthOspMtTBZApn0Rpg2peuUCVNPkFk6YYrRwPTC5ANlXnJxninzWgnzuEBmboS5pyueHMI0tJzLgzC8nE/+j8up5UwLYY4Ifgv4LmCNwH4Bexf0iHeany/8rOPnLr8D1uH/7yN+N2r9+LuOvzH5e1ej0cSaMqJJmzPxTCCtC6KcnxwJNSX+mVh/IOQbKd0hQUg0ZOcAAAAASUVORK5CYII=) !important;
    background-size: 100%; }

/* Dots */
.slick-slider {
  margin-bottom: 30px; }

.slick-slider-work {
  margin-bottom: 0px; }

.fs-instly .slick-slider {
  margin-bottom: 0; }

.slick-dots {
  position: absolute;
  bottom: -45px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 1; }

.purple-graphic-wrapper {
  max-width: 1200px;
  margin: 60px auto 24px auto;
  position: relative; }
  .purple-graphic-wrapper .nmbr-up {
    position: absolute;
    top: 0;
    left: -2px;
    width: 25%; }
    .purple-graphic-wrapper .nmbr-up img {
      width: 100%;
      padding-right: 13px;
      padding-top: 5px; }

.purple-graphic {
  width: 50%;
  display: inline-block;
  margin: 0 -2px; }
  .purple-graphic img {
    width: 100%; }

.ca-sj-box {
  width: 300px;
  background: url(/img/ca.png?ver=2.5.5) no-repeat center top;
  background-size: 270px;
  height: 140px;
  border-right: 1px solid #a072a5;
  margin: 26px 0;
  display: inline-block;
  vertical-align: top; }
  .ca-sj-box h4 {
    margin: 0 0 0 130px;
    line-height: 1;
    padding: 8px 0;
    letter-spacing: -1.1px; }
  .ca-sj-box p {
    margin: 8px 0 0 134px;
    font-size: 16px; }

div.lnkd-icon {
  width: 40px;
  height: 40px;
  position: absolute;
  z-index: 1000;
  left: 20px;
  top: -70px;
  cursor: pointer; }

.team-member-box:hover div.lnkd-icon {
  background: url(/img/lnkd_icon_hover.png?ver=2.5.5);
  background-size: 100%; }

.careers {
  background-color: #d6dede;
  overflow: hidden;
  display: flex;
  align-items: center; }
  @media screen and (max-width: 992px) {
    .careers {
      display: block; } }
  .careers .box {
    float: left;
    width: 50%;
    overflow: hidden;
    height: 100%;
    margin: 0; }
    .careers .box img {
      width: 100%; }
    @media screen and (max-width: 992px) {
      .careers .box {
        margin: 0 auto;
        float: none;
        width: 100%;
        padding: 60px 40px; } }
    .careers .box a.request-services {
      margin: 0 auto; }
    .careers .box .careers-box {
      margin: 0px auto;
      padding: 0px 60px;
      max-width: 800px;
      text-align: center;
      position: relative; }
      @media screen and (max-width: 992px) {
        .careers .box .careers-box {
          padding: 0px;
          max-width: 100%; } }
      .careers .box .careers-box p {
        margin: 26px auto; }

.pstr-sdw-mbl {
  display: none; }

.what-we-like a {
  color: #707071;
  text-decoration: none; }

.what-we-like a:hover {
  color: #707071;
  text-decoration: underline; }

.team-member-box {
  background-color: #252728;
  position: relative; }
  .team-member-box img {
    width: 100%; }

.team-info-box {
  padding: 20px 16px;
  min-height: 70px;
  position: relative;
  box-sizing: content-box; }
  .team-info-box h5 {
    color: #ffffff;
    font-weight: 300;
    font-size: 16px; }
  .team-info-box p {
    margin: 4px 0;
    color: #707071;
    font-size: 16px; }

/*---------------------------------------------------------------*/
/* img grid css*/
/*---------------------------------------------------------------*/
#grid-wrapper {
  margin: 0;
  position: relative; }

.grid-row {
  width: 100%;
  position: relative;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }
  .grid-row div.wefade {
    position: relative;
    flex-grow: 1;
    font-size: 0; }
    .grid-row div.wefade img {
      width: 100%;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-transition: opacity 1.5s ease-in-out;
      -moz-transition: opacity 1.5s ease-in-out;
      -o-transition: opacity 1.5s ease-in-out;
      transition: opacity 1.5s ease-in-out; }
      .grid-row div.wefade img.active {
        position: relative;
        opacity: 1; }

.leadership-box {
  text-transform: uppercase;
  background: #707071;
  min-height: 44px;
  padding: 14px 16px; }
  .leadership-box p {
    color: #ffffff;
    font-size: 12px;
    letter-spacing: 1px;
    margin-bottom: 0px; }

.hrz-line {
  width: 100%;
  height: 2px;
  border-top: solid 1px #707071; }

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite; }

.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s; }

@-webkit-keyframes iconAnimation {
  0% {
    opacity: 0; }
  10% {
    opacity: 1; }
  20% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes iconAnimation {
  0% {
    opacity: 0; }
  10% {
    opacity: 1; }
  20% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-webkit-keyframes iconAnimationOne {
  0% {
    opacity: 0; }
  10% {
    opacity: 0; }
  20% {
    opacity: 1; }
  30% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes iconAnimationOne {
  0% {
    opacity: 0; }
  10% {
    opacity: 0; }
  20% {
    opacity: 1; }
  30% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-webkit-keyframes iconAnimationTwo {
  0% {
    opacity: 0; }
  20% {
    opacity: 0; }
  30% {
    opacity: 1; }
  40% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes iconAnimationTwo {
  0% {
    opacity: 0; }
  20% {
    opacity: 0; }
  30% {
    opacity: 1; }
  40% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-webkit-keyframes iconAnimationThree {
  0% {
    opacity: 0; }
  30% {
    opacity: 0; }
  40% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes iconAnimationThree {
  0% {
    opacity: 0; }
  30% {
    opacity: 0; }
  40% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-webkit-keyframes iconAnimationFour {
  0% {
    opacity: 0; }
  40% {
    opacity: 0; }
  50% {
    opacity: 1; }
  60% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes iconAnimationFour {
  0% {
    opacity: 0; }
  40% {
    opacity: 0; }
  50% {
    opacity: 1; }
  60% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-webkit-keyframes iconAnimationFive {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  60% {
    opacity: 1; }
  70% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes iconAnimationFive {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  60% {
    opacity: 1; }
  70% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-webkit-keyframes iconAnimationSix {
  0% {
    opacity: 0; }
  60% {
    opacity: 0; }
  70% {
    opacity: 1; }
  80% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes iconAnimationSix {
  0% {
    opacity: 0; }
  60% {
    opacity: 0; }
  70% {
    opacity: 1; }
  80% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-webkit-keyframes iconAnimationSeven {
  0% {
    opacity: 0; }
  70% {
    opacity: 0; }
  80% {
    opacity: 1; }
  90% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes iconAnimationSeven {
  0% {
    opacity: 0; }
  70% {
    opacity: 0; }
  80% {
    opacity: 1; }
  90% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-webkit-keyframes bounce {
  0%, 20%, 53%, 80%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  40%, 43% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0); }
  70% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0); }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0); } }

@keyframes bounce {
  0%, 20%, 53%, 80%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  40%, 43% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    -ms-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0); }
  70% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    -ms-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0); }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    -ms-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0); } }

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  -ms-transform-origin: center bottom;
  transform-origin: center bottom; }

@-webkit-keyframes flash {
  0%, 50%, 100% {
    opacity: 1; }
  25%, 75% {
    opacity: 0; } }

@keyframes flash {
  0%, 50%, 100% {
    opacity: 1; }
  25%, 75% {
    opacity: 0; } }

.flash {
  -webkit-animation-name: flash;
  animation-name: flash; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    -ms-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse; }

@-webkit-keyframes pulse-large {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  13% {
    -webkit-transform: scale3d(1.15, 1.15, 1.15);
    -ms-transform: scale3d(1.15, 1.15, 1.15);
    transform: scale3d(1.15, 1.15, 1.15); }
  25% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    -ms-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  37% {
    -webkit-transform: scale3d(1.15, 1.15, 1.15);
    -ms-transform: scale3d(1.15, 1.15, 1.15);
    transform: scale3d(1.15, 1.15, 1.15); }
  50% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse-large {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  13% {
    -webkit-transform: scale3d(1.3, 1.3, 1.3);
    -ms-transform: scale3d(1.3, 1.3, 1.3);
    transform: scale3d(1.3, 1.3, 1.3); }
  25% {
    -webkit-transform: scale3d(1.25, 1.25, 1.25);
    -ms-transform: scale3d(1.25, 1.25, 1.25);
    transform: scale3d(1.25, 1.25, 1.25); }
  37% {
    -webkit-transform: scale3d(1.3, 1.3, 1.3);
    -ms-transform: scale3d(1.3, 1.3, 1.3);
    transform: scale3d(1.3, 1.3, 1.3); }
  50% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse-large {
  -webkit-animation-name: pulse-large;
  animation-name: pulse-large;
  animation-duration: 1s; }

@-webkit-keyframes rubberBand {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1); }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1); }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1); }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1); }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes rubberBand {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    -ms-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1); }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    -ms-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1); }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    -ms-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1); }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    -ms-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1); }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    -ms-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand; }

@-webkit-keyframes shake {
  0%, 100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); } }

@keyframes shake {
  0%, 100% {
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    -ms-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    -ms-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); } }

.shake {
  -webkit-animation-name: shake;
  animation-name: shake; }

@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg); }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg); }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg); }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg); }
  100% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg); } }

@keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    -ms-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg); }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    -ms-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg); }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    -ms-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg); }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    -ms-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg); }
  100% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    -ms-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg); } }

.swing {
  -webkit-transform-origin: top center;
  -ms-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing; }

@-webkit-keyframes tada {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  10%, 20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg); }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg); }
  40%, 60%, 80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes tada {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  10%, 20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    -ms-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg); }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg); }
  40%, 60%, 80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.tada {
  -webkit-animation-name: tada;
  animation-name: tada; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes wobble {
  0% {
    -webkit-transform: none;
    transform: none; }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg); }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg); }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg); }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg); }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg); }
  100% {
    -webkit-transform: none;
    transform: none; } }

@keyframes wobble {
  0% {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    -ms-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg); }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    -ms-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg); }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    -ms-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg); }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    -ms-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg); }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    -ms-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg); }
  100% {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; } }

.wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble; }

@-webkit-keyframes bounceIn {
  0%, 20%, 40%, 60%, 80%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03); }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97); }
  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes bounceIn {
  0%, 20%, 40%, 60%, 80%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    -ms-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    -ms-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    -ms-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    -ms-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03); }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    -ms-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97); }
  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.bounceIn {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
  -webkit-animation-duration: .75s;
  animation-duration: .75s; }

@-webkit-keyframes bounceInDown {
  0%, 60%, 75%, 90%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0); }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0); }
  100% {
    -webkit-transform: none;
    transform: none; } }

@keyframes bounceInDown {
  0%, 60%, 75%, 90%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    -ms-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    -ms-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0); }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    -ms-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    -ms-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0); }
  100% {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; } }

.bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown; }

@-webkit-keyframes bounceInLeft {
  0%, 60%, 75%, 90%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0); }
  100% {
    -webkit-transform: none;
    transform: none; } }

@keyframes bounceInLeft {
  0%, 60%, 75%, 90%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    -ms-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    -ms-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    -ms-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    -ms-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0); }
  100% {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; } }

.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft; }

@-webkit-keyframes bounceInRight {
  0%, 60%, 75%, 90%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0); }
  100% {
    -webkit-transform: none;
    transform: none; } }

@keyframes bounceInRight {
  0%, 60%, 75%, 90%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    -ms-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    -ms-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    -ms-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    -ms-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0); }
  100% {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; } }

.bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight; }

@-webkit-keyframes bounceInUp {
  0%, 60%, 75%, 90%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  0%, 60%, 75%, 90%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    -ms-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    -ms-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    -ms-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    -ms-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp; }

@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); } }

@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    -ms-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    -ms-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    -ms-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); } }

.bounceOut {
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut;
  -webkit-animation-duration: .75s;
  animation-duration: .75s; }

@-webkit-keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

@keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    -ms-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    -ms-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    -ms-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

.bounceOutDown {
  -webkit-animation-name: bounceOutDown;
  animation-name: bounceOutDown; }

@-webkit-keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); } }

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    -ms-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    -ms-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); } }

.bounceOutLeft {
  -webkit-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    -ms-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    -ms-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); } }

.bounceOutRight {
  -webkit-animation-name: bounceOutRight;
  animation-name: bounceOutRight; }

@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0); }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); } }

@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    -ms-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    -ms-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0); }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    -ms-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); } }

.bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  animation-name: bounceOutUp; }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn; }

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    -ms-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown; }

@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    -ms-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; } }

.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig; }

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    -ms-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft; }

@-webkit-keyframes fadeInLeftLess {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInLeftLess {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-20%, 0, 0);
    -ms-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; } }

.fadeInLeftLess {
  -webkit-animation-name: fadeInLeftLess;
  animation-name: fadeInLeftLess; }

@-webkit-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    -ms-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; } }

.fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig; }

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight; }

@-webkit-keyframes fadeInRightLess {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(50%, 0, 0);
    transform: translate3d(50%, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInRightLess {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(50%, 0, 0);
    -ms-transform: translate3d(50%, 0, 0);
    transform: translate3d(50%, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; } }

.fadeInRightLess {
  -webkit-animation-name: fadeInRightLess;
  animation-name: fadeInRightLess; }

@-webkit-keyframes fadeInRightMin {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(10%, 0, 0);
    transform: translate3d(50%, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInRightMin {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(10%, 0, 0);
    -ms-transform: translate3d(10%, 0, 0);
    transform: translate3d(10%, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; } }

.fadeInRightMin {
  -webkit-animation-name: fadeInRightMin;
  animation-name: fadeInRightMin; }

@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    -ms-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; } }

.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig; }

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    -ms-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

@-webkit-keyframes fadeInUpLess {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 40%, 0);
    transform: translate3d(0, 40%, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInUpLess {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 40%, 0);
    transform: translate3d(0, 40%, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInUpCustom {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100px, 0);
    transform: translate3d(0, -100px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@-webkit-keyframes fadeInUpCustom {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100px, 0);
    transform: translate3d(0, -100px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInUpLess {
  -webkit-animation-name: fadeInUpLess;
  animation-name: fadeInUpLess; }

@-webkit-keyframes fadeInUpMin {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 5%, 0);
    transform: translate3d(0, 5%, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInUpMin {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 5%, 0);
    transform: translate3d(0, 5%, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInUpMin {
  -webkit-animation-name: fadeInUpMin;
  animation-name: fadeInUpMin; }

@-webkit-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    -ms-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; } }

.fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig; }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    -ms-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown; }

@-webkit-keyframes fadeOutDownBig {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

@keyframes fadeOutDownBig {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    -ms-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

.fadeOutDownBig {
  -webkit-animation-name: fadeOutDownBig;
  animation-name: fadeOutDownBig; }

@-webkit-keyframes fadeOutLeft {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes fadeOutLeft {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    -ms-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft; }

@-webkit-keyframes fadeOutLeftBig {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); } }

@keyframes fadeOutLeftBig {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    -ms-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); } }

.fadeOutLeftBig {
  -webkit-animation-name: fadeOutLeftBig;
  animation-name: fadeOutLeftBig; }

@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

@keyframes fadeOutRight {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight; }

@-webkit-keyframes fadeOutRightBig {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); } }

@keyframes fadeOutRightBig {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    -ms-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); } }

.fadeOutRightBig {
  -webkit-animation-name: fadeOutRightBig;
  animation-name: fadeOutRightBig; }

@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    -ms-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp; }

@-webkit-keyframes fadeOutUpBig {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); } }

@keyframes fadeOutUpBig {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    -ms-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); } }

.fadeOutUpBig {
  -webkit-animation-name: fadeOutUpBig;
  animation-name: fadeOutUpBig; }

@-webkit-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; } }

@keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -ms-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -ms-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -ms-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -ms-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  100% {
    -webkit-transform: perspective(400px);
    -ms-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; } }

.animated.flip {
  -webkit-backface-visibility: visible;
  -ms-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: flip;
  animation-name: flip; }

@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

@keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -ms-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -ms-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    -ms-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    -ms-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  100% {
    -webkit-transform: perspective(400px);
    -ms-transform: perspective(400px);
    transform: perspective(400px); } }

.flipInX {
  -webkit-backface-visibility: visible !important;
  -ms-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  animation-name: flipInX; }

@-webkit-keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg); }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

@keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -ms-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -ms-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    -ms-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    -ms-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg); }
  100% {
    -webkit-transform: perspective(400px);
    -ms-transform: perspective(400px);
    transform: perspective(400px); } }

.flipInY {
  -webkit-backface-visibility: visible !important;
  -ms-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  animation-name: flipInY; }

@-webkit-keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  100% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

@keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px);
    -ms-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -ms-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  100% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -ms-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

.flipOutX {
  -webkit-animation-name: flipOutX;
  animation-name: flipOutX;
  -webkit-animation-duration: .75s;
  animation-duration: .75s;
  -webkit-backface-visibility: visible !important;
  -ms-backface-visibility: visible !important;
  backface-visibility: visible !important; }

@-webkit-keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1; }
  100% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0; } }

@keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px);
    -ms-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    -ms-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1; }
  100% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -ms-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0; } }

.flipOutY {
  -webkit-backface-visibility: visible !important;
  -ms-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipOutY;
  animation-name: flipOutY;
  -webkit-animation-duration: .75s;
  animation-duration: .75s; }

@-webkit-keyframes lightSpeedIn {
  0% {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0; }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1; }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1; }
  100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

@keyframes lightSpeedIn {
  0% {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    -ms-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0; }
  60% {
    -webkit-transform: skewX(20deg);
    -ms-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1; }
  80% {
    -webkit-transform: skewX(-5deg);
    -ms-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1; }
  100% {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    opacity: 1; } }

.lightSpeedIn {
  -webkit-animation-name: lightSpeedIn;
  animation-name: lightSpeedIn;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out; }

@-webkit-keyframes lightSpeedOut {
  0% {
    opacity: 1; }
  100% {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0; } }

@keyframes lightSpeedOut {
  0% {
    opacity: 1; }
  100% {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    -ms-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0; } }

.lightSpeedOut {
  -webkit-animation-name: lightSpeedOut;
  animation-name: lightSpeedOut;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in; }

@-webkit-keyframes rotateIn {
  0% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

@keyframes rotateIn {
  0% {
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    -ms-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    opacity: 1; } }

.rotateIn {
  -webkit-animation-name: rotateIn;
  animation-name: rotateIn; }

@-webkit-keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

@keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    -ms-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    opacity: 1; } }

.rotateInDownLeft {
  -webkit-animation-name: rotateInDownLeft;
  animation-name: rotateInDownLeft; }

@-webkit-keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

@keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    -ms-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    opacity: 1; } }

.rotateInDownRight {
  -webkit-animation-name: rotateInDownRight;
  animation-name: rotateInDownRight; }

@-webkit-keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

@keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    -ms-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    opacity: 1; } }

.rotateInUpLeft {
  -webkit-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft; }

@-webkit-keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

@keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    -ms-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    opacity: 1; } }

.rotateInUpRight {
  -webkit-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight; }

@-webkit-keyframes rotateOut {
  0% {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1; }
  100% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0; } }

@keyframes rotateOut {
  0% {
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    opacity: 1; }
  100% {
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    -ms-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0; } }

.rotateOut {
  -webkit-animation-name: rotateOut;
  animation-name: rotateOut; }

@-webkit-keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1; }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(0, 0, 1, 45deg);
    transform: rotate(0, 0, 1, 45deg);
    opacity: 0; } }

@keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1; }
  100% {
    -webkit-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(0, 0, 1, 45deg);
    -ms-transform: rotate(0, 0, 1, 45deg);
    transform: rotate(0, 0, 1, 45deg);
    opacity: 0; } }

.rotateOutDownLeft {
  -webkit-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft; }

@-webkit-keyframes rotateOutDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1; }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

@keyframes rotateOutDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1; }
  100% {
    -webkit-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    -ms-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

.rotateOutDownRight {
  -webkit-animation-name: rotateOutDownRight;
  animation-name: rotateOutDownRight; }

@-webkit-keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1; }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

@keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1; }
  100% {
    -webkit-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    -ms-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

.rotateOutUpLeft {
  -webkit-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft; }

@-webkit-keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1; }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0; } }

@keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1; }
  100% {
    -webkit-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    -ms-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0; } }

.rotateOutUpRight {
  -webkit-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight; }

@-webkit-keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  20%, 60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  40%, 80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1; }
  100% {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0; } }

@keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  20%, 60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    -ms-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  40%, 80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    -ms-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1; }
  100% {
    -webkit-transform: translate3d(0, 700px, 0);
    -ms-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0; } }

.hinge {
  -webkit-animation-name: hinge;
  animation-name: hinge; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    -ms-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; } }

.rollIn {
  -webkit-animation-name: rollIn;
  animation-name: rollIn; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes rollOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg); } }

@keyframes rollOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    -ms-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg); } }

.rollOut {
  -webkit-animation-name: rollOut;
  animation-name: rollOut; }

@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    -ms-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn; }

@-webkit-keyframes zoomInDown {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInDown {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInDown {
  -webkit-animation-name: zoomInDown;
  animation-name: zoomInDown; }

@-webkit-keyframes zoomInLeft {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInLeft {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInLeft {
  -webkit-animation-name: zoomInLeft;
  animation-name: zoomInLeft; }

@-webkit-keyframes zoomInRight {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInRight {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInRight {
  -webkit-animation-name: zoomInRight;
  animation-name: zoomInRight; }

@-webkit-keyframes zoomInUp {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInUp {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInUp {
  -webkit-animation-name: zoomInUp;
  animation-name: zoomInUp; }

@-webkit-keyframes zoomOut {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  100% {
    opacity: 0; } }

@keyframes zoomOut {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    -ms-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  100% {
    opacity: 0; } }

.zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut; }

@-webkit-keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomOutDown {
  -webkit-animation-name: zoomOutDown;
  animation-name: zoomOutDown; }

@-webkit-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center; } }

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    -ms-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    -ms-transform-origin: left center;
    transform-origin: left center; } }

.zoomOutLeft {
  -webkit-animation-name: zoomOutLeft;
  animation-name: zoomOutLeft; }

@-webkit-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center; } }

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    -ms-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    -ms-transform-origin: right center;
    transform-origin: right center; } }

.zoomOutRight {
  -webkit-animation-name: zoomOutRight;
  animation-name: zoomOutRight; }

@-webkit-keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomOutUp {
  -webkit-animation-name: zoomOutUp;
  animation-name: zoomOutUp; }

@-webkit-keyframes navZoom {
  33% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
    -webkit-transform-origin: left center;
    transform-origin: left center; }
  66% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
    -webkit-transform-origin: left center;
    transform-origin: left center; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: left center;
    transform-origin: left center; } }

@keyframes navZoom {
  33% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
    -webkit-transform-origin: left center;
    transform-origin: left center; }
  66% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
    -webkit-transform-origin: left center;
    transform-origin: left center; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: left center;
    transform-origin: left center; } }

@-webkit-keyframes navZoomLarge {
  33% {
    -webkit-transform: scale3d(1.15, 1.15, 1.15);
    transform: scale3d(1.15, 1.15, 1.15);
    -webkit-transform-origin: center center;
    transform-origin: center center; }
  66% {
    -webkit-transform: scale3d(1.15, 1.15, 1.15);
    transform: scale3d(1.15, 1.15, 1.15);
    -webkit-transform-origin: center center;
    transform-origin: center center; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center; } }

@keyframes navZoomLarge {
  33% {
    -webkit-transform: scale3d(1.15, 1.15, 1.15);
    transform: scale3d(1.15, 1.15, 1.15);
    -webkit-transform-origin: center center;
    transform-origin: center center; }
  66% {
    -webkit-transform: scale3d(1.15, 1.15, 1.15);
    transform: scale3d(1.15, 1.15, 1.15);
    -webkit-transform-origin: center center;
    transform-origin: center center; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center; } }

/*!
 * animsition v4.0.2
 * A simple and easy jQuery plugin for CSS animated page transitions.
 * http://blivesta.github.io/animsition
 * License : MIT
 * Author : blivesta (http://blivesta.com/)
 */
.animsition, .animsition-overlay {
  position: relative;
  opacity: 0;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.animsition-overlay-slide {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #ddd; }

.animsition-loading, .animsition-loading:after {
  width: 32px;
  height: 32px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -16px;
  margin-left: -16px;
  border-radius: 50%;
  z-index: 2; }

.animsition-loading {
  background-color: transparent;
  border-top: 5px solid rgba(0, 0, 0, 0.2);
  border-right: 5px solid rgba(0, 0, 0, 0.2);
  border-bottom: 5px solid rgba(0, 0, 0, 0.2);
  border-left: 5px solid #eee;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-duration: .8s;
  animation-duration: .8s;
  -webkit-animation-name: animsition-loading;
  animation-name: animsition-loading; }

@-webkit-keyframes animsition-loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn); } }

@keyframes animsition-loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn); } }

@-webkit-keyframes fade-in {
  0% {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  to {
    opacity: 1; } }

.fade-in {
  -webkit-animation-name: fade-in;
  animation-name: fade-in; }

@-webkit-keyframes fade-out {
  0% {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fade-out {
  0% {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fade-out;
  animation-name: fade-out; }

@-webkit-keyframes fade-in-up {
  0% {
    -webkit-transform: translateY(500px);
    transform: translateY(500px);
    opacity: 0; }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@keyframes fade-in-up {
  0% {
    -webkit-transform: translateY(500px);
    transform: translateY(500px);
    opacity: 0; }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

.fade-in-up {
  -webkit-animation-name: fade-in-up;
  animation-name: fade-in-up; }

@-webkit-keyframes fade-out-up {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; }
  to {
    -webkit-transform: translateY(-500px);
    transform: translateY(-500px);
    opacity: 0; } }

@keyframes fade-out-up {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; }
  to {
    -webkit-transform: translateY(-500px);
    transform: translateY(-500px);
    opacity: 0; } }

.fade-out-up {
  -webkit-animation-name: fade-out-up;
  animation-name: fade-out-up; }

@-webkit-keyframes fade-in-up-sm {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    opacity: 0; }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@keyframes fade-in-up-sm {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    opacity: 0; }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

.fade-in-up-sm {
  -webkit-animation-name: fade-in-up-sm;
  animation-name: fade-in-up-sm; }

@-webkit-keyframes fade-out-up-sm {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; }
  to {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0; } }

@keyframes fade-out-up-sm {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; }
  to {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0; } }

.fade-out-up-sm {
  -webkit-animation-name: fade-out-up-sm;
  animation-name: fade-out-up-sm; }

@-webkit-keyframes fade-in-up-lg {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0; }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@keyframes fade-in-up-lg {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0; }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

.fade-in-up-lg {
  -webkit-animation-name: fade-in-up-lg;
  animation-name: fade-in-up-lg; }

@-webkit-keyframes fade-out-up-lg {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; }
  to {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0; } }

@keyframes fade-out-up-lg {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; }
  to {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0; } }

.fade-out-up-lg {
  -webkit-animation-name: fade-out-up-lg;
  animation-name: fade-out-up-lg; }

@-webkit-keyframes fade-in-down {
  0% {
    -webkit-transform: translateY(-500px);
    transform: translateY(-500px);
    opacity: 0; }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@keyframes fade-in-down {
  0% {
    -webkit-transform: translateY(-500px);
    transform: translateY(-500px);
    opacity: 0; }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

.fade-in-down {
  -webkit-animation-name: fade-in-down;
  animation-name: fade-in-down; }

@-webkit-keyframes fade-out-down {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; }
  to {
    -webkit-transform: translateY(500px);
    transform: translateY(500px);
    opacity: 0; } }

@keyframes fade-out-down {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; }
  to {
    -webkit-transform: translateY(500px);
    transform: translateY(500px);
    opacity: 0; } }

.fade-out-down {
  -webkit-animation-name: fade-out-down;
  animation-name: fade-out-down; }

@-webkit-keyframes fade-in-down-sm {
  0% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0; }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@keyframes fade-in-down-sm {
  0% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0; }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

.fade-in-down-sm {
  -webkit-animation-name: fade-in-down-sm;
  animation-name: fade-in-down-sm; }

@-webkit-keyframes fade-out-down-sm {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; }
  to {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    opacity: 0; } }

@keyframes fade-out-down-sm {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; }
  to {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    opacity: 0; } }

.fade-out-down-sm {
  -webkit-animation-name: fade-out-down-sm;
  animation-name: fade-out-down-sm; }

.fade-in-down-lg {
  -webkit-animation-name: fade-in-down;
  animation-name: fade-in-down; }

@-webkit-keyframes fade-out-down-lg {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; }
  to {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0; } }

@keyframes fade-out-down-lg {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; }
  to {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0; } }

.fade-out-down-lg {
  -webkit-animation-name: fade-out-down-lg;
  animation-name: fade-out-down-lg; }

@-webkit-keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-500px);
    transform: translateX(-500px);
    opacity: 0; }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-500px);
    transform: translateX(-500px);
    opacity: 0; }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

.fade-in-left {
  -webkit-animation-name: fade-in-left;
  animation-name: fade-in-left; }

@-webkit-keyframes fade-out-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; }
  to {
    -webkit-transform: translateX(-500px);
    transform: translateX(-500px);
    opacity: 0; } }

@keyframes fade-out-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; }
  to {
    -webkit-transform: translateX(-500px);
    transform: translateX(-500px);
    opacity: 0; } }

.fade-out-left {
  -webkit-animation-name: fade-out-left;
  animation-name: fade-out-left; }

@-webkit-keyframes fade-in-left-sm {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0; }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

@keyframes fade-in-left-sm {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0; }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

.fade-in-left-sm {
  -webkit-animation-name: fade-in-left-sm;
  animation-name: fade-in-left-sm; }

@-webkit-keyframes fade-out-left-sm {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; }
  to {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0; } }

@keyframes fade-out-left-sm {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; }
  to {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0; } }

.fade-out-left-sm {
  -webkit-animation-name: fade-out-left-sm;
  animation-name: fade-out-left-sm; }

@-webkit-keyframes fade-in-left-lg {
  0% {
    -webkit-transform: translateX(-1500px);
    transform: translateX(-1500px);
    opacity: 0; }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

@keyframes fade-in-left-lg {
  0% {
    -webkit-transform: translateX(-1500px);
    transform: translateX(-1500px);
    opacity: 0; }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

.fade-in-left-lg {
  -webkit-animation-name: fade-in-left-lg;
  animation-name: fade-in-left-lg; }

@-webkit-keyframes fade-out-left-lg {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; }
  to {
    -webkit-transform: translateX(-1500px);
    transform: translateX(-1500px);
    opacity: 0; } }

@keyframes fade-out-left-lg {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; }
  to {
    -webkit-transform: translateX(-1500px);
    transform: translateX(-1500px);
    opacity: 0; } }

.fade-out-left-lg {
  -webkit-animation-name: fade-out-left-lg;
  animation-name: fade-out-left-lg; }

@-webkit-keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(500px);
    transform: translateX(500px);
    opacity: 0; }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

@keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(500px);
    transform: translateX(500px);
    opacity: 0; }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

.fade-in-right {
  -webkit-animation-name: fade-in-right;
  animation-name: fade-in-right; }

@-webkit-keyframes fade-out-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; }
  to {
    -webkit-transform: translateX(500px);
    transform: translateX(500px);
    opacity: 0; } }

@keyframes fade-out-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; }
  to {
    -webkit-transform: translateX(500px);
    transform: translateX(500px);
    opacity: 0; } }

.fade-out-right {
  -webkit-animation-name: fade-out-right;
  animation-name: fade-out-right; }

@-webkit-keyframes fade-in-right-sm {
  0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0; }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

@keyframes fade-in-right-sm {
  0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0; }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

.fade-in-right-sm {
  -webkit-animation-name: fade-in-right-sm;
  animation-name: fade-in-right-sm; }

@-webkit-keyframes fade-out-right-sm {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; }
  to {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0; } }

@keyframes fade-out-right-sm {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; }
  to {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0; } }

.fade-out-right-sm {
  -webkit-animation-name: fade-out-right-sm;
  animation-name: fade-out-right-sm; }

@-webkit-keyframes fade-in-right-lg {
  0% {
    -webkit-transform: translateX(1500px);
    transform: translateX(1500px);
    opacity: 0; }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

@keyframes fade-in-right-lg {
  0% {
    -webkit-transform: translateX(1500px);
    transform: translateX(1500px);
    opacity: 0; }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

.fade-in-right-lg {
  -webkit-animation-name: fade-in-right-lg;
  animation-name: fade-in-right-lg; }

@-webkit-keyframes fade-out-right-lg {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; }
  to {
    -webkit-transform: translateX(1500px);
    transform: translateX(1500px);
    opacity: 0; } }

@keyframes fade-out-right-lg {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; }
  to {
    -webkit-transform: translateX(1500px);
    transform: translateX(1500px);
    opacity: 0; } }

.fade-out-right-lg {
  -webkit-animation-name: fade-out-right-lg;
  animation-name: fade-out-right-lg; }

@-webkit-keyframes rotate-in {
  0% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transform-origin: center center;
    opacity: 0; }
  0%, to {
    -webkit-transform-origin: center center; }
  to {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    transform-origin: center center;
    opacity: 1; } }

@keyframes rotate-in {
  0% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transform-origin: center center;
    opacity: 0; }
  0%, to {
    -webkit-transform-origin: center center; }
  to {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    transform-origin: center center;
    opacity: 1; } }

.rotate-in {
  -webkit-animation-name: rotate-in;
  animation-name: rotate-in; }

@-webkit-keyframes rotate-out {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    transform-origin: center center;
    opacity: 1; }
  0%, to {
    -webkit-transform-origin: center center; }
  to {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    transform-origin: center center;
    opacity: 0; } }

@keyframes rotate-out {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    transform-origin: center center;
    opacity: 1; }
  0%, to {
    -webkit-transform-origin: center center; }
  to {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    transform-origin: center center;
    opacity: 0; } }

.rotate-out {
  -webkit-animation-name: rotate-out;
  animation-name: rotate-out; }

@-webkit-keyframes rotate-in-sm {
  0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    transform-origin: center center;
    opacity: 0; }
  0%, to {
    -webkit-transform-origin: center center; }
  to {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    transform-origin: center center;
    opacity: 1; } }

@keyframes rotate-in-sm {
  0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    transform-origin: center center;
    opacity: 0; }
  0%, to {
    -webkit-transform-origin: center center; }
  to {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    transform-origin: center center;
    opacity: 1; } }

.rotate-in-sm {
  -webkit-animation-name: rotate-in-sm;
  animation-name: rotate-in-sm; }

@-webkit-keyframes rotate-out-sm {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    transform-origin: center center;
    opacity: 1; }
  0%, to {
    -webkit-transform-origin: center center; }
  to {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    transform-origin: center center;
    opacity: 0; } }

@keyframes rotate-out-sm {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    transform-origin: center center;
    opacity: 1; }
  0%, to {
    -webkit-transform-origin: center center; }
  to {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    transform-origin: center center;
    opacity: 0; } }

.rotate-out-sm {
  -webkit-animation-name: rotate-out-sm;
  animation-name: rotate-out-sm; }

@-webkit-keyframes rotate-in-lg {
  0% {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
    transform-origin: center center;
    opacity: 0; }
  0%, to {
    -webkit-transform-origin: center center; }
  to {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    transform-origin: center center;
    opacity: 1; } }

@keyframes rotate-in-lg {
  0% {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
    transform-origin: center center;
    opacity: 0; }
  0%, to {
    -webkit-transform-origin: center center; }
  to {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    transform-origin: center center;
    opacity: 1; } }

.rotate-in-lg {
  -webkit-animation-name: rotate-in-lg;
  animation-name: rotate-in-lg; }

@-webkit-keyframes rotate-out-lg {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    transform-origin: center center;
    opacity: 1; }
  0%, to {
    -webkit-transform-origin: center center; }
  to {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    transform-origin: center center;
    opacity: 0; } }

@keyframes rotate-out-lg {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    transform-origin: center center;
    opacity: 1; }
  0%, to {
    -webkit-transform-origin: center center; }
  to {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    transform-origin: center center;
    opacity: 0; } }

.rotate-out-lg {
  -webkit-animation-name: rotate-out-lg;
  animation-name: rotate-out-lg; }

@-webkit-keyframes flip-in-x {
  0% {
    -webkit-transform: perspective(550px) rotateX(90deg);
    transform: perspective(550px) rotateX(90deg);
    opacity: 0; }
  to {
    -webkit-transform: perspective(550px) rotateX(0deg);
    transform: perspective(550px) rotateX(0deg);
    opacity: 1; } }

@keyframes flip-in-x {
  0% {
    -webkit-transform: perspective(550px) rotateX(90deg);
    transform: perspective(550px) rotateX(90deg);
    opacity: 0; }
  to {
    -webkit-transform: perspective(550px) rotateX(0deg);
    transform: perspective(550px) rotateX(0deg);
    opacity: 1; } }

.flip-in-x {
  -webkit-animation-name: flip-in-x;
  animation-name: flip-in-x;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important; }

@-webkit-keyframes flip-out-x {
  0% {
    -webkit-transform: perspective(550px) rotateX(0deg);
    transform: perspective(550px) rotateX(0deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(550px) rotateX(90deg);
    transform: perspective(550px) rotateX(90deg);
    opacity: 0; } }

@keyframes flip-out-x {
  0% {
    -webkit-transform: perspective(550px) rotateX(0deg);
    transform: perspective(550px) rotateX(0deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(550px) rotateX(90deg);
    transform: perspective(550px) rotateX(90deg);
    opacity: 0; } }

.flip-out-x {
  -webkit-animation-name: flip-out-x;
  animation-name: flip-out-x;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important; }

@-webkit-keyframes flip-in-x-nr {
  0% {
    -webkit-transform: perspective(100px) rotateX(90deg);
    transform: perspective(100px) rotateX(90deg);
    opacity: 0; }
  to {
    -webkit-transform: perspective(100px) rotateX(0deg);
    transform: perspective(100px) rotateX(0deg);
    opacity: 1; } }

@keyframes flip-in-x-nr {
  0% {
    -webkit-transform: perspective(100px) rotateX(90deg);
    transform: perspective(100px) rotateX(90deg);
    opacity: 0; }
  to {
    -webkit-transform: perspective(100px) rotateX(0deg);
    transform: perspective(100px) rotateX(0deg);
    opacity: 1; } }

.flip-in-x-nr {
  -webkit-animation-name: flip-in-x-nr;
  animation-name: flip-in-x-nr;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important; }

@-webkit-keyframes flip-out-x-nr {
  0% {
    -webkit-transform: perspective(100px) rotateX(0deg);
    transform: perspective(100px) rotateX(0deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(100px) rotateX(90deg);
    transform: perspective(100px) rotateX(90deg);
    opacity: 0; } }

@keyframes flip-out-x-nr {
  0% {
    -webkit-transform: perspective(100px) rotateX(0deg);
    transform: perspective(100px) rotateX(0deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(100px) rotateX(90deg);
    transform: perspective(100px) rotateX(90deg);
    opacity: 0; } }

.flip-out-x-nr {
  -webkit-animation-name: flip-out-x-nr;
  animation-name: flip-out-x-nr;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important; }

@-webkit-keyframes flip-in-x-fr {
  0% {
    -webkit-transform: perspective(1000px) rotateX(90deg);
    transform: perspective(1000px) rotateX(90deg);
    opacity: 0; }
  to {
    -webkit-transform: perspective(1000px) rotateX(0deg);
    transform: perspective(1000px) rotateX(0deg);
    opacity: 1; } }

@keyframes flip-in-x-fr {
  0% {
    -webkit-transform: perspective(1000px) rotateX(90deg);
    transform: perspective(1000px) rotateX(90deg);
    opacity: 0; }
  to {
    -webkit-transform: perspective(1000px) rotateX(0deg);
    transform: perspective(1000px) rotateX(0deg);
    opacity: 1; } }

.flip-in-x-fr {
  -webkit-animation-name: flip-in-x-fr;
  animation-name: flip-in-x-fr;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important; }

@-webkit-keyframes flip-out-x-fr {
  0% {
    -webkit-transform: perspective(1000px) rotateX(0deg);
    transform: perspective(1000px) rotateX(0deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(1000px) rotateX(90deg);
    transform: perspective(1000px) rotateX(90deg);
    opacity: 0; } }

@keyframes flip-out-x-fr {
  0% {
    -webkit-transform: perspective(1000px) rotateX(0deg);
    transform: perspective(1000px) rotateX(0deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(1000px) rotateX(90deg);
    transform: perspective(1000px) rotateX(90deg);
    opacity: 0; } }

.flip-out-x-fr {
  -webkit-animation-name: flip-out-x-fr;
  animation-name: flip-out-x-fr;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important; }

@-webkit-keyframes flip-in-y {
  0% {
    -webkit-transform: perspective(550px) rotateY(90deg);
    transform: perspective(550px) rotateY(90deg);
    opacity: 0; }
  to {
    -webkit-transform: perspective(550px) rotateY(0deg);
    transform: perspective(550px) rotateY(0deg);
    opacity: 1; } }

@keyframes flip-in-y {
  0% {
    -webkit-transform: perspective(550px) rotateY(90deg);
    transform: perspective(550px) rotateY(90deg);
    opacity: 0; }
  to {
    -webkit-transform: perspective(550px) rotateY(0deg);
    transform: perspective(550px) rotateY(0deg);
    opacity: 1; } }

.flip-in-y {
  -webkit-animation-name: flip-in-y;
  animation-name: flip-in-y;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important; }

@-webkit-keyframes flip-out-y {
  0% {
    -webkit-transform: perspective(550px) rotateY(0deg);
    transform: perspective(550px) rotateY(0deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(550px) rotateY(90deg);
    transform: perspective(550px) rotateY(90deg);
    opacity: 0; } }

@keyframes flip-out-y {
  0% {
    -webkit-transform: perspective(550px) rotateY(0deg);
    transform: perspective(550px) rotateY(0deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(550px) rotateY(90deg);
    transform: perspective(550px) rotateY(90deg);
    opacity: 0; } }

.flip-out-y {
  -webkit-animation-name: flip-out-y;
  animation-name: flip-out-y;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important; }

@-webkit-keyframes flip-in-y-nr {
  0% {
    -webkit-transform: perspective(100px) rotateY(90deg);
    transform: perspective(100px) rotateY(90deg);
    opacity: 0; }
  to {
    -webkit-transform: perspective(100px) rotateY(0deg);
    transform: perspective(100px) rotateY(0deg);
    opacity: 1; } }

@keyframes flip-in-y-nr {
  0% {
    -webkit-transform: perspective(100px) rotateY(90deg);
    transform: perspective(100px) rotateY(90deg);
    opacity: 0; }
  to {
    -webkit-transform: perspective(100px) rotateY(0deg);
    transform: perspective(100px) rotateY(0deg);
    opacity: 1; } }

.flip-in-y-nr {
  -webkit-animation-name: flip-in-y-nr;
  animation-name: flip-in-y-nr;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important; }

@-webkit-keyframes flip-out-y-nr {
  0% {
    -webkit-transform: perspective(100px) rotateY(0deg);
    transform: perspective(100px) rotateY(0deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(100px) rotateY(90deg);
    transform: perspective(100px) rotateY(90deg);
    opacity: 0; } }

@keyframes flip-out-y-nr {
  0% {
    -webkit-transform: perspective(100px) rotateY(0deg);
    transform: perspective(100px) rotateY(0deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(100px) rotateY(90deg);
    transform: perspective(100px) rotateY(90deg);
    opacity: 0; } }

.flip-out-y-nr {
  -webkit-animation-name: flip-out-y-nr;
  animation-name: flip-out-y-nr;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important; }

@-webkit-keyframes flip-in-y-fr {
  0% {
    -webkit-transform: perspective(1000px) rotateY(90deg);
    transform: perspective(1000px) rotateY(90deg);
    opacity: 0; }
  to {
    -webkit-transform: perspective(1000px) rotateY(0deg);
    transform: perspective(1000px) rotateY(0deg);
    opacity: 1; } }

@keyframes flip-in-y-fr {
  0% {
    -webkit-transform: perspective(1000px) rotateY(90deg);
    transform: perspective(1000px) rotateY(90deg);
    opacity: 0; }
  to {
    -webkit-transform: perspective(1000px) rotateY(0deg);
    transform: perspective(1000px) rotateY(0deg);
    opacity: 1; } }

.flip-in-y-fr {
  -webkit-animation-name: flip-in-y-fr;
  animation-name: flip-in-y-fr;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important; }

@-webkit-keyframes flip-out-y-fr {
  0% {
    -webkit-transform: perspective(1000px) rotateY(0deg);
    transform: perspective(1000px) rotateY(0deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(1000px) rotateY(90deg);
    transform: perspective(1000px) rotateY(90deg);
    opacity: 0; } }

@keyframes flip-out-y-fr {
  0% {
    -webkit-transform: perspective(1000px) rotateY(0deg);
    transform: perspective(1000px) rotateY(0deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(1000px) rotateY(90deg);
    transform: perspective(1000px) rotateY(90deg);
    opacity: 0; } }

.flip-out-y-fr {
  -webkit-animation-name: flip-out-y-fr;
  animation-name: flip-out-y-fr;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important; }

@-webkit-keyframes zoom-in {
  0% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes zoom-in {
  0% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
    opacity: 0; }
  to {
    opacity: 1; } }

.zoom-in {
  -webkit-animation-name: zoom-in;
  animation-name: zoom-in; }

@-webkit-keyframes zoom-out {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; }
  50% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7); }
  50%, to {
    opacity: 0; } }

@keyframes zoom-out {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; }
  50% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7); }
  50%, to {
    opacity: 0; } }

.zoom-out {
  -webkit-animation-name: zoom-out;
  animation-name: zoom-out; }

@-webkit-keyframes zoom-in-sm {
  0% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes zoom-in-sm {
  0% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    opacity: 0; }
  to {
    opacity: 1; } }

.zoom-in-sm {
  -webkit-animation-name: zoom-in-sm;
  animation-name: zoom-in-sm; }

@-webkit-keyframes zoom-out-sm {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; }
  50% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95); }
  50%, to {
    opacity: 0; } }

@keyframes zoom-out-sm {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; }
  50% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95); }
  50%, to {
    opacity: 0; } }

.zoom-out-sm {
  -webkit-animation-name: zoom-out-sm;
  animation-name: zoom-out-sm; }

@-webkit-keyframes zoom-in-lg {
  0% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes zoom-in-lg {
  0% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
    opacity: 0; }
  to {
    opacity: 1; } }

.zoom-in-lg {
  -webkit-animation-name: zoom-in-lg;
  animation-name: zoom-in-lg; }

@-webkit-keyframes zoom-out-lg {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; }
  50% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4); }
  50%, to {
    opacity: 0; } }

@keyframes zoom-out-lg {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; }
  50% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4); }
  50%, to {
    opacity: 0; } }

.zoom-out-lg {
  -webkit-animation-name: zoom-out-lg;
  animation-name: zoom-out-lg; }

@-webkit-keyframes overlay-slide-in-top {
  0% {
    height: 100%; }
  to {
    height: 0; } }

@keyframes overlay-slide-in-top {
  0% {
    height: 100%; }
  to {
    height: 0; } }

.overlay-slide-in-top {
  top: 0;
  height: 0;
  -webkit-animation-name: overlay-slide-in-top;
  animation-name: overlay-slide-in-top; }

@-webkit-keyframes overlay-slide-out-top {
  0% {
    height: 0; }
  to {
    height: 100%; } }

@keyframes overlay-slide-out-top {
  0% {
    height: 0; }
  to {
    height: 100%; } }

.overlay-slide-out-top {
  top: 0;
  height: 100%;
  -webkit-animation-name: overlay-slide-out-top;
  animation-name: overlay-slide-out-top; }

@-webkit-keyframes overlay-slide-in-bottom {
  0% {
    height: 100%; }
  to {
    height: 0; } }

@keyframes overlay-slide-in-bottom {
  0% {
    height: 100%; }
  to {
    height: 0; } }

.overlay-slide-in-bottom {
  bottom: 0;
  height: 0;
  -webkit-animation-name: overlay-slide-in-bottom;
  animation-name: overlay-slide-in-bottom; }

@-webkit-keyframes overlay-slide-out-bottom {
  0% {
    height: 0; }
  to {
    height: 100%; } }

@keyframes overlay-slide-out-bottom {
  0% {
    height: 0; }
  to {
    height: 100%; } }

.overlay-slide-out-bottom {
  bottom: 0;
  height: 100%;
  -webkit-animation-name: overlay-slide-out-bottom;
  animation-name: overlay-slide-out-bottom; }

@-webkit-keyframes overlay-slide-in-left {
  0% {
    width: 100%; }
  to {
    width: 0; } }

@keyframes overlay-slide-in-left {
  0% {
    width: 100%; }
  to {
    width: 0; } }

.overlay-slide-in-left {
  width: 0;
  -webkit-animation-name: overlay-slide-in-left;
  animation-name: overlay-slide-in-left; }

@-webkit-keyframes overlay-slide-out-left {
  0% {
    width: 0; }
  to {
    width: 100%; } }

@keyframes overlay-slide-out-left {
  0% {
    width: 0; }
  to {
    width: 100%; } }

.overlay-slide-out-left {
  left: 0;
  width: 100%;
  -webkit-animation-name: overlay-slide-out-left;
  animation-name: overlay-slide-out-left; }

@-webkit-keyframes overlay-slide-in-right {
  0% {
    width: 100%; }
  to {
    width: 0; } }

@keyframes overlay-slide-in-right {
  0% {
    width: 100%; }
  to {
    width: 0; } }

.overlay-slide-in-right {
  right: 0;
  width: 0;
  -webkit-animation-name: overlay-slide-in-right;
  animation-name: overlay-slide-in-right; }

@-webkit-keyframes overlay-slide-out-right {
  0% {
    width: 0; }
  to {
    width: 100%; } }

@keyframes overlay-slide-out-right {
  0% {
    width: 0; }
  to {
    width: 100%; } }

.overlay-slide-out-right {
  right: 0;
  width: 100%;
  -webkit-animation-name: overlay-slide-out-right;
  animation-name: overlay-slide-out-right; }

.pace {
  z-index: 99999; }

.pace.pace-inactive {
  opacity: 0; }

.hortonworks svg {
  width: 90%;
  margin: 0 5% 40px; }

.riverbed-devices img,
.acemetrix-devices img {
  margin: 0 auto;
  width: 100%;
  max-width: 840px; }

.riverbed-devices .txt-holder,
.acemetrix-devices .txt-holder {
  max-width: 80%;
  margin: 0 auto 10px; }

.animated-devices .devices-holder {
  position: relative; }
  .animated-devices .devices-holder img {
    width: 100%; }
  .animated-devices .devices-holder .phone {
    position: absolute;
    left: 6%;
    bottom: 4%;
    max-width: 14%;
    z-index: 3; }
  .animated-devices .devices-holder .tablet {
    position: absolute;
    left: 16%;
    bottom: 2%;
    max-width: 30%;
    z-index: 2; }
  .animated-devices .devices-holder .laptop {
    position: absolute;
    left: 22%;
    bottom: 6%;
    max-width: 72%;
    z-index: 1; }

.animated-devices .txt-holder {
  max-width: 80%;
  margin: 0 auto 10px; }

.riverbed-devices .devices-holder {
  position: relative;
  padding-top: 20px; }
  .riverbed-devices .devices-holder img {
    width: 100%;
    max-width: 100%; }
  .riverbed-devices .devices-holder .phone {
    position: absolute;
    left: 28%;
    bottom: 4%;
    max-width: 16%;
    z-index: 3; }
  .riverbed-devices .devices-holder .tablet {
    position: absolute;
    left: 10%;
    bottom: 2%;
    max-width: 30%;
    z-index: 2; }
  .riverbed-devices .devices-holder .desktop {
    position: absolute;
    left: 26%;
    bottom: 6%;
    max-width: 58%;
    z-index: 1; }
    .ua-mobile .riverbed-devices .devices-holder .desktop {
      display: none; }
    .riverbed-devices .devices-holder .desktop.only-mbl {
      display: none; }
      .ua-mobile .riverbed-devices .devices-holder .desktop.only-mbl {
        display: block; }
  .riverbed-devices .devices-holder .video-elm {
    position: absolute;
    left: 26.6%;
    bottom: 37%;
    max-width: 56%;
    z-index: 0; }

.acemetrix-devices img {
  max-width: 960px; }

.iphone-grid {
  padding: 34px 0 0; }
  .iphone-grid .col {
    width: 33.333%;
    float: left;
    padding: 0 3% 20px;
    box-sizing: border-box;
    position: relative; }
    .iphone-grid .col img {
      width: 100%; }
    .iphone-grid .col .gif-holder {
      position: absolute;
      top: 11%;
      left: 19.6%;
      width: 61%;
      opacity: 1; }
  .iphone-grid .txt-holder {
    max-width: 80%;
    margin: 50px auto 10px; }
  @media screen and (max-width: 520px) {
    .iphone-grid {
      padding: 14px 0 0; }
      .iphone-grid .txt-holder {
        margin: 0 auto 20px; } }

.case-twothrd {
  width: 70%;
  display: inline-block;
  vertical-align: top; }

/*
.case-twothrd img {
  width: 100%;
}
*/
/*
.case-twothrd.ng-mrg {
  margin-bottom: -80px;
}
*/
/*
.case-twothrd.ng-mrg img {
  max-width: 614px;
}
*/
.case-onethrd {
  width: 28%;
  display: inline-block;
  vertical-align: top; }
  .case-onethrd h4 {
    color: #707071;
    font-weight: 300;
    font-size: 26px;
    margin: 0 0 10px 0; }

.case-thrffts {
  width: 56%;
  display: inline-block;
  vertical-align: top;
  margin: 0 4% 0 0; }

/*
.case-thrffts img {
  width: 100%;
}
*/
.case-thrffts.icntrl {
  vertical-align: bottom; }

.case-thrffts.icntrl img {
  max-width: 560px;
  margin: 0 auto; }

.case-twffts {
  width: 39%;
  display: inline-block;
  vertical-align: bottom;
  margin: 0 0 -80px 0;
  text-align: left; }
  @media screen and (min-width: 981px) {
    .case-twffts .small-container {
      text-align: left; } }

.case-twffts img {
  width: 100%;
  max-width: 462px;
  margin: 0 auto; }

@media screen and (min-width: 781px) {
  img.hoover-site-graphic {
    margin: -80px auto 0 auto; } }

.full-width-technology {
  background: #d6dede;
  margin: 0; }
  .full-width-technology h2 {
    text-align: center; }
  .full-width-technology p {
    font-size: 16px;
    line-height: 20px; }

.technology-wrapper {
  text-align: center; }

.technology-box {
  width: 18%;
  display: inline-block;
  padding: 2%;
  text-align: center;
  vertical-align: top;
  line-height: 1.6; }

.technology-box img {
  width: 100%;
  margin: 0 auto 30px auto;
  max-width: 130px; }

.technology-box-five {
  width: 15%;
  display: inline-block;
  padding: 2%;
  text-align: center;
  vertical-align: top;
  line-height: 1.6; }

.technology-box-five img {
  width: 100%;
  margin: 0 auto 30px auto;
  max-width: 130px; }

.technology-box-six {
  width: 12%;
  display: inline-block;
  padding: 2%;
  text-align: center;
  vertical-align: top;
  line-height: 1.6; }

.technology-box-six img {
  width: 100%;
  margin: 0 auto 30px auto;
  max-width: 130px; }

.drupal-pstr-box {
  width: 18%;
  display: inline-block;
  padding: .6%;
  text-align: center; }

.drupal-pstr-box img {
  width: 100%;
  margin: 60px 0 20px 0;
  -webkit-transition: -webkit-transform all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.drupal-pstr-box img:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1); }

.vcra-dgtl-dspl-box {
  background: url("/img/case-studies/vocera/pattern.png?ver=2.5.5") no-repeat center bottom; }

.vcra-phn-box {
  width: 34%;
  display: inline-block;
  vertical-align: bottom;
  position: relative; }

.vcra-phn-box img {
  width: 100%; }

.vcra-dvcs {
  display: inline-block;
  vertical-align: top;
  width: 58%;
  text-align: right;
  margin-left: 6%; }

.vcra-dvcs img {
  width: 100%; }

.rae-dvces {
  float: right;
  width: 60%; }

.rae-dvces img {
  max-width: 745px;
  width: 100%; }

.case-rae-blprint {
  display: inline-block;
  width: 45%;
  vertical-align: bottom; }

.case-rae-blprint img {
  width: 100%; }

.rae-strategy-box {
  width: 48%;
  display: inline-block;
  vertical-align: top;
  margin: 0 5% 0 0; }

.rae-strategy-box img {
  width: 100%; }

.full-width-work h4 {
  color: #707071;
  font-weight: 300;
  font-size: 26px;
  margin: 87px 0 10px 0; }

.drpl-logo {
  width: 130px;
  margin: 0 0 0 -12px; }

.drpl-logo.mrg-spcl {
  margin: -30px 0 0 -6px; }

.drpl-logo img {
  width: 100%; }

.drplpster-hlf {
  width: 48%;
  display: inline-block;
  vertical-align: top; }

.pstr-txt-box {
  margin: 0 0 0 6%;
  max-width: 500px; }

img.pstr-monsters {
  margin: 0 0 0 -2%;
  width: 100%;
  max-width: 685px; }

.drplpster-hlf h4 {
  color: #707071;
  font-weight: 300;
  font-size: 26px;
  margin: 0 0 10px 0; }

.full-width-lght-gry-ea {
  background: #d6dede;
  padding: 90px 0 0 0; }

.ea-ticket-bg {
  background: url("/img/case-studies/ea/ticket.png?ver=2.5.5") center no-repeat #d6dede;
  background-size: 50%;
  position: initial !important; }

.ea-vrtcl-phn-box {
  width: 34%;
  float: left;
  vertical-align: bottom;
  z-index: 10; }

.ea-vrtcl-phn-box img {
  width: 100%; }

.ea-ticket-box {
  width: 40%;
  position: absolute;
  left: 50%;
  margin: 240px 0 0 -20%; }

.ea-ticket-box img {
  width: 100%; }

.ea-hrzntl-phn-box {
  display: inline-block;
  width: 44%;
  max-width: 690px;
  float: right;
  z-index: 10; }

.ea-hrzntl-phn-box img {
  width: 100%; }

.ea-mobil-txt-box {
  width: 52%;
  padding: 40px 0;
  display: inline-block;
  vertical-align: top;
  margin: 0 4% 0 0;
  float: left; }

.ea-mobil-txt-box h4 {
  color: #707071;
  font-weight: 300;
  font-size: 26px;
  margin: 0 0 10px 0; }

.instantly-banners-box .banners-wrapper {
  max-width: 1030px;
  margin: 40px auto 0 auto; }
  .instantly-banners-box .banners-wrapper .banner {
    width: 19%;
    display: inline-block;
    margin: 0 3.5%; }
    .instantly-banners-box .banners-wrapper .banner:first-child {
      margin-left: 0; }
    .instantly-banners-box .banners-wrapper .banner:last-child {
      margin-right: 0; }
    @media screen and (max-width: 520px) {
      .instantly-banners-box .banners-wrapper .banner {
        width: 30%;
        margin: 30px 3.5% 0 3.5%; } }

.mobil-top-paddng {
  display: none; }

.splitter-section {
  box-sizing: border-box; }
  .splitter-section .txt-holder {
    display: inline-block;
    vertical-align: middle;
    width: 32%; }
    @media screen and (max-width: 900px) {
      .splitter-section .txt-holder {
        width: 100%;
        display: block;
        text-align: center; } }
  .splitter-section img {
    display: inline-block;
    vertical-align: middle;
    max-width: 58%;
    margin-left: 8%; }
    @media screen and (max-width: 900px) {
      .splitter-section img {
        max-width: 100%;
        display: block;
        margin: 0 auto; } }

.intel-plans {
  text-align: center;
  padding-top: 20px; }
  .intel-plans img {
    width: 100%; }
  .intel-plans .col-left {
    display: inline-block;
    vertical-align: middle;
    width: 24%; }
  .intel-plans .col-right {
    display: inline-block;
    vertical-align: middle;
    width: 50%; }
  .intel-plans h2 {
    margin: 30px auto 0;
    font-weight: 100;
    font-size: 30px;
    line-height: 1.4;
    padding: 0 0 14px 0; }
    @media screen and (max-width: 1300px) {
      .intel-plans h2 {
        font-size: 26px; } }
    @media screen and (max-width: 900px) {
      .intel-plans h2 {
        font-size: 22px; } }

/*** solutions ***/
.multiDicplLeft,
.multiDicplRight {
  max-width: 20%;
  margin: 0;
  display: inline-block;
  vertical-align: top;
  text-align: right; }
  .multiDicplLeft.only-mobile,
  .multiDicplRight.only-mobile {
    display: none; }
  .multiDicplLeft div,
  .multiDicplRight div {
    margin: 70px 0; }
  .multiDicplLeft h3,
  .multiDicplRight h3 {
    font-size: 16px;
    text-transform: uppercase;
    font-family: "proxima-nova";
    color: #707071;
    font-weight: 400;
    margin: 0 0 6px; }
  .multiDicplLeft p,
  .multiDicplRight p {
    font-size: 16px; }

.multiDicplLeft {
  margin-right: 8%; }

.multiDicplRight {
  margin-left: 8%; }

.green-bubbles-wrapper {
  max-width: 40%;
  display: inline-block; }

.green-rounded {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  max-height: 38%;
  max-width: 38%;
  text-align: middle;
  display: inline-block;
  padding: 10%; }
  .green-rounded img {
    width: 100%;
    margin: 0 auto; }

.strategy-rounded {
  background: rgba(127, 199, 66, 0.8); }
  .strategy-rounded:hover {
    background: rgba(107, 168, 55, 0.8); }

.creative-rounded {
  margin: 0 0 0 -18%;
  background: rgba(74, 163, 14, 0.7); }
  .creative-rounded:hover {
    background: rgba(61, 136, 11, 0.7); }

.development-rounded {
  margin: -18% 0 0 0;
  background: rgba(13, 60, 1, 0.8);
  z-index: 1; }
  .development-rounded:hover {
    background: rgba(10, 46, 0, 0.8); }

.client-rounded {
  margin: -18% 0 0 -18%;
  background: rgba(68, 121, 0, 0.8);
  z-index: 10; }
  .client-rounded:hover {
    background: rgba(58, 102, 1, 0.8); }

.process-tabs-wrapper {
  margin: 40px 0; }

.tabs nav {
  max-width: 1100px;
  margin: 0 auto; }

.tabs *,
.green-bubbles-wrapper * {
  box-sizing: content-box; }

.tabs nav ul {
  list-style: none;
  margin: 0 auto; }
  .tabs nav ul li {
    display: inline; }

.tabs-content section {
  padding: 60px 0;
  display: none; }
  .tabs-content section:before, .tabs-content section:after {
    content: '';
    display: table; }
  .tabs-content section:after {
    clear: both; }

/* Fallback example */
.no-js .tabs-content section {
  display: block;
  padding-bottom: 2em;
  border-bottom: 1px solid #62b4e5; }

.tabs-content section.content-current {
  display: block;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-name: fadeInFromNone;
  animation-name: fadeInFromNone; }

div.refine-wrapper {
  margin: -34px 0 0 0; }

div.refine-txt {
  margin: -18px auto 0 -50px;
  width: 100px;
  text-align: center;
  font-family: 'Poppins', serif;
  text-transform: uppercase;
  color: #707071;
  font-size: 17px;
  letter-spacing: 2px;
  z-index: 20;
  background: #ffffff;
  position: absolute;
  left: 50%; }

div.refine-left-arrow {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAABDCAMAAAC4C5WEAAAAPFBMVEUAAADW3t7W3t7W3t7W3t7W3t7W3t7W3t7W3t7W3t7W3t7W3t7W3t7W3t7W3t7W3t7W3t7W3t7W3t7W3t5rFBzAAAAAE3RSTlMA2/cfCX5MsePK7l0zEr6cbj4piamSsgAAAHpJREFUeAHt0DsOwjAQhOGs1+9HAsz97wpyQQEeS+n3bz9pZO/xX9KDBxc8R0DiBoGkG/xux6vk6vDT3O6CVRNHhYSnjuVswMkfdCLyr2QoPwJAzzeRdBMNDQ0NDd0OKwZHwYtjQuPYUDj6jPLo6tfaBayP+naV7Fb4BpeOEk9NwV1lAAAAAElFTkSuQmCC) no-repeat;
  height: 67px;
  width: 28px;
  float: left; }

div.refine-line {
  width: calc(100% - 58px) !important;
  background: #d6dede;
  height: 2px;
  z-index: 0;
  margin: 65px auto 0 auto;
  display: inline-block; }

div.refine-right-arrow {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAABDCAMAAAC4C5WEAAAANlBMVEUAAADW3t7W3t7W3t7W3t7W3t7W3t7W3t7W3t7W3t7W3t7W3t7W3t7W3t7W3t7W3t7W3t7W3t48zw+DAAAAEXRSTlMARygH8t+mdekQ0MS0hhyRWn9gpMAAAABoSURBVHgB7dU5CgJBAAXR3pdZRuv+lxXB0ApFBrrSBz/94R/xrVJbVHzXsszl1AuPoCW4XBvd8cnhmKiOkxI8uCsuXLhwYfnJ7GQ4JjbHyOF4cjpuRF9lZLOr0vWQBnv2L9tnEPyc4Au0zQ40WXPXEAAAAABJRU5ErkJggg==) no-repeat;
  height: 67px;
  width: 30px;
  float: right; }

.green-arrow-tab-first {
  background: url("/img/solutions/tab_one.png?ver=2.5.5") no-repeat;
  background-size: 100% 100%;
  width: 18%;
  display: inline-block;
  color: #ffffff;
  padding: 16px 2% 12px 2%;
  font-size: 22px;
  font-weight: 100;
  cursor: pointer;
  position: relative; }
  .green-arrow-tab-first img {
    display: inline-block;
    width: 28px;
    margin: 0 6% 0 0;
    position: relative;
    z-index: 2; }
  .green-arrow-tab-first span {
    display: inline-block;
    font-family: 'Poppins', serif;
    font-weight: 300;
    vertical-align: top;
    font-size: 20px;
    position: relative;
    z-index: 2; }

.green-arrow-tab-rest {
  background: url("/img/solutions/tab_arrow.png?ver=2.5.5") no-repeat;
  background-size: 100% 100%;
  width: 16.2%;
  display: inline-block;
  margin: 0 0 0 -12px;
  color: #ffffff;
  padding: 16px 2% 12px 2%;
  font-size: 22px;
  font-weight: 100;
  cursor: pointer;
  position: relative; }
  .green-arrow-tab-rest img {
    display: inline-block;
    width: 28px;
    margin: 0 6% 0 0;
    z-index: 2;
    position: relative; }
  .green-arrow-tab-rest span {
    display: inline-block;
    font-family: 'Poppins', serif;
    font-weight: 300;
    vertical-align: top;
    font-size: 20px;
    z-index: 2;
    position: relative; }

.green-arrow-tab-first::before {
  background: url("/img/solutions/tab_one_active.png?ver=2.5.5") no-repeat;
  background-size: 100% 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  opacity: 0;
  -webkit-transition: all 0.5s cubic-bezier(0.42, 0, 0.58, 1);
  transition: all 0.5s cubic-bezier(0.42, 0, 0.58, 1); }

.green-arrow-tab-rest::before {
  background: url("/img/solutions/tab_arrow_active.png?ver=2.5.5") no-repeat;
  background-size: 100% 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  opacity: 0;
  -webkit-transition: all 0.5s cubic-bezier(0.42, 0, 0.58, 1);
  transition: all 0.5s cubic-bezier(0.42, 0, 0.58, 1); }

.green-arrow-tab-first:hover::before,
.tab-current .green-arrow-tab-first::before,
.green-arrow-tab-rest:hover::before,
.tab-current .green-arrow-tab-rest::before {
  opacity: 1; }

.tab-content-column {
  width: 45%;
  display: inline-block;
  vertical-align: top;
  text-align: left;
  margin: 0 4% 0 0; }
  .tab-content-column h3 {
    color: #707071;
    font-weight: 300;
    font-size: 26px;
    margin: 0 0 16px 0; }
  .tab-content-column p, .tab-content-column li {
    font-size: 16px;
    line-height: 1.3; }

.tab-content-column-right {
  width: 48%;
  display: inline-block;
  vertical-align: top;
  text-align: left; }

img.nmbr-grn {
  float: left;
  margin: 0 30px 100px 0;
  max-width: 100px; }

.tab-content-column ul {
  float: left;
  margin: 20px; }

.solutions-list h3 {
  font-size: 26px;
  font-weight: 300;
  margin: 0 0 16px 0; }
  @media screen and (max-width: 768px) {
    .solutions-list h3 {
      font-size: 20px; } }

.solutions-column {
  width: 21%;
  display: inline-block;
  vertical-align: top; }
  .solutions-column h3 {
    font-size: 26px;
    font-weight: 300;
    margin: 8px 0 16px 0; }
    @media screen and (max-width: 768px) {
      .solutions-column h3 {
        font-size: 20px; } }
  .solutions-column ul > li {
    margin: 22px 20px; }
    .solutions-column ul > li:first-child {
      margin-top: 0; }
  @media screen and (max-width: 920px) {
    .solutions-column {
      width: 32%; } }
  @media screen and (max-width: 580px) {
    .solutions-column {
      width: 100%;
      margin: 0; }
      .solutions-column ul {
        margin-bottom: 0;
        margin-top: 0; }
        .solutions-column ul li {
          margin: 6px 20px; }
          .solutions-column ul li:first-child {
            margin-top: 6px; }
          .solutions-column ul li:last-child {
            margin-bottom: 0; } }

.solutions-column-two {
  width: 33%;
  display: inline-block;
  vertical-align: top; }
  .solutions-column-two .svg-container {
    visibility: hidden;
    text-align: center; }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .solutions-column-two .svg-container {
        width: 100%;
        height: 0;
        padding-top: 100%;
        position: relative; }
        .solutions-column-two .svg-container.marketing {
          padding-top: 51%; }
        .solutions-column-two .svg-container.creative {
          padding-top: 88%; }
        .solutions-column-two .svg-container.agency {
          padding-top: 65%; }
        .solutions-column-two .svg-container svg {
          position: absolute;
          top: 0;
          left: 0; } }
    .solutions-column-two .svg-container.move-up {
      margin-top: -40px; }
      @media screen and (max-width: 920px) {
        .solutions-column-two .svg-container.move-up {
          margin-top: 0; } }
    .solutions-column-two .svg-container.done {
      visibility: visible; }
    .solutions-column-two .svg-container .svg-btm {
      width: 100%;
      display: block;
      height: 1px; }
  @media screen and (max-width: 920px) {
    .solutions-column-two {
      width: 50%;
      display: block;
      margin: 0 auto; } }
  @media screen and (max-width: 580px) {
    .solutions-column-two {
      width: 80%;
      display: block;
      margin-top: 20px; } }

.solutions-column-two img {
  width: 100%; }

.solution-grid canvas {
  background-color: #d6dede !important; }

.no-parallax {
  position: relative;
  z-index: 2; }

.parallax-index {
  z-index: 0 !important; }

.solutions-parallax-box {
  width: 100%;
  text-align: center;
  color: #ffffff;
  padding: 71px 0 85px 0; }
  @media screen and (min-width: 1024px) {
    .solutions-parallax-box.min-height {
      position: relative;
      height: 600px; }
      .solutions-parallax-box.min-height .full-width {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        z-index: 2;
        transform: translate(-50%, -50%); } }
  .solutions-parallax-box p.number {
    font-size: 160px;
    font-family: "proxima-nova";
    font-weight: 700;
    margin-bottom: 0;
    line-height: 1; }
  .solutions-parallax-box p.item {
    font-size: 74px;
    margin: -16px 0 20px;
    letter-spacing: -1.2px;
    line-height: 1; }
  .solutions-parallax-box p {
    font-size: 28px;
    font-family: 'Poppins', serif;
    font-weight: 300; }
  .solutions-parallax-box.strategy-prllx {
    background: url("/img/solutions/solutions_bg2.jpg?ver=2.5.5") center;
    background-attachment: fixed;
    background-size: cover; }
  .solutions-parallax-box.creative-prllx {
    background: url("/img/solutions/creative1.jpg?ver=2.5.5") center;
    background-attachment: fixed;
    background-size: cover; }
  .solutions-parallax-box.development-prllx {
    background: url("/img/solutions/solutions_bg3.jpg?ver=2.5.5") center;
    background-attachment: fixed;
    background-size: cover; }
  .solutions-parallax-box.marketing-prllx {
    background: url("/img/solutions/marketing1.jpg?ver=2.5.5") center;
    background-attachment: fixed;
    background-size: cover; }
  .solutions-parallax-box.agency-prllx {
    background: url("/img/solutions/agency_partnership1.jpg?ver=2.5.5") center;
    background-attachment: fixed;
    background-size: cover; }
  .solutions-parallax-box.video-parallax {
    padding: 150px 0 200px 0;
    z-index: 0; }
    .solutions-parallax-box.video-parallax video {
      position: fixed;
      z-index: -9999;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      transform: translate(-50%, -50%); }
      .ua-mobile .solutions-parallax-box.video-parallax video {
        display: none; }
  @media screen and (max-width: 780px) {
    .solutions-parallax-box {
      padding: 60px 0 90px 0; }
      .solutions-parallax-box.video-parallax {
        padding: 60px 0 90px 0; }
      .solutions-parallax-box.strategy-prllx, .solutions-parallax-box.creative-prllx, .solutions-parallax-box.development-prllx, .solutions-parallax-box.marketing-prllx, .solutions-parallax-box.agency-prllx {
        background-attachment: scroll; } }
  @media screen and (max-width: 580px) {
    .solutions-parallax-box p.number {
      font-size: 100px; }
    .solutions-parallax-box p.item {
      font-size: 50px;
      margin: -20px 0 0 0;
      letter-spacing: 2px; }
    .solutions-parallax-box p {
      font-size: 20px;
      letter-spacing: 0px; } }
  @media screen and (max-width: 520px) {
    .solutions-parallax-box {
      padding: 40px 0 65px 0; }
      .solutions-parallax-box.video-parallax {
        padding: 40px 0 65px 0;
        background: url("/img/solutions/videos/webenertia-solutions-video.jpg?ver=2.5.5") center;
        background-size: cover; }
        .solutions-parallax-box.video-parallax video {
          display: none; } }
  @media screen and (max-width: 420px) {
    .solutions-parallax-box p.number {
      font-size: 80px; }
    .solutions-parallax-box p.item {
      font-size: 40px;
      margin: -10px 0 0 0;
      letter-spacing: 2px; }
    .solutions-parallax-box p {
      font-size: 16px; } }

.bottom-container {
  text-align: center;
  padding: 94px 0 40px 0;
  background: #ffffff; }
  @media screen and (max-width: 1300px) {
    .bottom-container {
      padding: 60px 40px 10px; } }
  @media screen and (max-width: 520px) {
    .bottom-container {
      padding: 60px 20px 0; } }
  .bottom-container.full-min-height {
    padding-top: 0;
    padding-bottom: 0; }
    .bottom-container.full-min-height .request-services {
      margin: 0 auto; }
    @media screen and (max-width: 767px) {
      .bottom-container.full-min-height {
        padding: 40px; } }
  .bottom-container.clients-home {
    background: transparent;
    padding: 0 0 10px; }
  .bottom-container.clients-creative {
    background: transparent;
    padding: 0 0 30px; }
  .bottom-container p {
    margin: 26px auto; }
  .bottom-container .request-services {
    max-width: 300px;
    margin: 0 auto 60px auto; }

/*** main footer ***/
.footer_head_wrapper {
  width: 100%;
  background: #252728;
  line-height: 42px;
  color: #707071;
  height: 42px; }
  .footer_head_wrapper.fixed {
    left: 0px;
    bottom: 0px;
    z-index: 99; }

.footer_head {
  max-width: 1200px;
  position: relative;
  margin: auto; }
  .footer_head .copyright {
    float: left;
    font-size: 12px; }
  .footer_head a.connect {
    float: right;
    font-size: 12px;
    color: #ffffff;
    text-transform: uppercase;
    text-decoration: none !important; }
    .footer_head a.connect div {
      display: inline-block;
      background-color: #ef9123;
      border-radius: 2px;
      width: 14px;
      height: 14px;
      padding: 2px;
      margin: -2px 0 0 16px;
      vertical-align: middle; }
    .footer_head a.connect img {
      transition: all 140ms ease-in-out;
      width: 100%; }
  .footer_head .footer_content {
    height: 300px;
    display: none; }

.footer_section {
  background: #d6dede; }
  .footer_section i {
    background: url("/img/sprite.png?ver=2.5.5") no-repeat;
    background-size: 150px;
    width: 30px;
    height: 35px;
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px 0 -4px; }
    .footer_section i.pdf {
      background-position: 0 -30px; }
    .footer_section i.phone {
      background-position: -30px -30px;
      margin: 6px 5px 0 -4px; }
    .footer_section i.mail {
      background-position: -60px -30px; }

.about_wrapper {
  margin: 0 auto;
  max-width: 1280px;
  padding: 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap; }
  @media screen and (max-width: 580px) {
    .about_wrapper {
      padding: 20px; } }
  .about_wrapper h4 {
    font-weight: 300;
    font-size: 24px;
    line-height: 1.2;
    letter-spacing: -1.3px;
    margin: 0 0 14px; }
  .about_wrapper ul {
    padding: 0;
    margin: 0;
    list-style: none;
    color: #252728;
    font-size: 16px; }
    .about_wrapper ul li {
      padding: 0 0 4px; }
    .about_wrapper ul a {
      color: #252728;
      text-decoration: none; }
  .about_wrapper p {
    font-size: 16px;
    line-height: 1.2; }
  .about_wrapper .about-contact-us {
    flex-basis: 0;
    flex-grow: 1;
    padding: 0;
    box-sizing: border-box; }
    .about_wrapper .about-contact-us img.about-icon-pdf {
      display: inline-block;
      width: 18px;
      vertical-align: top;
      margin: -3px 8px 0 0; }
    .about_wrapper .about-contact-us img.about-icon-phone {
      display: inline-block;
      width: 15px;
      margin-right: 8px;
      vertical-align: top;
      margin: -4px 8px 0 0; }
    .about_wrapper .about-contact-us img.about-icon-email {
      display: inline-block;
      width: 20px;
      vertical-align: top;
      margin: 3px 8px 0 0; }
    @media screen and (max-width: 1200px) {
      .about_wrapper .about-contact-us {
        flex-basis: auto; } }
    @media screen and (max-width: 767px) {
      .about_wrapper .about-contact-us {
        width: 100%;
        padding-bottom: 10px;
        margin-bottom: 20px;
        border-bottom: solid 1px #707071; } }
  .about_wrapper .about-social {
    flex-basis: 0;
    flex-grow: 1;
    padding-left: 50px; }
    .about_wrapper .about-social .nav-social-box {
      text-align: left;
      white-space: nowrap; }
    @media screen and (max-width: 1200px) {
      .about_wrapper .about-social {
        padding-left: 25px; } }
    @media screen and (max-width: 1100px) {
      .about_wrapper .about-social {
        flex: 1 0 auto; } }
    @media screen and (max-width: 1023px) {
      .about_wrapper .about-social {
        flex: 1 0 100%;
        padding-top: 30px;
        padding-left: 0; } }
    @media screen and (max-width: 767px) {
      .about_wrapper .about-social {
        padding-top: 0; } }
  .about_wrapper:after {
    content: "";
    display: table;
    clear: both; }
  .about_wrapper a:not(.request-services):hover {
    color: #ef9123; }
  .about_wrapper a.about-link {
    text-transform: uppercase;
    color: #252728;
    text-decoration: none;
    font-size: 14px; }
    .about_wrapper a.about-link.small {
      text-transform: none; }
  .about_wrapper .about-link-break {
    height: 2px;
    clear: both; }

.footer-holder {
  background: #252728;
  color: #d6dede;
  padding: 20px 0; }
  .footer-holder .container {
    font-size: 16px;
    line-height: 20px;
    overflow: hidden; }
  .footer-holder .left {
    float: left;
    color: #707071;
    width: 50%; }
    .footer-holder .left p {
      padding: 0;
      font-size: 12px;
      line-height: 32px; }
  .footer-holder p {
    margin: 0; }
  .footer-holder .right {
    float: right; }
    .footer-holder .right img {
      display: inline-block;
      vertical-align: middle;
      margin: 0 5px 3px; }
    .footer-holder .right p {
      padding: 5px 0 0;
      font-size: 16px; }
  .footer-holder .logo {
    float: left;
    width: 33px;
    height: 32px;
    margin: 0 20px 0 0;
    overflow: hidden; }
    .footer-holder .logo img {
      width: auto;
      max-width: none;
      height: 32px; }
  @media screen and (max-width: 767px) {
    .footer-holder .logo {
      float: none;
      display: inline-block;
      vertical-align: top;
      margin: 0; }
    .footer-holder .right,
    .footer-holder .left {
      float: none;
      width: 100%;
      text-align: center; } }

#mc_embed_signup {
  overflow: hidden;
  padding-bottom: 4px; }
  #mc_embed_signup .mc-field-group {
    overflow: hidden;
    position: relative;
    padding: 0 40px 0 0;
    width: 100%;
    max-width: 230px; }
  #mc_embed_signup [type="email"] {
    display: block;
    width: 100%;
    background: #ffffff;
    border: none;
    padding: 0 18px;
    height: 40px;
    line-height: 40px;
    color: #707071; }
  #mc_embed_signup button {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    text-align: center;
    border: none;
    border-radius: 0 !important;
    padding: 0;
    margin: 0;
    height: 40px;
    color: #ffffff;
    background: #ef9123;
    cursor: pointer;
    z-index: 2; }
    #mc_embed_signup button svg {
      display: inline-block;
      vertical-align: middle;
      fill: #ffffff;
      width: 16px;
      height: 14px; }
    #mc_embed_signup button:hover {
      background: #252728 !important; }

#mc_embed_signup div.mce_inline_error {
  margin: 0;
  font-weight: normal;
  font-size: 12px;
  background-color: #e04444 !important; }

#header_wrapper {
  left: 0px;
  top: 0px;
  z-index: 101;
  position: fixed;
  left: 0;
  top: 0px;
  width: 100%; }

#header {
  max-width: 1200px;
  position: relative;
  margin: 0 auto;
  z-index: 4; }
  #header:after {
    content: '';
    display: block;
    clear: both; }
  @media screen and (max-width: 1300px) {
    #header {
      margin: 0 40px; } }
  @media screen and (max-width: 580px) {
    #header {
      margin: 0 20px; } }

#logo {
  float: left;
  position: relative;
  z-index: 1; }
  #logo span.cover_left,
  #logo span.cover_right {
    display: block;
    height: 89px;
    width: 2000px;
    background: #ffffff;
    position: absolute; }
  #logo span.cover_left {
    left: -2000px; }
  #logo span.cover_right {
    right: -1998px; }
  #logo img {
    max-width: 215px; }

.overlay-nav {
  opacity: 1;
  visibility: hidden;
  -webkit-transition: opacity 0s, all 0.5s ease-in-out;
  transition: opacity 0s, all 0.5s ease-in-out;
  z-index: 2;
  font-family: 'Poppins', serif !important; }
  .overlay-nav ul {
    opacity: 1; }
  .overlay-nav ul.nav-sec {
    min-width: 600px;
    list-style: none;
    padding: 30px 0;
    margin: 30px auto;
    position: relative;
    border-top: solid 1px #707071;
    border-bottom: solid 1px #707071;
    display: inline-block;
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px); }
    .overlay-nav ul.nav-sec a {
      color: #707071;
      font-size: 18px; }
    .overlay-nav ul.nav-sec li {
      display: inline-block;
      text-transform: uppercase;
      color: #707071;
      margin-right: 10px;
      border-right: solid 1px #707071;
      padding-right: 10px; }
      .overlay-nav ul.nav-sec li:last-child {
        border-right: none; }
      .overlay-nav ul.nav-sec li a:hover, .overlay-nav ul.nav-sec li a:focus {
        color: #ef9123; }
  .overlay-nav .nav-social-box div {
    opacity: 1;
    -webkit-transition: -webkit-transform 0.8s, opacity 1.5s;
    transition: transform 0.8s, opacity 1.5s;
    /* was 0.1 */
    -webkit-transform: translateY(-32px);
    transform: translateY(-32px); }
    @media screen and (max-width: 375px) {
      .overlay-nav .nav-social-box div {
        width: 14%;
        padding: 10px 0; } }
  .overlay-nav li {
    -webkit-transition: transform .5s ease;
    transition: transform .5s ease; }
  .overlay-nav li.blog {
    -webkit-transition-delay: 0s !important;
    transition-delay: 0s !important; }
  .overlay-nav li.about {
    -webkit-transition-delay: 0.1s !important;
    transition-delay: 0.1s !important; }
  .overlay-nav li.solutions {
    -webkit-transition-delay: 0.2s !important;
    transition-delay: 0.2s !important; }
  .overlay-nav li.work {
    -webkit-transition-delay: 0.3s !important;
    transition-delay: 0.3s !important; }
  .overlay-nav.open {
    opacity: 1;
    visibility: visible;
    /*-webkit-transition: opacity 1s;
      transition: opacity 1s;*/
    -webkit-transition-delay: 0s !important;
    transition-delay: 0s !important;
    -webkit-transition-delay: 0s !important;
    transition-delay: 0s !important; }
    .overlay-nav.open ul,
    .overlay-nav.open ul.nav-sec,
    .overlay-nav.open a.nav-contact-us,
    .overlay-nav.open .nav-social-box div {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0); }
    .overlay-nav.open li.work, .overlay-nav.open li.solutions, .overlay-nav.open li.about, .overlay-nav.open li.blog {
      -webkit-transform: translateY(0%) !important;
      -ms-transform: translateY(0%) !important;
      transform: translateY(0%) !important; }
    @media screen and (max-width: 1023px) {
      .overlay-nav.open li.work {
        -webkit-transition-delay: 0s !important;
        transition-delay: 0s !important; }
      .overlay-nav.open li.solutions {
        -webkit-transition-delay: 0.1s !important;
        transition-delay: 0.1s !important; }
      .overlay-nav.open li.about {
        -webkit-transition-delay: 0.2s !important;
        transition-delay: 0.2s !important; }
      .overlay-nav.open li.blog {
        -webkit-transition-delay: 0.3s !important;
        transition-delay: 0.3s !important; } }
    @media screen and (max-width: 767px) {
      .overlay-nav.open li.work,
      .overlay-nav.open li.solutions,
      .overlay-nav.open li.about,
      .overlay-nav.open li.blog {
        -webkit-transition-delay: 0s !important;
        transition-delay: 0s !important; } }
  .overlay-nav.close {
    /* nav ul {
         -webkit-transform: translateY(-30px);
                 transform: translateY(-30px);
      } */ }
    .overlay-nav.close ul,
    .overlay-nav.close ul.nav-sec,
    .overlay-nav.close a.nav-contact-us,
    .overlay-nav.close .nav-social-box div {
      opacity: 1; }
    .overlay-nav.close ul.nav-sec {
      -webkit-transform: translateY(-25px);
      transform: translateY(-25px); }
    .overlay-nav.close a.nav-contact-us {
      -webkit-transform: translateY(-28px);
      transform: translateY(-28px); }
    .overlay-nav.close .nav-social-box div {
      -webkit-transform: translateY(-32px);
      transform: translateY(-32px); }

.overlay {
  position: absolute;
  width: 100%;
  height: calc(100vh - 87px);
  top: 100%;
  left: 0;
  background: none !important;
  text-align: center;
  overflow: hidden; }
  .overlay a {
    text-decoration: none; }
  .overlay ul.nav-main {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    position: relative;
    font-size: 44px;
    line-height: 1.2;
    overflow: hidden; }
    @media screen and (min-width: 1024px) {
      .overlay ul.nav-main:after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 0;
        z-index: 1;
        content: '';
        background: rgba(0, 0, 0, 0.7);
        transition: height 0s  ease 0s; } }
    @media screen and (max-width: 1099px) {
      .overlay ul.nav-main {
        font-size: 34px; } }
    .overlay ul.nav-main .social-networks {
      margin: 0;
      padding: 0;
      position: static;
      visibility: visible;
      opacity: 1; }
      .overlay ul.nav-main .social-networks:after {
        content: '';
        display: block;
        clear: both; }
      .overlay ul.nav-main .social-networks li {
        margin: 0;
        padding: 0 15px 6px 0;
        float: left;
        clear: none;
        line-height: 0;
        display: block; }
        .overlay ul.nav-main .social-networks li:last-child {
          padding-right: 0; }
        .overlay ul.nav-main .social-networks li a {
          display: block;
          margin: 0;
          padding: 0 4px 2px; }
        .overlay ul.nav-main .social-networks li img {
          display: block;
          height: 20px;
          width: auto; }
        @media screen and (max-width: 1023px) {
          .overlay ul.nav-main .social-networks li {
            float: none;
            display: inline-block;
            vertical-align: top;
            padding: 8px 4px 0 !important; }
            .overlay ul.nav-main .social-networks li:last-child, .overlay ul.nav-main .social-networks li:first-child {
              padding: 8px 4px 0 !important; }
            .overlay ul.nav-main .social-networks li a {
              padding: 0 2px 6px; }
            .overlay ul.nav-main .social-networks li img {
              height: 18px; } }
    .overlay ul.nav-main > li {
      display: block;
      height: calc(100vh - 87px);
      width: 25%;
      float: left;
      position: relative;
      padding: 89px 2% 10px;
      margin: 0;
      text-align: left;
      z-index: 2; }
      .overlay ul.nav-main > li:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        transition: opacity 0.3s ease; }
        @media screen and (max-width: 1023px) {
          .overlay ul.nav-main > li:before {
            opacity: 1; } }
      .overlay ul.nav-main > li:hover:before {
        opacity: 1; }
      .overlay ul.nav-main > li:hover > ul > li:first-child:before {
        border-color: #ffffff !important; }
      .overlay ul.nav-main > li.work, .overlay ul.nav-main > li.solutions, .overlay ul.nav-main > li.about, .overlay ul.nav-main > li.blog {
        -webkit-transform: translateY(-110vh);
        -ms-transform: translateY(-110vh);
        transform: translateY(-110vh);
        -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
        transform-origin: 0 0; }
        @media screen and (max-width: 1023px), screen and (max-height: 899px) {
          .overlay ul.nav-main > li.work, .overlay ul.nav-main > li.solutions, .overlay ul.nav-main > li.about, .overlay ul.nav-main > li.blog {
            -webkit-transform: translateY(-150vh);
            -ms-transform: translateY(-150vh);
            transform: translateY(-150vh); } }
        @media screen and (max-width: 1023px), screen and (max-height: 679px) {
          .overlay ul.nav-main > li.work, .overlay ul.nav-main > li.solutions, .overlay ul.nav-main > li.about, .overlay ul.nav-main > li.blog {
            -webkit-transform: translateY(-205vh);
            -ms-transform: translateY(-205vh);
            transform: translateY(-205vh); } }
        @media screen and (max-width: 1023px), screen and (max-height: 519px) {
          .overlay ul.nav-main > li.work, .overlay ul.nav-main > li.solutions, .overlay ul.nav-main > li.about, .overlay ul.nav-main > li.blog {
            -webkit-transform: translateY(-245vh);
            -ms-transform: translateY(-245vh);
            transform: translateY(-245vh); } }
      .overlay ul.nav-main > li.work {
        background: url(/img/homepage/work.jpg?ver=2.5.5);
        background-repeat: no-repeat;
        background-size: cover; }
        .overlay ul.nav-main > li.work:before {
          background: #ef9123; }
      .overlay ul.nav-main > li.solutions {
        background: url(/img/homepage/solutions.jpg?ver=2.5.5);
        background-repeat: no-repeat;
        background-size: cover; }
        .overlay ul.nav-main > li.solutions:before {
          background: #a3cf5f; }
        @media screen and (max-width: 767px) {
          .overlay ul.nav-main > li.solutions {
            -webkit-transform: translateY(-166vh);
            -ms-transform: translateY(-166vh);
            transform: translateY(-166vh);
            transition-duration: 0.6s; } }
        @media screen and (max-width: 767px), screen and (max-height: 319px) {
          .overlay ul.nav-main > li.solutions {
            -webkit-transform: translateY(-180vh);
            -ms-transform: translateY(-180vh);
            transform: translateY(-180vh); } }
      .overlay ul.nav-main > li.about {
        background: url(/img/homepage/about_us.jpg?ver=2.5.5);
        background-repeat: no-repeat;
        background-size: cover; }
        .overlay ul.nav-main > li.about:before {
          background: #a072a5; }
        @media screen and (max-width: 767px) {
          .overlay ul.nav-main > li.about {
            -webkit-transform: translateY(-231vh);
            -ms-transform: translateY(-231vh);
            transform: translateY(-231vh);
            transition-duration: 0.9s; } }
        @media screen and (max-width: 767px), screen and (max-height: 319px) {
          .overlay ul.nav-main > li.about {
            -webkit-transform: translateY(-260vh);
            -ms-transform: translateY(-260vh);
            transform: translateY(-260vh); } }
      .overlay ul.nav-main > li.blog {
        background: url(/img/homepage/blog.jpg?ver=2.5.5);
        background-repeat: no-repeat;
        background-size: cover; }
        .overlay ul.nav-main > li.blog:before {
          background: #62b4e5; }
        @media screen and (max-width: 767px) {
          .overlay ul.nav-main > li.blog {
            -webkit-transform: translateY(-315vh);
            -ms-transform: translateY(-315vh);
            transform: translateY(-315vh);
            transition-duration: 1s; } }
        @media screen and (max-width: 767px), screen and (max-height: 319px) {
          .overlay ul.nav-main > li.blog {
            -webkit-transform: translateY(-350vh);
            -ms-transform: translateY(-350vh);
            transform: translateY(-350vh); } }
      @media screen and (max-height: 820px) {
        .overlay ul.nav-main > li {
          padding: 50px 2% 10px; } }
      .overlay ul.nav-main > li a {
        font-weight: 400;
        font-family: 'Poppins', serif !important;
        display: inline-block;
        vertical-align: top;
        text-align: left;
        color: #ffffff;
        z-index: 2;
        position: relative;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden; }
        .overlay ul.nav-main > li a:before {
          content: '';
          position: absolute;
          bottom: -3px;
          left: 0;
          width: 100%;
          height: 2px;
          background: #ffffff;
          -webkit-transform: scale3d(0, 1, 1);
          transform: scale3d(0, 1, 1);
          -webkit-transition: -webkit-transform 0.1s;
          transition: transform 0.1s; }
          @media screen and (max-width: 1023px) {
            .overlay ul.nav-main > li a:before {
              bottom: 0; } }
        .overlay ul.nav-main > li a:hover {
          text-decoration: none; }
          .overlay ul.nav-main > li a:hover:before {
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
            -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s; }
        .overlay ul.nav-main > li a span {
          margin-right: 7px; }
          .overlay ul.nav-main > li a span.sm {
            margin-right: 4px; }
      @media screen and (max-width: 1099px) {
        .overlay ul.nav-main > li {
          padding: 50px 15px 10px; } }
      .overlay ul.nav-main > li > ul {
        position: relative;
        bottom: 0;
        left: 0;
        margin: 0;
        margin-top: 40px;
        visibility: visible;
        opacity: 1;
        padding: 0 0 3px; }
        @media screen and (max-width: 1099px) {
          .overlay ul.nav-main > li > ul {
            padding: 0; } }
        .overlay ul.nav-main > li > ul.open {
          visibility: visible;
          opacity: 1;
          overflow: auto; }
        .overlay ul.nav-main > li > ul > li {
          display: block;
          font-size: 24px;
          margin: 0;
          padding: 16px 0 0 15px; }
          .overlay ul.nav-main > li > ul > li:first-child {
            padding: 0 0 0 15px; }
          .overlay ul.nav-main > li > ul > li:first-child:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            transition: border 0.3s ease; }
            @media screen and (max-width: 1023px) {
              .overlay ul.nav-main > li > ul > li:first-child:before {
                left: 0;
                top: 0;
                display: none; } }
          .overlay ul.nav-main > li > ul > li a {
            position: relative;
            font-family: 'Poppins', serif !important;
            font-weight: 300; }
            .overlay ul.nav-main > li > ul > li a:hover, .overlay ul.nav-main > li > ul > li a:focus {
              color: #ffffff; }
          @media screen and (max-width: 1099px) {
            .overlay ul.nav-main > li > ul > li {
              font-size: 20px; } }
        .overlay ul.nav-main > li > ul.a-orange li:first-child:before {
          border-left: 3px solid #ef9123; }
        .overlay ul.nav-main > li > ul.a-green li:first-child:before {
          border-left: 3px solid #a3cf5f; }
        .overlay ul.nav-main > li > ul.a-purple li:first-child:before {
          border-left: 3px solid #a072a5; }
        .overlay ul.nav-main > li > ul.a-blue li:first-child:before {
          border-left: 3px solid #62b4e5; }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft; }

/*** main navigation ***/
#navigation_one {
  float: left;
  position: relative;
  z-index: 2; }

#navigation_one ul {
  list-style: none;
  margin-left: 22px;
  margin-top: 45px; }

#navigation_one ul li {
  float: left;
  list-style: none;
  padding: 0;
  margin-right: 32px; }

#navigation_one ul li a {
  text-decoration: none;
  text-transform: uppercase;
  color: #707071; }

#navigation_one ul li.work a:hover {
  color: #ef9123; }

#navigation_one ul li.solutions a:hover {
  color: #a3cf5f; }

#navigation_one ul li.inside-we a:hover {
  color: #a072a5; }

#navigation_one ul li.blog a:hover {
  color: #62b4e5; }

#navigation_two {
  float: right;
  z-index: 2;
  position: relative; }

#navigation_two ul {
  list-style: none;
  margin-top: 50px;
  height: 10px; }

#navigation_two ul li {
  float: left;
  padding: 0;
  border-right: 1px solid #707071;
  padding: 0;
  line-height: 6px;
  padding-right: 8px;
  margin-right: 8px;
  list-style: none;
  padding-left: 0;
  margin-left: 0px; }

#navigation_two ul li:nth-child(3) {
  padding-right: 0px;
  margin-right: 0px;
  border-right: none; }

#navigation_two ul li a {
  color: #707071;
  font-size: 10px;
  text-transform: uppercase;
  text-decoration: none; }

#navigation_two ul li a:hover {
  text-decoration: underline; }

/*** nav css ***/
#nav-toggle {
  position: absolute;
  right: 0;
  top: 34px;
  z-index: 1000; }

#nav-toggle {
  cursor: pointer;
  padding: 10px 35px 16px 0px; }

#nav-toggle span, #nav-toggle span:before, #nav-toggle span:after {
  cursor: pointer;
  border-radius: 1px;
  height: 2px;
  width: 32px;
  background: #707071;
  position: absolute;
  display: block;
  content: ''; }

#nav-toggle span:before {
  top: -7px; }

#nav-toggle span:after {
  bottom: -7px; }

#nav-toggle:hover span:before {
  top: -9px; }

#nav-toggle:hover span:after {
  bottom: -9px; }

#nav-toggle span, #nav-toggle span:before, #nav-toggle span:after {
  transition: all 140ms ease-in-out; }

#nav-toggle.active span {
  background-color: transparent; }

#nav-toggle.active span:before, #nav-toggle.active span:after {
  top: 0; }

#nav-toggle.active span:before {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg); }

#nav-toggle.active span:after {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg); }

.nav-social-box {
  margin: 0 auto;
  text-align: center; }
  .nav-social-box div {
    background: #252728;
    display: inline-block;
    margin: 0 1.2px;
    width: 47px;
    border-radius: 2px;
    padding: 10px 0;
    box-sizing: border-box;
    text-align: center;
    -webkit-border-radius: 2px; }
    .nav-social-box div i {
      background: url("/img/sprite.png?ver=2.5.5") no-repeat;
      background-size: 140px;
      width: 28px;
      height: 27px;
      display: block;
      margin: 0 auto; }
      .nav-social-box div i.facebook {
        background: url("/img/social-icons/Facebook.svg") no-repeat 50% 50%;
        background-size: auto 25px;
        width: 100%; }
      .nav-social-box div i.twitter {
        background: url("/img/social-icons/Twitter.svg") no-repeat 50% 50%;
        background-size: auto 25px;
        width: 100%; }
      .nav-social-box div i.youtube {
        background-position: -56px 0; }
      .nav-social-box div i.linkedin {
        background: url("/img/social-icons/LinkedIn.svg") no-repeat 50% 50%;
        background-size: auto 25px;
        width: 100%; }
      .nav-social-box div i.instagram {
        background: url("/img/social-icons/Instagram_Solid.svg") no-repeat 50% 50%;
        background-size: auto 25px;
        width: 100%; }
      .nav-social-box div i.google {
        background: url("/img/social-icons/Google+.svg") no-repeat 50% 50%;
        background-size: auto 25px;
        width: 100%; }
      .nav-social-box div i.pintrst {
        background-position: -112px 0; }
    .nav-social-box div:hover {
      background-color: #ef9123; }
    .nav-social-box div img {
      width: 100%; }
    @media screen and (max-width: 375px) {
      .nav-social-box div {
        width: 18%;
        padding: 12px 0; } }
    @media screen and (max-width: 330px) {
      .nav-social-box div {
        width: 17.8%; } }

@media screen and (max-width: 1023px) {
  .overlay {
    margin: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 87px;
    overflow-y: scroll;
    bottom: 0;
    height: auto;
    -webkit-overflow-scrolling: touch; }
  .overlay-nav ul {
    -webkit-transform: none;
    transform: none;
    -webkit-transform: translate3d(0, 0, 0); }
  .overlay ul.nav-main > li > a {
    font-size: 34px !important; }
  .page.with-navigation {
    position: relative; }
    .page.with-navigation:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #ffffff;
      z-index: 1;
      visibility: hidden;
      transition: visibility 0s linear 0s; }
  #header_wrapper {
    transition: height 0s 0.6s; }
    .nav-open #header_wrapper {
      transition-delay: 0s;
      height: 100%; }
    #header_wrapper:before {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: 0;
      background: #ef9123;
      z-index: -1;
      overflow: hidden;
      transition: height 0s linear 0s; }
    #header_wrapper:after {
      content: '';
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
      height: 0;
      background: #62b4e5;
      overflow: hidden;
      transition: height 0s linear 0s; }
  .nav-open #header_wrapper:before,
  .nav-open #header_wrapper:after {
    height: 50vh;
    transition-delay: 0.6s; }
  .nav-open .page.with-navigation:after {
    visibility: visible;
    transition-delay: 0.6s; }
  .noScroll {
    overflow: hidden !important;
    height: 100% !important; }
  body.noScroll {
    position: fixed;
    width: 100%; }
  .overlay ul.nav-main > li {
    width: 100%;
    height: auto;
    padding: 30px 20px;
    min-height: 0;
    text-align: center;
    background-position: center center !important; }
  .overlay ul.nav-main > li ul li {
    font-size: 20px;
    line-height: 30px;
    padding: 0 0 4px !important; }
    .overlay ul.nav-main > li ul li:last-child {
      padding: 0 !important; }
  .overlay ul.nav-main > li ul {
    display: block !important;
    margin-top: 22px;
    padding: 0; }
  .overlay ul.nav-main > li ul li a {
    font-size: 20px !important;
    margin-left: 0; } }

@media screen and (max-width: 767px) {
  .overlay ul.nav-main > li {
    padding: 20px; } }

@media screen and (max-width: 767px), screen and (max-height: 480px) {
  .overlay ul.nav-main {
    font-size: 25px; } }

#header:before {
  content: '';
  position: absolute;
  top: 0;
  height: 0;
  left: 0;
  right: -9999px;
  background: #000;
  transition: height 0.3s ease 0.5s; }

.nav-open #header:before {
  height: 89px;
  transition-delay: 0s; }

.nav-open .overlay ul.nav-main:after {
  height: 100%;
  transition-delay: 0.6s; }

#header_wrapper {
  height: 89px; }
  #header_wrapper .request-services {
    z-index: 99999;
    position: absolute;
    right: 135px;
    top: 24px;
    padding: 11px 100px; }
    @media screen and (max-width: 767px) {
      #header_wrapper .request-services {
        right: 57px;
        padding: 11px 30px; } }
    @media screen and (max-width: 479px) {
      #header_wrapper .request-services {
        display: none; } }

#logo {
  left: 0;
  position: relative;
  z-index: 2; }
  #logo:before {
    content: '';
    position: absolute;
    z-index: -1;
    right: 100%;
    top: 0;
    bottom: 0;
    width: 9999px;
    background: #ffffff; }
  #logo:after {
    background: #ffffff;
    content: '';
    position: absolute;
    z-index: -1;
    left: 100%;
    margin-left: -3px;
    top: 0;
    bottom: 0;
    width: 9999px; }

.latestPost {
  color: #ffffff;
  font-size: 13px;
  line-height: 18px !important;
  padding: 0 0 0 15px;
  font-weight: 300;
  font-family: 'Poppins', serif !important; }
  .latestPost a {
    padding: 0 !important;
    margin: 0 !important;
    cursor: default;
    pointer-events: none; }
  .latestPost span {
    font-family: 'Poppins', serif !important;
    font-weight: 300;
    line-height: 18px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 13px; }
    .latestPost span.latest {
      border: 1px solid white;
      font-size: 12px; }
    .latestPost span.date {
      padding: 0; }
  @media screen and (max-width: 1023px) {
    .latestPost {
      padding: 0 0 10px !important; } }

.lastBlog {
  color: #ffffff;
  margin-top: 4px; }
  .lastBlog span.date, .lastBlog span.title {
    font-family: 'Poppins', serif !important;
    font-size: 24px;
    line-height: 32px;
    font-weight: 300; }
  .lastBlog span.title {
    display: block;
    float: none;
    margin: 0 0 10px; }
  .lastBlog span.excerpt {
    font-family: 'Poppins', serif !important;
    font-size: 18px;
    font-weight: 300;
    display: block;
    line-height: 24px; }
    .lastBlog span.excerpt p {
      margin: 0 0 10px;
      font-family: 'Poppins', serif !important;
      color: #ffffff !important;
      font-size: 18px; }
    .lastBlog span.excerpt .lastBlogLink {
      font-size: 18px;
      font-weight: bold; }
      .lastBlog span.excerpt .lastBlogLink .readMore {
        padding: 0px;
        margin: 0px;
        font-weight: bold;
        font-size: 18px; }
  @media screen and (max-width: 1099px) {
    .lastBlog span.date, .lastBlog span.title {
      font-size: 20px;
      line-height: 28px; }
    .lastBlog .lastBlogLink {
      font-size: 16px; }
      .lastBlog .lastBlogLink .readMore {
        font-size: 16px; }
    .lastBlog span.excerpt {
      font-size: 16px; } }
  @media screen and (max-width: 1023px) {
    .lastBlog {
      margin-top: 0;
      text-align: center; }
      .lastBlog p,
      .lastBlog span.title a {
        display: -webkit-box !important;
        max-height: 56px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis; }
      .lastBlog p {
        max-height: 49px; }
      .lastBlog span.title a {
        text-align: center !important; }
      .lastBlog span.date {
        font-size: 18px;
        line-height: 22px; }
      .lastBlog span.excerpt {
        line-height: 20px; }
      .lastBlog .readMore {
        line-height: 28px; } }

body.noScroll {
  overflow: hidden; }

@media screen and (max-width: 1023px) {
  .overlay ul.nav-main > li ul li.lastBlog {
    padding: 0 30% !important; } }

@media screen and (max-width: 767px) {
  .overlay ul.nav-main > li ul li.lastBlog {
    padding: 0 15% !important; } }

@media screen and (max-width: 450px) {
  .overlay ul.nav-main > li ul li.lastBlog {
    padding: 0 !important; } }

div.iScrollVerticalScrollbar {
  width: 5px !important;
  opacity: 0 !important;
  -webkit-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out; }
  .overlay.open div.iScrollVerticalScrollbar {
    opacity: 1 !important; }

div.iScrollIndicator {
  border: 0 none !important;
  border-radius: 5px !important; }

/* Demo Styles */
.irm-card {
  width: 100%;
  max-width: 800px;
  height: 640px;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 50px 0; }
  @media screen and (max-width: 1300px), screen and (max-height: 1100px) {
    .irm-card {
      max-width: 700px;
      height: 600px; } }
  @media screen and (max-height: 800px) {
    .irm-card {
      max-width: 500px;
      height: 400px; } }
  @media screen and (max-width: 900px) {
    .irm-card {
      width: 80%;
      height: 500px; } }
  @media screen and (max-width: 600px) {
    .irm-card {
      height: 400px; } }
  @media screen and (max-width: 420px) {
    .irm-card {
      width: 70%;
      height: 280px; } }

.irm-card-front {
  background: url("/img/case-studies/riverbed/webenertia_riverbed_grid_a.png?ver=2.5.5") no-repeat center center;
  background-size: 100%; }

.irm-card-back {
  background: url("/img/case-studies/riverbed/webenertia_riverbed_grid_b.png?ver=2.5.5") no-repeat center center;
  background-size: 100%; }

/* Card Flip */
.irm-card {
  position: relative; }
  .irm-card .irm-card-front,
  .irm-card .irm-card-back {
    height: 100%;
    width: 100%;
    overflow: hidden;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transition: -webkit-transform 1s;
    -ms-transition: -ms-transform 1s;
    transition: transform 1s;
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden; }
  .irm-card .irm-card-front {
    position: relative;
    z-index: 2;
    -webkit-transform: perspective(1000px) rotateX(0);
    -ms-transform: perspective(1000px) rotateX(0);
    transform: perspective(1000px) rotateX(0); }
  .irm-card .irm-card-back {
    position: absolute;
    top: 0;
    z-index: 1;
    -webkit-transform: perspective(1000px) rotateX(-180deg);
    -ms-transform: perspective(1000px) rotateX(-180deg);
    transform: perspective(1000px) rotateX(-180deg); }
  .irm-card.irm-flipped .irm-card-front {
    -webkit-transform: perspective(1000px) rotateX(180deg);
    -ms-transform: perspective(1000px) rotateX(180deg);
    transform: perspective(1000px) rotateX(180deg); }
  .irm-card.irm-flipped .irm-card-back {
    -webkit-transform: perspective(1000px) rotateX(0);
    -ms-transform: perspective(1000px) rotateX(0);
    transform: perspective(1000px) rotateX(0); }
  .irm-card.irm-flip-index .irm-card-front {
    z-index: 1; }
  .irm-card.irm-flip-index .irm-card-back {
    z-index: 2; }
  .irm-card.irm-flip-click, .irm-card.irm-flip-hover {
    cursor: pointer; }

.bg-video-component {
  position: relative;
  height: 80vh;
  max-height: 600px;
  box-sizing: border-box;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.3);
  z-index: 0; }
  .bg-video-component video {
    position: absolute;
    z-index: -2;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
    .ua-mobile .bg-video-component video {
      display: none; }
  .ua-mobile .bg-video-component.rvrbd {
    background: url("/img/case-studies/riverbed/video/webenertia-riverbed-video-poster.jpg?ver=2.5.5") no-repeat center;
    background-size: cover; }
  .ua-mobile .bg-video-component.acmtrx {
    background: url("/img/case-studies/acemetrix/video/video-bg-mbl.jpg?ver=2.5.5") no-repeat center;
    background-size: cover; }
  .bg-video-component .max-container.rvrbd {
    position: relative;
    height: 80vh;
    max-height: 760px;
    box-sizing: border-box; }
    .bg-video-component .max-container.rvrbd .txt-holder {
      position: absolute;
      bottom: 80px;
      left: 0; }
      .bg-video-component .max-container.rvrbd .txt-holder h3 {
        text-transform: uppercase;
        color: #ffffff;
        font-size: 70px; }
        .bg-video-component .max-container.rvrbd .txt-holder h3 span {
          font-size: 76px; }
        @media screen and (max-width: 1100px) {
          .bg-video-component .max-container.rvrbd .txt-holder h3 {
            font-size: 50px; }
            .bg-video-component .max-container.rvrbd .txt-holder h3 span {
              font-size: 54px; } }
        @media screen and (max-width: 900px) {
          .bg-video-component .max-container.rvrbd .txt-holder h3 {
            font-size: 40px; }
            .bg-video-component .max-container.rvrbd .txt-holder h3 span {
              font-size: 43px; } }
        @media screen and (max-width: 700px) {
          .bg-video-component .max-container.rvrbd .txt-holder h3 {
            font-size: 34px; }
            .bg-video-component .max-container.rvrbd .txt-holder h3 span {
              font-size: 36px; } }
  .bg-video-component .max-container.acmtrx {
    margin: 0 auto; }
    .bg-video-component .max-container.acmtrx .txt-holder {
      text-align: center; }
      @media screen and (max-width: 1300px) {
        .bg-video-component .max-container.acmtrx .txt-holder {
          margin: 0 40px; } }
      @media screen and (max-width: 980px) {
        .bg-video-component .max-container.acmtrx .txt-holder {
          text-align: center;
          max-width: 1000px;
          margin: 0 auto;
          position: absolute;
          top: 50%;
          width: 100%;
          -webkit-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          transform: translateY(-50%); } }
      @media screen and (max-width: 1000px) {
        .bg-video-component .max-container.acmtrx .txt-holder {
          padding: 80px 40px; } }
      @media screen and (max-width: 700px) {
        .bg-video-component .max-container.acmtrx .txt-holder {
          padding: 40px; } }
      .bg-video-component .max-container.acmtrx .txt-holder h2 {
        color: #ffffff;
        font-size: 60px;
        line-height: 1.1;
        margin-bottom: 10px; }
        @media screen and (max-width: 1100px) {
          .bg-video-component .max-container.acmtrx .txt-holder h2 {
            font-size: 50px; } }
        @media screen and (max-width: 900px) {
          .bg-video-component .max-container.acmtrx .txt-holder h2 {
            font-size: 40px; } }
        @media screen and (max-width: 700px) {
          .bg-video-component .max-container.acmtrx .txt-holder h2 {
            font-size: 34px; } }
      .bg-video-component .max-container.acmtrx .txt-holder p {
        color: #ffffff;
        font-size: 34px;
        margin-bottom: 0; }
        @media screen and (max-width: 1100px) {
          .bg-video-component .max-container.acmtrx .txt-holder p {
            font-size: 26px; } }
        @media screen and (max-width: 700px) {
          .bg-video-component .max-container.acmtrx .txt-holder p {
            font-size: 22px; } }
  @media screen and (max-width: 1000px) {
    .bg-video-component {
      max-height: 500px; }
      .bg-video-component .max-container.rvrbd {
        max-height: 500px; } }
  @media screen and (max-width: 676px) {
    .bg-video-component {
      max-height: 405px; }
      .bg-video-component .max-container.rvrbd {
        max-height: 405px; }
        .bg-video-component .max-container.rvrbd .txt-holder {
          bottom: 50px; } }

@media screen and (max-width: 767px) {
  body {
    font-size: 16px; } }

.irm-container-intro {
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  padding: 80px 0; }
  @media screen and (max-width: 1080px) {
    .irm-container-intro {
      margin: 0 40px; } }
  @media screen and (max-width: 960px) {
    .irm-container-intro {
      margin: 0 30px; } }
  @media screen and (max-width: 767px) {
    .irm-container-intro {
      margin: 0 20px; } }
  .irm-container-intro h2 {
    font-size: 35px;
    font-family: 'Source Sans Pro', sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    margin-bottom: 20px;
    line-height: 1.1; }
    @media screen and (max-width: 767px) {
      .irm-container-intro h2 {
        font-size: 28px; } }
  .irm-container-intro p {
    color: #5b7e96;
    font-size: 25px;
    font-family: 'Source Sans Pro', sans-serif;
    -webkit-font-smoothing: antialiased;
    line-height: 1.3; }
    @media screen and (max-width: 767px) {
      .irm-container-intro p {
        font-size: 20px; } }

a.irm-download-infographic {
  padding: 12px 30px;
  border: solid 1px #415464;
  border-radius: 40px;
  color: #415464;
  font-size: 24px;
  text-align: center;
  margin: 26px auto 0;
  text-decoration: none;
  text-transform: uppercase;
  display: block;
  max-width: 280px;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }
  @media screen and (max-width: 960px) {
    a.irm-download-infographic {
      font-size: 18px;
      padding: 8px 20px;
      max-width: 240px; } }
  a.irm-download-infographic:hover {
    background: #415464;
    color: #ffffff; }

/*
.centered {
  text-align: center;
}
*/
#irm-wrapper {
  overflow: hidden;
  position: relative; }

.irm-infographic {
  min-height: 400px;
  padding: 130px 0 150px;
  position: relative;
  overflow: hidden;
  display: none;
  background: #457c69;
  background: -moz-linear-gradient(-45deg, #457c69 1%, #286378 37%, #1d476f 67%, #1c294b 100%);
  background: -webkit-linear-gradient(-45deg, #457c69 1%, #286378 37%, #1d476f 67%, #1c294b 100%);
  background: linear-gradient(135deg, #457c69 1%, #286378 37%, #1d476f 67%, #1c294b 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#457c69', endColorstr='#1c294b',GradientType=1 );
  background-size: 100%;
  overflow: hidden; }
  .irm-infographic * {
    -webkit-font-smoothing: antialiased;
    font-family: "Source Sans Pro", sans-serif !important; }
  .irm-infographic video {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: .04; }
    .ua-mobile .irm-infographic video {
      display: none; }
  .irm-infographic .irm-container {
    margin: 0 auto;
    max-width: 1000px; }
    @media screen and (max-width: 640px) {
      .irm-infographic .irm-container {
        margin: 60px 0; } }
  .irm-infographic .irm-holder {
    width: 50%;
    margin: 0 auto;
    position: relative; }
    .irm-infographic .irm-holder img {
      width: 100%; }
    @media screen and (max-width: 640px) {
      .irm-infographic .irm-holder {
        width: 80%;
        max-width: 380px; } }
    .irm-infographic .irm-holder .center-sphere {
      position: absolute;
      top: 24%;
      left: 24%;
      width: 52%;
      height: 52%;
      border-radius: 50%;
      z-index: 3;
      background: #585858;
      background: -moz-radial-gradient(center, ellipse cover, #585858 0%, #2c2c2c 65%, #2c2c2c 65%);
      background: -webkit-radial-gradient(center, ellipse cover, #585858 0%, #2c2c2c 65%, #2c2c2c 65%);
      background: radial-gradient(ellipse at center, #585858 0%, #2c2c2c 65%, #2c2c2c 65%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#585858', endColorstr='#2c2c2c',GradientType=1 );
      -webkit-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out; }
      .irm-infographic .irm-holder .center-sphere:hover {
        background: #666666;
        /* Old browsers */
        background: -moz-radial-gradient(center, ellipse cover, #666666 0%, #2c2c2c 65%, #2c2c2c 65%);
        /* FF3.6-15 */
        background: -webkit-radial-gradient(center, ellipse cover, #666666 0%, #2c2c2c 65%, #2c2c2c 65%);
        /* Chrome10-25,Safari5.1-6 */
        background: radial-gradient(ellipse at center, #666666 0%, #2c2c2c 65%, #2c2c2c 65%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#666666', endColorstr='#2c2c2c',GradientType=1 );
        /* IE6-9 fallback on horizontal gradient */
        -moz-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2); }
        .irm-infographic .irm-holder .center-sphere:hover p.link:after {
          margin-left: 12px; }
      .irm-infographic .irm-holder .center-sphere p {
        color: #ffffff;
        text-transform: uppercase;
        text-align: center;
        font-size: 36px;
        font-weight: 700;
        line-height: 1;
        margin: 8px auto 0 auto;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        letter-spacing: -4px; }
        @media screen and (max-width: 1340px) {
          .irm-infographic .irm-holder .center-sphere p {
            font-size: 32px; } }
        @media screen and (max-width: 960px) {
          .irm-infographic .irm-holder .center-sphere p {
            font-size: 26px;
            letter-spacing: -3px;
            margin-top: 0; } }
        @media screen and (max-width: 767px) {
          .irm-infographic .irm-holder .center-sphere p {
            font-size: 22px; } }
        @media screen and (max-width: 479px) {
          .irm-infographic .irm-holder .center-sphere p {
            letter-spacing: -2px;
            font-size: 18px; } }
        .irm-infographic .irm-holder .center-sphere p.link {
          font-size: 16px;
          margin: 0;
          min-height: auto;
          position: relative;
          padding-right: 4%;
          letter-spacing: 0;
          color: #5fb4e5; }
          .irm-infographic .irm-holder .center-sphere p.link:after {
            position: absolute;
            content: "";
            width: 16px;
            height: 16px;
            background: url("/img/case-studies/riverbed/infographic/arrow-icons.png?ver=2.5.5") no-repeat 0 0;
            background-size: 400%;
            margin: 1% 0 0 6px;
            -webkit-transition: all 0.3s ease-out;
            transition: all 0.3s ease-out; }
            @media screen and (max-width: 767px) {
              .irm-infographic .irm-holder .center-sphere p.link:after {
                width: 14px;
                height: 14px; } }
            @media screen and (max-width: 479px) {
              .irm-infographic .irm-holder .center-sphere p.link:after {
                width: 12px;
                height: 12px; } }
          @media screen and (max-width: 767px) {
            .irm-infographic .irm-holder .center-sphere p.link {
              font-size: 14px;
              min-height: auto; } }
          @media screen and (max-width: 479px) {
            .irm-infographic .irm-holder .center-sphere p.link {
              font-size: 12px; } }
    .irm-infographic .irm-holder .bg-sphere {
      position: absolute;
      top: 5%;
      left: 5%;
      border-radius: 50%;
      width: 90%;
      height: 90%;
      opacity: .4;
      -webkit-animation: pulseMin 3s ease infinite;
      -moz-animation: pulseMin 3s ease infinite;
      animation: pulseMin 3s ease infinite;
      background: -moz-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0) 47%, rgba(0, 0, 0, 0.8) 100%);
      background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0) 47%, rgba(0, 0, 0, 0.8) 100%);
      background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0) 47%, rgba(0, 0, 0, 0.8) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=1 ); }
    .irm-infographic .irm-holder .connect-line {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 1px;
      width: 80%;
      margin-left: -40%;
      background: #ffffff;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      z-index: 2; }
      .irm-infographic .irm-holder .connect-line.scnd-line {
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg); }

.outer-sphere {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 28%;
  height: 28%;
  border-radius: 50%;
  z-index: 3;
  cursor: pointer;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  animation-iteration-count: 1;
  box-sizing: border-box; }
  .outer-sphere:hover {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
    -moz-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2); }
    .outer-sphere:hover .txt-holder {
      background: #ffffff; }
      .outer-sphere:hover .txt-holder > p {
        color: #252728; }
      .outer-sphere:hover .txt-holder .irm-popup > div {
        -moz-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2); }
        .outer-sphere:hover .txt-holder .irm-popup > div p.link:after {
          margin-left: 12px; }
  .outer-sphere.hovered {
    -webkit-transform: scale(1.02);
    -ms-transform: scale(1.02);
    transform: scale(1.02);
    -moz-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2); }
    .outer-sphere.hovered .txt-holder {
      background: #ffffff; }
      .outer-sphere.hovered .txt-holder > p {
        color: #252728; }
      .outer-sphere.hovered .txt-holder .irm-popup > div p.link:after {
        margin-left: 6px; }
  .outer-sphere.animation-none {
    animation-name: none !important; }
  .outer-sphere.top-left {
    top: 0;
    left: 0;
    background: #61bbe8;
    /* Old browsers */
    background: -moz-radial-gradient(center, ellipse cover, #61bbe8 1%, #4889af 64%);
    /* FF3.6-15 */
    background: -webkit-radial-gradient(center, ellipse cover, #61bbe8 1%, #4889af 64%);
    /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(ellipse at center, #61bbe8 1%, #4889af 64%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#61bbe8', endColorstr='#4889af',GradientType=1 );
    /* IE6-9 fallback on horizontal gradient */ }
    .outer-sphere.top-left:hover {
      background: #56bef2;
      /* Old browsers */
      background: -moz-radial-gradient(center, ellipse cover, #56bef2 1%, #2992cf 64%);
      /* FF3.6-15 */
      background: -webkit-radial-gradient(center, ellipse cover, #56bef2 1%, #2992cf 64%);
      /* Chrome10-25,Safari5.1-6 */
      background: radial-gradient(ellipse at center, #56bef2 1%, #2992cf 64%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#56bef2', endColorstr='#2992cf',GradientType=1 );
      /* IE6-9 fallback on horizontal gradient */ }
  .outer-sphere.top-right {
    top: 0;
    right: 0;
    left: auto;
    background: #f2621c;
    background: -moz-radial-gradient(center, ellipse cover, #f2621c 2%, #b94b15 56%);
    background: -webkit-radial-gradient(center, ellipse cover, #f2621c 2%, #b94b15 56%);
    background: radial-gradient(ellipse at center, #f2621c 2%, #b94b15 56%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2621c', endColorstr='#b94b15',GradientType=1 ); }
    .outer-sphere.top-right:hover {
      background: #ff5f0f;
      /* Old browsers */
      background: -moz-radial-gradient(center, ellipse cover, #ff5f0f 2%, #ce4500 60%);
      /* FF3.6-15 */
      background: -webkit-radial-gradient(center, ellipse cover, #ff5f0f 2%, #ce4500 60%);
      /* Chrome10-25,Safari5.1-6 */
      background: radial-gradient(ellipse at center, #ff5f0f 2%, #ce4500 60%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff5f0f', endColorstr='#ce4500',GradientType=1 );
      /* IE6-9 fallback on horizontal gradient */ }
  .outer-sphere.bottom-right {
    top: auto;
    bottom: 0;
    right: 0;
    left: auto;
    background: #58788f;
    background: -moz-radial-gradient(center, ellipse cover, #58788f 0%, #486173 57%);
    background: -webkit-radial-gradient(center, ellipse cover, #58788f 0%, #486173 57%);
    background: radial-gradient(ellipse at center, #58788f 0%, #486173 57%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#58788f', endColorstr='#486173',GradientType=1 ); }
    .outer-sphere.bottom-right:hover {
      background: #2681c2;
      /* Old browsers */
      background: -moz-radial-gradient(center, ellipse cover, #2681c2 0%, #1f699d 64%);
      /* FF3.6-15 */
      background: -webkit-radial-gradient(center, ellipse cover, #2681c2 0%, #1f699d 64%);
      /* Chrome10-25,Safari5.1-6 */
      background: radial-gradient(ellipse at center, #2681c2 0%, #1f699d 64%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2681c2', endColorstr='#1f699d',GradientType=1 );
      /* IE6-9 fallback on horizontal gradient */ }
  .outer-sphere.bottom-left {
    top: auto;
    bottom: 0;
    left: 0;
    background: #f7c216;
    /* Old browsers */
    background: -moz-radial-gradient(center, ellipse cover, #f7c216 1%, #bc8216 57%);
    /* FF3.6-15 */
    background: -webkit-radial-gradient(center, ellipse cover, #f7c216 1%, #bc8216 57%);
    /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(ellipse at center, #f7c216 1%, #bc8216 57%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7c216', endColorstr='#bc8216',GradientType=1 );
    /* IE6-9 fallback on horizontal gradient */ }
    .outer-sphere.bottom-left:hover {
      background: #ffc70f;
      /* Old browsers */
      background: -moz-radial-gradient(center, ellipse cover, #ffc70f 1%, #d28900 65%);
      /* FF3.6-15 */
      background: -webkit-radial-gradient(center, ellipse cover, #ffc70f 1%, #d28900 65%);
      /* Chrome10-25,Safari5.1-6 */
      background: radial-gradient(ellipse at center, #ffc70f 1%, #d28900 65%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffc70f', endColorstr='#d28900',GradientType=1 );
      /* IE6-9 fallback on horizontal gradient */ }
  .outer-sphere .txt-holder {
    background: #252728;
    padding: 7px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 114%;
    margin-left: -7%;
    border-radius: 2px;
    -webkit-transition: all 0.1s ease-out;
    transition: all 0.1s ease-out;
    box-sizing: border-box; }
    @media screen and (max-width: 479px) {
      .outer-sphere .txt-holder {
        width: 124%;
        margin-left: -12%; } }
    .outer-sphere .txt-holder p {
      color: #ffffff;
      text-transform: uppercase;
      text-align: center;
      font-size: 18px;
      line-height: 1;
      margin: 0 auto;
      letter-spacing: -2px;
      font-weight: 600; }
      @media screen and (max-width: 960px) {
        .outer-sphere .txt-holder p {
          font-size: 16px; } }
      @media screen and (max-width: 767px) {
        .outer-sphere .txt-holder p {
          font-size: 14px; } }
      @media screen and (max-width: 479px) {
        .outer-sphere .txt-holder p {
          font-size: 12px;
          letter-spacing: -1px;
          font-weight: 400; } }
    .outer-sphere .txt-holder .irm-popup {
      position: absolute;
      width: 114%; }
      .outer-sphere .txt-holder .irm-popup div {
        position: relative;
        border-radius: 6px;
        background: rgba(0, 0, 0, 0.7);
        text-align: center;
        width: 100%;
        padding: 16px 3% 22px;
        cursor: pointer;
        -webkit-transition: all 0.9s ease-out;
        transition: all 0.9s ease-out; }
        @media screen and (max-width: 479px) {
          .outer-sphere .txt-holder .irm-popup div {
            padding: 10px 2% 16px; } }
        .outer-sphere .txt-holder .irm-popup div:after {
          position: absolute;
          content: "";
          width: 0;
          height: 0; }
      .outer-sphere .txt-holder .irm-popup h2 {
        font-size: 46px;
        color: #ffffff;
        margin: 0;
        font-weight: 700;
        font-family: 'Source Sans Pro', sans-serif;
        -webkit-font-smoothing: antialiased;
        vertical-align: top;
        line-height: 1;
        position: relative; }
        @media screen and (max-width: 960px) {
          .outer-sphere .txt-holder .irm-popup h2 {
            font-size: 38px; } }
        @media screen and (max-width: 767px) {
          .outer-sphere .txt-holder .irm-popup h2 {
            font-size: 28px; } }
        @media screen and (max-width: 479px) {
          .outer-sphere .txt-holder .irm-popup h2 {
            font-size: 24px; } }
        .outer-sphere .txt-holder .irm-popup h2.sm-ttl {
          font-size: 42px;
          margin-bottom: 6px; }
          @media screen and (max-width: 960px) {
            .outer-sphere .txt-holder .irm-popup h2.sm-ttl {
              font-size: 32px; } }
          @media screen and (max-width: 767px) {
            .outer-sphere .txt-holder .irm-popup h2.sm-ttl {
              font-size: 24px; } }
          @media screen and (max-width: 479px) {
            .outer-sphere .txt-holder .irm-popup h2.sm-ttl {
              font-size: 22px; } }
        .outer-sphere .txt-holder .irm-popup h2 span {
          font-size: 30px;
          position: relative;
          top: -10px; }
          .outer-sphere .txt-holder .irm-popup h2 span.xelm {
            position: static;
            top: 6px; }
          @media screen and (max-width: 960px) {
            .outer-sphere .txt-holder .irm-popup h2 span {
              font-size: 26px; } }
          @media screen and (max-width: 767px) {
            .outer-sphere .txt-holder .irm-popup h2 span {
              font-size: 22px;
              min-height: 42px; } }
          @media screen and (max-width: 479px) {
            .outer-sphere .txt-holder .irm-popup h2 span {
              font-size: 18px; } }
      .outer-sphere .txt-holder .irm-popup p {
        font-weight: 700;
        letter-spacing: -1px;
        line-height: 1.2;
        margin-bottom: 20px;
        min-height: 63px; }
        @media screen and (max-width: 767px) {
          .outer-sphere .txt-holder .irm-popup p {
            margin-bottom: 10px;
            min-height: 42px; } }
        .outer-sphere .txt-holder .irm-popup p.link {
          font-size: 16px;
          margin: 0;
          min-height: auto;
          position: relative;
          padding-right: 16px; }
          .outer-sphere .txt-holder .irm-popup p.link:after {
            position: absolute;
            content: "";
            width: 16px;
            height: 16px;
            background: url("/img/case-studies/riverbed/infographic//arrow-icons.png?ver=2.5.5") no-repeat 0 0;
            background-size: 400%;
            margin: 2% 0 0 6px;
            -webkit-transition: all 0.3s ease-out;
            transition: all 0.3s ease-out; }
            @media screen and (max-width: 767px) {
              .outer-sphere .txt-holder .irm-popup p.link:after {
                width: 14px;
                height: 14px; } }
            @media screen and (max-width: 479px) {
              .outer-sphere .txt-holder .irm-popup p.link:after {
                width: 12px;
                height: 12px; } }
          @media screen and (max-width: 767px) {
            .outer-sphere .txt-holder .irm-popup p.link {
              font-size: 14px;
              min-height: auto; } }
          @media screen and (max-width: 479px) {
            .outer-sphere .txt-holder .irm-popup p.link {
              font-size: 12px; } }

.top-left .txt-holder .irm-popup {
  top: -200%;
  left: -140%; }
  @media screen and (max-width: 640px) {
    .top-left .txt-holder .irm-popup {
      top: -720%;
      left: -10%; } }
  .top-left .txt-holder .irm-popup p.link {
    color: #5fb4e5; }
  .top-left .txt-holder .irm-popup div:after {
    top: 34%;
    right: -15.4px;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    border-left: 16px solid rgba(0, 0, 0, 0.7); }
    @media screen and (max-width: 640px) {
      .top-left .txt-holder .irm-popup div:after {
        top: auto;
        bottom: -32px;
        left: 50%;
        margin-left: -15px;
        border-top: 16px solid rgba(0, 0, 0, 0.7);
        border-right: 16px solid transparent;
        border-left: 16px solid transparent; } }

.top-right .txt-holder .irm-popup {
  top: -200%;
  right: -140%; }
  @media screen and (max-width: 640px) {
    .top-right .txt-holder .irm-popup {
      top: -720%;
      left: -10%; } }
  .top-right .txt-holder .irm-popup p.link {
    color: #ff681d; }
    .top-right .txt-holder .irm-popup p.link:after {
      background: url(/img/case-studies/riverbed/infographic/arrow-icons.png?ver=2.5.5) no-repeat 29% 0;
      background-size: 400%; }
  .top-right .txt-holder .irm-popup div:after {
    top: 34%;
    left: -15.4px;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    border-right: 16px solid rgba(0, 0, 0, 0.7); }
    @media screen and (max-width: 640px) {
      .top-right .txt-holder .irm-popup div:after {
        top: auto;
        bottom: -32px;
        left: 50%;
        margin-left: -15px;
        border-top: 16px solid rgba(0, 0, 0, 0.7);
        border-right: 16px solid transparent;
        border-left: 16px solid transparent; } }

.bottom-right .txt-holder .irm-popup {
  bottom: -230%;
  right: -140%; }
  @media screen and (max-width: 640px) {
    .bottom-right .txt-holder .irm-popup {
      bottom: -700%;
      right: -10%; } }
  .bottom-right .txt-holder .irm-popup p.link {
    color: #5b7e96; }
    .bottom-right .txt-holder .irm-popup p.link:after {
      background: url(/img/case-studies/riverbed/infographic/arrow-icons.png?ver=2.5.5) no-repeat 100% 0;
      background-size: 400%; }
  .bottom-right .txt-holder .irm-popup div:after {
    top: 42%;
    left: -15.4px;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    border-right: 16px solid rgba(0, 0, 0, 0.7); }
    @media screen and (max-width: 640px) {
      .bottom-right .txt-holder .irm-popup div:after {
        top: -32px;
        left: 50%;
        margin-left: -15px;
        border-bottom: 16px solid rgba(0, 0, 0, 0.7);
        border-right: 16px solid transparent;
        border-left: 16px solid transparent; } }

.bottom-left .txt-holder .irm-popup {
  bottom: -230%;
  left: -140%; }
  @media screen and (max-width: 640px) {
    .bottom-left .txt-holder .irm-popup {
      bottom: -700%;
      left: -10%; } }
  .bottom-left .txt-holder .irm-popup p.link {
    color: #ffb81d; }
    .bottom-left .txt-holder .irm-popup p.link:after {
      background: url(/img/case-studies/riverbed/infographic/arrow-icons.png?ver=2.5.5) no-repeat 64% 0;
      background-size: 400%; }
  .bottom-left .txt-holder .irm-popup div:after {
    top: 42%;
    right: -15.4px;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    border-left: 16px solid rgba(0, 0, 0, 0.7); }
    @media screen and (max-width: 640px) {
      .bottom-left .txt-holder .irm-popup div:after {
        top: -32px;
        left: 50%;
        margin-left: -15px;
        border-bottom: 16px solid rgba(0, 0, 0, 0.7);
        border-right: 16px solid transparent;
        border-left: 16px solid transparent; } }

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 51%; }
  100% {
    background-position: 0% 50%; } }

@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 51%; }
  100% {
    background-position: 0% 50%; } }

@keyframes AnimationName {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 51%; }
  100% {
    background-position: 0% 50%; } }

.irm-overlay-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  display: none; }
  @media screen and (max-width: 1340px) {
    .irm-overlay-wrapper {
      background: rgba(0, 0, 0, 0.6); } }
  .irm-overlay-wrapper.irm-open {
    display: block; }
  .irm-overlay-wrapper .irm-overlay {
    position: relative;
    background: black;
    margin: 60px auto 0;
    border-radius: 6px;
    text-align: center;
    padding: 80px 7% 80px;
    max-width: 1180px;
    -webkit-transition: all 3s ease-in-out;
    transition: all 3s ease-in-out;
    min-height: 460px; }
    @media screen and (max-width: 1300px) {
      .irm-overlay-wrapper .irm-overlay {
        margin: 60px; } }
    @media screen and (max-width: 960px) {
      .irm-overlay-wrapper .irm-overlay {
        margin: 80px 40px;
        padding: 40px 5%;
        min-height: auto; } }
    @media screen and (max-width: 767px) {
      .irm-overlay-wrapper .irm-overlay {
        margin: 0; } }
    .irm-overlay-wrapper .irm-overlay .irm-overlay-header {
      border-bottom: solid 1px #707071;
      margin-bottom: 40px;
      padding-bottom: 30px; }
      @media screen and (max-width: 960px) {
        .irm-overlay-wrapper .irm-overlay .irm-overlay-header {
          margin-bottom: 20px;
          padding-bottom: 0; } }
      .irm-overlay-wrapper .irm-overlay .irm-overlay-header h2 {
        font-size: 36px;
        color: #ffffff;
        display: inline-block;
        vertical-align: middle;
        text-transform: uppercase;
        font-weight: 700;
        line-height: 1.1;
        margin-top: 20px; }
        @media screen and (max-width: 1340px) {
          .irm-overlay-wrapper .irm-overlay .irm-overlay-header h2 {
            font-size: 34px; } }
        @media screen and (max-width: 960px) {
          .irm-overlay-wrapper .irm-overlay .irm-overlay-header h2 {
            font-size: 26px; } }
        @media screen and (max-width: 767px) {
          .irm-overlay-wrapper .irm-overlay .irm-overlay-header h2 {
            font-size: 22px; } }
        .irm-overlay-wrapper .irm-overlay .irm-overlay-header h2.num {
          font-size: 110px;
          line-height: 1;
          margin: 0 6% 0 2%;
          position: relative; }
          @media screen and (max-width: 1340px) {
            .irm-overlay-wrapper .irm-overlay .irm-overlay-header h2.num {
              font-size: 90px; } }
          @media screen and (max-width: 960px) {
            .irm-overlay-wrapper .irm-overlay .irm-overlay-header h2.num {
              font-size: 70px; } }
          @media screen and (max-width: 767px) {
            .irm-overlay-wrapper .irm-overlay .irm-overlay-header h2.num {
              font-size: 50px; } }
          .irm-overlay-wrapper .irm-overlay .irm-overlay-header h2.num.sm-num {
            font-size: 62px;
            margin: 0 2%; }
            @media screen and (max-width: 1340px) {
              .irm-overlay-wrapper .irm-overlay .irm-overlay-header h2.num.sm-num {
                font-size: 50px; } }
            @media screen and (max-width: 1030px) {
              .irm-overlay-wrapper .irm-overlay .irm-overlay-header h2.num.sm-num {
                font-size: 40px; } }
            @media screen and (max-width: 767px) {
              .irm-overlay-wrapper .irm-overlay .irm-overlay-header h2.num.sm-num {
                font-size: 50px; } }
          .irm-overlay-wrapper .irm-overlay .irm-overlay-header h2.num span {
            font-size: 48px;
            position: absolute;
            top: 10px; }
            @media screen and (max-width: 1340px) {
              .irm-overlay-wrapper .irm-overlay .irm-overlay-header h2.num span {
                font-size: 36px; } }
            @media screen and (max-width: 960px) {
              .irm-overlay-wrapper .irm-overlay .irm-overlay-header h2.num span {
                font-size: 32px; } }
            @media screen and (max-width: 767px) {
              .irm-overlay-wrapper .irm-overlay .irm-overlay-header h2.num span {
                font-size: 22px; } }
        .irm-overlay-wrapper .irm-overlay .irm-overlay-header h2.title {
          max-width: 46%;
          text-align: left; }
          @media screen and (max-width: 767px) {
            .irm-overlay-wrapper .irm-overlay .irm-overlay-header h2.title {
              max-width: 100%;
              text-align: center; } }
      .irm-overlay-wrapper .irm-overlay .irm-overlay-header .outer-sphere {
        position: static;
        width: 130px;
        height: 130px;
        display: inline-block;
        vertical-align: top;
        margin: 0 4%;
        cursor: auto; }
        @media screen and (max-width: 960px) {
          .irm-overlay-wrapper .irm-overlay .irm-overlay-header .outer-sphere {
            width: 100px;
            height: 100px; } }
        @media screen and (max-width: 767px) {
          .irm-overlay-wrapper .irm-overlay .irm-overlay-header .outer-sphere {
            width: 80px;
            height: 80px;
            display: block; } }
        .irm-overlay-wrapper .irm-overlay .irm-overlay-header .outer-sphere:hover {
          -webkit-transform: scale(1.05);
          -ms-transform: scale(1.05);
          transform: scale(1.05);
          -moz-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2);
          -webkit-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2); }
          .irm-overlay-wrapper .irm-overlay .irm-overlay-header .outer-sphere:hover .txt-holder {
            background: #415464; }
            .irm-overlay-wrapper .irm-overlay .irm-overlay-header .outer-sphere:hover .txt-holder > p {
              color: #ffffff; }
            .irm-overlay-wrapper .irm-overlay .irm-overlay-header .outer-sphere:hover .txt-holder .irm-popup > div {
              -moz-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2);
              -webkit-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2);
              box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2); }
              .irm-overlay-wrapper .irm-overlay .irm-overlay-header .outer-sphere:hover .txt-holder .irm-popup > div p.link:after {
                margin-left: 12px; }
        .irm-overlay-wrapper .irm-overlay .irm-overlay-header .outer-sphere .txt-holder {
          background: #415464;
          border-radius: 0; }
          .irm-overlay-wrapper .irm-overlay .irm-overlay-header .outer-sphere .txt-holder p {
            font-size: 20px;
            font-weight: 700;
            letter-spacing: 0px; }
            @media screen and (max-width: 960px) {
              .irm-overlay-wrapper .irm-overlay .irm-overlay-header .outer-sphere .txt-holder p {
                font-size: 16px; } }
            @media screen and (max-width: 767px) {
              .irm-overlay-wrapper .irm-overlay .irm-overlay-header .outer-sphere .txt-holder p {
                font-size: 14px; } }
    .irm-overlay-wrapper .irm-overlay .irm-overlay-body p {
      color: #ffffff;
      font-size: 25px;
      line-height: 1.2;
      margin-bottom: 50px; }
      @media screen and (max-width: 1100px) {
        .irm-overlay-wrapper .irm-overlay .irm-overlay-body p {
          font-size: 20px; } }
      @media screen and (max-width: 960px) {
        .irm-overlay-wrapper .irm-overlay .irm-overlay-body p {
          font-size: 18px; } }
      @media screen and (max-width: 767px) {
        .irm-overlay-wrapper .irm-overlay .irm-overlay-body p {
          font-size: 18px; } }
    .irm-overlay-wrapper .irm-overlay .irm-overlay-body a {
      text-decoration: none;
      border: 0;
      outline: 0; }
    .irm-overlay-wrapper .irm-overlay .irm-overlay-body .document-col {
      display: inline-block;
      vertical-align: top;
      width: 80%;
      max-width: 330px;
      padding: 0 14px;
      text-align: left;
      cursor: pointer;
      margin-bottom: 20px; }
      .irm-overlay-wrapper .irm-overlay .irm-overlay-body .document-col img {
        max-width: 50px;
        margin-right: 16px;
        float: left; }
        @media screen and (max-width: 960px) {
          .irm-overlay-wrapper .irm-overlay .irm-overlay-body .document-col img {
            max-width: 40px; } }
        @media screen and (max-width: 767px) {
          .irm-overlay-wrapper .irm-overlay .irm-overlay-body .document-col img {
            max-width: 34px; } }
      .irm-overlay-wrapper .irm-overlay .irm-overlay-body .document-col p.link {
        color: #ff681d;
        font-size: 18px;
        text-transform: capitalize;
        font-weight: 600;
        line-height: 1.1;
        margin-bottom: 0;
        position: relative; }
        .irm-overlay-wrapper .irm-overlay .irm-overlay-body .document-col p.link:after {
          position: absolute;
          content: "";
          width: 16px;
          height: 16px;
          background: url("/img/case-studies/riverbed/infographic/arrow-icons.png?ver=2.5.5") no-repeat 0 0;
          background-size: 400%;
          margin: 2% 0 0 6px;
          -webkit-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out; }
          @media screen and (max-width: 767px) {
            .irm-overlay-wrapper .irm-overlay .irm-overlay-body .document-col p.link:after {
              width: 14px;
              height: 14px; } }
          @media screen and (max-width: 479px) {
            .irm-overlay-wrapper .irm-overlay .irm-overlay-body .document-col p.link:after {
              width: 12px;
              height: 12px; } }
        @media screen and (max-width: 960px) {
          .irm-overlay-wrapper .irm-overlay .irm-overlay-body .document-col p.link {
            font-size: 16px; } }
        @media screen and (max-width: 767px) {
          .irm-overlay-wrapper .irm-overlay .irm-overlay-body .document-col p.link {
            font-size: 14px; } }
      .irm-overlay-wrapper .irm-overlay .irm-overlay-body .document-col:hover p.link:after {
        margin-left: 12px; }

#irm-agility p.link {
  color: #5fb4e5; }

#irm-visibility p.link {
  color: #ff681d; }
  #irm-visibility p.link:after {
    background: url("/img/case-studies/riverbed/infographic/arrow-icons.png?ver=2.5.5") no-repeat 29% 0;
    background-size: 400%; }

#irm-performance p.link {
  color: #5b7e96; }
  #irm-performance p.link:after {
    background: url("/img/case-studies/riverbed/infographic/arrow-icons.png?ver=2.5.5") no-repeat 100% 0;
    background-size: 400%; }

#irm-security p.link {
  color: #ffb81d; }
  #irm-security p.link:after {
    background: url("/img/case-studies/riverbed/infographic/arrow-icons.png?ver=2.5.5") no-repeat 64% 0;
    background-size: 400%; }

.irm-close {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 30px;
  height: 30px;
  display: inline-block;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }
  .irm-close:hover {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg); }

.irm-close::before, .irm-close::after {
  content: '';
  position: absolute;
  height: 3px;
  width: 22px;
  background-color: #ffffff;
  top: 50%;
  left: 50%;
  border-radius: 2px; }

.irm-close::before {
  transform: translate(-50%, -50%) rotate(45deg); }

.irm-close::after {
  transform: translate(-50%, -50%) rotate(-45deg); }

.no-parallax {
  position: relative;
  z-index: 2; }

.parallax-index {
  z-index: 0; }

.case-study-couchbase-top {
  position: relative;
  height: 520px;
  color: #ffffff;
  overflow: hidden;
  z-index: 2; }
  .case-study-couchbase-top video {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  .case-study-couchbase-top .case-top-wrapper {
    padding: 142px 0 0; }
  .case-study-couchbase-top .case-logo-each {
    width: 370px; }
  @media screen and (max-width: 1023px) {
    .case-study-couchbase-top {
      height: 360px; }
      .case-study-couchbase-top .case-logo-each {
        width: 200px; }
      .case-study-couchbase-top .case-top-wrapper {
        padding: 122px 0 0; } }
  @media screen and (max-width: 767px) {
    .case-study-couchbase-top .case-top-wrapper {
      margin: 0 50px; }
    .case-study-couchbase-top video {
      height: 100%;
      width: auto; } }
  @media screen and (max-width: 520px) {
    .case-study-couchbase-top .case-top-wrapper {
      margin: 0 20px; } }

.full-width-slider.bg-white {
  background: #ffffff; }

.over-hidden {
  overflow: hidden; }

.full-width-slider .center-container {
  max-width: 1200px;
  margin: 0 auto; }

.full-width-slider .container .txt-holder {
  width: 80%;
  padding: 0 20px;
  margin: 0 auto;
  font-size: 16px;
  line-height: 24px;
  text-align: center; }
  .full-width-slider .container .txt-holder h4, .full-width-slider .container .txt-holder h2 {
    color: #707071;
    font-family: 'Poppins', serif;
    font-weight: 300;
    font-size: 26px;
    letter-spacing: -0.5px;
    line-height: 1.5;
    margin: 0 0 14px;
    padding-bottom: 0; }

.full-width-slider .item .centerd-container {
  max-width: 1240px;
  padding: 31px 20px 10px;
  margin: 0 auto;
  text-align: center; }
  .full-width-slider .item .centerd-container .iphone-grid {
    overflow: hidden;
    margin-top: 158px; }
  .full-width-slider .item .centerd-container .centered {
    margin: 0 auto; }
  .full-width-slider .item .centerd-container:after {
    content: '';
    clear: both;
    display: block; }
  .full-width-slider .item .centerd-container img {
    width: auto;
    max-width: 100%;
    height: auto;
    margin: 0;
    display: inline-block;
    vertical-align: top; }
  @media screen and (max-width: 1300px) {
    .full-width-slider .item .centerd-container .right-align {
      margin: 0 -40px 0 0;
      padding: 0 0 0 14px; } }
  @media screen and (max-width: 1023px) {
    .full-width-slider .item .centerd-container .right-align {
      margin: 0 -20px 0 0;
      padding: 0 0 0 20px; }
    .full-width-slider .item .centerd-container .iphone-grid {
      margin-top: 7%; } }
  @media screen and (max-width: 767px) {
    .full-width-slider .item .centerd-container .right-align {
      margin: 0;
      padding: 0 0 0 20px; } }

.gradient-block {
  overflow: hidden;
  background: #f98411;
  background: -moz-linear-gradient(top, #f98411 0%, #f57416 14%, #ee3d22 53%, #f98212 99%, #f18317 100%, #f18317 100%);
  background: -webkit-linear-gradient(top, #f98411 0%, #f57416 14%, #ee3d22 53%, #f98212 99%, #f18317 100%, #f18317 100%);
  background: linear-gradient(to bottom, #f98411 0%, #f57416 14%, #ee3d22 53%, #f98212 99%, #f18317 100%, #f18317 100%);
  position: relative;
  z-index: 2; }
  .gradient-block:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    border-bottom: 895px solid #ffffff;
    border-right: 110vw solid transparent; }
    @media screen and (max-width: 1023px) {
      .gradient-block:before {
        border-bottom: 650px solid #ffffff; } }
    @media screen and (max-width: 767px) {
      .gradient-block:before {
        border-bottom: 420px solid #ffffff;
        border-right: 205vw solid transparent; } }
  .gradient-block .large-block {
    padding: 159px 0 130px;
    color: #ffffff;
    position: relative;
    z-index: 3; }
    @media screen and (max-width: 1023px) {
      .gradient-block .large-block {
        padding: 100px 0; } }
    @media screen and (max-width: 767px) {
      .gradient-block .large-block {
        padding: 45px 0; } }
    .gradient-block .large-block img {
      float: right;
      width: auto;
      height: auto;
      margin: 0 -227px 0 0; }
      @media screen and (max-width: 1300px) {
        .gradient-block .large-block img {
          width: 90%; } }
      @media screen and (max-width: 1023px) {
        .gradient-block .large-block img {
          margin: 0 -20% 0 0;
          width: 80%; } }
      @media screen and (max-width: 767px) {
        .gradient-block .large-block img {
          float: none;
          width: auto;
          max-width: 100%;
          margin: 0 0 30px; } }
    .gradient-block .large-block:after {
      content: '';
      display: block;
      clear: both; }
    .gradient-block .large-block h2 {
      color: #ffffff; }
    .gradient-block .large-block .txt-holder {
      padding: 130px 50px 0 0; }
      @media screen and (max-width: 1300px) {
        .gradient-block .large-block .txt-holder {
          width: 300px; } }
      @media screen and (max-width: 1023px) {
        .gradient-block .large-block .txt-holder {
          width: 270px;
          padding: 0 30px 0 0; } }
      @media screen and (max-width: 767px) {
        .gradient-block .large-block .txt-holder {
          width: 100%;
          padding: 0;
          text-align: center; } }
  .gradient-block .txt-holder {
    overflow: hidden;
    font-size: 16px;
    line-height: 24px; }
    .gradient-block .txt-holder h4, .gradient-block .txt-holder h2 {
      margin: 0 0 14px; }
  .gradient-block .center-block {
    padding: 134px 0 120px;
    text-align: center;
    position: relative;
    z-index: 3; }
    .gradient-block .center-block img {
      display: inline-block;
      vertical-align: top; }
      @media screen and (max-width: 1300px) {
        .gradient-block .center-block img {
          max-width: 40%;
          height: auto; } }
    .gradient-block .center-block .txt-holder {
      position: absolute;
      left: 0;
      top: 38%;
      width: 28%;
      text-align: left; }
    @media screen and (max-width: 1023px) {
      .gradient-block .center-block {
        padding: 100px 0; }
        .gradient-block .center-block img {
          max-width: 36%; }
        .gradient-block .center-block .txt-holder {
          top: 24%; } }
    @media screen and (max-width: 767px) {
      .gradient-block .center-block {
        padding: 45px 0; }
        .gradient-block .center-block img {
          max-width: 50%;
          display: block;
          margin: 0 auto 20px; }
        .gradient-block .center-block .txt-holder {
          position: relative;
          width: 100%;
          text-align: center; } }

.paralax-block {
  height: 600px;
  position: relative;
  overflow: hidden;
  z-index: -1; }
  .paralax-block .bgcover {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; }
  @media screen and (max-width: 1023px) {
    .paralax-block {
      height: 500px; } }
  @media screen and (max-width: 767px) {
    .paralax-block {
      height: 350px; } }

.couchbase-paralax-text {
  background: #ffffff;
  position: relative;
  z-index: 2; }

.couchbase-container {
  padding: 96px 0 135px;
  font-size: 16px; }
  .couchbase-container h4, .couchbase-container h2 {
    line-height: 1.5;
    margin: 0 0 14px; }
  .couchbase-container .txt-holder {
    width: 80%;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding: 0;
    margin: 0 auto; }
  @media screen and (max-width: 767px) {
    .couchbase-container {
      padding: 45px 0 60px; } }

.gradient-block2 {
  background: #1e5799;
  background: -moz-linear-gradient(top, #1e5799 0%, #ee3e22 0%, #f98512 100%);
  background: -webkit-linear-gradient(top, #1e5799 0%, #ee3e22 0%, #f98512 100%);
  background: linear-gradient(to bottom, #1e5799 0%, #ee3e22 0%, #f98512 100%);
  color: #ffffff;
  position: relative;
  z-index: 2;
  padding: 0 0 50px; }
  .gradient-block2 h2 {
    color: #ffffff; }
  .gradient-block2 .top-image {
    position: relative; }
    .gradient-block2 .top-image img {
      display: block;
      margin: 0 auto;
      max-width: 100%; }

.couchbase-wireframes {
  overflow: hidden;
  margin: 0 -15px;
  -webkit-display: flex;
  display: flex;
  align-items: center;
  -webkit-flex-direction: row-reverse;
  flex-direction: row-reverse; }
  @media screen and (max-width: 767px) {
    .couchbase-wireframes {
      display: block; } }
  .couchbase-wireframes .col {
    float: left;
    width: 33.33%;
    padding: 0 15px; }
    .couchbase-wireframes .col img {
      max-width: 100%;
      height: auto; }
    @media screen and (max-width: 767px) {
      .couchbase-wireframes .col {
        width: 50%; }
        .couchbase-wireframes .col:first-child {
          text-align: center;
          width: 100%;
          padding: 0 15px 30px; } }
  .couchbase-wireframes .small-container {
    padding: 0 0 0 6%;
    text-align: left; }
    @media screen and (max-width: 767px) {
      .couchbase-wireframes .small-container {
        text-align: center; } }

.case-couchbase-prllx {
  background-image: url(/img/case-studies/couchbase/webenertia_couchbase_bg.jpg?ver=2.5.5);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  -webkit-background-size: cover;
  background-size: cover; }
  .case-couchbase-prllx.architecture {
    background-attachment: inherit; }

.item .case-study-couchbase-top {
  background: #000 url("/img/case-studies/couchbase/couchbase_banner_bg.jpg?ver=2.5.5") center;
  background-size: cover;
  height: auto; }
  .item .case-study-couchbase-top .case-top-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 80px 0 0 0;
    min-height: 360px;
    position: relative;
    -webkit-box-sizing: content-box;
    box-sizing: content-box; }
    @media screen and (max-width: 780px) {
      .item .case-study-couchbase-top .case-top-wrapper .case-banner-left-box {
        margin: 30px 0 0 0; } }
    @media screen and (max-width: 580px) {
      .item .case-study-couchbase-top .case-top-wrapper .case-banner-left-box {
        margin: 60px 0 0 0; } }
  .item .case-study-couchbase-top .case-logo-each {
    max-width: 236px; }
  .item .case-study-couchbase-top h1 {
    color: #ffffff;
    font-weight: 300 !important;
    font-size: 38px !important;
    line-height: 1 !important; }
    @media screen and (max-width: 980px) {
      .item .case-study-couchbase-top h1 {
        font-size: 30px !important; } }
  @media screen and (max-width: 767px) {
    .item .case-study-couchbase-top .case-top-wrapper {
      margin: 0 40px;
      padding: 50px 0 0; }
    .item .case-study-couchbase-top .case-logo-each {
      width: 190px; } }
  @media screen and (max-width: 520px) {
    .item .case-study-couchbase-top .case-top-wrapper {
      margin: 0 20px; } }

.parallax-remove .paralax-block {
  position: relative;
  z-index: 2; }
  .parallax-remove .paralax-block .bgcover {
    position: absolute;
    background-attachment: inherit; }

.case-study-mcafee-top {
  height: 497px;
  background: #fff;
  overflow: hidden;
  position: relative; }
  .case-study-mcafee-top:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff url("/img/case-studies/mcafee/we_mcafee_top.jpg?ver=2.5.5") 77% center no-repeat;
    background-size: contain; }
  .case-study-mcafee-top .case-top-wrapper {
    padding: 160px 0 0;
    position: relative;
    z-index: 2; }
  .case-study-mcafee-top h1 {
    color: #000 !important; }
  @media screen and (max-width: 1300px) {
    .case-study-mcafee-top .case-top-wrapper {
      margin: 0 40px; } }
  @media screen and (max-width: 767px) {
    .case-study-mcafee-top {
      height: 350px; }
      .case-study-mcafee-top:before {
        right: -44%;
        bottom: -20px;
        background-size: cover; }
      .case-study-mcafee-top .case-top-wrapper {
        padding: 139px 0 0; } }
  @media screen and (max-width: 520px) {
    .case-study-mcafee-top:before {
      right: -78%;
      bottom: -18px;
      top: -8px; }
    .case-study-mcafee-top .case-top-wrapper {
      margin: 0 20px; } }

.wrap-section-mcafee {
  position: relative;
  padding: 90px  0 58px;
  color: #ffffff;
  text-align: center; }
  @media screen and (min-width: 1000px) {
    .wrap-section-mcafee {
      height: 405px; } }
  @media screen and (min-width: 767px) and (max-width: 999px) {
    .wrap-section-mcafee {
      height: 350px; } }
  @media screen and (max-width: 1023px) {
    .wrap-section-mcafee {
      padding-top: 50px;
      padding-bottom: 35px; } }
  @media screen and (max-width: 767px) {
    .wrap-section-mcafee {
      padding-top: 45px;
      padding-bottom: 25px; } }
  .wrap-section-mcafee .video {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    overflow: hidden; }
  .wrap-section-mcafee .bg-video {
    background-size: cover;
    height: auto;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    width: auto;
    z-index: -1; }
  @media screen and (min-width: 768px) {
    .wrap-section-mcafee .container {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      z-index: 2;
      -ms-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); } }
  .wrap-section-mcafee .cta-component {
    position: relative;
    font-size: 18px;
    font-weight: 300;
    height: 100%;
    width: 100%;
    max-width: 840px;
    margin: 0 auto;
    padding: 10px 0 40px;
    z-index: 2;
    line-height: 1.667; }
    @media screen and (max-width: 1023px) {
      .wrap-section-mcafee .cta-component {
        padding: 20px 0 35px; } }
    .wrap-section-mcafee .cta-component h2 {
      font-size: 48px;
      font-weight: normal;
      color: #fff;
      line-height: 1.25;
      margin: 0;
      font-family: "proxima-nova"; }
      @media screen and (max-width: 1023px) {
        .wrap-section-mcafee .cta-component h2 {
          font-size: 32px; } }
      @media screen and (max-width: 767px) {
        .wrap-section-mcafee .cta-component h2 {
          font-size: 26px; } }
  .wrap-section-mcafee .btn {
    display: inline-block;
    vertical-align: top;
    font-size: 19px;
    line-height: 24px;
    border: 2px solid #fff;
    margin: 0;
    border-radius: 0;
    position: relative;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-align: center;
    overflow: hidden;
    min-width: 160px;
    padding: 13px 25px;
    white-space: normal;
    font-family: "proxima-nova", Helvetica, Arial, sans-serif !important;
    background: #fff;
    color: #c01818;
    text-decoration: none; }
    .wrap-section-mcafee .btn:before {
      content: "";
      line-height: 50px;
      position: absolute;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      top: 0;
      bottom: 0;
      opacity: 0;
      right: 17px;
      background: transparent url("/img/case-studies/mcafee/chevron-right.svg?ver=2.5.5") center center no-repeat;
      width: 10px;
      background-size: 10px auto; }
    .wrap-section-mcafee .btn:hover {
      text-decoration: none; }
      .wrap-section-mcafee .btn:hover:before {
        opacity: 1;
        right: 7px; }
    @media screen and (max-width: 1023px) {
      .wrap-section-mcafee .btn {
        min-width: 140px;
        font-size: 16px;
        padding: 10px 22px; } }
    @media screen and (max-width: 767px) {
      .wrap-section-mcafee .btn {
        min-width: 126px;
        font-size: 14px;
        line-height: 18px;
        border-width: 1px;
        padding: 8px 20px; }
        .wrap-section-mcafee .btn:before {
          background-size: 8px auto; } }

.full-width-content-lghtgry.mcafee-wrap {
  background: #ffffff; }
  .full-width-content-lghtgry.mcafee-wrap .case-rspnsv .animated-devices .devices-holder .tablet {
    bottom: 6%; }
  .full-width-content-lghtgry.mcafee-wrap .case-rspnsv .animated-devices .devices-holder .phone {
    bottom: 6%;
    left: 7%; }
  .full-width-content-lghtgry.mcafee-wrap .case-info-halfw {
    padding-top: 10px;
    line-height: 1.5; }
    .full-width-content-lghtgry.mcafee-wrap .case-info-halfw h4 {
      margin: 0 0 18px; }

.mcafee-wrap {
  position: relative; }
  .mcafee-wrap img {
    max-width: 100%;
    height: auto; }
  .mcafee-wrap .txt-holder {
    max-width: 80% !important;
    font-size: 16px;
    margin: 0 auto;
    line-height: 1.5;
    text-align: center; }
    @media screen and (max-width: 767px) {
      .mcafee-wrap .txt-holder {
        max-width: 100% !important; } }
    .mcafee-wrap .txt-holder h4 {
      margin: 0 0 14px; }
  .mcafee-wrap .rbrbd-wireframes {
    margin: 0 -2%; }
    .mcafee-wrap .rbrbd-wireframes:after {
      display: block;
      clear: both;
      content: ''; }
    .mcafee-wrap .rbrbd-wireframes .col {
      text-align: center; }
      .mcafee-wrap .rbrbd-wireframes .col:first-child {
        text-align: left; }
      .mcafee-wrap .rbrbd-wireframes .col:last-child {
        text-align: right; }
    .mcafee-wrap .rbrbd-wireframes .col img {
      max-width: 100%;
      width: auto;
      display: inline-block;
      vertical-align: top; }

.gallery-description {
  text-align: center;
  font-size: 30px;
  line-height: 1.5;
  padding: 63px 0 101px; }
  .gallery-description p {
    margin: 0; }
  @media screen and (max-width: 1023px) {
    .gallery-description {
      padding: 50px 0 80px;
      font-size: 24px; } }
  @media screen and (max-width: 767px) {
    .gallery-description {
      padding: 30px 0 45px;
      font-size: 18px; } }

.fade-gallery {
  position: static;
  margin: 0; }
  .fade-gallery img {
    width: 100%;
    height: auto; }
  .fade-gallery .slick-dots {
    left: 0;
    right: 0;
    bottom: 59px;
    text-align: center; }
    .fade-gallery .slick-dots li {
      width: auto;
      height: auto; }
    .fade-gallery .slick-dots li button {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #d5d7dc; }
      .fade-gallery .slick-dots li button:before {
        display: none; }
    .fade-gallery .slick-dots .slick-active button {
      background: #c01818; }
    @media screen and (max-width: 1023px) {
      .fade-gallery .slick-dots {
        bottom: 40px; } }
    @media screen and (max-width: 767px) {
      .fade-gallery .slick-dots {
        bottom: 22px; } }

.light-gradient {
  background: #bdbec3;
  background: -moz-linear-gradient(top, #bdbec3 0%, #eaecef 100%);
  background: -webkit-linear-gradient(top, #bdbec3 0%, #eaecef 100%);
  background: linear-gradient(to bottom, #bdbec3 0%, #eaecef 100%); }

.case-mcafee-prllx {
  background: url(/img/case-studies/mcafee/webenertia_mcafee_bg.png?ver=2.5.5) center;
  background-attachment: fixed;
  background-size: cover; }

.ua-mobile .case-mcafee-prllx {
  background-attachment: scroll; }

.item .case-study-mcafee-top {
  height: auto;
  background: #fff; }
  .item .case-study-mcafee-top:before {
    display: none; }
    @media screen and (max-width: 767px) {
      .item .case-study-mcafee-top:before {
        display: block; } }
  .item .case-study-mcafee-top .case-banner-right-box {
    bottom: 50%;
    -webkit-transform: translateY(50%);
    transform: translateY(50%); }
  .item .case-study-mcafee-top .case-top-wrapper {
    padding: 120px 0 0;
    min-height: 320px; }
    .item .case-study-mcafee-top .case-top-wrapper .case-banner-left-box {
      margin-top: 10px; }
    @media screen and (max-width: 767px) {
      .item .case-study-mcafee-top .case-top-wrapper {
        padding: 110px 0 0;
        min-height: 300px; } }

.no-parallax {
  position: relative;
  z-index: 2; }

.parallax-index {
  z-index: 0 !important; }

.cisco-z {
  z-index: 1; }
  .cisco-z.paralax {
    z-index: 0; }

.case-study-cisco-top {
  position: relative;
  height: 520px;
  color: #ffffff;
  overflow: hidden;
  z-index: 2;
  background: #001d34;
  background: -webkit-linear-gradient(left, #001d34 0%, #02223f 57%, #010b13 98%, #020a10 100%);
  background: -o-linear-gradient(left, #001d34 0%, #02223f 57%, #010b13 98%, #020a10 100%);
  background: -ms-linear-gradient(left, #001d34 0%, #02223f 57%, #010b13 98%, #020a10 100%);
  background: -moz-linear-gradient(left, #001d34 0%, #02223f 57%, #010b13 98%, #020a10 100%);
  background: linear-gradient(to right, #001d34 0%, #02223f 57%, #010b13 98%, #020a10 100%); }
  .case-study-cisco-top video {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: .09; }
  .case-study-cisco-top #bg-hex {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(/img/case-studies/cisco/CIS17002_Mobility_Infographic_R1.png);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover; }
  .case-study-cisco-top #animation_container {
    position: absolute;
    margin: auto;
    left: 38%;
    top: 50% !important;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%); }
  .case-study-cisco-top .case-top-wrapper {
    padding: 142px 0 0;
    z-index: 3; }
  .case-study-cisco-top .case-logo-each {
    width: 230px; }
  @media screen and (max-width: 1023px) {
    .case-study-cisco-top {
      height: 360px; }
      .case-study-cisco-top .case-logo-each {
        width: 170px; }
      .case-study-cisco-top .case-top-wrapper {
        padding: 95px 0 0;
        margin: 0 40px; }
      .case-study-cisco-top #animation_container {
        left: 33%; } }
  @media screen and (max-width: 767px) {
    .case-study-cisco-top .case-top-wrapper {
      margin: 0 50px; }
    .case-study-cisco-top video {
      height: 100%;
      width: auto; } }
  @media screen and (max-width: 520px) {
    .case-study-cisco-top .case-top-wrapper {
      margin: 0 20px; } }

.item .case-study-cisco-top {
  background: url(/img/case-studies/cisco/Cisco_Long_Thumbnail.jpg) no-repeat 50% 50%;
  background-size: cover;
  height: 440px; }
  @media screen and (max-width: 767px) {
    .item .case-study-cisco-top {
      height: 410px;
      background-position: 60% 50%; } }
  .item .case-study-cisco-top .case-top-wrapper {
    padding-top: 80px;
    min-height: 0; }
    @media screen and (max-width: 1023px) {
      .item .case-study-cisco-top .case-top-wrapper {
        padding-top: 109px; } }
    @media screen and (max-width: 767px) {
      .item .case-study-cisco-top .case-top-wrapper {
        padding-top: 62px; } }
    @media screen and (max-width: 580px) {
      .item .case-study-cisco-top .case-top-wrapper {
        padding-top: 37px; } }

.full-width-slider.bg-white {
  background: #fff;
  position: relative; }
  .full-width-slider.bg-white .maxwidth img {
    max-width: 78%; }
    @media screen and (max-width: 767px) {
      .full-width-slider.bg-white .maxwidth img {
        max-width: 100%; } }

.cisco-bg {
  position: relative; }
  .cisco-bg:after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    top: 100%;
    background: url(/img/case-studies/cisco/helix.png) 0 0 no-repeat;
    width: 370px;
    height: 1600px;
    margin-top: 50px; }
    @media screen and (max-width: 1300px) {
      .cisco-bg:after {
        left: -100px; } }
    @media screen and (max-width: 1023px) {
      .cisco-bg:after {
        display: none; } }

.full-width-slider .demo {
  position: relative;
  z-index: 2; }

.cisco-block {
  overflow: hidden; }
  .cisco-block video {
    display: block;
    margin: 0 -1%;
    width: 102%;
    height: auto; }
    @media screen and (max-width: 767px) {
      .cisco-block video {
        height: 350px;
        width: auto;
        margin: 0; } }
  .cisco-block .bg-animate-holder.small {
    color: #fff;
    text-align: center; }
    .cisco-block .bg-animate-holder.small .container {
      z-index: 4; }
    .cisco-block .bg-animate-holder.small h2 {
      color: #fff;
      font-size: 48px;
      line-height: 1.25;
      max-width: 840px;
      margin: 0 auto;
      font-weight: normal;
      font-family: "proxima-nova" !important; }
      @media screen and (max-width: 1023px) {
        .cisco-block .bg-animate-holder.small h2 {
          font-size: 32px; } }
      @media screen and (max-width: 767px) {
        .cisco-block .bg-animate-holder.small h2 {
          font-size: 26px; } }
    @media screen and (max-width: 1150px) {
      .cisco-block .bg-animate-holder.small video {
        height: 100%;
        width: auto;
        margin: 0; }
      .cisco-block .bg-animate-holder.small > .centered {
        width: auto; } }
    @media screen and (max-width: 767px) {
      .cisco-block .bg-animate-holder.small video {
        height: 116%;
        margin: -4% 0 0; }
      .cisco-block .bg-animate-holder.small .txt-holder.centered {
        height: auto; } }

.bg-animate-holder {
  overflow: hidden;
  width: 100%;
  background: #000;
  position: relative;
  height: 600px;
  display: block; }
  .bg-animate-holder .centered {
    position: absolute;
    top: 50%;
    width: 100%;
    height: auto;
    left: 50%;
    transform: translate(-50%, -50%); }
  @media screen and (max-width: 1023px) {
    .bg-animate-holder {
      height: 500px; } }
  @media screen and (max-width: 767px) {
    .bg-animate-holder {
      height: 350px; }
      .bg-animate-holder .centered {
        height: 100%;
        width: auto; } }

.cisco-vr {
  position: relative;
  padding: 140px 0 90px; }
  .cisco-vr:before {
    content: '';
    position: absolute;
    right: 0;
    z-index: 1;
    width: 543px;
    height: 470px;
    background: url(/img/case-studies/cisco/hex.png) center center no-repeat;
    top: -240px; }
  .cisco-vr .container {
    position: relative;
    z-index: 2;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
    flex-direction: row-reverse; }
  .cisco-vr .img {
    margin: 0 -80px 0 0; }
    .cisco-vr .img img {
      max-width: 100%;
      height: auto; }
    @media screen and (max-width: 1300px) {
      .cisco-vr .img {
        margin: 0; } }
  .cisco-vr .txt-holder {
    width: 415px;
    padding: 0 40px 0 0;
    margin: 0 auto;
    font-size: 16px;
    line-height: 24px;
    /*
		h2 {
			color: #707071;
		    font-family: 'Poppins', serif;
		    font-weight: 300;
		    font-size: 26px;
		    letter-spacing: -0.5px;
		    line-height: 1.5;
	    	margin: 0 0 14px;
			padding-bottom: 0;
		}
*/ }
  @media screen and (max-width: 767px) {
    .cisco-vr {
      padding: 40px 0; }
      .cisco-vr:before {
        display: none; }
      .cisco-vr .container {
        display: block; }
      .cisco-vr .txt-holder {
        width: 80%;
        padding: 20px 0 0;
        margin: 0 auto;
        text-align: center; } }

.cisco-paralax-text {
  background: #fff;
  position: relative;
  z-index: 2; }

.cisco-container {
  padding: 60px 0;
  font-size: 16px;
  /*
	h4, h2 {
		line-height: 1.5;
	   	margin: 0 0 14px;
	}
*/ }
  .cisco-container .txt-holder {
    width: 80%;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding: 0;
    margin: 0 auto; }
  @media screen and (max-width: 767px) {
    .cisco-container {
      padding: 40px 0; } }

.our-mission-container {
  width: 100%;
  background: #ffffff;
  min-height: 100px;
  max-width: 1200px;
  margin: 0 auto; }

.our-mission-content {
  text-align: center; }

.our-mission-container h3 {
  font-size: 72px;
  font-weight: bold;
  letter-spacing: -1.8px;
  line-height: 1;
  color: #707071; }

.our-mission-container h3 span {
  font-size: 72px;
  font-weight: bold;
  font-family: inherit;
  letter-spacing: -1.8px;
  line-height: 1;
  color: #252728; }

.our-mission-container h4 {
  font-size: 26px;
  font-weight: 300;
  margin-bottom: 39px; }

.our-core-values-container, .partnerships-certifications-container {
  background: #d6dede; }

.certification-grid {
  padding: 50px 0; }

.certification-grid h4 {
  font-weight: 300;
  color: #707071;
  font-size: 24px;
  letter-spacing: -0.5px;
  margin: 0 0 18px; }

.our-core-values-content h4 {
  font-size: 26px;
  font-weight: 300;
  margin-bottom: 48px; }

.certification-image {
  margin: 0 auto;
  max-width: 100%; }

.aligner {
  vertical-align: bottom !important;
  padding: 4px 30px 20px !important; }

@media screen and (max-width: 1200px) {
  .certification-grid {
    text-align: center;
    margin: 0 auto; } }

@media screen and (max-width: 1300px) {
  .our-mission-container {
    margin: 0;
    padding-left: 40px;
    padding-right: 40px; } }

@media screen and (max-width: 1150px) {
  .our-mission-container h3,
  .our-mission-container h3 span {
    font-size: 64px !important; } }

@media screen and (max-width: 1023px) {
  .our-mission-container h3,
  .our-mission-container h3 span {
    font-size: 50px !important; } }

@media screen and (max-width: 767px) {
  .our-mission-container h3,
  .our-mission-container h3 span {
    font-size: 34px !important; }
  .solution-grid .aligner {
    width: 48% !important; } }

@media screen and (max-width: 480px) {
  .our-mission-container {
    margin: 0 20px; }
  .our-mission-container h3,
  .our-mission-container h3 span {
    font-size: 28px !important; } }

@media screen and (max-width: 400px) {
  .our-mission-container h3,
  .our-mission-container h3 span {
    font-size: 22px !important; } }

.solution-grid {
  margin: 0 -15px 17px;
  font-size: 0;
  line-height: 0;
  letter-spacing: -4px;
  text-align: center; }
  .solution-grid .certification-box {
    display: inline-block;
    vertical-align: top;
    width: 18%;
    padding: 4px 0px 20px;
    margin: 0 6%;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    color: #707071;
    letter-spacing: 1px;
    line-height: 1.2; }
    @media screen and (max-width: 1023px) {
      .solution-grid .certification-box {
        width: 33.33%; } }
    @media screen and (max-width: 767px) {
      .solution-grid .certification-box {
        width: 55%;
        margin: 0 0%; } }

.years-number {
  position: absolute;
  font-weight: 700;
  letter-spacing: -5.1px;
  color: #a072a5;
  font-size: 300px;
  line-height: 0.8; }
  .years-number .number-counter {
    font-family: "Poppins", serif;
    line-height: 0; }

@media screen and (max-width: 1200px) {
  .years-number {
    font-size: calc( (100vw - 80px) / 4); } }

@media screen and (max-width: 780px) {
  .years-number {
    font-size: calc( (100vw - 80px) / 2); } }

@media screen and (max-width: 480px) {
  .years-number {
    font-size: calc( (100vw - 40px) / 2); } }

.sandhill-blue-bg {
  background-color: #053d55 !important; }
  .sandhill-blue-bg blockquote q, .sandhill-blue-bg blockquote cite {
    color: #ffffff; }

.sandhill-casestudy .v-middle-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3; }

.sandhill-casestudy .v-middle-inner {
  display: table;
  position: relative;
  text-align: center;
  width: 100%;
  height: 100%; }

.sandhill-casestudy .v-middle {
  display: table-cell;
  vertical-align: middle; }

.sandhill-casestudy .hero-banner {
  overflow: hidden;
  height: calc(100vh - 70px);
  max-height: 495px;
  left: 0;
  top: 0;
  right: 0; }
  @media (max-width: 812px) {
    .sandhill-casestudy .hero-banner {
      max-height: 450px; } }
  .sandhill-casestudy .hero-banner .inner-wrapper {
    position: relative;
    width: 100%;
    height: 100%; }
    .sandhill-casestudy .hero-banner .inner-wrapper.show {
      opacity: 1; }
    .sandhill-casestudy .hero-banner .inner-wrapper.hide {
      opacity: 0; }
  .sandhill-casestudy .hero-banner .background-image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url(/img/case-studies/sandhill/banner-bg-image.png) top no-repeat;
    background-size: 100% 100%; }
    .sandhill-casestudy .hero-banner .background-image .max-container {
      margin: 0 auto;
      height: 100%;
      position: relative; }
      @media (max-width: 1300px) {
        .sandhill-casestudy .hero-banner .background-image .max-container {
          padding: 0px 40px; } }
      @media (max-width: 780px) {
        .sandhill-casestudy .hero-banner .background-image .max-container {
          padding: 0px 20px; } }
    .sandhill-casestudy .hero-banner .background-image .background {
      position: absolute;
      z-index: 1;
      right: 15px;
      bottom: 0; }
    .sandhill-casestudy .hero-banner .background-image .background-1 {
      bottom: 0px;
      right: 20px;
      z-index: 1;
      width: auto;
      padding-bottom: 34px; }
      @media (min-width: 1201px) and (max-height: 790px) {
        .sandhill-casestudy .hero-banner .background-image .background-1 {
          padding-bottom: 32px; } }
      @media (min-width: 992px) and (max-width: 1200px) {
        .sandhill-casestudy .hero-banner .background-image .background-1 {
          right: 40px;
          padding-bottom: 32px; } }
      @media (min-width: 992px) and (max-width: 1024px) {
        .sandhill-casestudy .hero-banner .background-image .background-1 {
          right: 40px;
          padding-bottom: 33px; } }
      @media (min-width: 813px) and (max-width: 991px) {
        .sandhill-casestudy .hero-banner .background-image .background-1 {
          right: 40px;
          padding-bottom: 33px; } }
      @media (max-width: 991px) and (min-width: 813px) and (max-height: 400px) {
        .sandhill-casestudy .hero-banner .background-image .background-1 {
          padding-bottom: 20px; } }
      @media (min-width: 596px) and (max-width: 812px) {
        .sandhill-casestudy .hero-banner .background-image .background-1 {
          right: 35px;
          padding-bottom: 15px; } }
      @media (max-width: 812px) and (min-width: 596px) and (max-height: 400px) {
        .sandhill-casestudy .hero-banner .background-image .background-1 {
          padding-bottom: 19px; } }
      @media (max-width: 595px) {
        .sandhill-casestudy .hero-banner .background-image .background-1 {
          right: 30px;
          padding-bottom: 15px; } }
      @media (max-width: 595px) and (min-width: 481px) and (max-height: 400px) {
        .sandhill-casestudy .hero-banner .background-image .background-1 {
          padding-bottom: 15px; } }
      @media (max-width: 480px) {
        .sandhill-casestudy .hero-banner .background-image .background-1 {
          right: 30px;
          padding-bottom: 15px; } }
      .sandhill-casestudy .hero-banner .background-image .background-1 #hero_animation_container {
        height: 100% !important;
        float: right;
        margin: 0px; }
        @media (min-width: 1201px) and (max-height: 790px) {
          .sandhill-casestudy .hero-banner .background-image .background-1 #hero_animation_container {
            margin-right: -5px; } }
        @media (max-width: 991px) and (min-width: 813px) and (max-height: 400px) {
          .sandhill-casestudy .hero-banner .background-image .background-1 #hero_animation_container {
            margin-right: -7px; } }
        @media (max-width: 812px) and (min-width: 596px) and (max-height: 400px) {
          .sandhill-casestudy .hero-banner .background-image .background-1 #hero_animation_container {
            margin-right: -4px; } }
        @media (min-width: 596px) and (max-width: 812px) {
          .sandhill-casestudy .hero-banner .background-image .background-1 #hero_animation_container {
            margin-right: -5px; } }
        @media (max-width: 595px) {
          .sandhill-casestudy .hero-banner .background-image .background-1 #hero_animation_container {
            margin-right: -12px; } }
        @media (max-width: 595px) and (min-width: 481px) and (max-height: 400px) {
          .sandhill-casestudy .hero-banner .background-image .background-1 #hero_animation_container {
            margin-right: -12px; } }
        @media (max-width: 480px) {
          .sandhill-casestudy .hero-banner .background-image .background-1 #hero_animation_container {
            margin-right: -17px; } }
        .sandhill-casestudy .hero-banner .background-image .background-1 #hero_animation_container canvas#hero_canvas {
          max-width: 420px;
          width: auto !important;
          height: auto !important; }
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            .sandhill-casestudy .hero-banner .background-image .background-1 #hero_animation_container canvas#hero_canvas {
              width: 100% !important;
              height: 100% !important; } }
        .sandhill-casestudy .hero-banner .background-image .background-1 #hero_animation_container #hero_dom_overlay_container {
          height: 100% !important;
          width: 100% !important; }
      .sandhill-casestudy .hero-banner .background-image .background-1 .bg-left {
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: -1; }
        .sandhill-casestudy .hero-banner .background-image .background-1 .bg-left img {
          height: 100%;
          max-width: 100%;
          vertical-align: top;
          border: 0; }
      .sandhill-casestudy .hero-banner .background-image .background-1 .bg-right {
        position: absolute;
        bottom: 0;
        z-index: -1;
        right: -12px;
        width: calc(100% + 9px); }
        @media (min-width: 992px) and (max-width: 1200px) {
          .sandhill-casestudy .hero-banner .background-image .background-1 .bg-right {
            right: -13px;
            width: calc(100% + 9px); } }

@media all and (-ms-high-contrast: none) and (min-width: 1201px) and (max-height: 790px), (-ms-high-contrast: active) and (min-width: 1201px) and (max-height: 790px) {
  .sandhill-casestudy .hero-banner .background-image .background-1 .bg-right {
    right: -13px;
    width: calc(100% + 10px); } }
        @media (min-width: 992px) and (max-width: 1024px) {
          .sandhill-casestudy .hero-banner .background-image .background-1 .bg-right {
            right: -13px;
            width: calc(100% + 11px); } }
        @media (min-width: 813px) and (max-width: 991px) {
          .sandhill-casestudy .hero-banner .background-image .background-1 .bg-right {
            right: -12px;
            width: calc(100% + 9px); } }
        @media (min-width: 596px) and (max-width: 812px) {
          .sandhill-casestudy .hero-banner .background-image .background-1 .bg-right {
            right: -11px;
            width: calc(100% + 8px); } }
        @media (max-width: 595px) {
          .sandhill-casestudy .hero-banner .background-image .background-1 .bg-right {
            right: -22px;
            width: calc(100% + 19px); } }
        .sandhill-casestudy .hero-banner .background-image .background-1 .bg-right img {
          height: auto;
          max-width: 100%;
          vertical-align: top;
          border: 0; }
  .sandhill-casestudy .hero-banner .v-middle-wrapper {
    z-index: 0; }
  .sandhill-casestudy .hero-banner .v-middle-inner {
    text-align: left;
    z-index: 0;
    margin: 0px auto; }
    @media (max-width: 1300px) {
      .sandhill-casestudy .hero-banner .v-middle-inner {
        padding: 0px 40px; } }
    @media (max-width: 780px) {
      .sandhill-casestudy .hero-banner .v-middle-inner {
        padding: 0px 20px; } }
    @media (max-width: 480px) {
      .sandhill-casestudy .hero-banner .v-middle-inner .v-middle {
        vertical-align: top; } }
    .sandhill-casestudy .hero-banner .v-middle-inner .v-middle .content {
      max-width: 470px;
      width: 100%;
      margin-top: 6px;
      position: relative;
      float: left;
      /*
          a {
            margin: 9px 0 0 0;
            backface-visibility: hidden;
            opacity: 0;
            -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
            animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
            &.stop-animation {
              -webkit-animation-fill-mode: inherit;
              animation-fill-mode: inherit;
            }
            @media (max-width: 812px) {
              font-size: 14px;
            }
            @media (max-width: 480px) {
              margin-top: 0px;
            }
          }
*/ }
      @media (min-width: 596px) and (max-width: 812px) {
        .sandhill-casestudy .hero-banner .v-middle-inner .v-middle .content {
          max-width: 300px; } }
      @media (max-width: 595px) {
        .sandhill-casestudy .hero-banner .v-middle-inner .v-middle .content {
          max-width: 230px; } }
      @media (max-width: 480px) {
        .sandhill-casestudy .hero-banner .v-middle-inner .v-middle .content {
          margin-top: 60px;
          max-width: 100%; } }
      @media (max-width: 595px) and (orientation: landscape) {
        .sandhill-casestudy .hero-banner .v-middle-inner .v-middle .content {
          margin-top: 60px; } }
      .sandhill-casestudy .hero-banner .v-middle-inner .v-middle .content .animate {
        visibility: hidden;
        -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
        animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both; }
      .sandhill-casestudy .hero-banner .v-middle-inner .v-middle .content .banner-logo {
        display: block;
        /* float: left; */
        max-width: 370px; }
        @media (min-width: 813px) and (max-width: 991px) {
          .sandhill-casestudy .hero-banner .v-middle-inner .v-middle .content .banner-logo {
            max-width: 320px; } }
        @media (min-width: 596px) and (max-width: 812px) {
          .sandhill-casestudy .hero-banner .v-middle-inner .v-middle .content .banner-logo {
            max-width: 280px; } }
        @media (max-width: 595px) {
          .sandhill-casestudy .hero-banner .v-middle-inner .v-middle .content .banner-logo {
            max-width: 200px; } }
        .sandhill-casestudy .hero-banner .v-middle-inner .v-middle .content .banner-logo img {
          max-width: 100%;
          height: auto; }
      .sandhill-casestudy .hero-banner .v-middle-inner .v-middle .content h1 {
        padding: 10px 0 10px;
        backface-visibility: hidden;
        font-size: 38px;
        line-height: 1;
        font-weight: 300;
        text-align: left;
        /* float: left; */ }
        @media (min-width: 991px) and (max-width: 1024px) {
          .sandhill-casestudy .hero-banner .v-middle-inner .v-middle .content h1 {
            font-size: 36px; } }
        @media (min-width: 813px) and (max-width: 991px) {
          .sandhill-casestudy .hero-banner .v-middle-inner .v-middle .content h1 {
            font-size: 32px; } }
        @media (min-width: 596px) and (max-width: 812px) {
          .sandhill-casestudy .hero-banner .v-middle-inner .v-middle .content h1 {
            padding: 10px 0;
            font-size: 30px; } }
        @media (min-width: 481px) and (max-width: 595px) {
          .sandhill-casestudy .hero-banner .v-middle-inner .v-middle .content h1 {
            font-size: 26px; } }
        @media (max-width: 480px) {
          .sandhill-casestudy .hero-banner .v-middle-inner .v-middle .content h1 {
            font-size: 24px; } }
  .sandhill-casestudy .hero-banner #canvas, .sandhill-casestudy .hero-banner #dom_overlay_container, .sandhill-casestudy .hero-banner #animation_container {
    max-width: 100% !important;
    vertical-align: top; }

.ie9 .hero-banner .background-image .background {
  opacity: 1;
  visibility: visible; }

.ie9 .hero-banner #animation_container,
.ie9 .hero-banner #canvas,
.ie9 .hero-banner #dom_overlay_container {
  height: 633px !important; }

.touch .hero-banner .inner-wrapper {
  position: relative; }

.touch .hero-banner .background-image {
  opacity: 1 !important; }

.touch .hero-banner .v-middle-inner .v-middle .content {
  bottom: 0 !important; }
  .touch .hero-banner .v-middle-inner .v-middle .content h1, .touch .hero-banner .v-middle-inner .v-middle .content h4, .touch .hero-banner .v-middle-inner .v-middle .content a {
    opacity: 1 !important; }

/* Infographic slider*/
.sandhill-casestudy .infographic-slider {
  background: #fff;
  padding-top: 106px;
  padding-bottom: 50px;
  overflow: hidden; }
  @media (min-width: 992px) and (max-width: 1200px) {
    .sandhill-casestudy .infographic-slider {
      padding-top: 80px; } }
  @media (min-width: 813px) and (max-width: 991px) {
    .sandhill-casestudy .infographic-slider {
      padding-top: 50px; } }
  @media (min-width: 596px) and (max-width: 812px) {
    .sandhill-casestudy .infographic-slider {
      padding-top: 40px; } }
  @media (min-width: 481px) and (max-width: 595px) {
    .sandhill-casestudy .infographic-slider {
      padding-top: 30px;
      padding-bottom: 30px; } }
  @media (max-width: 480px) {
    .sandhill-casestudy .infographic-slider {
      padding-top: 30px;
      padding-bottom: 30px; } }
  .sandhill-casestudy .infographic-slider .slick-dots {
    height: 0;
    overflow: hidden; }
  .sandhill-casestudy .infographic-slider .slider-wrapper {
    position: relative;
    max-width: 1210px;
    padding: 0 20px;
    height: 622px;
    overflow: hidden;
    margin: 0 auto; }
    @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
      .sandhill-casestudy .infographic-slider .slider-wrapper {
        padding: 0; } }
    @media (min-width: 813px) and (max-width: 991px) {
      .sandhill-casestudy .infographic-slider .slider-wrapper {
        height: 600px; } }
    @media (min-width: 596px) and (max-width: 812px) {
      .sandhill-casestudy .infographic-slider .slider-wrapper {
        height: 560px; } }
    @media (min-width: 481px) and (max-width: 595px) {
      .sandhill-casestudy .infographic-slider .slider-wrapper {
        height: 510px; } }
    @media (max-width: 480px) {
      .sandhill-casestudy .infographic-slider .slider-wrapper {
        height: 480px; } }
    .sandhill-casestudy .infographic-slider .slider-wrapper:before {
      content: "";
      position: absolute;
      top: 0;
      left: calc(-100% + 20px);
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.75);
      z-index: 1; }
      @media (max-width: 1209px) {
        .sandhill-casestudy .infographic-slider .slider-wrapper:before {
          display: none; } }
    .sandhill-casestudy .infographic-slider .slider-wrapper:after {
      content: "";
      position: absolute;
      top: 0;
      right: calc(-100% + 20px);
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.75);
      z-index: 1; }
      @media (max-width: 1209px) {
        .sandhill-casestudy .infographic-slider .slider-wrapper:after {
          display: none; } }
    .sandhill-casestudy .infographic-slider .slider-wrapper .slide-wrapp {
      width: calc(100vw - 40px);
      max-width: 1170px;
      float: left;
      overflow: visible !important; }
      .sandhill-casestudy .infographic-slider .slider-wrapper .slide-wrapp.scale-child {
        position: relative;
        z-index: 1; }
      .sandhill-casestudy .infographic-slider .slider-wrapper .slide-wrapp:after {
        content: "";
        clear: both;
        display: block; }
    .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track {
      width: 10000000px; }
      @media (max-width: 812px) {
        .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track {
          width: 100%;
          display: block;
          position: relative; } }
      .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track:after {
        content: "";
        display: block;
        clear: both; }
      .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slick-list {
        float: left;
        height: 100%;
        width: 100%;
        overflow: visible;
        transform-style: preserve-3d; }
        .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slick-list:after {
          content: "";
          clear: both;
          display: block; }
      .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slick-track {
        float: left;
        height: 100%; }
      .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item {
        position: relative;
        float: left;
        width: 25%;
        height: 622px;
        border-right: 1px solid #fff;
        box-sizing: border-box;
        cursor: pointer; }
        @media (min-width: 992px) and (max-width: 1024px) {
          .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item {
            width: 33.33%; } }
        @media (min-width: 813px) and (max-width: 991px) {
          .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item {
            height: 600px;
            width: 33.33%; } }
        @media (min-width: 596px) and (max-width: 812px) {
          .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item {
            height: 560px;
            width: calc(50vw - 20px); } }
        @media (min-width: 481px) and (max-width: 595px) {
          .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item {
            height: 510px;
            width: calc(50vw - 20px); } }
        @media (max-width: 480px) {
          .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item {
            height: 480px;
            width: calc(100vw - 40px); } }
        .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item.scale-parent {
          position: relative;
          z-index: 2; }
        .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item.scale-parent-previous .content-wrapper .bg-img img {
          width: 100% !important;
          height: auto !important;
          margin-left: 0 !important; }
        .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item.scale-parent-previous .content-wrapper .bg-img > div canvas {
          width: 100% !important;
          height: auto !important;
          margin-left: 0 !important; }
        .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item.scale-down {
          position: relative; }
        .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item.scale-up {
          transform: translateY(-4.7%);
          transition: all, 0.4s, cubic-bezier(0, 0, 1, 1); }
          @media (min-width: 596px) and (max-width: 812px) {
            .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item.scale-up {
              height: 560px !important;
              transform: translateY(0%); } }
          @media (min-width: 481px) and (max-width: 595px) {
            .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item.scale-up {
              height: 510px !important;
              transform: translateY(0%); } }
          @media (max-width: 480px) {
            .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item.scale-up {
              height: 480px !important;
              transform: translateY(0%); } }
          .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item.scale-up .hover-active {
            transition: all 0.4s cubic-bezier(0, 0, 1, 1);
            border: 0 !important; }
            .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item.scale-up .hover-active:before {
              border-color: #fff !important; }
          .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item.scale-up .scale-item {
            border: 0 !important; }
            @media (max-width: 595px) {
              .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item.scale-up .scale-item {
                width: 100% !important;
                height: 100% !important;
                transform: translateY(0) translateX(0) !important; } }
            .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item.scale-up .scale-item:before {
              border-color: #fff !important;
              opacity: 1; }
        .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item.scale-parent-previous {
          transform: translateY(0%); }
          .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item.scale-parent-previous .hover-active {
            width: 100% !important;
            height: 100% !important;
            transform: translateX(0) !important;
            transition: all 0s cubic-bezier(0, 0.62, 1, 1);
            border: 0 !important; }
            .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item.scale-parent-previous .hover-active:before {
              border-color: #fff !important; }
          .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item.scale-parent-previous .scale-item {
            width: 100% !important;
            height: 100% !important;
            transform: translateX(0) !important;
            transition: all 0s cubic-bezier(0, 0.62, 1, 1);
            border: 0 !important; }
            .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item.scale-parent-previous .scale-item:before {
              border-color: #fff !important; }
        .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item.scale-parent-previous .slide-inner {
          width: 100% !important;
          height: 100% !important;
          transform: translateX(0) !important;
          transition: all 0s cubic-bezier(0, 0.62, 1, 1);
          border: 0 !important; }
          .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item.scale-parent-previous .slide-inner:before {
            border-color: #fff !important; }
        .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .pop-up-content {
          display: none; }
        .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 622px;
          overflow: hidden;
          background: #272e3e; }
          @media (min-width: 813px) and (max-width: 991px) {
            .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner {
              height: 600px; } }
          @media (min-width: 596px) and (max-width: 812px) {
            .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner {
              height: 560px; } }
          @media (min-width: 481px) and (max-width: 595px) {
            .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner {
              height: 510px; } }
          @media (max-width: 480px) {
            .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner {
              height: 480px; } }
          .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner.scale-item {
            margin-top: 0 !important;
            margin-left: 0 !important; }
          .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner.scale-item {
            z-index: 2;
            border: 1px solid #fff;
            height: 100%; }
            @media (max-width: 812px) {
              .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner.scale-item {
                width: 100% !important;
                height: 100% !important;
                transform: translateX(0%) !important; } }
            .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner.scale-item .content-wrapper {
              opacity: 0;
              transition: opacity 0s 0s cubic-bezier(0, 0.62, 1, 1); }
            .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner.scale-item .bg-img img {
              width: 100% !important;
              height: auto !important;
              margin-left: 0 !important; }
            .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner.scale-item .bg-img > div canvas {
              width: 100% !important;
              height: auto !important;
              margin-left: 0 !important; }
            .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner.scale-item .hover-content {
              opacity: 1; }
              .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner.scale-item .hover-content h5 {
                font-size: 24px;
                font-family: 'Poppins', serif;
                bottom: 300px;
                opacity: 1;
                line-height: 1.3;
                transform: translate(0, 0);
                transition: opacity .35s linear .9s, -webkit-transform .45s linear .9s;
                transition: opacity .35s linear .9s, transform .45s linear .9s;
                transition: opacity .35s linear .9s, transform .45s linear .9s, -webkit-transform .45s linear .9s; }
                @media (min-width: 992px) and (max-width: 1200px) {
                  .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner.scale-item .hover-content h5 {
                    font-size: 22px; } }
                @media (min-width: 813px) and (max-width: 991px) {
                  .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner.scale-item .hover-content h5 {
                    font-size: 20px; } }
                @media (min-width: 596px) and (max-width: 812px) {
                  .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner.scale-item .hover-content h5 {
                    font-size: 18px;
                    bottom: 350px !important; } }
                @media (min-width: 481px) and (max-width: 595px) {
                  .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner.scale-item .hover-content h5 {
                    font-size: 18px;
                    bottom: 350px !important; } }
                @media (max-width: 480px) {
                  .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner.scale-item .hover-content h5 {
                    font-size: 16px; } }
                .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner.scale-item .hover-content h5:after {
                  bottom: -34px;
                  transform: scaleX(1.8); }
              .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner.scale-item .hover-content p {
                opacity: 1;
                transform: translate(0, 0);
                transition: opacity .35s linear .9s, -webkit-transform .45s linear .9s;
                transition: opacity .35s linear .9s, transform .45s linear .9s;
                transition: opacity .35s linear .9s, transform .45s linear .9s, -webkit-transform .45s linear .9s; }
                @media (min-width: 596px) and (max-width: 812px) {
                  .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner.scale-item .hover-content p {
                    top: 270px !important; } }
                @media (min-width: 481px) and (max-width: 595px) {
                  .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner.scale-item .hover-content p {
                    top: 220px !important; } }
              .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner.scale-item .hover-content .bg-img {
                opacity: 1;
                transform: translate(0, 0);
                transition: opacity .35s linear .9s, -webkit-transform .45s linear .9s;
                transition: opacity .35s linear .9s, transform .45s linear .9s;
                transition: opacity .35s linear .9s, transform .45s linear .9s, -webkit-transform .45s linear .9s; }
                .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner.scale-item .hover-content .bg-img:before {
                  opacity: 0.7;
                  background: linear-gradient(270deg, transparent 0, transparent 18%, #000 75%, #000);
                  background: -moz-linear-gradient(270deg, transparent 0, transparent 18%, #000 75%, #000);
                  background: -webkit-linear-gradient(270deg, transparent 0, transparent 18%, #000 75%, #000); }
                  @media screen and (max-width: 767px) {
                    .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner.scale-item .hover-content .bg-img:before {
                      background: rgba(39, 46, 62, 0.8);
                      opacity: 1; } }
                  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
                    .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner.scale-item .hover-content .bg-img:before {
                      background: rgba(39, 46, 62, 0.8);
                      opacity: 1; } }
          .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner:before {
            content: "";
            position: absolute;
            border: 1px solid #4398b5;
            width: 0;
            height: 0;
            box-sizing: border-box;
            top: 20px;
            left: 20px;
            z-index: 2;
            width: calc(100% - 40px);
            height: calc(100% - 40px);
            opacity: 0;
            transition: all 0.4s cubic-bezier(0, 0.62, 1, 1); }
          .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner .bg-img {
            width: 100%;
            height: 100%;
            overflow: hidden; }
            .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner .bg-img:before {
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              background: rgba(39, 46, 62, 0.8);
              z-index: 1; }
            .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner .bg-img > div {
              width: 100% !important;
              height: 100% !important; }
            .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner .bg-img img {
              width: 100%;
              height: auto; }
          .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner .content-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            text-align: center;
            transition: opacity 0.4s 0.3s cubic-bezier(0, 0.62, 1, 1); }
            .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner .content-wrapper h5 {
              position: absolute;
              left: 0;
              bottom: 303px;
              font-family: 'Poppins', serif;
              color: #fff;
              font-size: 18px;
              font-weight: 500;
              width: 100%;
              padding: 0 20px;
              z-index: 1;
              transition: all, 0.2s, cubic-bezier(0, 0.62, 1, 1); }
              @media (min-width: 992px) and (max-width: 1200px) {
                .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner .content-wrapper h5 {
                  font-size: 22px; } }
              @media (min-width: 813px) and (max-width: 991px) {
                .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner .content-wrapper h5 {
                  font-size: 20px; } }
              @media (min-width: 596px) and (max-width: 812px) {
                .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner .content-wrapper h5 {
                  font-size: 18px; } }
              @media (min-width: 481px) and (max-width: 595px) {
                .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner .content-wrapper h5 {
                  font-size: 18px; } }
              @media (max-width: 480px) {
                .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner .content-wrapper h5 {
                  font-size: 16px; } }
              .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner .content-wrapper h5:after {
                content: '';
                position: absolute;
                bottom: -37px;
                left: calc(50% - 26px);
                width: 52px;
                height: 4px;
                background: #cf473a;
                transition: all 0.3s cubic-bezier(0, 0.62, 1, 1); }
            .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner .content-wrapper p {
              position: absolute;
              top: 415px;
              left: 0;
              padding: 0 40px;
              color: #fff;
              opacity: 0;
              line-height: 2;
              z-index: 1; }
              @media (min-width: 813px) and (max-width: 991px) {
                .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner .content-wrapper p {
                  top: 345px; } }
              @media (min-width: 596px) and (max-width: 812px) {
                .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner .content-wrapper p {
                  top: 270px !important; } }
              @media (min-width: 481px) and (max-width: 595px) {
                .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner .content-wrapper p {
                  top: 220px !important; } }
            .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner .content-wrapper .bg-img {
              position: relative;
              z-index: 1; }
            .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner .content-wrapper .canvas-container {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              z-index: 0;
              opacity: 0;
              transition: all .4s linear; }
              .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner .content-wrapper .canvas-container:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: rgba(39, 46, 62, 0.8);
                z-index: 1; }
              .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner .content-wrapper .canvas-container > div {
                width: 100% !important;
                height: 100% !important; }
          .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner .hover-content {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            text-align: center;
            opacity: 0; }
            .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner .hover-content h5 {
              position: absolute;
              left: 0;
              bottom: 303px;
              font-family: 'Poppins', serif;
              color: #fff;
              font-size: 18px;
              font-weight: 500;
              width: 100%;
              padding: 0 20px;
              z-index: 1;
              transform: translate(-10px, 0);
              opacity: 0; }
              @media (min-width: 992px) and (max-width: 1200px) {
                .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner .hover-content h5 {
                  font-size: 22px; } }
              @media (min-width: 813px) and (max-width: 991px) {
                .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner .hover-content h5 {
                  font-size: 20px; } }
              @media (min-width: 596px) and (max-width: 812px) {
                .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner .hover-content h5 {
                  font-size: 18px; } }
              @media (min-width: 481px) and (max-width: 595px) {
                .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner .hover-content h5 {
                  font-size: 18px; } }
              @media (max-width: 480px) {
                .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner .hover-content h5 {
                  font-size: 16px; } }
              .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner .hover-content h5:after {
                content: '';
                position: absolute;
                bottom: -37px;
                left: calc(50% - 26px);
                width: 52px;
                height: 4px;
                background: #cf473a; }
            .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner .hover-content p {
              position: absolute;
              top: 417px;
              left: 0;
              padding: 0 40px;
              color: #fff;
              opacity: 1;
              font-size: 14px;
              font-weight: 300;
              line-height: 1.9;
              z-index: 1;
              transform: translate(-10px, 0);
              opacity: 0; }
              @media (min-width: 813px) and (max-width: 991px) {
                .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner .hover-content p {
                  top: 375px;
                  font-size: 14px; } }
              @media (min-width: 596px) and (max-width: 812px) {
                .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner .hover-content p {
                  top: 345px;
                  font-size: 14px; } }
              @media (min-width: 481px) and (max-width: 595px) {
                .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner .hover-content p {
                  top: 295px;
                  font-size: 12px; } }
              @media (max-width: 480px) {
                .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner .hover-content p {
                  top: 255px;
                  font-size: 12px; } }
            .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner .hover-content .bg-img {
              transform: translate(0, 100px);
              opacity: 0; }
              .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner .hover-content .bg-img iframe {
                width: 100%;
                height: 100%;
                border: 0; }
        .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item:hover .slide-inner:before {
          opacity: 1; }
        .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item:hover .slide-inner .content-wrapper h5:after {
          transform: scaleX(1.8); }
        .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item:hover .slide-inner .content-wrapper .bg-img {
          opacity: 0; }
        .sandhill-casestudy .infographic-slider .slider-wrapper .slide-track .slide-item:hover .slide-inner .content-wrapper .canvas-container {
          opacity: 1; }
  .sandhill-casestudy .infographic-slider .slide-arrow {
    position: relative;
    width: calc(100% - 50px);
    max-width: 1170px;
    height: 34px;
    margin: 26px auto 0; }
    @media (max-width: 812px) {
      .sandhill-casestudy .infographic-slider .slide-arrow {
        display: none; } }
    .sandhill-casestudy .infographic-slider .slide-arrow:after {
      content: "";
      display: block;
      clear: both; }
    .sandhill-casestudy .infographic-slider .slide-arrow .next {
      position: absolute;
      top: 0;
      right: 0;
      width: 34px;
      height: 34px;
      border-radius: 50%;
      overflow: hidden;
      float: right; }
      .sandhill-casestudy .infographic-slider .slide-arrow .next.active {
        cursor: pointer; }
        .sandhill-casestudy .infographic-slider .slide-arrow .next.active img {
          width: 100%;
          height: 100%;
          transition: all 0.2s cubic-bezier(0, 0.62, 1, 1); }
          .sandhill-casestudy .infographic-slider .slide-arrow .next.active img:nth-child(1) {
            opacity: 1; }
          .sandhill-casestudy .infographic-slider .slide-arrow .next.active img:nth-child(2) {
            opacity: 0; }
      .sandhill-casestudy .infographic-slider .slide-arrow .next img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0; }
        .sandhill-casestudy .infographic-slider .slide-arrow .next img:nth-child(1) {
          opacity: 0; }
    .sandhill-casestudy .infographic-slider .slide-arrow .prev {
      position: absolute;
      top: 0;
      left: 0;
      width: 34px;
      height: 34px;
      border-radius: 50%;
      overflow: hidden;
      float: left; }
      .sandhill-casestudy .infographic-slider .slide-arrow .prev.active {
        cursor: pointer; }
        .sandhill-casestudy .infographic-slider .slide-arrow .prev.active img {
          width: 100%;
          height: 100%;
          transition: all 0.2s cubic-bezier(0, 0.62, 1, 1);
          transform: rotate(180deg); }
          .sandhill-casestudy .infographic-slider .slide-arrow .prev.active img:nth-child(1) {
            opacity: 1; }
          .sandhill-casestudy .infographic-slider .slide-arrow .prev.active img:nth-child(2) {
            opacity: 0; }
      .sandhill-casestudy .infographic-slider .slide-arrow .prev img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: rotate(180deg); }
        .sandhill-casestudy .infographic-slider .slide-arrow .prev img:nth-child(1) {
          opacity: 0; }
    .sandhill-casestudy .infographic-slider .slide-arrow .slide-nav {
      position: relative;
      margin: 0 auto;
      top: calc(50% - 5px);
      width: calc(100% - 136px);
      height: 100%; }
      @media (max-width: 812px) {
        .sandhill-casestudy .infographic-slider .slide-arrow .slide-nav {
          visibility: hidden; } }
      .sandhill-casestudy .infographic-slider .slide-arrow .slide-nav:after {
        content: "";
        position: absolute;
        top: 5px;
        left: 0px;
        width: 100%;
        height: 1px;
        background: #e6edef; }
      .sandhill-casestudy .infographic-slider .slide-arrow .slide-nav span {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 11px;
        height: 11px;
        float: left;
        z-index: 1;
        transition: all 0.4s cubic-bezier(0, 0.62, 1, 1);
        cursor: pointer; }
        .sandhill-casestudy .infographic-slider .slide-arrow .slide-nav span:nth-child(1) {
          margin-left: 0px; }
        .sandhill-casestudy .infographic-slider .slide-arrow .slide-nav span:last-child {
          margin-right: 0px; }
        .sandhill-casestudy .infographic-slider .slide-arrow .slide-nav span:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 11px;
          height: 11px;
          background: #e6edef;
          transform: rotate(45deg);
          transition: all 0.4s cubic-bezier(0, 0.62, 1, 1); }
        .sandhill-casestudy .infographic-slider .slide-arrow .slide-nav span:hover .pop-up {
          transform: translateY(0) scale(1); }
        .sandhill-casestudy .infographic-slider .slide-arrow .slide-nav span:hover:after {
          background: #4298b5; }
        .sandhill-casestudy .infographic-slider .slide-arrow .slide-nav span.active:after {
          background: #4298b5; }
        .sandhill-casestudy .infographic-slider .slide-arrow .slide-nav span .pop-up {
          position: absolute;
          top: -290px;
          left: calc(50% - 143px);
          width: 286px;
          height: 286px;
          border-radius: 50%;
          overflow: hidden;
          background: #4298b5;
          -webkit-transform: translatey(143px) scale(0);
          -moz-transform: translatey(143px) scale(0);
          -ms-transform: translatey(143px) scale(0);
          -o-transform: translatey(143px) scale(0);
          transform: translatey(143px) scale(0);
          transition: all 0.4s cubic-bezier(0, 0.62, 1, 1); }
          .sandhill-casestudy .infographic-slider .slide-arrow .slide-nav span .pop-up .bg-img {
            text-align: center; }
            .sandhill-casestudy .infographic-slider .slide-arrow .slide-nav span .pop-up .bg-img img {
              width: 100%; }
          .sandhill-casestudy .infographic-slider .slide-arrow .slide-nav span .pop-up h5 {
            font-size: 20px;
            font-weight: 900;
            text-align: center;
            padding: 0 40px;
            color: #fff; }

.ie10 .client-experience .infographic-slider .slider-wrapper .slide-track .slide-item .slide-inner.scale-item .bg-img canvas {
  height: 100% !important; }

/*
 * intro-with-image css
 */
.intro-with-image {
  z-index: 1;
  background: #fff;
  max-height: 1078px;
  overflow: hidden;
  position: relative; }
  @media (min-width: 1900px) {
    .intro-with-image {
      max-height: inherit; } }
  .intro-with-image .intro-video {
    position: relative; }
    @media (max-width: 1024px) {
      .intro-with-image .intro-video {
        height: 550px; } }
    @media screen and (min-width: 1400px) {
      .intro-with-image .intro-video .v-middle-wrapper {
        -webkit-transform: translateX(0%) translateY(10px);
        transform: translateX(0%) translateY(10px); } }
    @media (min-width: 992px) and (max-width: 1399px) {
      .intro-with-image .intro-video .v-middle-wrapper {
        -webkit-transform: translateX(0%) translateY(-10%);
        transform: translateX(0%) translateY(-10%); } }
    @media (min-width: 813px) and (max-width: 991px) {
      .intro-with-image .intro-video .v-middle-wrapper {
        /*
        -webkit-transform: translateX(0%) translateY(-12%);
        transform: translateX(0%) translateY(-12%);
*/ } }
    @media (min-width: 596px) and (max-width: 812px) {
      .intro-with-image .intro-video .v-middle-wrapper {
        /*
        -webkit-transform: translateX(0%) translateY(-12%);
        transform: translateX(0%) translateY(-12%);
*/ } }
    @media (min-width: 481px) and (max-width: 595px) {
      .intro-with-image .intro-video .v-middle-wrapper {
        /*
        -webkit-transform: translateX(0%) translateY(-14%);
        transform: translateX(0%) translateY(-14%);
*/ } }
    @media (max-width: 480px) {
      .intro-with-image .intro-video .v-middle-wrapper {
        /*
        -webkit-transform: translateX(0%) translateY(-16%);
        transform: translateX(0%) translateY(-16%);
*/ } }
    .intro-with-image .intro-video .v-middle-wrapper .v-middle {
      vertical-align: top;
      /*
        @media screen and (max-width: 812px) and (min-width: 596px){
          padding-top: 0;
        }
        @media screen and (max-width: 595px) and (min-width: 481px){
          padding-top: 44px;
        }
*/ }
      @media screen and (max-width: 1400px) and (min-width: 1201px) {
        .intro-with-image .intro-video .v-middle-wrapper .v-middle {
          padding-top: 0; } }
      @media screen and (max-width: 1200px) and (min-width: 992px) {
        .intro-with-image .intro-video .v-middle-wrapper .v-middle {
          padding-top: 0; } }
      @media screen and (max-width: 991px) and (min-width: 813px) {
        .intro-with-image .intro-video .v-middle-wrapper .v-middle {
          padding-top: 50px; } }
      @media screen and (max-width: 812px) and (min-width: 768px) {
        .intro-with-image .intro-video .v-middle-wrapper .v-middle {
          padding-top: 40px; } }
      @media screen and (max-width: 480px) {
        .intro-with-image .intro-video .v-middle-wrapper .v-middle {
          padding-top: 0; } }
    @media (max-width: 1024px) {
      .intro-with-image .intro-video .video-canvas {
        height: 100%;
        width: 100%; } }
    .intro-with-image .intro-video .location {
      position: absolute;
      background: #FFFFFF;
      max-width: 160px;
      min-width: 160px;
      margin: 4px auto 0;
      padding: 6px 10px 4px 36px;
      right: calc(50% - 160px);
      opacity: 0;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      display: none; }
      @media screen and (max-width: 812px) {
        .intro-with-image .intro-video .location:not(:root:root) {
          opacity: 1;
          backface-visibility: visible; } }
      @media (min-width: 481px) and (max-width: 595px) {
        .intro-with-image .intro-video .location {
          padding: 5px 10px 5px 35px; } }
      @media (max-width: 480px) {
        .intro-with-image .intro-video .location {
          max-width: 125px;
          min-width: 125px;
          padding: 5px 10px 5px 13px;
          right: calc(50% - 140px); } }
      .intro-with-image .intro-video .location p {
        text-align: left;
        color: #CF473A;
        font-size: 10px;
        font-weight: 700;
        line-height: 1.28;
        text-transform: uppercase;
        margin: 0; }
        @media (max-width: 480px) {
          .intro-with-image .intro-video .location p {
            font-size: 9px; } }
    .intro-with-image .intro-video video {
      position: relative;
      width: 100%;
      height: 100%;
      -webkit-appearance: none;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      z-index: -100;
      -webkit-transform: translateX(-50%) translateY(2%);
      transform: translateX(-50%) translateY(2%);
      /*
      @media  (max-width: 480px) {
        height: 550px;
        -webkit-transform: translateX(-50%) translateY(3%);
        transform: translateX(-50%) translateY(3%);
      }
*/ }
      .intro-with-image .intro-video video div {
        display: none; }
      .intro-with-image .intro-video video::-webkit-media-controls-start-playback-button {
        display: none !important;
        -webkit-appearance: none; }
      @media screen and (min-width: 1400px) {
        .intro-with-image .intro-video video {
          -webkit-transform: translateX(-50%) translateY(100px);
          transform: translateX(-50%) translateY(69px); } }
      @media (min-width: 813px) and (max-width: 991px) {
        .intro-with-image .intro-video video {
          height: 600px;
          -webkit-transform: translateX(-50%) translateY(2%);
          transform: translateX(-50%) translateY(2%); } }
      @media (min-width: 596px) and (max-width: 812px) {
        .intro-with-image .intro-video video {
          height: 550px;
          -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-35%); } }
      @media screen and (max-width: 595px) {
        .intro-with-image .intro-video video {
          height: 550px;
          -webkit-transform: translateX(-50%) translateY(2%);
          transform: translateX(-50%) translateY(-50%); } }
      @media screen and (-webkit-min-device-pixel-ratio: 0) {
        .intro-with-image .intro-video video {
          object-fit: cover; } }
    .intro-with-image .intro-video video[poster] {
      width: 100%; }
  .intro-with-image .content {
    width: 100%;
    max-width: 500px;
    padding: 0 20px;
    margin: auto; }
    .intro-with-image .content h2 {
      color: #707071;
      font-size: 24px;
      font-weight: 300;
      margin: 0; }
      @media (min-width: 813px) and (max-width: 991px) {
        .intro-with-image .content h2 {
          margin-bottom: 0; } }
      @media (max-width: 812px) {
        .intro-with-image .content h2 {
          margin-bottom: 0; } }
      @media (min-width: 596px) and (max-width: 812px) {
        .intro-with-image .content h2 {
          padding-top: 0; } }
      @media (min-width: 481px) and (max-width: 495px) {
        .intro-with-image .content h2 {
          padding-top: 0; } }
      @media (max-width: 480px) {
        .intro-with-image .content h2 {
          padding-top: 0; } }
    @media (min-width: 813px) and (max-width: 991px) {
      .intro-with-image .content p {
        margin-bottom: 0; } }
    @media (max-width: 812px) {
      .intro-with-image .content p {
        margin-top: 14px;
        margin-bottom: 0;
        line-height: 1.3; } }
    .intro-with-image .content .fade-in-down {
      visibility: hidden;
      visibility: visible\ie9;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both; }
  .intro-with-image.overlay {
    vertical-align: middle;
    padding-bottom: 142px;
    padding-top: inherit; }

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    visibility: visible;
    transform: none; } }

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    visibility: visible;
    transform: none; } }

@-moz-keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    visibility: visible;
    transform: none; } }

@-ms-keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    visibility: visible;
    transform: none; } }

.home .intro-with-image .bg-img img {
  display: none; }

.home .intro-with-image .intro-video {
  display: block;
  position: relative;
  padding-top: 140px; }
  @media (min-width: 1400px) {
    .home .intro-with-image .intro-video {
      padding-top: 0px; } }
  .home .intro-with-image .intro-video .v-middle-wrapper {
    z-index: 99; }

.statistics-circle-block {
  position: relative;
  overflow: hidden;
  background-image: url(/img/case-studies/sandhill/circle-block-bg-1.png);
  background-repeat: repeat; }
  .statistics-circle-block .col-wrapper {
    width: calc(100% + 76px);
    margin-left: -38px;
    text-align: center;
    color: white; }
    .statistics-circle-block .col-wrapper .col {
      width: calc(20% - 76px);
      max-width: 176px;
      margin: 0 38px;
      float: left;
      display: block; }
      @media (min-width: 992px) and (max-width: 1200px) {
        .statistics-circle-block .col-wrapper .col {
          width: 33.33%;
          margin: 0 25px;
          float: none;
          display: inline-block;
          vertical-align: top; } }
      @media (min-width: 813px) and (max-width: 991px) {
        .statistics-circle-block .col-wrapper .col {
          width: 33.33%;
          margin: 0 25px;
          float: none;
          display: inline-block;
          vertical-align: top; } }
      @media (min-width: 596px) and (max-width: 812px) {
        .statistics-circle-block .col-wrapper .col {
          width: 33.33%;
          margin: 0 25px;
          float: none;
          display: inline-block;
          vertical-align: top; } }
      @media (min-width: 481px) and (max-width: 595px) {
        .statistics-circle-block .col-wrapper .col {
          width: 50%;
          margin: 0 25px;
          float: none;
          display: inline-block;
          vertical-align: top; } }
      @media (max-width: 480px) {
        .statistics-circle-block .col-wrapper .col {
          width: 100%;
          float: none;
          margin: 0 auto 38px; } }
      .statistics-circle-block .col-wrapper .col .progress {
        width: 176px;
        height: 176px;
        background: none;
        margin: 0 auto;
        box-shadow: none;
        position: relative; }
        .statistics-circle-block .col-wrapper .col .progress:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: calc(50% - 89px);
          border: 22px solid #e4e7ea;
          opacity: 0.3;
          border-radius: 50%;
          z-index: 99; }
        .statistics-circle-block .col-wrapper .col .progress p {
          font-size: 45px;
          color: #ffffff;
          margin: 0 auto;
          font-weight: 700; }
        .statistics-circle-block .col-wrapper .col .progress .value {
          font-size: 45px;
          font-weight: 700;
          color: white;
          margin: 0 auto; }
        .statistics-circle-block .col-wrapper .col .progress .suffix-icon {
          color: #ffffff;
          margin-left: -11px;
          display: inline-block; }
        .statistics-circle-block .col-wrapper .col .progress .progress-inner {
          width: 50%;
          height: 100%;
          overflow: hidden;
          position: absolute;
          top: 0px;
          z-index: 1; }
        .statistics-circle-block .col-wrapper .col .progress .progress-bar {
          width: 100%;
          height: 100%;
          background: none;
          border-width: 22px;
          border-style: solid;
          position: absolute;
          top: 0; }
        .statistics-circle-block .col-wrapper .col .progress .progress-left {
          left: 0; }
          .statistics-circle-block .col-wrapper .col .progress .progress-left .progress-bar {
            left: 100%;
            border-top-right-radius: 100px;
            border-bottom-right-radius: 100px;
            border-left: 0;
            -webkit-transform-origin: center left;
            transform-origin: center left; }
        .statistics-circle-block .col-wrapper .col .progress .progress-right {
          right: 0; }
          @media (max-width: 1024px) {
            .statistics-circle-block .col-wrapper .col .progress .progress-right {
              right: 1px; } }
          .statistics-circle-block .col-wrapper .col .progress .progress-right .progress-bar {
            border-top-left-radius: 100px;
            border-bottom-left-radius: 100px;
            left: -100%;
            border-right: 0;
            -webkit-transform-origin: center right;
            transform-origin: center right;
            animation: loading-1 1.8s linear forwards; }
      .statistics-circle-block .col-wrapper .col .text-box p {
        color: #ffffff;
        font-size: 18px;
        line-height: 1.4;
        width: 100%;
        max-width: 150px;
        font-weight: 300;
        margin: 27px auto;
        padding: 10px 0 14px; }
        @media screen and (max-width: 991px) and (min-width: 813px) {
          .statistics-circle-block .col-wrapper .col .text-box p {
            margin: 10px auto 27px; } }
        @media screen and (max-width: 812px) {
          .statistics-circle-block .col-wrapper .col .text-box p {
            margin: 10px auto 27px; } }
  @media (min-width: 596px) {
    .statistics-circle-block .col.animated .progress.circle-one .progress-bar {
      border-color: white; }
    .statistics-circle-block .col.animated .progress.circle-one .progress-right .progress-bar {
      animation: loading-1 2s linear forwards; }
    .statistics-circle-block .col.animated .progress.circle-two .progress-bar {
      border-color: #ffffff; }
    .statistics-circle-block .col.animated .progress.circle-two .progress-left .progress-bar {
      animation: loading-2 1s linear forwards 1s; }
    .statistics-circle-block .col.animated .progress.circle-two .progress-right .progress-bar {
      animation: loading-2 1s linear forwards; }
    .statistics-circle-block .col.animated .progress.circle-three .progress-bar {
      border-color: #ffffff; }
    .statistics-circle-block .col.animated .progress.circle-three .progress-right .progress-bar {
      animation: loading-3 2s linear forwards; }
    .statistics-circle-block .col.animated .progress.circle-four .progress-bar {
      border-color: #ffffff; }
    .statistics-circle-block .col.animated .progress.circle-four .progress-right .progress-bar {
      animation: loading-4 2s linear forwards; }
    .statistics-circle-block .col.animated .progress.circle-five .progress-bar {
      border-color: #ffffff; }
    .statistics-circle-block .col.animated .progress.circle-five .progress-right .progress-bar {
      animation: loading-5 2s linear forwards; } }
  @media (max-width: 595px) {
    .statistics-circle-block .col .progress.circle-one .progress-bar {
      border-color: #ffffff; }
    .statistics-circle-block .col .progress.circle-one .progress-right .progress-bar {
      animation: loading-1 2s linear forwards; }
    .statistics-circle-block .col .progress.circle-two .progress-bar {
      border-color: #ffffff; }
    .statistics-circle-block .col .progress.circle-two .progress-left .progress-bar {
      animation: loading-2 1s linear forwards 1s; }
    .statistics-circle-block .col .progress.circle-two .progress-right .progress-bar {
      animation: loading-2 1s linear forwards; }
    .statistics-circle-block .col .progress.circle-three .progress-bar {
      border-color: #ffffff; }
    .statistics-circle-block .col .progress.circle-three .progress-right .progress-bar {
      animation: loading-3 2s linear forwards; }
    .statistics-circle-block .col .progress.circle-four .progress-bar {
      border-color: #ffffff; }
    .statistics-circle-block .col .progress.circle-four .progress-right .progress-bar {
      animation: loading-4 2s linear forwards; }
    .statistics-circle-block .col .progress.circle-five .progress-bar {
      border-color: #ffffff; }
    .statistics-circle-block .col .progress.circle-five .progress-right .progress-bar {
      animation: loading-5 2s linear forwards; } }
  .statistics-circle-block .small-container {
    text-align: center; }
    .statistics-circle-block .small-container h2, .statistics-circle-block .small-container p {
      color: #ffffff; }

@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg); } }

@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); } }

@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(80deg);
    transform: rotate(80deg); } }

@keyframes loading-4 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(100deg);
    transform: rotate(100deg); } }

@keyframes loading-5 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(26deg);
    transform: rotate(26deg); } }

.sandhill-casestudy .full-width-content {
  width: 100%;
  max-width: 1280px;
  margin: auto;
  /*
    img {
      width: auto;
      height: auto;
      max-width: 100%;
    }
*/ }
  .sandhill-casestudy .full-width-content .left-container {
    width: 35%;
    float: left; }
    @media (min-width: 992px) and (max-width: 1200px) {
      .sandhill-casestudy .full-width-content .left-container {
        width: 40%; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .sandhill-casestudy .full-width-content .left-container {
        width: 45%; } }
    @media (max-width: 767px) {
      .sandhill-casestudy .full-width-content .left-container {
        width: 100%;
        float: none; } }
    .sandhill-casestudy .full-width-content .left-container .txt-holder {
      width: 100%;
      padding: 0 14px 0px 0;
      text-align: left;
      margin-top: 172px; }
      @media (min-width: 768px) and (max-width: 991px) {
        .sandhill-casestudy .full-width-content .left-container .txt-holder {
          margin-top: 100px; } }
      @media (max-width: 767px) {
        .sandhill-casestudy .full-width-content .left-container .txt-holder {
          width: 80%;
          margin: 0 auto;
          text-align: center; } }
      @media (max-width: 595px) {
        .sandhill-casestudy .full-width-content .left-container .txt-holder {
          width: 100%;
          padding: 0; } }
  .sandhill-casestudy .full-width-content .right-container {
    width: 63%;
    padding-left: 7px;
    float: right; }
    .sandhill-casestudy .full-width-content .right-container img {
      float: right; }
    @media (min-width: 992px) and (max-width: 1200px) {
      .sandhill-casestudy .full-width-content .right-container {
        width: 60%; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .sandhill-casestudy .full-width-content .right-container {
        width: 55%; } }
    @media (max-width: 767px) {
      .sandhill-casestudy .full-width-content .right-container {
        width: 100%;
        padding: 20px;
        float: none; } }

.sandhill-casestudy .col-two-block .container {
  font-size: 0; }
  .sandhill-casestudy .col-two-block .container:after {
    content: '';
    display: block;
    clear: both; }

.sandhill-casestudy .col-two-block .left-block {
  display: inline-block;
  vertical-align: middle;
  width: 78%;
  margin: 0 0 0 -310px; }
  @media (min-width: 992px) and (max-width: 1200px) {
    .sandhill-casestudy .col-two-block .left-block {
      width: 78%;
      margin: 0 0 0 -230px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .sandhill-casestudy .col-two-block .left-block {
      width: 50%;
      margin: 0 0 0 -80px; } }
  @media (max-width: 767px) {
    .sandhill-casestudy .col-two-block .left-block {
      width: 100%;
      margin: 0 auto; } }
  @media (max-width: 595px) {
    .sandhill-casestudy .col-two-block .left-block {
      width: 100%;
      margin: 0 auto; } }
  @media (max-width: 480px) {
    .sandhill-casestudy .col-two-block .left-block {
      width: 100%;
      margin: 0 auto; } }
  .sandhill-casestudy .col-two-block .left-block img {
    max-width: 1277px;
    height: auto;
    margin: 0 0 0 -170px; }
    @media (min-width: 992px) and (max-width: 1200px) {
      .sandhill-casestudy .col-two-block .left-block img {
        max-width: 800px;
        margin: 0 auto; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .sandhill-casestudy .col-two-block .left-block img {
        max-width: 450px;
        height: auto;
        margin: 0 auto; } }
    @media (max-width: 767px) {
      .sandhill-casestudy .col-two-block .left-block img {
        max-width: 100%;
        height: auto;
        margin: 0 auto; } }

.sandhill-casestudy .col-two-block .right-block {
  width: 32%;
  margin: 0 0 0 150px;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  /*
      h4 {
        font-size: 26px;
        font-weight: 300;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        margin-top: 9px;
      }
*/ }
  @media (min-width: 992px) and (max-width: 1200px) {
    .sandhill-casestudy .col-two-block .right-block {
      margin: 0 0 0 100px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .sandhill-casestudy .col-two-block .right-block {
      width: 50%;
      margin: 0 0 0 60px;
      padding: 0 0 0 59px; } }
  @media (max-width: 767px) {
    .sandhill-casestudy .col-two-block .right-block {
      width: 100%;
      margin: 0 auto; } }
  @media (max-width: 595px) {
    .sandhill-casestudy .col-two-block .right-block {
      width: 100%;
      margin: 0 auto; } }
  @media (max-width: 480px) {
    .sandhill-casestudy .col-two-block .right-block {
      width: 100%;
      margin: 0 auto; } }

.sandhill-casestudy .infographic-quote-block {
  padding: 40px 0 80px; }
  @media (min-width: 768px) and (max-width: 991px) {
    .sandhill-casestudy .infographic-quote-block {
      padding: 0px 0 40px; } }
  @media (min-width: 596px) and (max-width: 767px) {
    .sandhill-casestudy .infographic-quote-block {
      padding: 0px 0 40px; } }
  @media (max-width: 595px) {
    .sandhill-casestudy .infographic-quote-block {
      padding: 10px 0 30px; } }
  .sandhill-casestudy .infographic-quote-block .content-wrap {
    width: 100%;
    max-width: 385px;
    margin: 0 auto;
    text-align: center; }
    .sandhill-casestudy .infographic-quote-block .content-wrap h2 {
      margin: 0 auto 14px;
      font-size: 24px;
      font-family: 'Poppins', serif;
      line-height: 2;
      padding: 0 0 0 0; }
    .sandhill-casestudy .infographic-quote-block .content-wrap p {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.77; }

.sandhill-casestudy .gradient-block {
  background-image: url(/img/case-studies/sandhill/circle-block-bg-1.png);
  background-position: 50% 50%;
  background-repeat: repeat; }
  @media (min-width: 1024px) and (max-width: 1200px) {
    .sandhill-casestudy .gradient-block:before {
      border-right: 117vw solid transparent; } }
  @media (min-width: 1500px) {
    .sandhill-casestudy .gradient-block:before {
      border-bottom: 800px solid #ffffff; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .sandhill-casestudy .gradient-block:before {
      border-bottom: 650px solid #ffffff;
      border-right: 114vw solid transparent; } }
  @media (min-width: 596px) and (max-width: 767px) {
    .sandhill-casestudy .gradient-block:before {
      border-bottom: 650px solid #ffffff;
      border-right: 136vw solid transparent; } }
  @media (max-width: 595px) {
    .sandhill-casestudy .gradient-block:before {
      border-bottom: 590px solid #ffffff;
      border-right: 168vw solid transparent; } }
  .sandhill-casestudy .gradient-block .large-block {
    padding-top: 71px;
    padding-bottom: 85px; }
    @media (max-width: 768px) {
      .sandhill-casestudy .gradient-block .large-block {
        padding: 40px 0 20px; } }
    @media screen and (max-width: 1200px) and (min-width: 1024px) {
      .sandhill-casestudy .gradient-block .large-block .txt-holder {
        padding: 50px 40px 0 0; } }
    @media screen and (max-width: 1023px) {
      .sandhill-casestudy .gradient-block .large-block .txt-holder {
        padding: 0 30px 30px 0; } }
    @media screen and (max-width: 767px) {
      .sandhill-casestudy .gradient-block .large-block .txt-holder {
        padding: 0 0 30px;
        text-align: center; } }
  .sandhill-casestudy .gradient-block .center-block {
    padding-top: 71px;
    padding-bottom: 85px; }
    @media screen and (max-width: 767px) {
      .sandhill-casestudy .gradient-block .center-block {
        padding: 20px 0 40px; } }
    @media screen and (max-width: 1200px) and (min-width: 1024px) {
      .sandhill-casestudy .gradient-block .center-block .txt-holder {
        padding: 50px 40px 0 0; } }
    @media screen and (max-width: 1023px) {
      .sandhill-casestudy .gradient-block .center-block .txt-holder {
        padding: 0 30px 30px 0; } }
    @media screen and (max-width: 767px) {
      .sandhill-casestudy .gradient-block .center-block .txt-holder {
        padding: 0 0 30px;
        text-align: center; } }
    @media screen and (max-width: 767px) {
      .sandhill-casestudy .gradient-block .center-block img {
        margin-bottom: 30px; } }

.sandhill-casestudy .blue-quote-block {
  padding: 105px 0 94px;
  background-color: #053d55; }
  @media screen and (max-width: 1200px) and (min-width: 992px) {
    .sandhill-casestudy .blue-quote-block {
      padding: 70px 0; } }
  @media screen and (max-width: 991px) and (min-width: 768px) {
    .sandhill-casestudy .blue-quote-block {
      padding: 60px 0; } }
  @media screen and (max-width: 767px) {
    .sandhill-casestudy .blue-quote-block {
      padding: 40px 0; } }
  .sandhill-casestudy .blue-quote-block p {
    font-size: 16px;
    font-weight: 100;
    margin: 0 auto;
    line-height: 1.3;
    color: #ffffff; }
  .sandhill-casestudy .blue-quote-block h4 {
    margin: 0 auto;
    font-style: italic;
    max-width: 1100px;
    font-weight: 100;
    font-size: 30px;
    line-height: 1.6;
    padding: 0 0 14px 0;
    color: #ffffff; }
    @media screen and (max-width: 991px) and (min-width: 768px) {
      .sandhill-casestudy .blue-quote-block h4 {
        font-size: 24px; } }
    @media screen and (max-width: 767px) {
      .sandhill-casestudy .blue-quote-block h4 {
        font-size: 22px; } }
    .sandhill-casestudy .blue-quote-block h4:before {
      content: "''";
      font-size: 32px;
      display: inline-block;
      line-height: 1;
      height: 20px;
      width: 20px;
      position: relative;
      top: 1px; }
    .sandhill-casestudy .blue-quote-block h4:after {
      content: "''";
      font-size: 32px;
      display: inline-block;
      line-height: 1;
      height: 20px;
      width: 20px;
      position: relative;
      top: 3px;
      left: -15px; }
  .sandhill-casestudy .blue-quote-block .content-wrap {
    text-align: center; }

.sandhill-casestudy .full-width-tchnlgy {
  float: left;
  width: 100%; }
  .sandhill-casestudy .full-width-tchnlgy .case-rspnsv {
    padding: 106px 0 33px; }
    @media screen and (max-width: 1200px) and (min-width: 992px) {
      .sandhill-casestudy .full-width-tchnlgy .case-rspnsv {
        padding: 70px 0 30px; } }
    @media screen and (max-width: 991px) and (min-width: 768px) {
      .sandhill-casestudy .full-width-tchnlgy .case-rspnsv {
        padding: 60px 0 30px; } }
    @media screen and (max-width: 767px) {
      .sandhill-casestudy .full-width-tchnlgy .case-rspnsv {
        padding: 40px 0 30px; } }
  @media screen and (max-width: 991px) and (min-width: 768px) {
    .sandhill-casestudy .full-width-tchnlgy .tchnlgy-wrapper {
      margin: 0; } }
  @media screen and (max-width: 767px) {
    .sandhill-casestudy .full-width-tchnlgy .tchnlgy-wrapper {
      margin: 0; } }
  @media screen and (max-width: 767px) {
    .sandhill-casestudy .full-width-tchnlgy .tchnlgy-wrapper:nth-child(6) .technology-box:last-child {
      border-bottom: none; } }
  .sandhill-casestudy .full-width-tchnlgy .technology-box {
    padding: 26px 25px 27px 23px;
    width: 20%; }
    @media screen and (max-width: 1200px) and (min-width: 992px) {
      .sandhill-casestudy .full-width-tchnlgy .technology-box {
        width: 30%; } }
    @media screen and (max-width: 991px) and (min-width: 768px) {
      .sandhill-casestudy .full-width-tchnlgy .technology-box {
        width: 30%;
        text-align: center;
        border-bottom: none;
        margin: 0; } }
    @media screen and (max-width: 767px) {
      .sandhill-casestudy .full-width-tchnlgy .technology-box {
        width: 100%;
        text-align: center;
        margin-bottom: 4px; } }
    .sandhill-casestudy .full-width-tchnlgy .technology-box img {
      margin: 0 auto 27px auto; }
      @media screen and (max-width: 991px) and (min-width: 768px) {
        .sandhill-casestudy .full-width-tchnlgy .technology-box img {
          float: none; } }
      @media screen and (max-width: 767px) {
        .sandhill-casestudy .full-width-tchnlgy .technology-box img {
          float: none; } }
    .sandhill-casestudy .full-width-tchnlgy .technology-box p {
      line-height: 1.5;
      margin-bottom: 0;
      float: left;
      width: 100%; }
      .sandhill-casestudy .full-width-tchnlgy .technology-box p span {
        display: block; }

.sandhill-casestudy .cs-nav,
.sandhill-casestudy .bottom-container {
  /*
    float: left;
    width: 100%;
*/ }

.sandhill-casestudy .case-prllx-container {
  padding: 48px 0 108px 0; }
  @media screen and (max-width: 991px) and (min-width: 768px) {
    .sandhill-casestudy .case-prllx-container {
      padding: 20px 0 80px 0; } }
  @media screen and (max-width: 767px) {
    .sandhill-casestudy .case-prllx-container {
      padding: 10px 0 70px 0; } }
  .sandhill-casestudy .case-prllx-container.sandhill-prllx {
    background-image: url(/img/case-studies/sandhill/webenertia_sandhill_bg.jpg?ver=2.2.5);
    background-position: 50% 50%;
    background-break: no-repeat;
    background-attachment: fixed;
    background-size: cover; }
  .sandhill-casestudy .case-prllx-container img {
    height: auto;
    max-width: 100%;
    vertical-align: top;
    border: 0;
    width: auto; }

.sandhill-casestudy .sandhill-frame {
  float: left;
  width: 100%;
  padding: 119px 0; }
  @media screen and (max-width: 1200px) and (min-width: 992px) {
    .sandhill-casestudy .sandhill-frame {
      padding: 80px 0; } }
  @media screen and (max-width: 991px) and (min-width: 768px) {
    .sandhill-casestudy .sandhill-frame {
      padding: 60px 0; } }
  @media screen and (max-width: 767px) {
    .sandhill-casestudy .sandhill-frame {
      padding: 40px 0; } }
  .sandhill-casestudy .sandhill-frame .sandhill-wireframes {
    float: left;
    width: 100%;
    display: table; }
    @media screen and (max-width: 767px) {
      .sandhill-casestudy .sandhill-frame .sandhill-wireframes {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -moz-box-align: start;
        -ms-flex-align: start;
        -webkit-align-items: flex-start;
        align-items: flex-start; } }
    .sandhill-casestudy .sandhill-frame .sandhill-wireframes .col-seven {
      width: 67%;
      display: table-cell;
      vertical-align: middle; }
      @media screen and (max-width: 991px) and (min-width: 768px) {
        .sandhill-casestudy .sandhill-frame .sandhill-wireframes .col-seven {
          width: 70%; } }
      @media screen and (max-width: 767px) {
        .sandhill-casestudy .sandhill-frame .sandhill-wireframes .col-seven {
          float: left;
          width: 100%;
          -webkit-box-ordinal-group: 2;
          -moz-box-ordinal-group: 2;
          -ms-flex-order: 2;
          -webkit-order: 2;
          order: 2; } }
      .sandhill-casestudy .sandhill-frame .sandhill-wireframes .col-seven img {
        max-width: 100%;
        height: auto; }
        @media screen and (max-width: 767px) {
          .sandhill-casestudy .sandhill-frame .sandhill-wireframes .col-seven img {
            margin: 0 auto 20px; } }
      .sandhill-casestudy .sandhill-frame .sandhill-wireframes .col-seven .col {
        float: left;
        margin-right: 55px; }
        @media screen and (max-width: 1200px) and (min-width: 992px) {
          .sandhill-casestudy .sandhill-frame .sandhill-wireframes .col-seven .col {
            margin-right: 28px; } }
        @media screen and (max-width: 991px) and (min-width: 768px) {
          .sandhill-casestudy .sandhill-frame .sandhill-wireframes .col-seven .col {
            margin-right: 20px;
            max-width: 214px; } }
        @media screen and (max-width: 767px) {
          .sandhill-casestudy .sandhill-frame .sandhill-wireframes .col-seven .col {
            width: 100%;
            max-width: 267px;
            margin: 0 auto;
            float: none; } }
        .sandhill-casestudy .sandhill-frame .sandhill-wireframes .col-seven .col:last-child {
          margin-right: 0; }
          @media screen and (max-width: 767px) {
            .sandhill-casestudy .sandhill-frame .sandhill-wireframes .col-seven .col:last-child {
              margin: 0 auto; } }
    .sandhill-casestudy .sandhill-frame .sandhill-wireframes .col-three {
      width: 33%;
      display: table-cell;
      vertical-align: middle; }
      @media screen and (max-width: 991px) and (min-width: 768px) {
        .sandhill-casestudy .sandhill-frame .sandhill-wireframes .col-three {
          width: 30%; } }
      @media screen and (max-width: 767px) {
        .sandhill-casestudy .sandhill-frame .sandhill-wireframes .col-three {
          float: left;
          width: 100%;
          -webkit-box-ordinal-group: 1;
          -moz-box-ordinal-group: 1;
          -ms-flex-order: 1;
          -webkit-order: 1;
          order: 1; } }
    .sandhill-casestudy .sandhill-frame .sandhill-wireframes .txt-holder {
      font-size: 16px;
      line-height: 24px;
      padding: 0 0 0 45px; }
      @media screen and (max-width: 991px) and (min-width: 768px) {
        .sandhill-casestudy .sandhill-frame .sandhill-wireframes .txt-holder {
          padding: 0 0 0 20px; } }
      @media screen and (max-width: 991px) and (min-width: 768px) {
        .sandhill-casestudy .sandhill-frame .sandhill-wireframes .txt-holder h3 {
          margin: 0 0 10px; } }
      @media screen and (max-width: 767px) {
        .sandhill-casestudy .sandhill-frame .sandhill-wireframes .txt-holder h3 {
          margin: 0 0 10px; } }
      @media screen and (max-width: 767px) {
        .sandhill-casestudy .sandhill-frame .sandhill-wireframes .txt-holder {
          padding: 0; } }

/*
.footer_section {
  float: left;
  width: 100%;
  position: relative;
  z-index: 2;
}
*/
.tabsets {
  padding: 0;
  margin: 0 -1px 50px 0;
  list-style: none;
  display: flex;
  width: 100%;
  z-index: 2;
  font-size: 12px;
  font-weight: bold;
  position: relative; }
  .tabsets li {
    flex: 1;
    padding: 0 1px 1px 0; }
  .tabsets a {
    display: flex;
    align-items: center;
    text-decoration: none;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    height: 60px;
    padding: 5px 10px;
    justify-content: center;
    transition: all 0.3s ease;
    background: #707071; }
    .tabsets a.active, .tabsets a:hover {
      background: #262728; }
  @media screen and (max-width: 767px) {
    .tabsets {
      flex-wrap: wrap;
      font-size: 11px; }
      .tabsets li {
        flex: 50%; }
      .tabsets a {
        height: 39px; } }

body.compensate-for-scrollbar {
  overflow: hidden; }

.fancybox-active {
  height: auto; }

.fancybox-is-hidden {
  left: -9999px;
  margin: 0;
  position: absolute !important;
  top: -9999px;
  visibility: hidden; }

.fancybox-container {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  height: 100%;
  left: 0;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  width: 100%;
  z-index: 99992; }

.fancybox-container * {
  box-sizing: border-box; }

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }

.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto; }

.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71); }

.fancybox-is-open .fancybox-bg {
  opacity: .87;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1); }

.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption,
.fancybox-navigation .fancybox-button {
  direction: ltr;
  opacity: 0;
  position: absolute;
  transition: opacity .25s, visibility 0s linear .25s;
  visibility: hidden;
  z-index: 99997; }

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption,
.fancybox-show-nav .fancybox-navigation .fancybox-button {
  opacity: 1;
  transition: opacity .25s, visibility 0s;
  visibility: visible; }

.fancybox-infobar {
  color: #ccc;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  text-align: center;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.fancybox-toolbar {
  right: 0;
  top: 0; }

.fancybox-stage {
  direction: ltr;
  overflow: visible;
  -webkit-transform: translate3d(0, 0, 0);
  z-index: 99994; }

.fancybox-is-open .fancybox-stage {
  overflow: hidden; }

.fancybox-slide {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: none;
  height: 100%;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  white-space: normal;
  width: 100%;
  z-index: 99994; }

.fancybox-slide::before {
  content: '';
  display: inline-block;
  height: 100%;
  margin-right: -.25em;
  vertical-align: middle;
  width: 0; }

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
  display: block; }

.fancybox-slide--next {
  z-index: 99995; }

.fancybox-slide--image {
  overflow: visible;
  padding: 44px 0; }

.fancybox-slide--image::before {
  display: none; }

.fancybox-slide--html {
  padding: 6px 6px 0 6px; }

.fancybox-slide--iframe {
  padding: 44px 44px 0; }

.fancybox-content {
  background: #fff;
  display: inline-block;
  margin: 0 0 6px 0;
  max-width: 100%;
  overflow: auto;
  padding: 0;
  padding: 24px;
  position: relative;
  text-align: left;
  vertical-align: middle; }

.fancybox-slide--image .fancybox-content {
  -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  margin: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99995; }

.fancybox-can-zoomOut .fancybox-content {
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.fancybox-can-zoomIn .fancybox-content {
  cursor: -webkit-zoom-in;
  cursor: zoom-in; }

.fancybox-can-drag .fancybox-content {
  cursor: -webkit-grab;
  cursor: grab; }

.fancybox-is-dragging .fancybox-content {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.fancybox-container [data-selectable='true'] {
  cursor: text; }

.fancybox-image,
.fancybox-spaceball {
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.fancybox-spaceball {
  z-index: 1; }

.fancybox-slide--html .fancybox-content {
  margin-bottom: 6px; }

.fancybox-slide--video .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--iframe .fancybox-content {
  height: 100%;
  margin: 0;
  overflow: visible;
  padding: 0;
  width: 100%; }

.fancybox-slide--video .fancybox-content {
  background: #000; }

.fancybox-slide--map .fancybox-content {
  background: #e5e3df; }

.fancybox-slide--iframe .fancybox-content {
  background: #fff;
  height: calc(100% - 44px);
  margin-bottom: 44px; }

.fancybox-video,
.fancybox-iframe {
  background: transparent;
  border: 0;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%; }

.fancybox-iframe {
  vertical-align: top; }

.fancybox-error {
  background: #fff;
  cursor: default;
  max-width: 400px;
  padding: 40px;
  width: 100%; }

.fancybox-error p {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0; }

/* Buttons */
.fancybox-button {
  background: rgba(30, 30, 30, 0.6);
  border: 0;
  border-radius: 0;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  outline: none;
  padding: 10px;
  transition: color .2s;
  vertical-align: top;
  width: 44px; }

.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
  color: #ccc; }

.fancybox-button:focus,
.fancybox-button:hover {
  color: #fff; }

.fancybox-button.disabled,
.fancybox-button.disabled:hover,
.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
  color: #888;
  cursor: default; }

.fancybox-button svg {
  display: block;
  overflow: visible;
  position: relative;
  shape-rendering: geometricPrecision; }

.fancybox-button svg path {
  fill: transparent;
  stroke: currentColor;
  stroke-linejoin: round;
  stroke-width: 3; }

.fancybox-button--play svg path:nth-child(2) {
  display: none; }

.fancybox-button--pause svg path:nth-child(1) {
  display: none; }

.fancybox-button--play svg path,
.fancybox-button--share svg path,
.fancybox-button--thumbs svg path {
  fill: currentColor; }

.fancybox-button--share svg path {
  stroke-width: 1; }

/* Navigation arrows */
.fancybox-navigation .fancybox-button {
  height: 38px;
  opacity: 0;
  padding: 6px;
  position: absolute;
  top: 50%;
  width: 38px; }

.fancybox-show-nav .fancybox-navigation .fancybox-button {
  transition: opacity .25s, visibility 0s, color .25s; }

.fancybox-navigation .fancybox-button::after {
  content: '';
  left: -25px;
  padding: 50px;
  position: absolute;
  top: -25px; }

.fancybox-navigation .fancybox-button--arrow_left {
  left: 6px; }

.fancybox-navigation .fancybox-button--arrow_right {
  right: 6px; }

/* Close button on the top right corner of html content */
.fancybox-close-small {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #555;
  cursor: pointer;
  height: 44px;
  margin: 0;
  padding: 6px;
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  z-index: 10; }

.fancybox-close-small svg {
  fill: transparent;
  opacity: .8;
  stroke: currentColor;
  stroke-width: 1.5;
  transition: stroke .1s; }

.fancybox-close-small:focus {
  outline: none; }

.fancybox-close-small:hover svg {
  opacity: 1; }

.fancybox-slide--image .fancybox-close-small,
.fancybox-slide--video .fancybox-close-small,
.fancybox-slide--iframe .fancybox-close-small {
  color: #ccc;
  padding: 5px;
  right: -12px;
  top: -44px; }

.fancybox-slide--image .fancybox-close-small:hover svg,
.fancybox-slide--video .fancybox-close-small:hover svg,
.fancybox-slide--iframe .fancybox-close-small:hover svg {
  background: transparent;
  color: #fff; }

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-drag .fancybox-close-small {
  display: none; }

/* Caption */
.fancybox-caption {
  bottom: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 25px 44px 25px 44px;
  right: 0; }

.fancybox-caption::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAEtCAQAAABjBcL7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAHRJREFUKM+Vk8EOgDAIQ0vj/3+xBw8qIZZueFnIKC90MCAI8DlrkHGeqqGIU6lVigrBtpCWqeRWoHDNqs0F7VNVBVxmHRlvoVqjaYkdnDIaivH2HqZ5+oZj3JUzWB+cOz4G48Bg+tsJ/tqu4dLC/4Xb+0GcF5BwBC0AA53qAAAAAElFTkSuQmCC);
  background-repeat: repeat-x;
  background-size: contain;
  bottom: 0;
  content: '';
  display: block;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: -25px;
  z-index: -1; }

.fancybox-caption::after {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  content: '';
  display: block;
  left: 44px;
  position: absolute;
  right: 44px;
  top: 0; }

.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
  color: #ccc;
  text-decoration: none; }

.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline; }

/* Loading indicator */
.fancybox-loading {
  -webkit-animation: fancybox-rotate .8s infinite linear;
  animation: fancybox-rotate .8s infinite linear;
  background: transparent;
  border: 6px solid rgba(100, 100, 100, 0.5);
  border-radius: 100%;
  border-top-color: #fff;
  height: 60px;
  left: 50%;
  margin: -30px 0 0 -30px;
  opacity: .6;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 60px;
  z-index: 99999; }

@-webkit-keyframes fancybox-rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes fancybox-rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

/* Transition effects */
.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1); }

/* transitionEffect: slide */
.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/* transitionEffect: fade */
.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1; }

/* transitionEffect: zoom-in-out */
.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: scale3d(1.5, 1.5, 1.5);
  transform: scale3d(1.5, 1.5, 1.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1); }

/* transitionEffect: rotate */
.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: rotate(-360deg);
  -ms-transform: rotate(-360deg);
  transform: rotate(-360deg); }

.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg); }

.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg); }

/* transitionEffect: circular */
.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0); }

/* transitionEffect: tube */
.fancybox-fx-tube.fancybox-slide--previous {
  -webkit-transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg); }

.fancybox-fx-tube.fancybox-slide--next {
  -webkit-transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg); }

.fancybox-fx-tube.fancybox-slide--current {
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1); }

/* Share */
.fancybox-share {
  background: #f4f4f4;
  border-radius: 3px;
  max-width: 90%;
  padding: 30px;
  text-align: center; }

.fancybox-share h1 {
  color: #222;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 20px 0; }

.fancybox-share p {
  margin: 0;
  padding: 0; }

.fancybox-share__button {
  border: 0;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 5px 10px 5px;
  min-width: 130px;
  padding: 0 15px;
  text-decoration: none;
  transition: all .2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap; }

.fancybox-share__button:visited,
.fancybox-share__button:link {
  color: #fff; }

.fancybox-share__button:hover {
  text-decoration: none; }

.fancybox-share__button--fb {
  background: #3b5998; }

.fancybox-share__button--fb:hover {
  background: #344e86; }

.fancybox-share__button--pt {
  background: #bd081d; }

.fancybox-share__button--pt:hover {
  background: #aa0719; }

.fancybox-share__button--tw {
  background: #1da1f2; }

.fancybox-share__button--tw:hover {
  background: #0d95e8; }

.fancybox-share__button svg {
  height: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 25px; }

.fancybox-share__button svg path {
  fill: #fff; }

.fancybox-share__input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  color: #5d5b5b;
  font-size: 14px;
  margin: 10px 0 0 0;
  outline: none;
  padding: 10px 15px;
  width: 100%; }

/* Thumbs */
.fancybox-thumbs {
  background: #fff;
  bottom: 0;
  display: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 2px 2px 4px 2px;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  width: 212px;
  z-index: 99995; }

.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden; }

.fancybox-show-thumbs .fancybox-thumbs {
  display: block; }

.fancybox-show-thumbs .fancybox-inner {
  right: 212px; }

.fancybox-thumbs > ul {
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  position: relative;
  white-space: nowrap;
  width: 100%; }

.fancybox-thumbs-x > ul {
  overflow: hidden; }

.fancybox-thumbs-y > ul::-webkit-scrollbar {
  width: 7px; }

.fancybox-thumbs-y > ul::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }

.fancybox-thumbs-y > ul::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px; }

.fancybox-thumbs > ul > li {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  cursor: pointer;
  float: left;
  height: 75px;
  margin: 2px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100px; }

.fancybox-thumbs-loading {
  background: rgba(0, 0, 0, 0.1); }

.fancybox-thumbs > ul > li {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }

.fancybox-thumbs > ul > li:before {
  border: 4px solid #4ea7f9;
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 99991; }

.fancybox-thumbs .fancybox-thumbs-active:before {
  opacity: 1; }

/* Styling for Small-Screen Devices */
@media all and (max-width: 800px) {
  .fancybox-thumbs {
    width: 110px; }
  .fancybox-show-thumbs .fancybox-inner {
    right: 110px; }
  .fancybox-thumbs > ul > li {
    max-width: calc(100% - 10px); } }

.case-study-riverbed2-top {
  position: relative;
  overflow: hidden;
  background: url(/img/case-studies/riverbed_re_brand/webenertia_riverbed_hero-board.jpg?ver=2.5.5) no-repeat 50% 50%;
  background-size: cover; }
  .case-study-riverbed2-top .case-top-wrapper {
    height: 480px;
    min-height: 200px;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    padding: 20px 0; }
  .case-study-riverbed2-top .case-banner-left-box {
    display: block; }
  .case-study-riverbed2-top .case-banner-right-box img {
    width: auto;
    max-width: 100%;
    margin: 0 0 0 auto; }
  @media only screen and (min-width: 1024px) and (max-height: 690px) {
    .case-study-riverbed2-top .case-top-wrapper {
      height: 360px; } }
  @media screen and (max-width: 1023px) {
    .case-study-riverbed2-top .case-top-wrapper {
      height: 320px;
      margin: 0 40px; } }
  @media screen and (max-width: 520px) {
    .case-study-riverbed2-top .case-top-wrapper {
      margin: 0 20px; } }

.rvrb-row {
  padding: 12px 0 30px;
  position: relative;
  margin: 0 0 83px; }
  @media screen and (max-width: 767px) {
    .rvrb-row {
      margin: 0 0 40px; } }
  .rvrb-row p {
    font-family: 'Poppins', serif;
    font-size: 26px;
    font-weight: 100;
    letter-spacing: -0.5px;
    color: #707071;
    margin: 0 0 44px; }
    @media screen and (max-width: 767px) {
      .rvrb-row p {
        margin: 0 0 20px; } }
  .rvrb-row:after {
    content: '';
    display: block;
    clear: both;
    height: 0; }
  .rvrb-row img {
    max-width: 100%; }
    @media screen and (max-width: 767px) {
      .rvrb-row img {
        margin: 0 auto; } }
  .rvrb-row .col {
    width: 50%;
    float: left;
    padding: 0 0 0 100px; }
    .rvrb-row .col:last-child:before {
      content: '';
      position: absolute;
      left: 50%;
      top: -10px;
      bottom: 0;
      width: 4px;
      background: #d6dede;
      margin-left: -2px; }
    @media screen and (max-width: 1023px) {
      .rvrb-row .col:last-child {
        padding-right: 0;
        padding-left: 50px; } }
    @media screen and (max-width: 767px) {
      .rvrb-row .col:last-child {
        padding: 25px 0 0; }
        .rvrb-row .col:last-child:before {
          display: none; } }
    @media screen and (max-width: 1200px) {
      .rvrb-row .col {
        padding: 0 50px; } }
    @media screen and (max-width: 1023px) {
      .rvrb-row .col {
        padding-left: 0; } }
    @media screen and (max-width: 767px) {
      .rvrb-row .col {
        padding: 0 0 25px;
        width: 100%;
        float: none;
        text-align: center; } }
  .rvrb-row .image-wrap {
    display: flex;
    align-items: center;
    height: 100px; }
    @media screen and (max-width: 767px) {
      .rvrb-row .image-wrap {
        display: block;
        height: auto; } }

.rvrb-wrap .txt-holder {
  max-width: 80% !important;
  font-size: 16px;
  margin: 0 auto;
  line-height: 1.5;
  text-align: center;
  margin: 0 auto 10px; }

.rvrb-image-grid {
  overflow: hidden;
  display: flex;
  align-itens: center;
  flex-wrap: wrap;
  position: relative; }
  .rvrb-image-grid .col {
    width: 100%;
    position: relative;
    overflow: hidden; }
    .rvrb-image-grid .col:before {
      content: "";
      display: block;
      padding-top: 48%; }
    @media screen and (max-width: 767px) {
      .rvrb-image-grid .col:before {
        display: none; } }
  .rvrb-image-grid .col-small {
    width: 37%;
    position: relative;
    overflow: hidden; }
    .rvrb-image-grid .col-small:before {
      content: "";
      display: block;
      padding-top: 128%; }
    @media screen and (max-width: 767px) {
      .rvrb-image-grid .col-small {
        width: 100%; }
        .rvrb-image-grid .col-small:before {
          display: none; } }
  .rvrb-image-grid .col-large {
    width: 63%;
    position: relative;
    overflow: hidden; }
    .rvrb-image-grid .col-large:before {
      content: "";
      display: block;
      padding-top: 75%; }
    @media screen and (max-width: 767px) {
      .rvrb-image-grid .col-large {
        width: 100%; }
        .rvrb-image-grid .col-large:before {
          display: none; } }
  .rvrb-image-grid img {
    position: absolute;
    width: 104%;
    max-width: none;
    z-index: 2;
    top: 0;
    left: -2%; }
    @media screen and (max-width: 767px) {
      .rvrb-image-grid img {
        position: relative; } }
  .rvrb-image-grid .txt-holder {
    text-align: left; }
    @media screen and (max-width: 767px) {
      .rvrb-image-grid .txt-holder {
        text-align: center; } }
  .rvrb-image-grid .rvrb-wrap {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center; }
    .rvrb-image-grid .rvrb-wrap p:last-child {
      margin: 0; }
    @media screen and (max-width: 767px) {
      .rvrb-image-grid .rvrb-wrap {
        position: relative;
        display: block; } }

.rvrb-box {
  overflow: hidden;
  padding: 75px 0 50px; }
  .rvrb-box .col {
    float: left;
    width: 16.66%;
    padding: 0 10px 40px;
    text-align: center;
    text-transform: uppercase; }
  .rvrb-box p {
    font-size: 14px;
    margin: 0 0 13px; }
    .rvrb-box p strong {
      font-family: 'Poppins', serif; }
  .rvrb-box .circle {
    max-width: 140px;
    width: 100%;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 37px; }
    .rvrb-box .circle:before {
      content: "";
      display: block;
      padding-top: 100%; }
  @media screen and (max-width: 1023px) {
    .rvrb-box .col {
      width: 33.33%; } }
  @media screen and (max-width: 767px) {
    .rvrb-box {
      padding: 40px 0 20px; }
      .rvrb-box .col {
        width: 50%;
        padding: 0 10px 30px; }
        .rvrb-box .col:nth-child(odd) {
          clear: both; }
      .rvrb-box .circle {
        margin: 0 auto 20px; }
      .rvrb-box p {
        margin: 0 0 7px; } }

.rvrb-large-grid {
  overflow: hidden;
  margin: 0 -6px; }
  .rvrb-large-grid .col {
    float: left;
    width: 50%;
    padding: 0 6px 12px;
    overflow: hidden; }
    @media screen and (max-width: 767px) {
      .rvrb-large-grid .col {
        float: none;
        width: 100%;
        max-width: 420px;
        margin: 0 auto; } }
  .rvrb-large-grid .holder {
    position: relative;
    overflow: hidden;
    color: #ffffff;
    padding: 0 0 0 210px; }
    @media screen and (max-width: 1023px) {
      .rvrb-large-grid .holder {
        font-size: 20px;
        padding: 0 0 0 140px; } }
  .rvrb-large-grid img {
    width: 100%; }
  .rvrb-large-grid p {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    bottom: 0;
    width: 210px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    line-height: 1.2;
    font-weight: 100; }
    @media screen and (max-width: 1023px) {
      .rvrb-large-grid p {
        width: 140px; } }

.rvrb-row-center {
  display: flex;
  align-items: center;
  padding: 20px 0; }
  .rvrb-row-center .col:first-child {
    width: 54%;
    padding: 0 15px 0 0; }
    .rvrb-row-center .col:first-child p {
      font-family: 'Poppins', serif;
      font-size: 26px;
      margin: 0 0 20px;
      font-weight: 100;
      letter-spacing: -0.5px;
      color: #707071; }
    .rvrb-row-center .col:first-child div {
      margin: 0 0 65px; }
      .rvrb-row-center .col:first-child div:last-child {
        margin: 0; }
    .rvrb-row-center .col:first-child div + p {
      margin-top: -20px; }
  .rvrb-row-center .col {
    width: 46%; }
  .rvrb-row-center img {
    max-width: 100%;
    height: auto; }
  .rvrb-row-center .txt-holder {
    text-align: left;
    margin: 0;
    max-width: 100% !important; }
  @media screen and (max-width: 767px) {
    .rvrb-row-center {
      display: block;
      padding: 0; }
      .rvrb-row-center .col:first-child,
      .rvrb-row-center .col {
        width: 100%;
        padding: 0; }
      .rvrb-row-center .txt-holder {
        padding: 35px 0 0;
        margin: 0 auto;
        text-align: center;
        max-width: 80% !important; } }

.rvrb-wrap.bg-figure {
  overflow: hidden;
  background: #d6dede url(/img/case-studies/riverbed_re_brand/webenertia_riverbed_gradient.png?ver=2.5.5) no-repeat 50% 25%;
  background-size: cover; }
  .rvrb-wrap.bg-figure img {
    float: left;
    max-width: 100%;
    height: auto; }
  .rvrb-wrap.bg-figure .txt-holder {
    text-align: left;
    float: right;
    width: 400px;
    padding: 70px 0 0; }
  .rvrb-wrap.bg-figure .max-container {
    overflow: hidden; }
  @media screen and (max-width: 1023px) {
    .rvrb-wrap.bg-figure img {
      width: 50%; }
    .rvrb-wrap.bg-figure .max-container {
      overflow: hidden;
      padding: 80px 0; }
    .rvrb-wrap.bg-figure .txt-holder {
      width: 48%;
      padding: 20px 0 0; } }
  @media screen and (max-width: 767px) {
    .rvrb-wrap.bg-figure img {
      float: none;
      margin: 0 auto;
      width: auto; }
    .rvrb-wrap.bg-figure .txt-holder {
      float: none;
      width: 100%;
      margin: 0 auto;
      text-align: center; }
      .rvrb-wrap.bg-figure .txt-holder h2, .rvrb-wrap.bg-figure .txt-holder p {
        color: #ffffff; } }

.rvrb-images {
  overflow: hidden;
  padding: 47px 0 0;
  margin: 0 -55px;
  display: flex;
  flex-wrap: wrap;
  align-items: center; }
  .rvrb-images .col {
    width: 50%;
    padding: 0 15px 6px; }
    .rvrb-images .col:nth-child(even) img {
      width: auto;
      max-width: 100%; }
  .rvrb-images img {
    margin: 0 auto;
    width: 100%; }
  @media screen and (max-width: 1299px) {
    .rvrb-images {
      margin: 0 -50px; } }
  @media screen and (max-width: 520px) {
    .rvrb-images {
      padding: 30px 0 0;
      margin: 0 -30px; } }

.rvrb-wrap.bg-figure2 {
  overflow: hidden;
  background: #d6dede url(/img/case-studies/riverbed_re_brand/webenertia_riverbed_gradient_line.png?ver=2.5.5) no-repeat 50% 66%;
  background-size: cover;
  position: relative; }
  .rvrb-wrap.bg-figure2 img {
    max-width: 100%; }
  .rvrb-wrap.bg-figure2 .video-box {
    margin: 0 -140px 0 0;
    float: right;
    position: relative; }
  .rvrb-wrap.bg-figure2 video {
    position: absolute;
    z-index: 2;
    top: 16%;
    left: 12%;
    width: 76%;
    height: auto; }
  .rvrb-wrap.bg-figure2 .txt-holder {
    text-align: left;
    position: absolute;
    bottom: 100px;
    left: 50%;
    margin-left: -600px;
    width: 400px; }
  .rvrb-wrap.bg-figure2 .case-rspnsv {
    padding: 0; }
  @media screen and (max-width: 1299px) {
    .rvrb-wrap.bg-figure2 .txt-holder {
      margin: 0;
      left: 40px; }
    .rvrb-wrap.bg-figure2 .video-box {
      width: 65%; } }
  @media screen and (max-width: 1023px) {
    .rvrb-wrap.bg-figure2 {
      padding: 100px 0; }
      .rvrb-wrap.bg-figure2 .txt-holder {
        width: 350px;
        bottom: 50px; } }
  @media screen and (max-width: 767px) {
    .rvrb-wrap.bg-figure2 {
      padding: 40px 0;
      background-size: 100% auto; }
      .rvrb-wrap.bg-figure2 .video-box {
        float: none;
        margin: 0 auto;
        width: 80%; }
      .rvrb-wrap.bg-figure2 .txt-holder {
        position: static;
        width: auto;
        text-align: center;
        margin: 0 auto;
        padding: 0 0 20px; } }

/**NOTES:
For future interns:

- in-session means we are hiring
- not-in-session means we are NOT hiring

These classes are applied to the body tag found in header.php
Happy coding!
*/
/* SPECIFIC STUFF */
.in-session #internship-session {
  display: none !important; }

.not-in-session #hiring {
  display: none !important; }

.not-in-session .hiring-only {
  display: none !important; }

#header_wrapper #nav-menu {
  position: absolute;
  z-index: 2;
  right: 0;
  padding-top: 30px; }
  #header_wrapper #nav-menu ul {
    list-style: none; }
    #header_wrapper #nav-menu ul li {
      display: inline;
      text-align: center;
      padding: 0 30px; }
      #header_wrapper #nav-menu ul li:last-child {
        padding-right: 0; }
      #header_wrapper #nav-menu ul li a {
        color: #707071;
        font-size: 20px;
        line-height: 30px;
        font-family: 'Poppins', serif;
        display: inline-block;
        vertical-align: top;
        position: relative; }
        #header_wrapper #nav-menu ul li a:visited, #header_wrapper #nav-menu ul li a:link {
          text-decoration: none; }
        #header_wrapper #nav-menu ul li a:before {
          position: absolute;
          bottom: -3px;
          left: 0;
          content: '';
          width: 100%;
          height: 2px;
          background: #707071;
          -webkit-transform: scale3d(0, 1, 1);
          transform: scale3d(0, 1, 1);
          -webkit-transition: -webkit-transform 0.1s;
          transition: -webkit-transform 0.1s;
          transition: transform 0.1s;
          transition: transform 0.1s, -webkit-transform 0.1s, -o-transform 0.1s; }
        #header_wrapper #nav-menu ul li a:hover {
          text-decoration: none; }
          #header_wrapper #nav-menu ul li a:hover:before {
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
            -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s; }
      #header_wrapper #nav-menu ul li .nav-active {
        text-decoration: none; }
        #header_wrapper #nav-menu ul li .nav-active:before {
          -webkit-transform: scale3d(1, 1, 1);
          transform: scale3d(1, 1, 1);
          -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s; }

#header_wrapper #nav-burger {
  /* positioning */
  z-index: 9999;
  position: absolute;
  right: 0;
  margin-top: 20px;
  display: inline-block;
  overflow: visible;
  padding: 15px;
  /* animation */
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: .15s;
  transition-property: opacity, filter;
  text-transform: none;
  background-color: transparent; }
  #header_wrapper #nav-burger #burger-box {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 24px; }
    #header_wrapper #nav-burger #burger-box #burger-patty {
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      transition-duration: 75ms;
      position: absolute;
      width: 32px;
      height: 2px;
      transition-property: transform;
      border-radius: 4px;
      background-color: #707071;
      top: 50%;
      display: block;
      margin-top: -2px;
      /* buns */ }
      #header_wrapper #nav-burger #burger-box #burger-patty:before {
        transition: top 75ms ease .12s, opacity 75ms ease;
        top: -7px;
        display: block;
        content: '';
        position: absolute;
        width: 32px;
        height: 2px;
        transition-timing-function: ease;
        transition-duration: .15s;
        transition-property: transform;
        border-radius: 4px;
        background-color: #707071; }
      #header_wrapper #nav-burger #burger-box #burger-patty:after {
        transition: bottom 75ms ease 0.12s, opacity 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
        bottom: -7px;
        display: block;
        content: '';
        position: absolute;
        width: 32px;
        height: 2px;
        transition-timing-function: ease;
        transition-duration: .15s;
        transition-property: transform;
        border-radius: 4px;
        background-color: #707071; }
      #header_wrapper #nav-burger #burger-box #burger-patty.is-active {
        transition-delay: .12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: rotate(45deg); }
        #header_wrapper #nav-burger #burger-box #burger-patty.is-active:before {
          top: 0;
          transition: top 75ms ease, opacity 75ms ease .12s;
          opacity: 0; }
        #header_wrapper #nav-burger #burger-box #burger-patty.is-active:after {
          bottom: 0;
          transition: bottom 75ms ease, transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
          transform: rotate(-90deg); }

#hamnav-container {
  display: none;
  overflow-y: auto;
  /* positioning */
  position: fixed;
  left: 0px;
  top: 89px;
  z-index: 3;
  /* dimensions */
  height: calc(100vh - 89px);
  width: 100%;
  /* background */
  background-color: #da254d; }
  #hamnav-container #nav-for-hamburger {
    position: relative; }
    #hamnav-container #nav-for-hamburger #hamburger-nav {
      list-style-type: none;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 50%); }
      @media screen and (max-width: 767px) {
        #hamnav-container #nav-for-hamburger #hamburger-nav {
          position: relative;
          top: unset;
          left: unset;
          transform: none; } }
      #hamnav-container #nav-for-hamburger #hamburger-nav li {
        vertical-align: middle;
        padding: 30px; }
        #hamnav-container #nav-for-hamburger #hamburger-nav li a {
          font-family: 'Poppins', serif;
          font-size: 44px !important;
          color: white;
          line-height: 52.8px;
          position: relative;
          display: inline-block;
          vertical-align: top; }
          #hamnav-container #nav-for-hamburger #hamburger-nav li a:visited, #hamnav-container #nav-for-hamburger #hamburger-nav li a:link {
            text-decoration: none; }
          #hamnav-container #nav-for-hamburger #hamburger-nav li a:before {
            position: absolute;
            bottom: -3px;
            left: 0;
            content: '';
            width: 100%;
            height: 2px;
            background: #ffffff;
            -webkit-transform: scale3d(0, 1, 1);
            transform: scale3d(0, 1, 1);
            -webkit-transition: -webkit-transform 0.1s;
            transition: -webkit-transform 0.1s;
            transition: transform 0.1s;
            transition: transform 0.1s, -webkit-transform 0.1s, -o-transform 0.1s; }
          #hamnav-container #nav-for-hamburger #hamburger-nav li a:hover {
            text-decoration: none; }
            #hamnav-container #nav-for-hamburger #hamburger-nav li a:hover:before {
              -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
              -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
              transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
              -webkit-transition-duration: 0.3s;
              transition-duration: 0.3s; }
        #hamnav-container #nav-for-hamburger #hamburger-nav li .nav-active {
          text-decoration: none; }
          #hamnav-container #nav-for-hamburger #hamburger-nav li .nav-active:before {
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
            -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s; }
        @media screen and (max-width: 767px) {
          #hamnav-container #nav-for-hamburger #hamburger-nav li {
            padding: 15px; }
            #hamnav-container #nav-for-hamburger #hamburger-nav li a {
              font-size: 36px !important; } }

.header-blade {
  position: relative;
  text-align: center;
  overflow: hidden;
  background: linear-gradient(rgba(20, 20, 20, 0.5), rgba(20, 20, 20, 0.5)), url("/img/Microsite_Hero.jpg");
  background-position: center center;
  background-size: cover;
  height: 889px; }
  @media screen and (max-width: 760px) {
    .header-blade {
      height: 689px; } }
  .header-blade .text {
    color: #ffffff;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 100%;
    max-width: 1100px;
    margin: auto;
    margin-top: 44.5px; }
    .header-blade .text h3 {
      font-size: 72px;
      line-height: 1;
      letter-spacing: -0.09px;
      color: #ffffff;
      width: 100%;
      margin: auto;
      margin-bottom: 24px;
      font-weight: bold; }
    .header-blade .text p {
      font-size: 20px;
      line-height: 28px;
      margin: 0 auto;
      max-width: 950px; }
    @media screen and (max-width: 1300px) {
      .header-blade .text {
        padding: 0 40px;
        max-width: 800px; } }
    @media screen and (max-width: 1023px) {
      .header-blade .text h3 {
        font-size: 34px; } }
    @media screen and (max-width: 767px) {
      .header-blade .text {
        margin-bottom: 40px;
        margin-top: 20px;
        max-width: 600px; }
        .header-blade .text h3 {
          font-size: 30px;
          width: 100%; }
        .header-blade .text p {
          max-width: 550px; } }
    @media screen and (max-width: 580px) {
      .header-blade .text {
        margin-bottom: 20px;
        padding: 0 20px; } }

.about-us .box {
  height: 566px;
  display: flex;
  align-content: center;
  flex-direction: column;
  -ms-flex-align: center; }
  .about-us .box .text {
    max-width: 500px;
    text-align: left;
    margin: auto; }
    @media screen and (max-width: 1300px) {
      .about-us .box .text {
        max-width: 100%;
        padding: 0 40px; } }
    @media screen and (max-width: 767px) {
      .about-us .box .text {
        margin-bottom: 40px;
        margin-top: 20px; } }
    @media screen and (max-width: 580px) {
      .about-us .box .text {
        margin-bottom: 20px;
        padding: 0 20px; } }
    .about-us .box .text .blade-title {
      margin: 0 0 15px; }
  .about-us .box:first-child {
    background-color: #da254d; }
    .about-us .box:first-child * {
      color: #ffffff; }
  .about-us .box.about-us-image {
    background: url("/img/About_Image.jpg");
    background-size: cover;
    background-position: center center; }
  @media screen and (max-width: 1023px) {
    .about-us .box {
      display: block;
      height: auto;
      padding: 40px 0; }
      .about-us .box .text {
        max-width: none;
        text-align: center; }
        .about-us .box .text .blade-title {
          margin-bottom: 24px; } }

.logos .solutions-grid {
  overflow: hidden; }

.logos h2 {
  max-width: 1002px;
  margin: 0 auto 25px auto;
  font-size: 30px;
  line-height: 35px; }
  @media screen and (max-width: 1300px) {
    .logos h2 {
      max-width: none;
      padding: 0 40px; } }
  @media screen and (max-width: 1023px) {
    .logos h2 {
      font-size: 26px; } }
  @media screen and (max-width: 767px) {
    .logos h2 {
      letter-spacing: -1px; } }
  @media screen and (max-width: 580px) {
    .logos h2 {
      padding: 0 20px; } }
  @media screen and (max-width: 500px) {
    .logos h2 {
      font-size: 24px; } }

@media screen and (max-width: 1023px) {
  .logos img {
    margin: auto; } }

@media screen and (max-width: 760px) {
  .logos img {
    margin-top: 10px; } }

.about-internship {
  position: relative;
  text-align: center;
  padding-top: 0 !important; }
  .about-internship .blade-title {
    margin-bottom: 24px; }
  .about-internship .about-internship-text {
    max-width: 955px;
    width: 100%;
    margin: auto; }
    .about-internship .about-internship-text .red-break {
      margin-bottom: 18px;
      color: #da254d;
      background-color: #da254d;
      border: none;
      height: 5px; }
    .about-internship .about-internship-text p {
      margin: 0 auto 18px auto; }
    @media screen and (max-width: 1023px) {
      .about-internship .about-internship-text {
        max-width: none;
        padding: 0 40px; } }
    @media screen and (max-width: 767px) {
      .about-internship .about-internship-text {
        margin-bottom: 40px;
        margin-top: 40px; } }
    @media screen and (max-width: 580px) {
      .about-internship .about-internship-text {
        margin-bottom: 20px;
        padding: 0 20px; } }
    .about-internship .about-internship-text .row {
      width: 100%;
      padding-top: 46px; }
      @media screen and (max-width: 767px) {
        .about-internship .about-internship-text .row {
          padding: 0; } }
      .about-internship .about-internship-text .row .col {
        max-width: 218px; }
        .about-internship .about-internship-text .row .col .icon-wrapper {
          width: 70px;
          margin-bottom: 10px; }
          .about-internship .about-internship-text .row .col .icon-wrapper:first-child {
            width: 80px; }
          .about-internship .about-internship-text .row .col .icon-wrapper .icon {
            height: 60px; }
            .about-internship .about-internship-text .row .col .icon-wrapper .icon.designer {
              background: url("/img/Designer_icon.svg") no-repeat 50% 50%; }
            .about-internship .about-internship-text .row .col .icon-wrapper .icon.developer {
              background: url("/img/Developer_icon.svg") no-repeat 50% 50%; }
            .about-internship .about-internship-text .row .col .icon-wrapper .icon.project-manager {
              background: url("/img/PM_Icon.svg") no-repeat 50% 50%; }
        .about-internship .about-internship-text .row .col .blade-title {
          font-size: 18px;
          line-height: 1.2;
          font-weight: bold;
          font-family: proxima-nova, 'arial', 'sans-serif'; }
          @media screen and (max-width: 767px) {
            .about-internship .about-internship-text .row .col .blade-title {
              letter-spacing: 0px !important; } }
        .about-internship .about-internship-text .row .col p {
          font-size: 12px;
          line-height: 1.5;
          margin-bottom: 58px;
          letter-spacing: 0.01px;
          font-weight: bold; }
          @media screen and (max-width: 1023px) {
            .about-internship .about-internship-text .row .col p {
              font-size: 14px;
              line-height: 1.2; } }
  .about-internship .apply {
    margin: 47px auto 0 auto; }

.slider-for-mobile {
  position: relative; }
  .slider-for-mobile .slider-image {
    width: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transition: opacity 1.5s ease-in-out;
    -moz-transition: opacity 1.5s ease-in-out;
    -o-transition: opacity 1.5s ease-in-out;
    transition: opacity 1.5s ease-in-out; }
    .slider-for-mobile .slider-image.active {
      position: relative;
      opacity: 1; }

.meet-interns {
  padding-top: 0px !important;
  text-align: center; }
  .meet-interns .intern-blade:nth-child(odd) {
    background: #d6dede; }
  @media screen and (max-width: 580px) {
    .meet-interns .intern-blade.padding {
      padding: 35px 0 !important; } }
  .meet-interns .intern-blade .subtitle {
    font-weight: bold;
    margin-bottom: 24px; }
    @media screen and (max-width: 767px) {
      .meet-interns .intern-blade .subtitle {
        margin-bottom: 40px; } }
  .meet-interns .intern-image {
    align-content: center;
    max-width: 344px;
    margin-bottom: 15.5px; }
    @media screen and (max-width: 767px) {
      .meet-interns .intern-image {
        margin-bottom: 5px; } }
    .meet-interns .intern-image img {
      padding: 0;
      width: 100%; }
  .meet-interns .intern-name {
    font-size: 18px;
    line-height: 35px;
    font-weight: bold;
    font-family: proxima-nova, 'arial', 'sans-serif'; }
  .meet-interns .intern-title {
    font-size: 12px;
    line-height: 15px;
    font-weight: bold;
    margin-bottom: 15px; }
  .meet-interns .row.container {
    max-width: 1110px !important; }
  @media screen and (max-width: 1023px) {
    .meet-interns .row.container {
      justify-content: center;
      padding: 40px 40px 0 40px !important; } }
  @media screen and (max-width: 767px) {
    .meet-interns .row.container {
      padding-top: 0 !important; } }
  .meet-interns .row .col {
    width: calc(33% - 8px);
    max-width: 344px; }
    @media screen and (max-width: 1023px) {
      .meet-interns .row .col {
        margin-bottom: 24px; } }
    @media screen and (max-width: 767px) {
      .meet-interns .row .col {
        width: 100%; } }
  .meet-interns .social-media-icons {
    z-index: 2;
    font-size: 0;
    width: 125px;
    display: flex;
    margin: auto;
    justify-content: center; }
    .meet-interns .social-media-icons a .icon-wrapper {
      width: 35px;
      margin-right: 10px; }
      .meet-interns .social-media-icons a .icon-wrapper .icon {
        background-size: auto 25px;
        height: 35px; }
        .meet-interns .social-media-icons a .icon-wrapper .icon.linkedin {
          background: url("/img/linkedin.svg") no-repeat 50% 50%; }
        .meet-interns .social-media-icons a .icon-wrapper .icon.facebook {
          background: url("/img/Facebook.svg") no-repeat 50% 50%; }
        .meet-interns .social-media-icons a .icon-wrapper .icon.instagram {
          background: url("/img/Instagram.svg") no-repeat 50% 50%; }
    .meet-interns .social-media-icons a:last-child .icon-wrapper {
      margin-right: 0; }
  .meet-interns .projects-involved {
    padding-top: 50px; }
    .meet-interns .projects-involved .blade-title {
      padding-bottom: 30px; }
    .meet-interns .projects-involved .project-button {
      width: 60%; }
    .meet-interns .projects-involved .project-buttons {
      width: 68%;
      margin: auto;
      text-align: center;
      font-size: 0; }
      .meet-interns .projects-involved .project-buttons .project-button {
        max-width: 210px;
        display: inline-block;
        margin-right: 15px; }
        .meet-interns .projects-involved .project-buttons .project-button:last-child {
          margin-right: 0px; }
      @media screen and (max-width: 639px) {
        .meet-interns .projects-involved .project-buttons {
          width: 210px; }
          .meet-interns .projects-involved .project-buttons .project-button {
            width: 100%;
            margin: 0 0 20px 0; } }

.internship-session {
  background: #da254d;
  text-align: center;
  color: #ffffff;
  width: 100%; }
  @media screen and (max-width: 1300px) {
    .internship-session .text {
      padding: 0 40px; } }
  @media screen and (max-width: 580px) {
    .internship-session .text {
      padding: 15px 20px; } }
  .internship-session .text .blade-title {
    color: #ffffff;
    margin-bottom: 18px; }
  .internship-session .text p {
    max-width: 955px;
    margin: auto; }

.hiring {
  text-align: center; }
  @media screen and (max-width: 580px) {
    .hiring {
      padding: 40px 0 !important; } }
  .hiring .blade-title {
    margin: 0 0 32px 0; }
  @media screen and (max-width: 780px) {
    .hiring .container {
      padding: 0 40px; } }
  @media screen and (max-width: 767px) {
    .hiring .container {
      padding: 0 20px; } }
  .hiring .internship-positions .position {
    background: #ffffff;
    padding: 0 30px 40px 30px;
    max-width: 397px;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .hiring .internship-positions .position .icon-wrapper {
      margin: 75px 5px 24px 5px;
      width: 80px;
      flex-grow: 1; }
      .hiring .internship-positions .position .icon-wrapper .icon {
        background-size: auto 25px;
        width: 100%;
        height: 60px; }
        .hiring .internship-positions .position .icon-wrapper .icon.designer {
          background: url("/img/Designer_icon.svg") no-repeat 50% 50%; }
        .hiring .internship-positions .position .icon-wrapper .icon.developer {
          background: url("/img/Developer_icon.svg") no-repeat 50% 50%; }
        .hiring .internship-positions .position .icon-wrapper .icon.project-manager {
          background: url("/img/PM_Icon.svg") no-repeat 50% 50%; }
    .hiring .internship-positions .position .blade-title {
      flex-grow: 1;
      margin-bottom: 20px;
      font-family: proxima-nova, 'arial', 'sans-serif';
      font-weight: bold;
      font-size: 18px; }
      @media screen and (max-width: 767px) {
        .hiring .internship-positions .position .blade-title {
          letter-spacing: 0 !important; } }
    .hiring .internship-positions .position .text {
      width: 100%;
      max-width: 220px;
      margin-bottom: 50px;
      font-weight: bold;
      font-family: "proxima-nova", arial, sans-serif;
      font-size: 12px;
      line-height: 1.4;
      flex-grow: 2; }
    .hiring .internship-positions .position .button-container {
      width: 100%; }
      .hiring .internship-positions .position .button-container .we-button {
        width: 100%;
        flex-grow: 1; }

.contact-form-blade {
  text-align: center; }
  .contact-form-blade .contact-form-wrapper {
    width: 100%;
    margin: auto; }
    @media screen and (max-width: 1300px) {
      .contact-form-blade .contact-form-wrapper {
        padding: 0 40px; } }
    @media screen and (max-width: 1023px) {
      .contact-form-blade .contact-form-wrapper .text {
        margin-bottom: 37px !important; } }
    @media screen and (max-width: 580px) {
      .contact-form-blade .contact-form-wrapper {
        padding: 0 20px; } }
    .contact-form-blade .contact-form-wrapper .blade-title {
      line-height: 30px;
      margin-bottom: 30px; }
    .contact-form-blade .contact-form-wrapper .text {
      line-height: 28px;
      max-width: 955px;
      margin: 0 auto 67px auto; }
    .contact-form-blade .contact-form-wrapper form {
      max-width: 955px;
      margin: auto; }
      .contact-form-blade .contact-form-wrapper form input,
      .contact-form-blade .contact-form-wrapper form textarea {
        background-color: #d6dede;
        font-size: 16px;
        line-height: 20px;
        white-space: pre-wrap; }
      .contact-form-blade .contact-form-wrapper form input[aria-invalid="true"],
      .contact-form-blade .contact-form-wrapper form textarea[aria-invalid="true"] {
        border: solid 2px #f00 !important; }
      .contact-form-blade .contact-form-wrapper form .clearfix {
        margin-bottom: 15px; }
        .contact-form-blade .contact-form-wrapper form .clearfix input {
          float: left;
          width: calc(50% - 10px);
          height: 56px;
          padding: 18px 12px;
          border: solid 1px #d6dede;
          -webkit-appearance: none;
          border-radius: 0; }
          .contact-form-blade .contact-form-wrapper form .clearfix input#name {
            margin-right: 15px; }
          @media screen and (max-width: 767px) {
            .contact-form-blade .contact-form-wrapper form .clearfix input {
              float: none;
              width: 100%; }
              .contact-form-blade .contact-form-wrapper form .clearfix input#name {
                margin-right: 0;
                margin-bottom: 15px; } }
      .contact-form-blade .contact-form-wrapper form #message {
        padding: 20px 12px;
        border: solid 1px #d6dede;
        -webkit-appearance: none;
        border-radius: 0;
        width: 100%;
        height: 216px;
        margin-bottom: 20px; }
      .contact-form-blade .contact-form-wrapper form #contact-submit {
        width: 100%;
        max-width: 270px; }
        @media screen and (max-width: 767px) {
          .contact-form-blade .contact-form-wrapper form #contact-submit {
            max-width: none; } }
    .contact-form-blade .contact-form-wrapper #form-failed {
      display: none;
      color: #f00;
      padding: 10px 0;
      margin: auto; }

.thank-you {
  background-color: #ffffff;
  text-align: center;
  width: 100%;
  display: none; }
  @media screen and (max-width: 1300px) {
    .thank-you .text {
      padding: 0 40px; } }
  @media screen and (max-width: 580px) {
    .thank-you .text {
      padding: 15px 20px; } }
  .thank-you .text .blade-title {
    font-size: 72px;
    line-height: 1;
    letter-spacing: -0.09px;
    margin-bottom: 18px;
    font-weight: bold; }
  .thank-you .text .diddy {
    max-width: 955px;
    margin: auto; }

/* GENERIC STUFF */
*, h1, h2, h3, h4, h5 {
  letter-spacing: normal; }

p {
  font-size: 20px;
  line-height: 28px; }
  @media screen and (max-width: 1023px) {
    p {
      font-size: 18px;
      line-height: 1.4; } }
  @media screen and (max-width: 580px) {
    p {
      font-size: 16px; } }

.row {
  margin: 0 auto;
  display: flex;
  justify-content: space-between; }
  .row .col {
    width: calc(33% - 10px); }
    .row .col:first-child {
      margin-right: 15px; }
    .row .col:last-child {
      margin-left: 15px; }
  @media screen and (max-width: 767px) {
    .row {
      display: block; }
      .row .col {
        margin: 20px auto;
        width: 100%; }
        .row .col:first-child {
          margin-right: auto; }
        .row .col:last-child {
          margin-left: auto;
          margin-bottom: 0; } }

.two-column {
  overflow: hidden; }
  .two-column .box {
    position: relative;
    float: left;
    width: 50%; }
    @media screen and (max-width: 1023px) {
      .two-column .box {
        width: 100%;
        float: none; }
        .two-column .box:last-child {
          display: none; } }

.we-button {
  /* border + color */
  border-radius: 2px;
  -webkit-border-radius: 2px;
  border: solid 3px black;
  color: black;
  /* positioning */
  text-align: center;
  text-transform: uppercase !important;
  display: block;
  /* font */
  font-size: 12px;
  padding: 11px 0;
  font-weight: 600;
  letter-spacing: 1px;
  font-family: proxima-nova !important;
  text-decoration: none;
  /* sizing */
  max-width: 210px;
  margin: auto;
  /* animation */
  transition: all 0.3s;
  cursor: pointer; }
  .we-button:visited {
    color: black; }
  .we-button:hover {
    color: #ffffff;
    background: black; }

.we-button[disabled="disabled"] {
  cursor: not-allowed;
  opacity: .1; }

.we-button-red {
  background: #da254d;
  color: #ffffff;
  border: solid 3px #da254d; }
  .we-button-red:visited {
    color: #ffffff; }
  .we-button-red:hover {
    background: none;
    color: #da254d; }

.blade-title {
  margin: auto;
  font-size: 30px;
  line-height: 1.2;
  letter-spacing: 0.04px; }
  @media screen and (max-width: 1023px) {
    .blade-title {
      font-size: 26px; } }
  @media screen and (max-width: 767px) {
    .blade-title {
      margin: 0 0 12px;
      letter-spacing: -1px !important; } }
  @media screen and (max-width: 500px) {
    .blade-title {
      font-size: 24px; } }

.icon-wrapper {
  display: inline-block; }
  .icon-wrapper .icon {
    display: block;
    width: 100%; }

.clearfix {
  overflow: auto; }

@media screen and (min-width: 1024px) {
  #nav-burger, #nav-for-burger {
    display: none !important; } }

@media screen and (min-width: 768px) {
  #slider-for-mobile {
    display: none !important; } }

@media screen and (max-width: 767px) {
  #grid-wrapper {
    display: none !important; } }

@media screen and (max-width: 1023px) {
  #nav-menu {
    display: none; } }

@media screen and (min-width: 1024px) {
  #hamnav-container {
    display: none !important; } }

.lock-screen {
  overflow: hidden; }

.admin-bar #header_wrapper {
  top: 32px; }
  @media screen and (max-width: 782px) {
    .admin-bar #header_wrapper {
      top: 46px; } }

.admin-bar .sec-nav-container {
  top: 121px; }
  @media screen and (max-width: 782px) {
    .admin-bar .sec-nav-container {
      top: 135px; } }

* {
  padding: 0;
  margin: 0;
  outline: none;
  font-family: "proxima-nova", arial, sans-serif;
  box-sizing: border-box; }
  *:before, *:after {
    box-sizing: border-box; }

body,
html {
  height: 100%;
  width: 100%;
  color: #707071; }

a {
  cursor: pointer;
  color: #ef9123; }

a.hover-link {
  text-decoration: none; }
  a.hover-link:hover {
    text-decoration: underline; }

img {
  display: block;
  border: 0 none;
  max-width: 100%;
  height: auto; }

.center {
  text-align: center; }

.padding,
.container.padding {
  padding-top: 71px;
  padding-bottom: 85px; }
  @media screen and (max-width: 767px) {
    .padding,
    .container.padding {
      padding: 40px 0; } }
  @media screen and (max-width: 580px) {
    .padding,
    .container.padding {
      padding: 20px 0; } }

.region-home-page-content.padding,
.full-min-height.padding {
  padding-top: 0;
  padding-bottom: 0; }
  @media screen and (max-width: 767px) {
    .region-home-page-content.padding,
    .full-min-height.padding {
      padding: 40px 0; } }
  @media screen and (max-width: 580px) {
    .region-home-page-content.padding,
    .full-min-height.padding {
      padding: 20px 0; } }

/*** iPhoneX support ***/
@supports (padding: 0px) {
  #page > .padding,
  #page > .bottom-container,
  #page > .full-width-content,
  #page > .full-width-content-grey,
  #page .location-section .box .text,
  #page_wrapper .location-section .box .text,
  #page > .footer-section,
  #page > .work-grey-content {
    padding-left: max(12px, env(safe-area-inset-left));
    padding-right: max(12px, env(safe-area-inset-right)); }
  #page .team-crsl .leadership-box {
    padding-left: calc(16px + max(20px, env(safe-area-inset-left)));
    padding-right: calc(16px + max(20px, env(safe-area-inset-right))); }
  #page .team-crsl .team-info-box h5, #page .team-crsl .team-info-box p {
    padding-left: max(20px, env(safe-area-inset-left));
    padding-right: max(20px, env(safe-area-inset-right)); }
  #page .team-crsl .team-info-box .lnkd-icon {
    left: max(20px, env(safe-area-inset-left)); }
  @media screen and (max-width: 1023px) {
    #page .location-section .box .anim-btn,
    #page_wrapper .location-section .box .anim-btn {
      right: max(20px, env(safe-area-inset-right)); } }
  #page .crsl-home .slick-prev,
  #page .team-crsl .slick-prev,
  .full-width-slider .slick-slider .slick-prev {
    left: max(0px, env(safe-area-inset-left)); }
  #page .crsl-home .slick-next,
  #page .team-crsl .slick-next,
  .full-width-slider .slick-slider .slick-next {
    right: max(0px, env(safe-area-inset-right)); }
  .sandhill-casestudy .infographic-slider .slick-slider .slick-prev {
    margin-left: max(0px, calc(env(safe-area-inset-left) - 12px)); }
  .sandhill-casestudy .infographic-slider .slick-slider .slick-next {
    margin-right: max(0px, calc(env(safe-area-inset-right) - 12px)); }
  .page .cs-nav {
    padding-left: max(30px, env(safe-area-inset-left));
    padding-right: max(30px, env(safe-area-inset-right)); }
  #page_wrapper .irm-infographic .irm-container {
    padding-left: max(0px, env(safe-area-inset-left));
    padding-right: max(0px, env(safe-area-inset-right)); }
  #page > .full-width-content.cisco-block {
    padding-left: 0;
    padding-right: 0; }
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    #page > .full-width-content-grey > .max-container {
      margin: 0 auto; }
    #page > .max-container {
      margin-left: max(0px, env(safe-area-inset-left)) !important;
      margin-right: max(0px, env(safe-area-inset-right)) !important; }
    .bg-video-component .max-container.acmtrx .txt-holder {
      padding: 20px 0; }
    .ua-mobile .bg-video-component.acmtrx {
      height: 90vh; } } }

#wrapper {
  background: #ffffff; }

.full-width {
  width: 100%;
  margin: 0 auto; }

.full-min-height {
  display: flex;
  align-items: center; }
  @media screen and (min-width: 1000px) {
    .full-min-height {
      min-height: 405px !important; } }
  @media screen and (min-width: 767px) and (max-width: 999px) {
    .full-min-height {
      min-height: 350px !important; } }
  .full-min-height:after {
    display: block;
    content: '';
    min-height: inherit;
    font-size: 0; }

/*
.ua-ie-11 .full-min-height {
}
*/
/*** pace loader bar ***/
.pace {
  pointer-events: none;
  user-select: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transform: translate3d(0, -50px, 0);
  transition: transform .5s ease-out; }
  .pace.pace-active {
    transform: translate3d(0, 0, 0); }
  .pace .pace-progress {
    display: block;
    position: fixed;
    z-index: 2000;
    top: 0;
    right: 100%;
    width: 100%;
    height: 5px;
    background: #252728;
    pointer-events: none; }

body.home.pace-done {
  background: #252728; }

body.solutions.pace-done {
  background: #a3cf5f; }

body.work.pace-done {
  background: #ef9123; }

body.about.pace-done {
  background: #a072a5; }

body.blog.pace-done,
body.pace-done[class^="main-nav-"],
body.pace-done[class*=" main-nav-"] {
  background: #62b4e5; }

#hm-sldr-wrap {
  background: url(/img/spinner3.gif?ver=2.5.5) no-repeat center center;
  width: 100%;
  min-height: 0; }

.purple {
  background: #a072a5; }

.orange {
  background: #ef9123;
  color: #ffffff; }
  .orange h1, .orange h2, .orange h3, .orange h4 {
    color: #ffffff; }

.burnt-orange {
  background: #f37736;
  color: #ffffff; }

.green {
  background: #a3cf5f; }

.light-grey-bg {
  background: #d6dede; }

.lightest-grey-bg {
  background: #f9fafa; }

.dark-blue-grey {
  background: #252728; }

.bg-white {
  background-color: #ffffff; }

.fixed {
  position: fixed !important; }

.full-width-content {
  width: 100%; }

.full-width-content-grey {
  width: 100%;
  min-height: 100px;
  background: #d6dede; }
  .full-width-content-grey.solutions a {
    color: #a3cf5f;
    text-decoration: none; }
    .full-width-content-grey.solutions a:hover {
      text-decoration: underline; }
  .full-width-content-grey.ie-fix {
    min-height: 520px; }

.full-width-content-darkgrey {
  width: 100%;
  background: #252728;
  color: #ffffff; }

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

#skip {
  display: none; }

#overlay {
  z-index: 98;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: none; }

.max-container {
  max-width: 1200px;
  margin: 0 auto;
  position: relative; }
  @media screen and (max-width: 1300px) {
    .max-container {
      margin: 0 40px; } }
  @media screen and (max-width: 580px) {
    .max-container {
      margin: 0 20px; } }

/*** main page ***/
.why-webe p {
  font-size: 17px; }

.counter-holder {
  font-size: 34px;
  color: #ef9123; }
  .counter-holder p {
    font-size: 34px; }
  .counter-holder .counter {
    font-weight: bold;
    font-size: 150px;
    line-height: 120px;
    letter-spacing: -2px;
    margin: 0 0 12px; }
    .counter-holder .counter span {
      font-size: 66%;
      display: inline-block;
      vertical-align: top;
      margin-top: 11px;
      line-height: 1; }
  @media screen and (max-width: 1023px) {
    .counter-holder {
      font-size: 24px; }
      .counter-holder .counter {
        font-size: 100px;
        line-height: 90px;
        margin: 0 0 5px; }
        .counter-holder .counter span {
          margin-top: 16px; } }

.recent-blogs {
  overflow: hidden;
  font-size: 0;
  line-height: 0;
  letter-spacing: -4px;
  padding: 32px 45px 0; }
  @media screen and (max-width: 1023px) {
    .recent-blogs {
      padding: 32px 0 0; } }
  @media screen and (max-width: 767px) {
    .recent-blogs {
      max-width: 357px;
      margin: 0 auto; } }
  .recent-blogs .box {
    letter-spacing: normal;
    display: inline-block;
    vertical-align: top;
    width: 33.33%;
    overflow: auto;
    padding: 0 0 15px; }
    @media screen and (max-width: 767px) {
      .recent-blogs .box {
        width: 100%;
        display: block;
        font-size: 14px; } }
    .recent-blogs .box h3 {
      margin: 25px 30px 15px;
      font-weight: 300;
      font-size: 24px; }
      @media screen and (max-width: 1023px) {
        .recent-blogs .box h3 {
          margin: 25px 20px 15px; } }
      @media screen and (max-width: 767px) {
        .recent-blogs .box h3 {
          font-size: 22px; } }
    .recent-blogs .box p {
      font-size: 16px;
      line-height: 1.25;
      padding: 0 30px 15px;
      margin: 0; }
      @media screen and (max-width: 1023px) {
        .recent-blogs .box p {
          padding: 0 20px 15px; } }
    .recent-blogs .box a {
      text-decoration: none; }
      .recent-blogs .box a:hover h3 {
        color: #ef9123; }
  .recent-blogs img {
    width: 100%;
    height: auto;
    opacity: 1;
    transition: opacity 0.3s ease; }
  .recent-blogs .inner-box {
    position: relative;
    background-color: #252728; }
    .recent-blogs .inner-box .anim-btn {
      position: absolute;
      right: 20px;
      bottom: 20px; }

.solution-grid {
  margin: 0 -15px 17px;
  font-size: 0;
  line-height: 0;
  letter-spacing: -4px;
  text-align: center; }
  .solution-grid .box {
    display: inline-block;
    vertical-align: top;
    width: 20%;
    padding: 4px 15px 20px;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    color: #707071;
    letter-spacing: 1px;
    line-height: 1.2; }
    .solution-grid .box a {
      color: #252728;
      text-decoration: none; }
    .solution-grid .box h4 {
      font-weight: bold;
      font-size: 18px;
      margin: 0 0 24px; }
    @media screen and (max-width: 1023px) {
      .solution-grid .box {
        width: 33.33%; } }
    @media screen and (max-width: 767px) {
      .solution-grid .box {
        width: 100%; }
        .solution-grid .box h4 {
          margin: 0 0 15px; } }
  .solution-grid ul {
    list-style: none;
    padding: 0;
    margin: 0; }
    .solution-grid ul li {
      padding: 0 0 10px; }
  .solution-grid .image {
    position: relative;
    margin: 0 0 17px;
    text-align: center; }
    .solution-grid .image a {
      display: inline-block;
      vertical-align: top; }
      .solution-grid .image a * > {
        display: block;
        margin: 0 auto; }
    .solution-grid .image img {
      display: block;
      margin: 0 auto; }
    @media screen and (max-width: 767px) {
      .solution-grid .image {
        margin: 0 0 12px; } }

.video-section {
  overflow: hidden;
  text-align: center; }
  .video-section a,
  .video-section .mcafee-video-full {
    position: relative;
    z-index: 3;
    display: block;
    height: 100%;
    text-decoration: none;
    color: #ffffff;
    background: #252728; }
    .video-section a:hover .bg,
    .video-section .mcafee-video-full:hover .bg {
      opacity: 0.7; }
  .video-section h2 {
    margin-bottom: 10px; }
  .video-section .bg {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    opacity: 0.8;
    transition: all 0.3s ease; }
    .video-section .bg video {
      width: 100%;
      height: auto;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background-size: cover;
      min-width: 100%; }
  @media screen and (min-width: 1000px) {
    .video-section {
      height: 405px; } }
  @media screen and (min-width: 767px) and (max-width: 999px) {
    .video-section {
      height: 350px; } }
  @media screen and (max-width: 1023px) {
    .video-section h2 {
      font-size: 24px; } }
  @media screen and (max-width: 767px) {
    .video-section a {
      height: 240px; } }
  @media screen and (max-width: 479px) {
    .video-section .bg video {
      width: 144%;
      left: 50%;
      transform: translate(-50%, -50%); } }

a.request-services {
  border-radius: 2px;
  color: #ffffff;
  text-decoration: none;
  padding: 11px 0;
  text-align: center;
  margin: 10px 0 0;
  text-transform: uppercase !important;
  font-size: 12px;
  display: block;
  font-weight: 600;
  letter-spacing: 1px;
  transition: all 0.3s;
  font-family: proxima-nova !important; }
  a.request-services.home {
    background: #ef9123;
    border: solid 3px #ef9123; }
    a.request-services.home:hover {
      background: none;
      color: #ef9123; }
  a.request-services.case-studies {
    background: none;
    color: #252728;
    border: solid 3px #252728;
    max-width: 300px;
    margin: 0 auto; }
    a.request-services.case-studies:hover {
      background: #252728;
      color: #ffffff; }
  a.request-services.work {
    background: #ef9123;
    max-width: 300px;
    border: solid 3px #ef9123;
    margin-top: 30px; }
    a.request-services.work:visited {
      color: #ffffff; }
    a.request-services.work:hover {
      background: none;
      color: #ef9123; }
  a.request-services.work-overview {
    background: none;
    max-width: 300px;
    border: solid 3px #ffffff;
    margin-top: 30px; }
    a.request-services.work-overview:hover {
      background: #ffffff;
      color: #ef9123; }
  a.request-services.solutions {
    background: #a3cf5f;
    border: solid 3px #a3cf5f;
    margin-top: 30px;
    max-width: 300px; }
    a.request-services.solutions:hover {
      background: none;
      color: #a3cf5f; }
  a.request-services.solutions-overview {
    background: none;
    max-width: 300px;
    border: solid 3px #ffffff;
    margin-top: 30px; }
    a.request-services.solutions-overview:hover {
      background: #ffffff;
      color: #a3cf5f; }
  a.request-services.about {
    background: #a072a5;
    max-width: 280px;
    border: solid 3px #a072a5; }
    a.request-services.about:hover {
      background: none;
      color: #a072a5; }
  a.request-services.awards {
    background: #252728;
    max-width: 300px;
    margin: 0 auto;
    border: solid 3px #252728; }
    a.request-services.awards:hover {
      background: none;
      border-color: #ffffff; }

/*** work css ***/
.common-top-section {
  position: relative;
  width: 100%;
  min-height: 134px;
  margin: 0;
  padding: 134px 0 0; }

body.solutions .common-top-section {
  background: #a3cf5f; }
  @media screen and (max-width: 730px) {
    body.solutions .common-top-section {
      padding-top: 154px; } }

body.work .common-top-section {
  background: #ef9123; }

body.about .common-top-section {
  background: #a072a5; }

ul.nav-sec {
  list-style: none; }

.sec-nav-container {
  width: 100%;
  position: fixed;
  top: 89px;
  padding: 12px 0;
  z-index: 4; }

body.solutions .sec-nav-container {
  background: #a3cf5f; }
  body.solutions .sec-nav-container a.active {
    color: #F0FFAC !important; }

body.work .sec-nav-container {
  background: #ef9123; }
  body.work .sec-nav-container a.active {
    color: #FFDE70 !important; }

body.about .sec-nav-container {
  background: #a072a5; }
  body.about .sec-nav-container a.active {
    color: #EDBFF2 !important; }

.sec-nav-wrapper {
  max-width: 1200px;
  margin: 0 auto; }
  @media screen and (max-width: 1300px) {
    .sec-nav-wrapper {
      margin: 0 40px; } }
  @media screen and (max-width: 580px) {
    .sec-nav-wrapper {
      margin: 0 20px; } }

ul.sec-nav li {
  display: inline-block;
  vertical-align: middle;
  margin: -1px 12px 1px 0; }
  ul.sec-nav li::after {
    content: "|";
    color: #ffffff;
    margin: 5px 0 0 14px;
    font-size: 70%;
    display: inline-block;
    vertical-align: top; }
  ul.sec-nav li:first-child::after {
    content: ":";
    margin: 3px 0 0 14px;
    font-size: 80%; }
  ul.sec-nav li:last-child::after {
    content: "";
    margin: 0 0 0 8px; }

ul.sec-nav a {
  text-transform: uppercase;
  text-decoration: none;
  color: #ffffff;
  font-family: "proxima-nova";
  font-size: 12px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  font-weight: 400;
  margin: 0;
  line-height: 14px;
  -webkit-font-smoothing: initial; }
  ul.sec-nav a:before {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 1px;
    background: #ffffff;
    transform: scale3d(0, 1, 1);
    transition: transform 0.2s; }
  ul.sec-nav a:hover:before {
    transform: scale3d(1, 1, 1); }

.top-section-wrapper {
  margin: 0 auto; }
  .solutions-inner .top-section-wrapper,
  .work-inner .top-section-wrapper,
  .about-inner .top-section-wrapper {
    background-color: #ffffff;
    max-width: none; }
    .solutions-inner .top-section-wrapper h1,
    .work-inner .top-section-wrapper h1,
    .about-inner .top-section-wrapper h1 {
      color: #707071;
      margin-top: 0; }
    .solutions-inner .top-section-wrapper h2,
    .work-inner .top-section-wrapper h2,
    .about-inner .top-section-wrapper h2 {
      color: #252728; }
    .solutions-inner .top-section-wrapper p,
    .work-inner .top-section-wrapper p,
    .about-inner .top-section-wrapper p {
      color: #707071; }
    .solutions-inner .top-section-wrapper span,
    .solutions-inner .top-section-wrapper span a,
    .work-inner .top-section-wrapper span,
    .work-inner .top-section-wrapper span a,
    .about-inner .top-section-wrapper span,
    .about-inner .top-section-wrapper span a {
      font-family: 'Poppins', serif;
      text-decoration: none; }
  .solutions-inner .top-section-wrapper h1 a {
    font-family: 'Poppins', serif;
    color: #a3cf5f;
    text-decoration: none; }
    .solutions-inner .top-section-wrapper h1 a:hover {
      text-decoration: underline; }
  .solutions-inner .top-section-wrapper p a {
    color: #a3cf5f; }
  .work-inner .top-section-wrapper h1 a {
    font-family: 'Poppins', serif;
    color: #ef9123;
    text-decoration: none; }
    .work-inner .top-section-wrapper h1 a:hover {
      text-decoration: underline; }
  .work-inner .top-section-wrapper p a {
    color: #ef9123; }
  .about-inner .top-section-wrapper h1 a {
    font-family: 'Poppins', serif;
    color: #a072a5;
    text-decoration: none; }
    .about-inner .top-section-wrapper h1 a:hover {
      text-decoration: underline; }
  .about-inner .top-section-wrapper p a {
    color: #a072a5; }
  .top-section-wrapper .top-section-inner {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 35px;
    padding-bottom: 71px; }
    @media screen and (max-width: 1300px) {
      .top-section-wrapper .top-section-inner {
        margin: 0 40px;
        overflow: hidden; } }
    @media screen and (max-width: 767px) {
      .top-section-wrapper .top-section-inner {
        padding-bottom: 40px;
        padding-top: 20px; } }
    @media screen and (max-width: 580px) {
      .top-section-wrapper .top-section-inner {
        padding-bottom: 20px;
        margin: 0 20px; } }
  .top-section-wrapper h1 {
    color: #ffffff;
    font-weight: 300;
    font-size: 24px;
    letter-spacing: -1.2px; }
    @media screen and (max-width: 780px) {
      .top-section-wrapper h1 {
        font-size: 20px; } }
  .top-section-wrapper h2 {
    color: #ffffff;
    margin: 3px 0 30px 0;
    font-weight: bold;
    font-size: 60px;
    letter-spacing: -1px;
    text-transform: uppercase;
    line-height: 1.1; }
    @media screen and (max-width: 1200px) {
      .top-section-wrapper h2 {
        font-size: 50px; } }
    @media screen and (max-width: 960px) {
      .top-section-wrapper h2 {
        font-size: 40px;
        margin-bottom: 15px; } }
    @media screen and (max-width: 768px) {
      .top-section-wrapper h2 {
        font-size: 32px; } }
    @media screen and (max-width: 580px) {
      .top-section-wrapper h2 {
        font-size: 26px;
        margin-bottom: 10px; } }
  .top-section-wrapper p {
    color: #ffffff;
    font-weight: 100;
    max-width: 1000px; }
    .top-section-wrapper p:last-child {
      margin-bottom: 0; }
    @media screen and (max-width: 580px) {
      .top-section-wrapper p {
        font-size: 16px;
        line-height: 1.2;
        margin-bottom: 10px; } }
    .top-section-wrapper p a {
      text-decoration: none; }
      .top-section-wrapper p a:hover {
        text-decoration: underline; }

.quote-wrapper {
  background-color: #d6dede;
  width: 100%; }

blockquote {
  padding: 71px 40px 85px 40px;
  text-align: center;
  max-width: 1280px;
  margin: 0 auto; }
  @media screen and (max-width: 767px) {
    blockquote {
      padding: 40px; } }
  @media screen and (max-width: 580px) {
    blockquote {
      padding: 20px; } }
  blockquote q {
    display: block;
    margin: 0 auto;
    max-width: 1200px;
    font-style: italic;
    font-weight: 300;
    font-size: 30px;
    letter-spacing: -1.3px;
    color: #252728;
    font-family: 'Poppins', serif;
    line-height: 1.6;
    padding-bottom: 14px; }
    @media screen and (max-width: 1023px) {
      blockquote q {
        font-size: 26px;
        line-height: 1.4;
        letter-spacing: -1.1px; } }
    @media screen and (max-width: 580px) {
      blockquote q {
        font-size: 24px;
        letter-spacing: -0.5px; } }
  blockquote cite {
    display: block;
    margin: 0;
    font-size: 16px;
    line-height: 1;
    color: #707071;
    font-style: normal; }

.grey-content-wrapper {
  margin: 0px auto; }

.grey-content-box {
  padding: 30px 0 50px 0;
  margin: 0 15px 0 15px; }
  .grey-content-box h4 {
    color: #707071;
    font-weight: 300;
    font-size: 26px;
    margin: 0 0 10px 0; }
  .grey-content-box p {
    color: #707071;
    margin: 0 0 18px 0;
    font-size: 14px; }

a.learn-more-btn {
  color: #ffffff;
  text-transform: uppercase;
  padding: 3px 10px 2px 10px;
  text-decoration: none;
  font-size: 12px;
  font-weight: 400;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s; }

.max-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 0; }

.drupal-logo {
  max-width: 1168px !important; }

.clients-logo-container {
  background: #d6dede; }

.clients-logo-wrapper {
  padding: 0;
  display: flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  overflow: hidden; }
  .clients-logo-wrapper.home {
    margin: 0 0 50px; }
    @media screen and (max-width: 767px) {
      .clients-logo-wrapper.home {
        margin: 0 0 40px; } }
  .clients-logo-wrapper > div {
    width: 25%;
    position: relative; }
    .clients-logo-wrapper > div:before {
      content: '';
      position: absolute;
      z-index: 1;
      bottom: -3px;
      left: 0;
      right: 0;
      background-image: linear-gradient(to right, #bac1c2 15%, #d6dede 16%);
      background-size: 22px 3px;
      background-repeat: repeat-x;
      background-position: 0 0;
      height: 3px; }
    .clients-logo-wrapper > div:after {
      content: '';
      position: absolute;
      z-index: 1;
      top: -4px;
      bottom: 0;
      right: -3px;
      background-image: linear-gradient(to bottom, #bac1c2 15%, #d6dede 16%);
      background-size: 3px 22px;
      background-repeat: repeat-y;
      background-position: 0 0;
      width: 3px; }
    .clients-logo-wrapper > div .box {
      width: 100%;
      position: relative;
      padding: 0; }
      .clients-logo-wrapper > div .box:before {
        content: "";
        display: block;
        padding-top: 100%; }
    @media screen and (max-width: 767px) {
      .clients-logo-wrapper > div {
        width: 50%; } }
  .clients-logo-wrapper a {
    display: block;
    text-decoration: none;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: transparent;
    transition: all 0.3s ease; }
    .clients-logo-wrapper a:hover {
      background: #252728; }
      .clients-logo-wrapper a:hover .anim-btn {
        border-color: #ffffff; }
        .clients-logo-wrapper a:hover .anim-btn .arrow:before {
          border-color: #252728; }
        .clients-logo-wrapper a:hover .anim-btn .arrow:after {
          background: #252728; }
        @media screen and (max-width: 1023px) {
          .clients-logo-wrapper a:hover .anim-btn {
            border-color: #252728; }
            .clients-logo-wrapper a:hover .anim-btn span {
              max-width: 0;
              width: 0;
              padding: 0; } }
      .clients-logo-wrapper a:hover img.hide {
        opacity: 1; }
  .clients-logo-wrapper img {
    width: 80%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    backface-visibility: hidden; }
    .clients-logo-wrapper img.hide {
      display: block;
      z-index: 4;
      opacity: 0;
      transition: opacity 0.3s ease; }
  .clients-logo-wrapper .anim-btn {
    position: absolute;
    bottom: 20px;
    right: 20px;
    color: #252728;
    border-color: #252728; }
    .clients-logo-wrapper .anim-btn .arrow:before {
      border-color: #252728; }
    .clients-logo-wrapper .anim-btn .arrow:after {
      background: #252728; }
    .ua-mobile .clients-logo-wrapper .anim-btn {
      background: transparent; }
      .ua-mobile .clients-logo-wrapper .anim-btn span {
        max-width: 0;
        padding: 0; }
      .ua-mobile .clients-logo-wrapper .anim-btn .arrow:before {
        border-color: #252728; }
      .ua-mobile .clients-logo-wrapper .anim-btn .arrow:after {
        background: #252728; }
    @media screen and (max-width: 1023px) {
      .clients-logo-wrapper .anim-btn {
        background: transparent; }
        .clients-logo-wrapper .anim-btn span {
          max-width: 0;
          padding: 0; } }
    @media screen and (max-width: 767px) {
      .clients-logo-wrapper .anim-btn {
        bottom: 10px;
        right: 10px; } }

#page_wrapper {
  overflow: hidden;
  position: relative; }

.work-logo-wrapper {
  max-width: 1200px;
  margin: 40px auto; }

.work-logo-wrapper div {
  width: 26.5%;
  display: inline-block;
  padding: 3%; }

.work-logo-wrapper img {
  width: 100%; }

.view-awards-container {
  padding-top: 433px; }

/*---------------------------------------------------------------*/
/* inside webenertia page css*/
/*---------------------------------------------------------------*/
.two-thirds-wrapper {
  width: 894px;
  width: calc(100% - 310px) !important;
  display: inline-block;
  margin: 20px 0; }
  .two-thirds-wrapper h4 {
    text-align: center; }

.one-third-box {
  display: inline-block;
  width: 32%;
  text-align: center;
  vertical-align: top; }
  .one-third-box p {
    margin: 8px 0; }
  .one-third-box.what-we-like p {
    font-size: 16px; }

.half-width-box {
  width: 50%;
  margin: 0 -2px;
  display: inline-block;
  vertical-align: top; }
  .half-width-box img {
    width: 100%; }

.email-list-form {
  margin: 0 0 60px 0; }

input.input-email-list {
  -webkit-appearance: none;
  border: solid 2px #707071;
  font-size: 16px;
  padding: 8px 6px;
  width: 220px;
  border-radius: 2px 0 0 2px;
  -webkit-border-radius: 2px 0 0 2px;
  text-align: center; }

input.email-submit-btn {
  appearance: none;
  border: solid 3px #707071;
  background: #707071;
  font-size: 12px;
  color: #ffffff;
  margin: 0 0 0 -8px;
  border-radius: 0 2px 2px 0;
  height: 40px;
  width: 60px;
  vertical-align: top; }
  input.email-submit-btn:hover {
    background: #252728;
    border: solid 3px #252728;
    cursor: pointer; }

/*---------------------------------------------------------------*/
/* case studies grid css*/
/*---------------------------------------------------------------*/
.grid-container {
  padding: 80px 0 0 0;
  background: #d6dede; }

.grid {
  padding: 20px 40px 100px 40px;
  max-width: 1600px;
  margin: 0 auto;
  list-style: none; }
  @media screen and (max-width: 580px) {
    .grid {
      padding: 20px;
      margin: 0; } }
  .grid.creative {
    padding-bottom: 80px; }
  .grid li {
    float: left;
    margin: 0;
    text-align: left;
    position: relative;
    width: 25%; }
    @media screen and (max-width: 920px) {
      .grid li {
        width: 50%; } }
    @media screen and (max-width: 580px) {
      .grid li {
        width: 100%;
        float: none; } }
  .grid figure {
    margin: 0;
    position: relative; }
    .grid figure img {
      max-width: 100%;
      display: block;
      position: relative; }
      @media screen and (max-width: 580px) {
        .grid figure img {
          width: 100%; } }
  .grid figcaption {
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px 4% 40px 4%;
    background: #252728;
    color: #707071;
    width: 100%; }
    @media screen and (max-width: 780px) {
      .grid figcaption {
        padding-top: 12px;
        padding-bottom: 20px;
        min-height: 90px; } }
    .grid figcaption h3 {
      margin: 0;
      padding: 0;
      color: #ffffff;
      font-weight: 300;
      font-size: 24px;
      line-height: 1.5; }
      @media screen and (max-width: 1300px) {
        .grid figcaption h3 {
          font-size: 20px; } }

.cs-style-3 figure {
  overflow: hidden; }
  .cs-style-3 figure img {
    -webkit-transition: -webkit-transform 0.4s;
    -moz-transition: -moz-transform 0.4s;
    transition: transform 0.4s; }

.cs-style-3 figcaption {
  top: auto;
  bottom: 0;
  opacity: 0;
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: -webkit-transform 0.4s, opacity 0.1s 0.3s;
  -moz-transition: -moz-transform 0.4s, opacity 0.1s 0.3s;
  transition: transform 0.4s, opacity 0.1s 0.3s; }
  .cs-style-3 figcaption a {
    position: absolute;
    bottom: 20px;
    right: 20px; }

.cs-style-3 .cse-itm {
  overflow: hidden; }
  .cs-style-3 .cse-itm a {
    text-decoration: none; }
  .cs-style-3 .cse-itm img {
    -webkit-transition: -webkit-transform 0.4s;
    -moz-transition: -moz-transform 0.4s;
    transition: transform 0.4s; }
  .cs-style-3 .cse-itm .cse-cptn {
    top: auto;
    bottom: 0;
    -webkit-transform: translateY(28%);
    -moz-transform: translateY(28%);
    -ms-transform: translateY(28%);
    transform: translateY(28%);
    -webkit-transition: -webkit-transform 0.4s, opacity 0.1s 0.3s;
    -moz-transition: -moz-transform 0.4s, opacity 0.1s 0.3s;
    transition: transform 0.4s, opacity 0.1s 0.3s; }

.no-touch .cs-style-3 figure:hover img,
.cs-style-3 figure.cs-hover img {
  -webkit-transform: translateY(-50px);
  -moz-transform: translateY(-50px);
  -ms-transform: translateY(-50px);
  transform: translateY(-50px);
  opacity: 1; }

.no-touch .cs-style-3 figure:hover figcaption,
.cs-style-3 figure.cs-hover figcaption {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition: -webkit-transform 0.4s, opacity 0.1s;
  -moz-transition: -moz-transform 0.4s, opacity 0.1s;
  transition: transform 0.4s, opacity 0.1s; }

.no-touch .cs-style-3 .cse-itm:hover img,
.cs-style-3 .cse-itm.cs-hover img {
  -webkit-transform: translateY(-50px);
  -moz-transform: translateY(-50px);
  -ms-transform: translateY(-50px);
  transform: translateY(-50px);
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  opacity: 1; }

.no-touch .cs-style-3 .cse-itm:hover .cse-cptn,
.cs-style-3 .cse-itm.cs-hover .cse-cptn {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition: -webkit-transform 0.4s, opacity 0.1s;
  -moz-transition: -moz-transform 0.4s, opacity 0.1s;
  transition: transform 0.4s, opacity 0.1s; }

.crsl-hm-wrppr {
  overflow: hidden;
  position: relative;
  background: #252728; }
  .crsl-hm-wrppr .slick-slider {
    margin: 0;
    z-index: 4; }
  .crsl-hm-wrppr .intro-text-holder {
    position: absolute;
    top: 89px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    text-transform: uppercase;
    text-align: center;
    opacity: 0; }
    .load-done .crsl-hm-wrppr .intro-text-holder {
      -webkit-animation-name: fadeInUpCustom;
      animation-name: fadeInUpCustom;
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both; }
  .crsl-hm-wrppr .intro-text {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    padding: 15px;
    width: 100%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-family: 'Poppins', serif;
    color: white;
    font: 50px/1.2 'Poppins', serif; }
    @media screen and (max-width: 1449px) {
      .crsl-hm-wrppr .intro-text {
        font-size: 40px; } }
    @media screen and (max-width: 1023px) {
      .crsl-hm-wrppr .intro-text {
        font-size: 32px; } }
    @media screen and (max-width: 767px) {
      .crsl-hm-wrppr .intro-text {
        font-size: 24px; } }
    .crsl-hm-wrppr .intro-text span {
      font-family: 'Poppins', serif; }
    .crsl-hm-wrppr .intro-text strong {
      font-family: 'Poppins', serif;
      display: block;
      font-weight: bold;
      font-size: 110px;
      letter-spacing: -2px;
      margin: 0 0 -15px; }
      @media screen and (max-width: 1449px) {
        .crsl-hm-wrppr .intro-text strong {
          font-size: 80px;
          margin: 0 0 -7px;
          letter-spacing: -1px; } }
      @media screen and (max-width: 1023px) {
        .crsl-hm-wrppr .intro-text strong {
          font-size: 60px; } }
      @media screen and (max-width: 767px) {
        .crsl-hm-wrppr .intro-text strong {
          font-size: 45px;
          margin: 0; } }

.grid-hm .cse-itm {
  width: 100%;
  padding-top: 120%;
  margin: 0;
  text-align: left;
  position: relative;
  overflow: hidden;
  font-size: 14px; }
  @media screen and (max-width: 1449px) {
    .grid-hm .cse-itm {
      padding-top: 140%; } }
  @media screen and (max-width: 1249px) {
    .grid-hm .cse-itm {
      padding-top: 155%; } }
  @media screen and (max-width: 1023px) {
    .grid-hm .cse-itm {
      padding-top: 125%;
      font-size: 12px; } }
  @media screen and (max-width: 767px) {
    .grid-hm .cse-itm {
      padding-top: 88%; } }
  @media screen and (max-width: 519px) {
    .grid-hm .cse-itm {
      padding-top: 128%; } }
  .grid-hm .cse-itm img {
    position: absolute;
    width: 100%;
    height: auto;
    max-width: none;
    top: 50%;
    left: 0%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: .80;
    -webkit-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out; }
  .grid-hm .cse-itm:hover img {
    opacity: 0.7; }
  .grid-hm .cse-itm h3 {
    font: normal 30px/1.2 'Poppins', serif;
    margin: 0 0 13px;
    color: #ffffff; }
    @media screen and (max-width: 1023px) {
      .grid-hm .cse-itm h3 {
        font-size: 24px; } }
  .grid-hm .cse-itm hr {
    margin: 0 0 16px;
    width: 70px;
    border: 1px solid #ffffff; }
    @media screen and (max-width: 1023px) {
      .grid-hm .cse-itm hr {
        margin: 0 0 12px; } }
  .grid-hm .cse-itm a {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 166px 62px 75px;
    color: #ffffff;
    text-decoration: none; }
    @media screen and (max-width: 1099px) {
      .grid-hm .cse-itm a {
        padding: 120px 62px 70px; } }
    @media screen and (max-width: 1023px) {
      .grid-hm .cse-itm a {
        padding: 120px 62px 60px; } }
  .grid-hm .cse-itm .head {
    overflow: hidden;
    margin: 0 0 11px;
    font-size: 14px; }
    .grid-hm .cse-itm .head strong {
      display: inline-block;
      vertical-align: top;
      margin: 0 9px 0 0; }
    @media screen and (max-width: 1023px) {
      .grid-hm .cse-itm .head {
        font-size: 12px; } }
  .grid-hm .cse-itm .separator {
    display: inline-block;
    vertical-align: top;
    padding: 0 9px 0 0;
    height: 14px;
    border-left: 1px solid #ffffff; }
  .grid-hm .cse-itm p {
    text-transform: uppercase;
    margin: 0 0 4px;
    font-weight: bold;
    min-height: 0; }
  .grid-hm .cse-itm .large {
    font-size: 24px; }
    @media screen and (max-width: 1023px) {
      .grid-hm .cse-itm .large {
        font-size: 17px; } }
  .grid-hm .cse-itm .anim-btn {
    position: absolute;
    right: 62px;
    bottom: 30px; }
    @media screen and (max-width: 1023px) {
      .grid-hm .cse-itm .anim-btn {
        background: #ffffff; }
        .grid-hm .cse-itm .anim-btn span {
          max-width: 200px;
          padding: 0 5px 0 10px;
          z-index: 5; }
        .grid-hm .cse-itm .anim-btn .arrow:before {
          border-color: #252728; }
        .grid-hm .cse-itm .anim-btn .arrow:after {
          background: #252728; } }

.crsl-home .slick-prev, .crsl-home .slick-next {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease 2.5s;
  transition: opacity 0.3s ease 2.5s; }

.crsl-home .grid-hm {
  background: #252728;
  position: relative; }
  .crsl-home .grid-hm.slick-active {
    opacity: 0;
    transition: top 0.7s ease 2.3s;
    top: -100vh; }
    .crsl-home .grid-hm.slick-active + .slick-active {
      -webkit-transition-delay: 2.2s;
      transition-delay: 2.2s; }
    .crsl-home .grid-hm.slick-active + .slick-active + .slick-active {
      -webkit-transition-delay: 2.1s;
      transition-delay: 2.1s; }

.load-done .crsl-home .slick-prev, .load-done .crsl-home .slick-next {
  opacity: 1; }

.load-done .crsl-home .grid-hm.slick-active {
  opacity: 1;
  top: 0; }

.anim-btn {
  display: inline-block;
  vertical-align: top;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  white-space: nowrap;
  line-height: 24px;
  border: 1px solid #ffffff;
  border-radius: 2px;
  position: relative;
  padding: 0 24px 0 0;
  overflow: hidden;
  transition: all 0.3s ease;
  background: transparent; }
  .anim-btn span {
    display: inline-block;
    vertical-align: top;
    max-width: 0;
    overflow: hidden;
    color: #252728;
    transition: all 0.3s ease; }
  .anim-btn .arrow {
    width: 16px;
    height: 12px;
    position: absolute;
    top: 6px;
    display: block;
    max-width: none;
    right: 5px;
    overflow: visible;
    transition: all 0.3s ease; }
    .anim-btn .arrow:before {
      content: '';
      position: absolute;
      transition: all 0.3s ease;
      right: 0;
      top: 50%;
      width: 9px;
      height: 9px;
      border: 2px solid #ffffff;
      border-width: 0 2px 2px 0;
      transform: translateY(-53%) rotate(-45deg); }
    .anim-btn .arrow:after {
      transition: all 0.3s ease;
      content: '';
      position: absolute;
      right: 1px;
      top: 50%;
      width: 14px;
      height: 2px;
      background: #ffffff;
      margin-top: -1px; }
  .ua-mobile .anim-btn {
    background: #ffffff; }
    .ua-mobile .anim-btn span {
      max-width: 200px;
      padding: 0 5px 0 10px; }
    .ua-mobile .anim-btn .arrow:before {
      border-color: #252728; }
    .ua-mobile .anim-btn .arrow:after {
      background: #252728; }
  @media screen and (max-width: 1023px) {
    .anim-btn {
      background: #ffffff; }
      .anim-btn span {
        max-width: 200px;
        padding: 0 5px 0 10px; }
      .anim-btn .arrow:before {
        border-color: #252728; }
      .anim-btn .arrow:after {
        background: #252728; } }

a:hover .anim-btn {
  background: #ffffff; }
  a:hover .anim-btn span {
    max-width: 200px;
    padding: 0 5px 0 10px; }
  a:hover .anim-btn .arrow:before {
    border-color: #252728; }
  a:hover .anim-btn .arrow:after {
    background: #252728; }

.play-icon {
  display: inline-block;
  vertical-align: top;
  width: 42px;
  height: 42px;
  background: url("/img/play-icon.png?ver=2.5.5") center center no-repeat;
  background-size: cover; }

/*grid home blogs -----------------------------------------------*/
.box {
  overflow: hidden; }
  .box a.blg-link-box {
    position: relative; }
    .box a.blg-link-box .blg-cptn {
      top: auto;
      -webkit-transform: translateY(100%);
      -moz-transform: translateY(100%);
      -ms-transform: translateY(100%);
      transform: translateY(100%);
      -webkit-transition: -webkit-transform 0.4s, opacity 0.1s 0.3s;
      -moz-transition: -moz-transform 0.4s, opacity 0.1s 0.3s;
      transition: transform 0.4s, opacity 0.1s 0.3s;
      padding: 12px;
      background: #252728;
      color: #707071;
      width: 100%;
      margin: -44px 0 0 0; }
      .box a.blg-link-box .blg-cptn span {
        text-align: center;
        padding: 3px 10px 2px;
        border-radius: 2px;
        display: inline-block;
        background: #707071;
        color: #707071;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 12px; }

.box a.blg-link-box:hover .blg-cptn,
.box .cse-itm.cs-hover .blg-cptn {
  opacity: 1;
  -webkit-transform: translateY(3%);
  -moz-transform: translateY(3%);
  -ms-transform: translateY(3%);
  transform: translateY(3%);
  -webkit-transition: -webkit-transform 0.4s, opacity 0.1s;
  -moz-transition: -moz-transform 0.4s, opacity 0.1s;
  transition: transform 0.4s, opacity 0.1s; }

/*---------------------------------------------------------------*/
/* case studies each css*/
/*---------------------------------------------------------------*/
.case-study-hoover-top {
  background: url("/img/case-studies/hoover/hoover_header_bg1.jpg?ver=2.5.5") center;
  background-size: cover; }

.case-study-ea-top {
  background: url("/img/case-studies/ea/ea_header_bg.jpg?ver=2.5.5") center;
  background-size: cover; }

.case-study-drupal-top {
  background: url("/img/case-studies/drupal/banner1.jpg?ver=2.5.5") center;
  background-size: cover; }

.case-study-instantly-top {
  background: url("/img/case-studies/instantly/banner.jpg?ver=2.5.5") center;
  background-size: cover; }
  @media screen and (max-width: 767px) {
    .case-study-instantly-top .case-banner-right-box {
      position: absolute;
      left: 0; }
      .case-study-instantly-top .case-banner-right-box img {
        width: 85%; } }

.case-study-rae-top {
  background: url("/img/case-studies/rae-systems/banner.jpg?ver=2.5.5") center;
  background-size: cover; }

.case-study-vocera-top {
  background: url("/img/case-studies/vocera/vocera_banner_2.jpg?ver=2.5.5") center;
  background-size: cover; }

.case-study-silverpeak-top {
  background: url("/img/case-studies/silver-peak/banner.jpg?ver=2.5.5") center;
  background-size: cover; }

.case-study-icontrol-top {
  background: url("/img/case-studies/icontrol/banner.jpg?ver=2.5.5") center;
  background-size: cover; }

.case-study-acemetrix-top {
  background: url("/img/case-studies/acemetrix/video/webenertia_acemetrix_banner.jpg?ver=2.5.5") center;
  background-size: cover;
  position: relative;
  overflow: hidden; }
  .case-study-acemetrix-top video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
    .ua-mobile .case-study-acemetrix-top video {
      display: none; }
  .case-study-acemetrix-top .case-logo-each {
    max-width: 230px; }
  .case-study-acemetrix-top .case-banner-right-box {
    /*width: 56%;*/
    width: 47%; }
    @media screen and (max-width: 780px) {
      .case-study-acemetrix-top .case-banner-right-box {
        width: 47%;
        position: absolute;
        bottom: 0;
        right: 0; } }
    @media screen and (max-width: 600px) {
      .case-study-acemetrix-top .case-banner-right-box {
        width: 100%;
        max-width: 360px;
        position: absolute;
        bottom: -15px; } }
  .case-study-acemetrix-top .case-banner-right-box img {
    max-width: 500px; }

.case-study-full-width {
  background-size: cover; }
  .case-study-full-width .case-study-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    box-sizing: content-box; }
  .case-study-full-width .case-logo-each {
    max-width: 236px; }
  .case-study-full-width .case-banner-right-box {
    width: 47%; }
    @media screen and (max-width: 780px) {
      .case-study-full-width .case-banner-right-box {
        width: 52%;
        position: absolute;
        bottom: 0;
        right: 0; } }
    @media screen and (max-width: 600px) {
      .case-study-full-width .case-banner-right-box {
        width: 100%;
        max-width: 360px;
        position: absolute;
        bottom: 0; } }

.case-study-riverbed-top {
  background: url("/img/case-studies/riverbed/webenertia_riverbed_banner_a.jpg?ver=2.5.5") center;
  background-size: cover; }
  .case-study-riverbed-top .case-logo-each {
    max-width: 236px; }
  .case-study-riverbed-top .case-banner-right-box {
    /*width: 56%;*/
    width: 47%; }
    @media screen and (max-width: 780px) {
      .case-study-riverbed-top .case-banner-right-box {
        width: 52%;
        position: absolute;
        bottom: 0;
        right: 0; } }
    @media screen and (max-width: 600px) {
      .case-study-riverbed-top .case-banner-right-box {
        width: 100%;
        max-width: 360px;
        position: absolute;
        bottom: 0; } }
  @media screen and (max-width: 767px) {
    .case-study-riverbed-top .case-top-wrapper {
      margin: 0 40px;
      padding: 50px 0 0; } }
  @media screen and (max-width: 580px) {
    .case-study-riverbed-top .case-top-wrapper {
      margin: 0 20px; } }

.case-study-intel-top {
  background: url("/img/case-studies/intel/banner-intel.jpg?ver=2.5.5") center;
  background-size: cover; }
  .case-study-intel-top .case-logo-each {
    padding-top: 30px;
    max-width: 300px; }
  .case-study-intel-top .case-banner-right-box {
    width: 47%; }
    @media screen and (max-width: 780px) {
      .case-study-intel-top .case-banner-right-box {
        width: 47%;
        position: absolute;
        right: 0;
        bottom: 0; } }
    @media screen and (max-width: 600px) {
      .case-study-intel-top .case-banner-right-box {
        width: 100%;
        max-width: 360px;
        position: static;
        bottom: 0; } }
  @media screen and (max-width: 767px) {
    .case-study-intel-top .case-top-wrapper {
      margin: 0 40px;
      padding: 50px 0 0; } }
  @media screen and (max-width: 580px) {
    .case-study-intel-top .case-top-wrapper {
      margin: 0 20px; } }

.case-study-hortonworks-top {
  background: url("/img/case-studies/hortonworks/webenertia_hortonworks_banner.jpg?ver=2.5.5") center top;
  background-size: cover; }
  .case-study-hortonworks-top .case-logo-each {
    max-width: 300px;
    margin-top: 40px; }
  .case-study-hortonworks-top .case-top-wrapper {
    min-height: 440px; }
    .case-study-hortonworks-top .case-top-wrapper h1 {
      color: #252728; }
    .case-study-hortonworks-top .case-top-wrapper .case-banner-right-box {
      max-width: 530px; }

.case-study-vocera-top h1, .case-study-icontrol-top h1 {
  color: #707071 !important; }

.rvrbed-nav-component {
  position: relative;
  box-sizing: border-box; }
  .rvrbed-nav-component .max-container .rvrbd-nav-lbl-box {
    width: 48%;
    position: relative;
    display: inline-block;
    vertical-align: middle; }
    @media screen and (max-width: 1023px) {
      .rvrbed-nav-component .max-container .rvrbd-nav-lbl-box {
        width: 60%; } }
    .rvrbed-nav-component .max-container .rvrbd-nav-lbl-box img {
      max-width: 110px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 2%; }
    .rvrbed-nav-component .max-container .rvrbd-nav-lbl-box .txt-holder {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 150px); }
    @media screen and (max-width: 900px) {
      .rvrbed-nav-component .max-container .rvrbd-nav-lbl-box {
        display: block;
        width: 100%; } }
    @media screen and (max-width: 420px) {
      .rvrbed-nav-component .max-container .rvrbd-nav-lbl-box img {
        max-width: 80px;
        display: block;
        margin: 0 auto 20px; }
      .rvrbed-nav-component .max-container .rvrbd-nav-lbl-box .txt-holder {
        display: block;
        width: 100%; } }
  .rvrbed-nav-component .max-container .rvrbd-video {
    position: relative;
    display: inline-block;
    width: 48%;
    vertical-align: middle;
    right: -82px; }
    @media screen and (max-width: 1023px) {
      .rvrbed-nav-component .max-container .rvrbd-video {
        width: 35%; } }
    @media screen and (max-width: 900px) {
      .rvrbed-nav-component .max-container .rvrbd-video {
        display: block;
        width: 100%;
        right: auto;
        margin: 0 auto; } }
    .ua-mobile .rvrbed-nav-component .max-container .rvrbd-video {
      display: none; }
    .rvrbed-nav-component .max-container .rvrbd-video video {
      max-width: 100%; }

.full-width-slider .item .hover-expand-grid {
  padding: 60px 50px 0 50px; }
  .full-width-slider .item .hover-expand-grid .e-one {
    margin-bottom: 6px; }
    .full-width-slider .item .hover-expand-grid .e-one img {
      width: 100%;
      max-width: 720px;
      -webkit-transition: -webkit-transform all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      .full-width-slider .item .hover-expand-grid .e-one img:hover {
        background: rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 10px 20px 0px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 10px 20px 0px 0px rgba(0, 0, 0, 0.2);
        -webkit-transform: translate(0px, -30px) scale(1.1);
        -ms-transform: translate(0px, -30px) scale(1.1);
        transform: translate(0px, -30px) scale(1.1); }
  .full-width-slider .item .hover-expand-grid .e-two {
    margin-bottom: 6px; }
    .full-width-slider .item .hover-expand-grid .e-two img {
      width: 100%;
      max-width: 720px;
      -webkit-transition: -webkit-transform all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      .full-width-slider .item .hover-expand-grid .e-two img:hover {
        background: rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 10px 20px 0px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 10px 20px 0px 0px rgba(0, 0, 0, 0.2);
        -webkit-transform: translate(0px, 7px) scale(1.1);
        -ms-transform: translate(0px, 7px) scale(1.1);
        transform: translate(0px, 7px) scale(1.1); }
  .full-width-slider .item .hover-expand-grid .e-three {
    margin-bottom: 50px; }
    .full-width-slider .item .hover-expand-grid .e-three img {
      width: 100%;
      max-width: 720px;
      -webkit-transition: -webkit-transform all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      .full-width-slider .item .hover-expand-grid .e-three img:hover {
        background: rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 10px 20px 0px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 10px 20px 0px 0px rgba(0, 0, 0, 0.2);
        -webkit-transform: translate(0px, 14px) scale(1.1);
        -ms-transform: translate(0px, 14px) scale(1.1);
        transform: translate(0px, 14px) scale(1.1); }

.case-top-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 0 0 0;
  min-height: 360px;
  position: relative;
  box-sizing: content-box; }
  @media screen and (max-width: 1300px) {
    .case-top-wrapper {
      margin: 0 40px; } }
  @media screen and (max-width: 580px) {
    .case-top-wrapper {
      margin: 0 20px; } }
  .case-top-wrapper h1 {
    color: #ffffff;
    font-weight: 300;
    font-size: 38px;
    line-height: 1; }

.case-banner-left-box {
  width: 52%;
  display: inline-block;
  vertical-align: top; }

.case-banner-right-box {
  width: 47%;
  display: inline-block;
  vertical-align: bottom;
  position: absolute;
  bottom: 0;
  right: 0; }
  .case-banner-right-box.overflow-hidden {
    overflow: hidden; }
  .case-banner-right-box img {
    width: 100%; }

.case-logo-each {
  width: 300px;
  margin: 0 0 20px 0; }
  .case-logo-each img {
    width: 100%; }
  .case-logo-each.rae-sys {
    margin-left: -4px; }
  .case-logo-each.indx img {
    max-width: 249px; }
  .case-logo-each.slvr-pk img {
    max-width: 249px; }

.case-info-full {
  max-width: 80%;
  margin: 0 auto;
  padding: 40px 0; }

.case-info-half {
  width: 45%;
  display: inline-block;
  vertical-align: top;
  margin: 0 4% 0 0; }
  .case-info-half:last-child {
    margin-right: 0; }
  .dark-blue-grey .case-info-half p {
    color: #ffffff; }
  .case-info-half.dplblk {
    display: block; }
  .case-info-half ul {
    color: #ef9123;
    display: inline-block;
    vertical-align: top;
    margin: 0 60px 0 20px; }
    .case-info-half ul li {
      margin: 0 0 8px 0; }
      .case-info-half ul li a {
        text-decoration: none; }
        .case-info-half ul li a:hover {
          text-decoration: underline; }

.grybar-two-thrds {
  width: 65%;
  padding: 53px 0 40px;
  display: inline-block;
  vertical-align: top;
  margin: 0 4% 0 0; }
  .grybar-two-thrds h4 {
    color: #707071;
    font-family: 'Poppins', serif;
    font-weight: 300;
    font-size: 24px;
    margin: 0 0 10px; }
  .grybar-two-thrds ul {
    color: #ef9123;
    display: inline-block;
    vertical-align: top;
    margin: 0 60px 0 20px; }
    .grybar-two-thrds ul li {
      margin: 0 0 8px 0; }
      .grybar-two-thrds ul li a {
        text-decoration: none; }
        .grybar-two-thrds ul li a:hover {
          text-decoration: underline; }

.graybar-half {
  width: 50%;
  padding: 0 2% 0 0;
  display: inline-block;
  vertical-align: top;
  margin: 0 4% 0 0;
  box-sizing: border-box; }
  .graybar-half h2 {
    margin-bottom: 10px;
    font-weight: 300; }
  .graybar-half p:last-child {
    margin: 0; }
  @media screen and (max-width: 780px) {
    .graybar-half {
      width: 100%;
      padding-bottom: 20px; } }
  @media screen and (max-width: 520px) {
    .graybar-half {
      padding-bottom: 10px; } }

.graybar-one-third {
  width: 33%;
  padding: 0;
  display: inline-block;
  vertical-align: top; }
  @media screen and (max-width: 780px) {
    .graybar-one-third {
      width: 100%;
      padding-top: 20px; } }
  @media screen and (max-width: 520px) {
    .graybar-one-third {
      padding-top: 10px; } }
  .graybar-one-third h2 {
    margin-bottom: 10px;
    font-weight: 300; }
  .graybar-one-third ul {
    color: #707071;
    display: inline-block;
    vertical-align: top;
    padding: 0 15px 0 0;
    margin: 0 0 0 20px;
    font-size: 20px;
    line-height: 1.4; }
    .graybar-one-third ul:last-child {
      padding-right: 0; }
    @media screen and (max-width: 1023px) {
      .graybar-one-third ul {
        font-size: 18px; } }
    @media screen and (max-width: 500px) {
      .graybar-one-third ul {
        font-size: 16px; } }
    .graybar-one-third ul li {
      margin: 0 0 8px 0; }
      .graybar-one-third ul li a {
        text-decoration: none; }
        .graybar-one-third ul li a:hover {
          text-decoration: underline; }

.rbrbd-wireframes .col {
  width: 33.333%;
  float: left;
  padding: 10px 2%;
  box-sizing: border-box; }
  .rbrbd-wireframes .col img {
    width: 100%; }

@media screen and (max-width: 780px) {
  .rbrbd-wireframes {
    margin-top: 30px; } }

/**/
.gmscm-cntr {
  text-align: center; }
  .gmscm-cntr img {
    margin: 0 auto;
    width: 100%;
    max-width: 881px; }

.full-width-cntr-mdia {
  background: #d6dede;
  padding: 60px 0; }

.full-width-cntr-mdia img {
  margin: 0 auto;
  width: 100%;
  max-width: 652px; }

.full-width-lght-gry {
  background: #d6dede;
  padding: 80px 0; }

.full-width-slider {
  background: #d6dede;
  text-align: center; }
  .full-width-slider.mcafee {
    background: #ffffff;
    padding-top: 0; }
    .full-width-slider.mcafee .item img {
      max-width: none; }
    .full-width-slider.mcafee.not-full-width {
      padding-top: 71px; }
      .full-width-slider.mcafee.not-full-width .item img {
        max-width: 1200px;
        -webkit-box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1); }
  .full-width-slider .item img {
    max-width: 1200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto; }
  .full-width-slider .case-logo-smaller {
    width: 70% !important;
    margin-left: 0px !important; }
  .full-width-slider .riverbed-monitor {
    max-width: 1200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    left: 50px; }
  .full-width-slider.hortonworks {
    background: #ffffff; }
    .full-width-slider.hortonworks .item img {
      width: 80%;
      max-width: 1096px; }
  .full-width-slider.intel .item .item-wrapper {
    margin: 0 auto;
    width: 80%;
    max-width: 1200px; }
  .full-width-slider.intel .item .txt-holder {
    width: 100%;
    max-width: 80%;
    margin: 0 auto 40px;
    text-align: center; }
  .full-width-slider.intel .item.one img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 4%;
    width: 50%; }
    @media screen and (max-width: 700px) {
      .full-width-slider.intel .item.one img {
        width: 70%;
        max-width: 70%;
        display: block; } }
  .full-width-slider.intel .item.one .txt-holder {
    max-width: 40%;
    display: inline-block;
    vertical-align: middle;
    text-align: left; }
    @media screen and (max-width: 700px) {
      .full-width-slider.intel .item.one .txt-holder {
        width: 80%;
        max-width: 80%;
        display: block; } }
  .full-width-slider.acmtrx .item img {
    width: 90%;
    max-width: 1100px;
    margin-top: 40px; }
    @media screen and (max-height: 860px) {
      .full-width-slider.acmtrx .item img {
        max-width: 900px; } }
  .full-width-slider.fs-instly {
    padding: 40px 0 0 0; }

.demo .container {
  max-width: none;
  padding: 0; }

.drpl-prllx-box {
  background: url("/img/case-studies/drupal/addy.jpg?ver=2.5.5") center;
  background-attachment: fixed;
  background-size: cover;
  min-height: 600px; }

.wrk-awrds-prllx {
  background: url("/img/work_aaa_bg.png?ver=2.5.5") center no-repeat #000;
  background-attachment: fixed;
  background-size: 900px;
  text-align: center;
  padding: 150px 0 80px 0; }

.wrk-awrds-prllx h2 {
  color: #ffffff;
  font-size: 100px;
  font-weight: 300;
  margin: 0 0 100px 0; }

.wrk-awrds-prllx span {
  font-size: 120px;
  font-family: inherit;
  font-weight: 600;
  display: inline-block;
  min-width: 200px; }

.awrds-yr-clmn {
  width: 16%;
  display: inline-block;
  vertical-align: top;
  padding: 26px 0 0 0; }

.awrds-yr-clmn h2 {
  font-size: 54px;
  font-weight: 300; }

.awrds-yr-clmn a,
.awrds-list-clmn a {
  text-decoration: none;
  font-family: 'Poppins', serif; }

.awrds-list-clmn p {
  font-size: 14px; }

.awrds-list-clmn-wrapper {
  width: 83%;
  display: inline-block; }

.awrds-list-clmn {
  width: 48%;
  min-height: 200px;
  display: inline-block;
  vertical-align: top;
  font-size: 12px; }
  .awrds-list-clmn div {
    padding-left: 50px; }
  .awrds-list-clmn h4 {
    margin: 44px 0 8px 0;
    color: #707071;
    font-size: 24px;
    letter-spacing: -1.1px;
    font-weight: 300; }

.awrds-yr-brk {
  width: 100%;
  background: url("/img/hrz-dot-brk.png?ver=2.5.5") repeat-x;
  height: 9px;
  margin: 70px 0 20px 0; }

div.awrds-bstof-shw {
  background: url("/img/work/best_show.png?ver=2.5.5") no-repeat;
  background-size: 39px 51px; }

div.awrds-judge {
  background: url("/img/work/judges.png?ver=2.5.5") no-repeat;
  background-size: 39px 51px; }

div.awrds-statue {
  background: url("/img/work/statue.png?ver=2.5.5") no-repeat;
  background-size: 39px 51px; }

div.awrds-pixel {
  background: url("/img/work/icon_pixel_logo.png?ver=2.5.5") no-repeat;
  background-size: 39px 51px; }

div.awrds-lma {
  background: url("/img/work/icon_lma.png?ver=2.5.5") no-repeat;
  background-size: 39px 51px; }

.contact-us-form input.cntc-halfw-inpt-left {
  width: 48%;
  display: inline-block;
  margin: 10px 1% 10px 0;
  padding: 18px 12px;
  border: solid 1px #ffffff;
  font-size: 16px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box; }

.contact-us-form input.cntc-halfw-inpt-right {
  width: 48.5%;
  display: inline-block;
  margin: 10px 0 10px 1%;
  padding: 20px 12px;
  border: solid 1px #ffffff;
  font-size: 16px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box; }

.contact-us-form select.cntc-halfw-inpt-right {
  appearance: button;
  user-select: none;
  /*
    padding-end: 20px;
    padding-start: 2px;
*/
  background-color: #ffffff;
  background-image: url(/img/select_arrow.png?ver=2.5.5);
  background-position: center right;
  background-repeat: no-repeat;
  background-size: 32px;
  color: #707071;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 48.5%;
  display: inline-block;
  margin: 10px 0 -24px 1%;
  padding: 18px 12px;
  border: solid 1px #ffffff;
  border-radius: 0px;
  font-size: 16px; }
  .contact-us-form select.cntc-halfw-inpt-right::ms-expand {
    opacity: 0.01; }

@-moz-document url-prefix() {}.contact-us-form textarea.cntc-fullw-inpt {
  width: 99%;
  margin: 10px 0;
  border: solid 1px #ffffff;
  padding: 20px 12px;
  border: solid 1px #ffffff;
  font-size: 16px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box; }

.contact-us-form .frm-bttn-wrapper {
  width: 300px;
  margin: 10px auto 0 auto; }

.contact-us-form input.contact-us-submit {
  -webkit-appearance: none;
  text-align: center;
  color: #ffffff;
  margin: 10px auto;
  margin-bottom: 0;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  background: #a072a5;
  padding: 10px;
  font-size: 12px;
  font-weight: 600;
  font-family: proxima-nova;
  min-width: 300px;
  text-transform: uppercase;
  cursor: pointer;
  border: solid 3px #a072a5;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  letter-spacing: 1px; }

.contact-us-form ul.bdgt-type {
  list-style-type: none;
  width: 48%;
  display: inline-block;
  margin: 10px 1% 10px 0;
  vertical-align: top; }
  .contact-us-form ul.bdgt-type li {
    float: left;
    margin: 0;
    width: 49%;
    height: 56px;
    position: relative; }
    .contact-us-form ul.bdgt-type li.left {
      margin: 0 2% 0 0; }
    @media screen and (max-width: 580px) {
      .contact-us-form ul.bdgt-type li {
        width: 100%;
        height: 46px; }
        .contact-us-form ul.bdgt-type li.left {
          margin: 0 0 10px; } }
  .contact-us-form ul.bdgt-type label, .contact-us-form ul.bdgt-type input {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .contact-us-form ul.bdgt-type input[type="radio"] {
    opacity: 0.011;
    z-index: 20; }
  .contact-us-form ul.bdgt-type input[type="radio"]:checked + label {
    border: 3px solid #a072a5;
    color: #252728; }
    .contact-us-form ul.bdgt-type input[type="radio"]:checked + label label {
      padding: 16px 0;
      text-align: center;
      background: #ffffff;
      border: 3px solid #707071;
      cursor: pointer;
      z-index: 10; }
      .contact-us-form ul.bdgt-type input[type="radio"]:checked + label label.error {
        border: 3px solid #ffffff; }
      .contact-us-form ul.bdgt-type input[type="radio"]:checked + label label:hover {
        border: 3px solid #a072a5; }
      @media screen and (max-width: 910px) and (min-width: 810px) {
        .contact-us-form ul.bdgt-type input[type="radio"]:checked + label label {
          font-size: 14px; } }
      @media screen and (max-width: 821px) and (min-width: 811px) {
        .contact-us-form ul.bdgt-type input[type="radio"]:checked + label label {
          padding: 8px 0; } }
      @media screen and (max-width: 810px) and (min-width: 765px) {
        .contact-us-form ul.bdgt-type input[type="radio"]:checked + label label {
          font-size: 12px;
          padding: 18px 0; } }
  .contact-us-form ul.bdgt-type label {
    padding: 16px 0;
    text-align: center;
    background: #ffffff;
    border: 3px solid #707071;
    cursor: pointer;
    z-index: 10; }
    .contact-us-form ul.bdgt-type label.error {
      border: 3px solid #ffffff; }
    .contact-us-form ul.bdgt-type label:hover {
      border: 3px solid #a072a5; }
    @media screen and (max-width: 910px) and (min-width: 810px) {
      .contact-us-form ul.bdgt-type label {
        font-size: 14px; } }
    @media screen and (max-width: 810px) and (min-width: 765px) {
      .contact-us-form ul.bdgt-type label {
        font-size: 12px;
        padding: 18px 0; } }
    @media screen and (max-width: 580px) {
      .contact-us-form ul.bdgt-type label {
        padding: 12px 0; } }

.contact-us-form input.contact-us-submit:hover {
  background: none;
  color: #a072a5; }

@media screen and (max-width: 767px) {
  .contact-us-form input.cntc-halfw-inpt-left,
  .contact-us-form input.cntc-halfw-inpt-right,
  .contact-us-form ul.bdgt-type {
    width: 100%;
    margin: 10px 0;
    font-size: 14px; }
  .contact-us-form input.cntc-halfw-inpt-left,
  .contact-us-form input.cntc-halfw-inpt-right {
    padding: 10px; }
  .contact-us-form textarea.cntc-fullw-inpt {
    width: 100%;
    padding: 10px;
    font-size: 14px; }
  .contact-us-form select.cntc-halfw-inpt-right {
    width: 100%;
    display: inline-block;
    margin: 10px 0;
    padding: 10px;
    font-size: 14px; } }

@media screen and (max-width: 580px) {
  .contact-us-form .frm-bttn-wrapper {
    width: 100%; }
  .contact-us-form input.contact-us-submit {
    min-width: 100px;
    width: 100%;
    margin-top: 0; } }

@media screen and (max-width: 340px) {
  .contact-us-form input.contact-us-submit {
    max-width: 280px; } }

#warning {
  color: #e04444;
  text-align: center;
  margin: -20px 0 20px 0; }

.success {
  color: #707071;
  font-weight: normal;
  font-size: 20px;
  font-family: "proxima-nova";
  max-width: 1000px;
  line-height: 1.4; }

.error {
  outline: 2px solid #e04444; }

.spce-only-mbl-tw {
  display: inline-block; }

img.map-img-rplc {
  display: none;
  width: 100%; }

.max-container .hvr-sldr-cptn {
  margin: -80px auto 0 auto; }

.careers-itm-box {
  display: inline-block; }

.work-prllx-container {
  width: 100%; }

.case-prllx-container {
  width: 100%;
  text-align: center; }
  .case-prllx-container img {
    margin: 0 auto; }
  .case-prllx-container h2 {
    color: #ffffff; }
    .case-prllx-container h2 p {
      line-height: 1.6; }

.case-vocera-prllx {
  background: url("/img/case-studies/prllx-bgs/vocera.jpg?ver=2.5.5") center;
  background-attachment: fixed;
  background-size: cover; }

.case-silver-prllx {
  background: url("/img/case-studies/prllx-bgs/silverpeak.jpg?ver=2.5.5") center;
  background-attachment: fixed;
  background-size: cover; }

.case-icontrol-prllx {
  background: url("/img/case-studies/prllx-bgs/icontrol.jpg?ver=2.5.5") center;
  background-attachment: fixed;
  background-size: cover; }

.case-instantly-prllx {
  background: url("/img/case-studies/prllx-bgs/instantly.jpg?ver=2.5.5") center;
  background-attachment: fixed;
  background-size: cover; }

.case-hoover-prllx {
  background: url("/img/case-studies/prllx-bgs/hoover.jpg?ver=2.5.5") center;
  background-attachment: fixed;
  background-size: cover; }

.case-riverbed-prllx {
  background: url("/img/case-studies/prllx-bgs/webenertia_riverbed_bg.jpg?ver=2.5.5") center;
  background-attachment: fixed;
  background-size: cover; }

.case-acemetrix-prllx {
  background: url("/img/case-studies/acemetrix/webenertia_acemetrix_sitemap_bg.jpg?ver=2.5.5") center;
  background-attachment: fixed;
  background-size: cover; }

.case-hortonworks-prllx {
  background: url("/img/case-studies/hortonworks/webenertia_hortonworks_bg.jpg?ver=2.5.5") center;
  background-attachment: fixed;
  background-size: cover; }

.case-intel-prllx {
  background: url("/img/case-studies/intel/webenertia_intel_bg.jpg?ver=2.5.5") center;
  background-attachment: fixed;
  background-size: cover;
  padding-bottom: 0; }
  @media screen and (max-width: 580px) {
    .case-intel-prllx {
      padding-bottom: 0px !important; } }
  .case-intel-prllx .col {
    width: 44%;
    display: inline-block;
    vertical-align: top;
    padding: 60px 2% 0; }
    @media screen and (max-width: 900px) {
      .case-intel-prllx .col {
        display: block;
        width: 100%;
        padding-bottom: 40px; } }
    .case-intel-prllx .col img {
      margin: 0;
      width: 90%;
      max-width: 500px; }
    .case-intel-prllx .col h4 {
      border-bottom: solid 1px #707071;
      padding-bottom: 20px;
      margin-bottom: 20px; }
    .case-intel-prllx .col ol {
      margin-left: 20px; }
      .case-intel-prllx .col ol li {
        margin-bottom: 24px; }

.case-rae-prllx {
  background: url("/img/case-studies/prllx-bgs/rae.jpg?ver=2.5.5") center;
  background-attachment: fixed;
  background-size: cover; }

.cs-scial-media-fxd {
  float: right;
  right: 100px;
  top: 60px;
  width: 110px;
  min-height: 100px;
  z-index: 1000;
  text-align: right;
  position: relative; }

.cs-scial-media-fxd .cs-lnkdin {
  background: url(/img/case-studies/linkedin.png?ver=2.5.5) no-repeat;
  background-size: 100%;
  width: 60px;
  height: 60px;
  position: absolute;
  right: 0px;
  transition: all 0.4s ease-out;
  border: solid 3px #707071; }

.cs-scial-media-fxd .cs-lnkdin:hover {
  border: solid 3px #ef9123;
  width: 60px;
  height: 60px;
  z-index: 8; }

.cs-scial-media-fxd .cs-facebk {
  background: url(/img/case-studies/facebook.png?ver=2.5.5) no-repeat;
  background-size: 100%;
  width: 60px;
  height: 60px;
  position: absolute;
  right: 0px;
  top: 63px;
  transition: all 0.4s ease-out;
  border: solid 3px #707071; }

.cs-scial-media-fxd .cs-facebk:hover {
  border: solid 3px #ef9123;
  width: 60px;
  height: 60px;
  z-index: 8; }

.cs-scial-media-fxd .cs-twtr {
  background: url(/img/case-studies/twitter.png?ver=2.5.5) no-repeat;
  background-size: 100%;
  width: 60px;
  height: 60px;
  position: absolute;
  right: 0px;
  top: 126px;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  border: solid 3px #707071; }

.cs-scial-media-fxd .cs-twtr:hover {
  border: solid 3px #ef9123;
  width: 60px;
  height: 60px;
  z-index: 8; }

img.scl-icon-addths {
  max-width: 60px;
  border: solid 3px #707071 !important;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  z-index: 1; }

img.scl-icon-addths:hover {
  border: solid 3px #ef9123 !important;
  z-index: 8; }

.cntc-cnfrm {
  margin: 100px auto;
  max-width: 600px; }

.item .case-banner-left-box .request-services-work {
  margin-top: 30px; }

img.vcr_gif {
  width: 47%;
  position: absolute;
  bottom: 30%;
  left: 28%; }

img.animated-monstr {
  position: absolute;
  width: 48%;
  max-width: 290px;
  left: 50%;
  top: 38%; }

.cs-nav {
  width: 100%;
  padding: 40px 30px;
  background-color: #f9fafa;
  border-top: solid 1px #d6dede;
  border-bottom: solid 1px #d6dede;
  box-sizing: border-box; }

.cs-nav .prev {
  float: left; }

.cs-nav .next {
  float: right; }

.cs-nav .prev a,
.cs-nav .next a {
  color: #707071;
  text-transform: uppercase;
  text-decoration: none;
  font-family: proxima-nova;
  vertical-align: middle;
  font-size: 12px; }

.cs-nav .prev a span {
  margin: 0 6px 0 0; }

.cs-nav .next a span {
  margin: 0 0 0 6px; }

.cs-nav .prev a:hover,
.cs-nav .next a:hover {
  color: #ef9123; }

/* icontrol icons ----------------------------- */
.icntrl-icon-box {
  text-align: center;
  max-width: 540px;
  margin: 0 auto; }

.img-mngmnt-box {
  width: 13%;
  display: inline-block;
  margin: 14px 5%;
  border-radius: 50%;
  border: solid 1px #62b4e5;
  position: relative;
  overflow: hidden;
  transition: all .4s ease-in-out; }

.img-mngmnt-box img {
  width: 100%;
  border-style: none; }

.img-mngmnt-box:hover {
  background-color: #ffffff;
  transition: all .4s ease-in-out; }

.img-mngmnt-box:hover .wht-icn.a,
.img-mngmnt-box:hover .wht-icn.b,
.img-mngmnt-box:hover .wht-icn.c,
.img-mngmnt-box:hover .wht-icn.d,
.img-mngmnt-box:hover .wht-icn.f,
.img-mngmnt-box:hover .wht-icn.g,
.img-mngmnt-box:hover .wht-icn.h {
  opacity: 1 !important;
  animation: none; }

.img-mngmnt-box .wht-icn {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-border-radius: 50%; }

.img-mngmnt-box .wht-icn.a {
  background-color: #62b4e5;
  -webkit-animation: iconAnimation 5s infinite;
  animation: iconAnimation 5s infinite; }

.img-mngmnt-box .wht-icn.b {
  background-color: #62b4e5;
  -webkit-animation: iconAnimationOne 5s infinite;
  animation: iconAnimationOne 5s infinite; }

.img-mngmnt-box .wht-icn.c {
  background-color: #62b4e5;
  -webkit-animation: iconAnimationTwo 5s infinite;
  animation: iconAnimationTwo 5s infinite; }

.img-mngmnt-box .wht-icn.d {
  background-color: #62b4e5;
  -webkit-animation: iconAnimationThree 5s infinite;
  animation: iconAnimationThree 5s infinite; }

.img-mngmnt-box .wht-icn.e {
  background-color: #62b4e5;
  -webkit-animation: iconAnimationFour 5s infinite;
  animation: iconAnimationFour 5s infinite; }

.img-mngmnt-box .wht-icn.f {
  background-color: #62b4e5;
  -webkit-animation: iconAnimationFive 5s infinite;
  animation: iconAnimationFive 5s infinite; }

.img-mngmnt-box .wht-icn.g {
  background-color: #62b4e5;
  -webkit-animation: iconAnimationSix 5s infinite;
  animation: iconAnimationSix 5s infinite; }

.img-mngmnt-box .wht-icn.h {
  background-color: #62b4e5;
  -webkit-animation: iconAnimationSeven 5s infinite;
  animation: iconAnimationSeven 5s infinite; }

.not-found-box {
  background: #d6dede;
  padding: 90px 0;
  text-align: center; }

.not-found-box div {
  max-width: 1200px;
  margin: 14vh auto; }

.not-found-box h1 {
  color: #707071;
  font-size: 120px;
  margin-bottom: 0px; }

.not-found-box h2 {
  color: #707071;
  font-size: 40px;
  margin-bottom: 60px; }

.not-found-box a.request-services {
  margin: 10px auto 0 auto; }

.pstr-sdw-cncl {
  max-width: 566px; }

.cntc-tch-clmn .nav-social-box {
  text-align: left; }

.drplpster-hlf.btm-rltv {
  vertical-align: bottom;
  position: relative; }

h4.sltn-pkr {
  margin-bottom: 10px; }

h1.blk-shdw {
  text-shadow: 0 0 14px #252728; }

/*---------------------------------------------------------------*/
/* media queries css*/
/*---------------------------------------------------------------*/
@media screen and (min-width: 2300px) {
  .ea-mobil-txt-box {
    margin: 0 40% 0 0; } }

@media screen and (max-width: 1600px) {
  .cs-scial-media-fxd {
    right: 0px; } }

@media screen and (max-width: 1300px) {
  .footer_head {
    margin: 0 40px; }
  .max-wrapper {
    margin: 0 40px; }
  .half-width-box img {
    width: auto; }
  img.nmbr-grn {
    margin-right: 20px; }
  .tabs nav ul {
    margin: 0 20px; }
  .wrk-awrds-prllx h2 {
    font-size: 80px; }
  .wrk-awrds-prllx span {
    font-size: 100px;
    min-width: 150px; } }

@media screen and (max-width: 1200px) {
  .break-line {
    background-color: transparent; }
  .center-quote h2 {
    font-size: 26px; }
  .multiDicplLeft.only-desktop {
    display: none; }
  .multiDicplLeft.only-mobile {
    display: inline-block;
    margin: 0 0 -30px 0 !important; }
  .green-bubbles-wrapper {
    margin: 40px auto;
    max-width: 500px;
    display: block; }
  .multiDicpl-box {
    text-align: center;
    max-width: 900px;
    margin: 0 auto; }
  .multiDicplLeft {
    text-align: left;
    margin: 0;
    max-width: 500px; }
  .multiDicplRight {
    text-align: left;
    margin: 0 0 30px 0;
    max-width: 500px; }
  .multiDicplLeft div,
  .multiDicplRight div {
    margin: 30px 0; }
  .green-arrow-tab-rest span {
    font-size: 16px;
    margin: 4px 0 0 0; }
  .green-arrow-tab-first {
    width: 17%; }
  .green-arrow-tab-rest {
    width: 16%; }
  .green-arrow-tab-first span {
    font-size: 16px;
    margin: 4px 0 0 0; }
  img.hover-site-grphc {
    margin: 0 auto 0 auto;
    width: 100%; }
  .case-logo-each {
    width: 200px; }
  .case-top-wrapper h2 {
    font-size: 28px !important; }
  .awrds-list-clmn {
    width: 100%; }
  .awrds-yr-clmn {
    width: 28%; }
  .awrds-list-clmn-wrapper {
    width: 60%; }
  .max-container .hvr-sldr-cptn {
    margin: -60px auto 0 auto; } }

@media screen and (max-width: 1120px) {
  .technology-box-six {
    width: 100%;
    text-align: left; }
  .technology-box-six img {
    width: 100px;
    float: left;
    margin: 0 4% 0 0; }
  img.animated-monstr {
    display: none; } }

/* iPad Landscape Menu Fix */
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  .overlay ul.nav-sec {
    padding: 15px 0;
    margin: 20px auto; } }

@media screen and (max-width: 980px) {
  div.addrs-clmn {
    width: 48%;
    display: inline-block;
    vertical-align: top; }
  div.cntc-tch-clmn {
    width: 38%; }
  div.sn-js-img-box {
    width: 100%;
    margin: 60px 0 0 0; }
  .case-twothrd {
    width: 100%; }
  .case-onethrd {
    width: 100%;
    text-align: center; }
  .tchnlgy-wrapper {
    margin: 40px 0 0 0; }
  .technology-box {
    width: 100%;
    text-align: left; }
  .technology-box img {
    width: 100px;
    float: left;
    margin: 0 4% 0 0; }
  .technology-box-five {
    width: 100%;
    text-align: left; }
  .technology-box-five img {
    width: 100px;
    float: left;
    margin: 0 4% 0 0; }
  .case-thrffts {
    width: 100%; }
    .case-thrffts img {
      margin: 0 auto; }
  .case-twffts {
    width: 100%;
    margin: 0 auto;
    text-align: center; }
  .case-top-wrapper {
    padding: 40px 0 0 0; }
  .full-width-slider h2 {
    font-size: 26px; }
  .tabs {
    margin: 0 20px; }
  .rae-dvces {
    float: none;
    width: 100%;
    margin: 0 auto; }
  .case-rae-blprint {
    width: 100%;
    text-align: center; }
  .case-rae-blprint img {
    width: 100%;
    max-width: 512px;
    margin: 40px auto 0 auto !important; }
  .rae-strategy-box {
    width: 100%;
    margin: 0; }
  .rae-strategy-box img {
    width: 100%;
    max-width: 512px;
    margin: 40px auto 0 auto !important; }
  .drplpster-hlf {
    width: 100%; }
  .pstr-txt-box {
    margin: 0 auto; }
  .spce-only-mbl-neight {
    height: 60px;
    width: 100%; }
  .case-top-wrapper h1 {
    font-size: 30px !important; } }

@media screen and (max-width: 920px) {
  .career-grey-container.full-min-height {
    display: block; }
  .half-width-box {
    width: 100%; }
  .half-width-box img {
    width: 100%; }
  .half-width-box img {
    display: none; }
  .two-thirds-wrapper {
    width: 100%; }
  .green-arrow-tab-first {
    margin: 0 0 0 -6px; }
  .green-arrow-tab-rest {
    margin: 0 0 0 -6px; }
  .green-arrow-tab-rest span {
    display: none; }
  .green-arrow-tab-first span {
    display: none; }
  .tab-content-column {
    width: calc(100% - 100px);
    display: inline-block;
    vertical-align: top;
    margin: 0 60px; }
  img.nmbr-grn {
    margin-right: 10px;
    width: 100px; }
  .tabs nav ul {
    margin: 0 30px; }
  .spce-only-mbl-tw {
    height: 120px;
    width: 100%; }
  .ca-sj-box {
    border-right: none;
    width: 100%;
    text-align: center; }
  .two-thirds-wrapper {
    width: calc(100% - 10px) !important;
    display: inline-block;
    margin: 20px 0; }
  .cs-scial-media-fxd {
    display: none; } }

@media screen and (max-width: 780px) {
  .square-bubble {
    max-width: calc(93% - 150px); }
  .client-testimonial .rounded {
    width: 150px;
    height: 150px;
    margin-left: 0; }
  .square-bubble-txt h2 {
    font-size: 16px; }
  .overlay ul.nav-sec {
    min-width: 220px;
    max-width: 410px; }
  .overlay ul.nav-sec a {
    font-size: 13px; }
  .center-quote h2 {
    font-size: 20px; }
  img.nmbr-grn {
    width: 60px; }
  .tabs-content {
    margin: 0; }
  .case-info-half {
    width: 100%;
    padding: 20px 0 0 0;
    text-align: center; }
    .case-info-half img {
      margin: 0 auto; }
  .case-top-wrapper h2 {
    font-size: 22px !important; }
  .spce-only-mbl {
    height: 40px;
    width: 100%; }
  img.slvr-wrfrm {
    margin: 0; }
  .crsl-hm-wrppr {
    margin-top: 0; }
  .purple-graphic {
    width: 100%; }
  .purple-graphic-wrapper .nmbr-up {
    width: 50%; }
  .ca-sj-box {
    border-right: none;
    width: 100%;
    text-align: center; }
  .technology-box {
    border-bottom: solid 1px #707071;
    margin: 0 0 30px 0;
    padding: 0 0 30px 0; }
  .technology-box-five {
    border-bottom: solid 1px #707071;
    margin: 0 0 30px 0;
    padding: 0 0 30px 0; }
  .technology-box-six {
    border-bottom: solid 1px #707071;
    margin: 0 0 30px 0;
    padding: 0 0 30px 0; }
  .max-container .hvr-sldr-cptn {
    margin: -40px auto 0 auto; }
  .grybar-two-thrds {
    width: 100%;
    padding: 40px 0 0 0; }
  /*
  .grybar-one-thrd {
    width: 100%;
    padding: 40px 0 0 0;
  }
*/
  .case-vocera-prllx,
  .case-silver-prllx,
  .case-icontrol-prllx,
  .case-instantly-prllx,
  .case-hoover-prllx,
  .case-riverbed-prllx,
  .case-acemetrix-prllx,
  .case-hortonworks-prllx,
  .case-intel-prllx {
    background-attachment: scroll; }
  .mobil-top-paddng {
    padding-top: 40px;
    display: block; }
  .drpl-prllx-box {
    background-attachment: scroll;
    min-height: 400px; }
  .wrk-awrds-prllx {
    background-attachment: scroll; }
  div.lnkd-icon {
    background: url(/img/lnkd_icon_hover.png?ver=2.5.5);
    background-size: 100%; }
  .item .case-top-wrapper h1 {
    font-size: 32px !important; }
  .item .case-top-wrapper .case-banner-left-box {
    margin: 30px 0 0 0; }
  .case-top-wrapper h1 {
    font-size: 28px !important; }
  img.vcr_gif {
    display: none; }
  div.rae-ipad-gif {
    display: none; }
  .footer_head {
    margin: 0 20px; } }

@media screen and (max-width: 767px) {
  .one-third-box {
    width: 100%;
    text-align: center; }
  .square-bubble {
    max-width: 100%;
    display: block;
    padding: 40px 0;
    border-top: solid 8px #a3cf5f;
    border-left: none; }
  .case-top-wrapper {
    padding: 40px 0; }
  .case-banner-left-box {
    width: 100%; }
  .work-case-top-wrapper {
    padding: 40px 0 !important; }
  .case-banner-right-box {
    width: 60%;
    display: block;
    position: static;
    margin: 30px 0 -1px 0; }
  .full-width-slider h2 {
    font-size: 22px; }
  img.rounded {
    width: 33%;
    margin: 20px 0; }
  .ea-ticket-bg {
    background: none; }
  .ea-hrzntl-phn-box {
    display: none; }
  .ea-mobil-txt-box {
    width: 100%; }
  .ea-vrtcl-phn-box {
    float: none;
    width: 80%;
    max-width: 350px; }
  .awrds-yr-clmn {
    width: 100%; }
  .awrds-list-clmn-wrapper {
    width: 100%; }
  .wrk-awrds-prllx h2 {
    font-size: 50px;
    margin: 0 0 60px 0; }
  .wrk-awrds-prllx span {
    font-size: 70px;
    min-width: 100px; }
  .wrk-awrds-prllx {
    padding: 100px 0 60px 0; }
  .work-logo-wrapper {
    margin: 40px 60px; }
  .sldr-stlss .case-banner-right-box {
    display: none; }
  .overlay ul.nav-sec {
    margin: 15px auto;
    padding: 10px 0; }
  .client-testimonial {
    text-align: center; }
  .client-testimonial h4 {
    text-align: center; }
  a.nav-contact-us {
    max-width: 235px; }
  .not-found-box h1 {
    font-size: 80px; }
  .not-found-box h2 {
    font-size: 26px; }
  .not-found-box a.request-services-work {
    max-width: 270px; } }

@media screen and (max-width: 700px) {
  .crsl-hm-wrppr {
    margin-top: 0; } }

@media screen and (max-width: 580px) {
  .multiDicpl-box {
    margin: 0 40px; }
  .tab-content-column {
    margin: 0; }
  .green-arrow-tab-first {
    margin: 0 0 0 -4px;
    padding: 10px 2% 4px 2%;
    width: 16%; }
  .green-arrow-tab-rest {
    margin: 0 0 0 -4px;
    padding: 10px 2% 4px 2%;
    width: 16%; }
  div.addrs-clmn {
    width: 100%; }
  div.cntc-tch-clmn {
    width: 100%;
    border-left: none;
    border-top: solid 1px #707071;
    margin: 22px 0;
    padding: 14px 0 0 0; }
  div.sn-js-img-box {
    width: 100%;
    margin: 20px 0 0 0; }
  .case-logo-each {
    width: 150px; }
  .vcra-dgtl-dspl-box {
    background: none; }
  .tabs {
    margin: 0px; }
  .max-container .hvr-sldr-cptn {
    margin: -10px auto 0 auto; }
  input.carrers-input-two {
    margin: 14px 12px 14px 0; }
  .careers-itm-box {
    display: block;
    margin: 0 0 0 3px; }
  .top-section-wrapper p {
    font-size: 18px;
    line-height: 1.2; }
  .case-prllx-container {
    padding: 60px 0 90px 0; }
  .pstr-sdw-cncl {
    display: none; }
  .pstr-sdw-mbl {
    display: block; }
  ul.sec-nav li:first-child::after {
    margin: 2px 0 0 8px; }
  ul.sec-nav li {
    margin: 0 5px 0 0; }
  ul.sec-nav li::after {
    margin: 4px 0 0 9px; }
  .footer_head_wrapper.fixed {
    position: static !important; }
  .item .case-top-wrapper h1 {
    font-size: 24px !important; }
  .item .case-top-wrapper .case-banner-left-box {
    margin: 60px 0 0 0; }
  .case-banner-right-box {
    width: 80%; } }

@media screen and (max-width: 520px) {
  .work-logo-wrapper div {
    width: 100%;
    display: block; }
  .overlay ul.nav-sec {
    min-width: 220px;
    max-width: 260px; }
  .overlay ul.nav-sec li {
    display: block;
    border-right: none;
    margin: 0;
    padding: 0; }
  .no-mobil {
    display: none; }
  .footer_head .copyright {
    display: none; }
  .one-third-box {
    width: 100%; }
  .frm-bttn-wrapper {
    width: 100%; }
  .multiDicpl-box {
    margin: 0 20px; }
  .max-wrapper {
    margin: 0 20px; }
  .gmscm-cntr h2 {
    font-size: 20px;
    line-height: 1.4; }
  .drupal-pstr-box {
    width: 17.5%; }
  .wrk-awrds-prllx h2 {
    font-size: 36px;
    margin: 0 0 42px 0; }
  .wrk-awrds-prllx span {
    font-size: 42px;
    min-width: 60px; }
  .career-grey-container {
    max-height: 1000px; }
  .ca-sj-box h4 {
    font-size: 20px;
    margin: 10px 0 0 130px; }
  .case-rspnsv {
    padding: 40px 0; }
  .drpl-prllx-box {
    min-height: 200px; }
  .case-banner-right-box {
    width: 100%; }
  .cs-nav {
    padding: 30px 14px; } }

@media screen and (max-width: 420px) {
  .case-client-logo-box {
    display: block;
    min-height: 60px;
    width: 30%;
    border-right: none; }
  .square-bubble-txt {
    width: auto; }
  .case-banner-right-box {
    width: 100%; }
  input.input-email-list {
    width: 200px; } }

@media screen and (max-width: 767px), screen and (max-height: 680px) {
  .overlay ul.nav-sec {
    margin: 15px auto;
    padding: 10px 0; }
  a.nav-contact-us {
    margin: 10px auto 0 auto; } }

@media screen and (max-height: 430px) {
  a.nav-contact-us {
    margin: 5px auto 12px; }
  .overlay ul.nav-sec {
    display: none; } }

.blog-modern-comment {
  display: none !important; }

.hwx-canvas {
  width: 100%;
  height: auto;
  margin-top: 30px; }

.first-four .grid-container {
  padding: 0; }
  .first-four .grid-container .grid {
    padding-top: 0;
    padding-bottom: 0; }

.first-four li {
  display: none; }

.first-four li:nth-child(1),
.first-four li:nth-child(2),
.first-four li:nth-child(3),
.first-four li:nth-child(4) {
  display: block; }

.case-studies-title .first-four-only {
  display: none; }

@media screen and (max-width: 425px) {
  .team-member-box img {
    opacity: 1 !important; } }

.rae-systems-wrapper {
  position: relative;
  display: inline-block; }

.ipad-wrapper {
  max-width: 100%; }

.rae-ipad-gif img {
  position: absolute;
  top: 16%;
  left: 26%;
  width: 48% !important; }

.small-container {
  text-align: center;
  padding: 0 15px;
  max-width: 800px;
  display: block;
  font-size: 20px;
  margin: 0 auto; }
  .small-container .button-wrapper {
    padding: 9px 0 0; }
    .small-container .button-wrapper a.request-services {
      display: inline-block;
      vertical-align: top;
      margin: 0 4px;
      min-width: 270px; }
  @media screen and (max-width: 1023px) {
    .small-container {
      font-size: 18px; } }
  @media screen and (max-width: 767px) {
    .small-container {
      font-size: 16px; }
      .small-container .button-wrapper {
        padding: 0; }
        .small-container .button-wrapper a.request-services {
          display: block;
          min-width: 0;
          margin: 10px auto 0; } }

.bg-gray {
  background: #d6dede; }

.centered {
  text-align: center; }
  .centered .request-services {
    margin: 0 auto; }

.container {
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;
  font-size: 20px; }
  .container h3 {
    margin: 0 0 18px;
    letter-spacing: -1.3px; }
  @media screen and (max-width: 1023px) {
    .container {
      font-size: 18px; }
      .container h3 {
        font-size: 24px; } }
  @media screen and (max-width: 780px) {
    .container {
      padding: 0 20px; } }
  @media screen and (max-width: 767px) {
    .container {
      font-size: 16px; }
      .container h3 {
        margin: 0 0 12px; } }

.bottom-margin {
  margin-bottom: 32px; }

.grid-3-col {
  overflow: hidden;
  margin: 36px -15px 0;
  position: relative;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  color: #707071;
  font-weight: 300;
  font-size: 17px;
  line-height: 24px;
  font-family: 'Poppins', serif; }
  @media screen and (max-width: 1023px) {
    .grid-3-col {
      font-size: 14px;
      line-height: 20px; } }
  @media screen and (max-width: 767px) {
    .grid-3-col {
      display: block;
      margin: 20px -15px 0; } }
  .grid-3-col * {
    font-family: 'Poppins', serif; }
  .grid-3-col .col {
    padding: 11px 15px;
    width: 33.33%;
    position: relative; }
    .grid-3-col .col:before {
      content: '';
      position: absolute;
      top: -9999px;
      left: 0;
      bottom: -9999px;
      border-left: 1px solid #707071; }
    .grid-3-col .col > * {
      text-align: center; }
    .grid-3-col .col:first-child:before {
      display: none; }
    @media screen and (max-width: 767px) {
      .grid-3-col .col {
        width: 100%;
        max-width: 357px;
        margin: 0 auto;
        padding: 30px 15px; }
        .grid-3-col .col:before {
          border-left: none;
          top: 0;
          right: 0;
          bottom: auto;
          border-top: 1px solid #707071; } }
  .grid-3-col h4 {
    color: #ef9123;
    font: 300 40px/1 'Poppins', serif;
    margin: 0 0 14px;
    letter-spacing: -2px; }
    .grid-3-col h4 sup {
      font-size: 30%;
      vertical-align: top; }
    @media screen and (max-width: 1199px) {
      .grid-3-col h4 {
        font-size: 34px; } }
    @media screen and (max-width: 1023px) {
      .grid-3-col h4 {
        letter-spacing: -1px;
        font-size: 24px; } }
  .grid-3-col p {
    margin: 0; }

.custom-image-block {
  position: relative;
  overflow: hidden;
  text-align: left !important; }
  .custom-image-block img {
    max-width: none; }
  .custom-image-block .text {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 65%; }
  .custom-image-block p a {
    color: #707071;
    text-decoration: none; }
    .custom-image-block p a span {
      color: #e74769;
      font-size: 14px;
      line-height: 24px; }
  .custom-image-block h4 {
    font-size: 34px;
    margin: 0 0 17px; }
  @media screen and (max-width: 1199px) {
    .custom-image-block img {
      width: 300px;
      height: auto; }
    .custom-image-block h4 {
      font-size: 30px;
      margin: 0 0 7px; }
    .custom-image-block p {
      font-size: 15px; } }
  @media screen and (max-width: 1023px) {
    .custom-image-block {
      margin: 0 -8px 0 0; }
      .custom-image-block .text {
        max-width: 72%; }
      .custom-image-block h4 {
        font-size: 26px; }
      .custom-image-block p {
        margin: 17px 0 0 44px; } }
  @media screen and (max-width: 767px) {
    .custom-image-block {
      padding: 17px 0 0;
      margin: 0; }
      .custom-image-block p {
        display: block;
        margin: 0 0 0 35px; }
      .custom-image-block h4 {
        margin: 0 0 9px; }
      .custom-image-block .text {
        max-width: 73%;
        top: 24px; } }

.location-section {
  overflow: hidden;
  text-align: center; }
  .location-section p {
    margin: 0;
    font-size: 20px; }
  .location-section .box {
    float: left;
    width: 50%; }
    @media screen and (max-width: 767px) {
      .location-section .box {
        width: 100%;
        float: none; } }
  .location-section a {
    overflow: hidden;
    display: block;
    background: #252728;
    text-decoration: none;
    color: #ffffff;
    position: relative; }
    .location-section a:hover img {
      opacity: 0.7; }
    @media screen and (max-width: 1023px) {
      .location-section a {
        height: 300px; } }
    @media screen and (max-width: 767px) {
      .location-section a {
        height: 240px; } }
  .location-section img {
    width: 100%;
    max-width: none;
    opacity: 0.8;
    transition: opacity 0.3s ease; }
    @media screen and (max-width: 1023px) {
      .location-section img {
        height: 100%;
        width: auto;
        position: relative;
        left: 50%;
        transform: translateX(-50%); } }
    @media screen and (max-width: 767px) {
      .location-section img {
        width: 100%;
        height: auto;
        left: 0;
        top: 50%;
        transform: translateY(-50%); } }
    @media screen and (max-width: 479px) {
      .location-section img {
        height: 112%;
        width: auto;
        left: 50%;
        transform: translate(-50%, -50%); } }
  .location-section .anim-btn {
    position: absolute;
    right: 20px;
    bottom: 20px; }
  .location-section .text {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    z-index: 2;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  .location-section h3 {
    color: #ffffff;
    margin: 0 0 10px;
    letter-spacing: -1.3px;
    font: normal 30px/1.2 'Poppins', serif; }
  @media screen and (max-width: 1023px) {
    .location-section h3 {
      font-size: 24px; } }

.scroll-top-btn {
  position: fixed;
  z-index: 10;
  right: 20px;
  bottom: 20px;
  width: 47px;
  height: 47px;
  text-align: center;
  border-radius: 2px;
  overflow: hidden;
  background: rgba(43, 51, 55, 0.2);
  text-decoration: none;
  line-height: 47px;
  color: #ffffff;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
  display: none; }
  .scroll-top-btn svg {
    fill: #ffffff;
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    width: 18px;
    height: 19px; }
  .scroll-top-btn:hover {
    text-decoration: none;
    background: rgba(43, 51, 55, 0.6); }
  @media screen and (max-width: 1023px) {
    .scroll-top-btn {
      display: none !important; } }

.drupal-col {
  width: 33%;
  float: right;
  display: block; }
  .drupal-col img {
    margin: 0 auto;
    float: left; }
  @media screen and (max-width: 1224px) {
    .drupal-col {
      width: 25%; }
      .drupal-col img {
        float: none; } }
  @media screen and (max-width: 580px) {
    .drupal-col {
      width: 100%;
      float: none;
      margin: 10px auto;
      overflow: hidden;
      text-align: center; }
      .drupal-col img {
        display: inline-block;
        margin: 0 10px; } }

.no-max-width {
  max-width: none !important; }

.newest-case-studies .item > div {
  height: 405px; }
  @media screen and (max-width: 767px) {
    .newest-case-studies .item > div {
      height: auto; } }

@media screen and (min-width: 768px) {
  .newest-case-studies .item .case-top-wrapper {
    padding: 0 !important;
    top: 50%;
    transform: translateY(-50%); } }

@media screen and (max-width: 780px) {
  .newest-case-studies .item .case-top-wrapper {
    margin: 0px 40px; } }

@media screen and (max-width: 767px) {
  .newest-case-studies .item .case-top-wrapper {
    padding: 40px 0; } }

@media screen and (max-width: 520px) {
  .newest-case-studies .item .case-top-wrapper {
    margin: 0px 20px; } }

.newest-case-studies .item .case-top-wrapper .case-banner-left-box {
  margin-top: 0; }

.newest-case-studies .item .case-top-wrapper h3 {
  font-weight: 300 !important;
  font-size: 38px !important; }
  @media screen and (max-width: 980px) {
    .newest-case-studies .item .case-top-wrapper h3 {
      font-size: 30px !important; } }
  @media screen and (max-width: 780px) {
    .newest-case-studies .item .case-top-wrapper h3 {
      font-size: 26px !important; } }
  @media screen and (max-width: 520px) {
    .newest-case-studies .item .case-top-wrapper h3 {
      font-size: 24px !important; } }

.newest-case-studies .item .case-top-wrapper a.request-services {
  margin-bottom: 0; }

.newest-case-studies .item .case-study-mcafee-top:before {
  content: none;
  display: none; }

.newest-case-studies .item .case-study-mcafee-top .case-top-wrapper {
  min-height: 0 !important;
  top: auto;
  transform: none; }
  @media screen and (max-width: 1200px) {
    .newest-case-studies .item .case-study-mcafee-top .case-top-wrapper {
      padding: 30px 0 !important; } }
  @media screen and (max-width: 1000px) {
    .newest-case-studies .item .case-study-mcafee-top .case-top-wrapper {
      padding: 40px 0 !important; } }
  @media screen and (max-width: 900px) {
    .newest-case-studies .item .case-study-mcafee-top .case-top-wrapper {
      padding: 50px 0 !important; } }
  @media screen and (max-width: 767px) {
    .newest-case-studies .item .case-study-mcafee-top .case-top-wrapper {
      padding: 40px 0 0 !important; } }
  .newest-case-studies .item .case-study-mcafee-top .case-top-wrapper .case-banner-left-box {
    display: inline-block;
    vertical-align: middle; }
    @media screen and (max-width: 767px) {
      .newest-case-studies .item .case-study-mcafee-top .case-top-wrapper .case-banner-left-box {
        width: 52%; } }
    @media screen and (max-width: 522px) {
      .newest-case-studies .item .case-study-mcafee-top .case-top-wrapper .case-banner-left-box {
        display: block;
        width: 100%; } }
  .newest-case-studies .item .case-study-mcafee-top .case-top-wrapper .case-banner-right-box {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    bottom: auto;
    right: auto;
    transform: none; }
    @media screen and (max-width: 767px) {
      .newest-case-studies .item .case-study-mcafee-top .case-top-wrapper .case-banner-right-box {
        width: 46%;
        margin: 0; } }
    @media screen and (max-width: 522px) {
      .newest-case-studies .item .case-study-mcafee-top .case-top-wrapper .case-banner-right-box {
        display: block;
        width: 100%; } }

.item .case-study-sandhill-top {
  background: url(/img/case-studies/sandhill/SH_background.jpg) no-repeat 50% 50%;
  background-size: cover;
  height: 440px; }
  @media screen and (max-width: 767px) {
    .item .case-study-sandhill-top {
      height: 410px;
      background-position: 60% 50%; } }
  .item .case-study-sandhill-top .case-top-wrapper {
    padding-top: 80px;
    min-height: 0; }
    @media screen and (max-width: 1023px) {
      .item .case-study-sandhill-top .case-top-wrapper {
        padding-top: 109px; } }
    @media screen and (max-width: 767px) {
      .item .case-study-sandhill-top .case-top-wrapper {
        padding-top: 62px; } }
    @media screen and (max-width: 580px) {
      .item .case-study-sandhill-top .case-top-wrapper {
        padding-top: 37px; } }

.item .case-study-riverbed-rebrand-top {
  background: url(/img/case-studies/riverbed_re_brand/riv_background.jpg) no-repeat 50% 50%;
  background-size: cover;
  height: 440px; }
  @media screen and (max-width: 767px) {
    .item .case-study-riverbed-rebrand-top {
      height: 410px;
      background-position: 60% 50%; } }
  .item .case-study-riverbed-rebrand-top .case-top-wrapper {
    padding-top: 80px;
    min-height: 0; }
    @media screen and (max-width: 1023px) {
      .item .case-study-riverbed-rebrand-top .case-top-wrapper {
        padding-top: 109px; } }
    @media screen and (max-width: 767px) {
      .item .case-study-riverbed-rebrand-top .case-top-wrapper {
        padding-top: 62px; } }
    @media screen and (max-width: 580px) {
      .item .case-study-riverbed-rebrand-top .case-top-wrapper {
        padding-top: 37px; } }

#page_wrapper > img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1; }

@media screen and (max-width: 768px) {
  .fancybox-slide {
    padding-left: 0;
    padding-right: 0; } }

.stay-connected-container {
  text-align: center;
  align-items: stretch; }
  @media screen and (max-width: 768px) {
    .stay-connected-container {
      display: block; } }
  .stay-connected-container .half-width-box {
    display: block;
    position: relative;
    margin: 0; }
    @media screen and (max-width: 768px) {
      .stay-connected-container .half-width-box .v-align {
        position: relative;
        top: auto;
        left: auto;
        transform: none;
        padding: 40px; } }
    .stay-connected-container .half-width-box .mailing-box {
      padding-left: 60px;
      padding-right: 60px;
      max-width: 820px; }
      @media screen and (max-width: 768px) {
        .stay-connected-container .half-width-box .mailing-box {
          padding: 40px;
          max-width: 100%; } }
      @media screen and (max-width: 580px) {
        .stay-connected-container .half-width-box .mailing-box {
          padding: 20px; } }
      .stay-connected-container .half-width-box .mailing-box h2 {
        color: #ffffff; }
      .stay-connected-container .half-width-box .mailing-box p {
        color: #ffffff;
        margin: 26px auto; }
      .stay-connected-container .half-width-box .mailing-box #mc_embed_signup .mc-field-group {
        margin: 0 auto;
        max-width: 240px;
        padding: 0 50px 0 0; }
      .stay-connected-container .half-width-box .mailing-box #mc_embed_signup button {
        width: 50px;
        border: 1px solid;
        background: transparent;
        text-transform: uppercase; }
    .stay-connected-container .half-width-box .social-box {
      padding-left: 60px;
      padding-right: 60px;
      max-width: 700px; }
      @media screen and (max-width: 768px) {
        .stay-connected-container .half-width-box .social-box {
          padding: 40px; } }
      @media screen and (max-width: 580px) {
        .stay-connected-container .half-width-box .social-box {
          padding: 20px; } }
      .stay-connected-container .half-width-box .social-box p {
        margin: 26px auto; }

.thank-you-first-four {
  padding-bottom: 40px; }
  .thank-you-first-four .grid-container {
    background-color: #ffffff; }

.max-wrapper .centered {
  margin-bottom: 20px; }

a.thank-you-view-all {
  background: #a072a5;
  max-width: 280px;
  border: solid 3px #a072a5;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  color: #ffffff;
  text-decoration: none;
  padding: 11px 0;
  text-align: center;
  margin: 0 auto;
  text-transform: uppercase !important;
  font-size: 12px;
  display: block;
  font-weight: 600;
  letter-spacing: 1px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  font-family: proxima-nova !important; }

a.thank-you-view-all:hover {
  background: none;
  color: #a072a5; }
