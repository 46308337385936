.purple-graphic-wrapper {
  max-width: 1200px;
  margin: 60px auto 24px auto;
  position: relative;

  .nmbr-up {
    position: absolute;
    top: 0;
    left: -2px;
    width: 25%;

    img {
      width: 100%;
      padding-right: 13px;
      padding-top: 5px;
    }
  }
}

.purple-graphic {
  width: 50%;
  display: inline-block;
  margin: 0 -2px;

  img {
    width: 100%;
  }
}

.ca-sj-box {
  width: 300px;
  background: url(/img/ca.png#{$assetVersion}) no-repeat center top;
  background-size: 270px;
  height: 140px;
  border-right: 1px solid $webePurple;
  margin: 26px 0;
  display: inline-block;
  vertical-align: top;

  h4 {
    margin: 0 0 0 130px;
    line-height: 1;
    padding: 8px 0;
    letter-spacing: -1.1px;
  }

  p {
    margin: 8px 0 0 134px;
    font-size: 16px;
  }

}


div.lnkd-icon {
  width: 40px;
  height: 40px;
  position: absolute;
  z-index: 1000;
  left: 20px;
  top: -70px;
  cursor: pointer;
}

.team-member-box:hover div.lnkd-icon {
  background: url(/img/lnkd_icon_hover.png#{$assetVersion});
  background-size: 100%;
}

.careers {
  background-color: $lightGrey;
  overflow: hidden;
  display: flex;
  align-items: center;
  @media screen and (max-width: 992px) {
    display: block;
  }

  .box {
    float: left;
    width: 50%;
    overflow: hidden;
    height: 100%;
    margin: 0;

    img {
      width: 100%;
    }

    @media screen and (max-width: 992px) {
      margin: 0 auto;
      float: none;
      width: 100%;
      padding: 60px 40px;
    }

    a.request-services {
      margin: 0 auto;
    }

    .careers-box {
      margin: 0px auto;
      padding:0px 60px;
      max-width: 800px;
      text-align: center;
      position: relative;
      @media screen and (max-width: 992px) {
        padding: 0px;
        max-width: 100%;
      }

      p {
        margin: 26px auto;
      }

    }

  }

}

.pstr-sdw-mbl {
  display: none;
}

.what-we-like a {
  color: $darkGrey;
  text-decoration: none;
}

.what-we-like a:hover {
  color: $darkGrey;
  text-decoration: underline;
}

.team-member-box {
  background-color: $webeBlack;
  position: relative;

  img {
    width: 100%;
  }
}

.team-info-box {
  padding: 20px 16px;
  min-height: 70px;
  position: relative;
  box-sizing: content-box;

  h5 {
    color: $white;
    font-weight: 300;
    font-size: 16px;
  }

  p {
    margin: 4px 0;
    color: $darkGrey;
    font-size: 16px;
  }

}


/*---------------------------------------------------------------*/
/* img grid css*/
/*---------------------------------------------------------------*/
#grid-wrapper {
  margin: 0;
  position: relative;
}

.grid-row {
  width: 100%;
  position: relative;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  div.wefade {
    position: relative;
    flex-grow: 1;
    font-size: 0;

    img {
      width: 100%;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-transition: opacity 1.5s ease-in-out;
      -moz-transition: opacity 1.5s ease-in-out;
      -o-transition: opacity 1.5s ease-in-out;
      transition: opacity 1.5s ease-in-out;

      &.active {
        position: relative;
        opacity: 1;
      }
    }
  }
}


.leadership-box {
  text-transform: uppercase;
  background: $darkGrey;
  min-height: 44px;
  padding: 14px 16px;

  p {
    color: $white;
    font-size: 12px;
    letter-spacing: 1px;
    margin-bottom: 0px;
  }
}

.hrz-line {
  width: 100%;
  height: 2px;
  border-top: solid 1px $darkGrey;
}
