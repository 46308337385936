.bottom-container {
  text-align: center;
  padding: 94px 0 40px 0;
  background: $white;

  @media screen and (max-width: 1300px) {
    padding: 60px 40px 10px;
  }

  @media screen and (max-width: 520px) {
    padding: 60px 20px 0;
  }

  &.full-min-height {
    padding-top: 0;
    padding-bottom: 0;

    .request-services {
      margin: 0 auto;
    }

    @media screen and (max-width: 767px) {
      padding: 40px;
    }
  }


  &.clients-home {
    background: transparent;
    padding: 0 0 10px;
  }

  &.clients-creative {
    background: transparent;
    padding: 0 0 30px;
  }

  p {
    margin: 26px auto;
  }

  .request-services {
    max-width: 300px;
    margin: 0 auto 60px auto;
  }

}
